import styled from "styled-components"

import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import { getFontColorForBackgroundContrast } from "QuorumGrassroots/styled-components/helperFunctions"
import { constants } from "QuorumGrassroots/constants"

export const Container = styleWithOrgDesign(styled.div<{ isSingleColumnLayout: boolean }>`
    --custom-font-color: ${(props) =>
        getFontColorForBackgroundContrast(props.organizationDesign.primary_color, constants.colors.gray[7])};
    --custom-border-color: ${(props) =>
        getFontColorForBackgroundContrast(props.organizationDesign.primary_color, constants.colors.gray[7])};

    width: 100%;

    ${(props) =>
        props.isSingleColumnLayout &&
        `
        max-width: 448px;
        --custom-font-color: ${constants.colors.gray[8]};
        --custom-border-color: ${constants.colors.gray[5]};
    `}

    display: flex;
    flex-direction: column;
    gap: 12px;

    & .mantine-InputWrapper-label {
        color: var(--custom-font-color);
        font-family: "${(props) => props.organizationDesign.font_family}";
    }
`)

export const PersonalStory = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;
`

export const IconContainer = styled.div`
    width: 3rem;
    height: 3rem;
    margin-bottom: -0.5rem;
    border-radius: 3rem;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(255, 99, 71, 0.2);
    color: rgba(255, 99, 71, 1);
`

export const Subtitle = styled.h3`
    margin: 0;
    font-size: 16px;
    font-weight: 500;
`

export const StyledButton = styled.button`
    min-width: 145px;
    border: 0;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    background: black;
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    font-size: 16px;
    font-weight: 500;
`
