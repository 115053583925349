import { MultiSelect as MantineMultiSelect } from "@mantine/core"
import styled from "styled-components"

import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"

export const MultiSelect = styleWithOrgDesign(styled(MantineMultiSelect)`
    width: 100%;

    font-size: 14px;
    color: var(--custom-font-color);
    border-color: var(--custom-border-color);

    & .mantine-MultiSelect-input {
        border-radius: 8px;
        background-color: white;
        color: var(--custom-font-color);

        min-height: 48px;
    }

    & .mantine-Input-rightSection svg {
        color: var(--custom-font-color) !important;
    }
`)
