import React, { useRef, useState } from "react"
import { Trans } from "react-i18next"
import { Button, Loader, Popover, Textarea } from "@mantine/core"

import { StyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"
import {
    StyledAnchor,
    StyledRecordButton,
    StyledRecordingArea,
    StyledTermsOfServiceCheckbox,
    StyledTermsOfServiceContainer,
    StyledPopoverDropdown,
    StyledPopoverTitle,
} from "QuorumGrassroots/campaign-forms/components/StoryForm/StoryForm.styles"
import { updateSupporterHasParticipatedInCampaigns, postGrassrootsAction } from "QuorumGrassroots/services"
import { StyledContrastText } from "QuorumGrassroots/styled-components/components/StyledContrastText"
import { useNavigate } from "react-router-dom"
import { useMutation } from "@tanstack/react-query"
import { Campaign } from "@/types/djangio"
import BACKENDERROR from "app/static/frontend/imports/backenderror"
import { swalConfigs } from "QuorumGrassroots/swalConfigs"
import { getPointsForCampaign } from "QuorumGrassroots/campaign-forms/helpers"
import { withGamificationModal } from "QuorumGrassroots/campaign-forms/wrappers/withGamificationModal"
import { getPathObject, requiredFieldValidation, runUserJavascript } from "QuorumGrassroots/helperFunctions"
import { useGrassrootsActionCenterSettings } from "QuorumGrassroots/services/grassrootsActionCenterSettings"
import { getReduxlessThanksDisplayProps } from "QuorumGrassroots/widgets/ReusableComponents/WidgetWrappers/ThankableWrapper"
import { ThanksDisplay } from "QuorumGrassroots/widgets/ReusableComponents/ThanksDisplay"
import { VideoPreview } from "QuorumGrassroots/campaign-forms/components/StoryForm/VideoPreview/VideoPreview"
import { RecorderModal } from "QuorumGrassroots/campaign-forms/components/StoryForm/RecorderModal/RecorderModal"
import { RecorderStatus, useRecorder } from "QuorumGrassroots/framework/hooks/useRecorder/useRecorder"

const { ShareStoryType } = DjangIO.app.grassroots.campaign.types

const STORY_TEXT_SESSION_KEY = "storyText"

interface Video {
    share_story_video_uuid: string
}

const getTextFromSession = (campaignId: string | undefined) => {
    const sessionTextData = sessionStorage.getItem(STORY_TEXT_SESSION_KEY) ?? "{}"
    const { text, campaignId: sessionCampaignId } = JSON.parse(sessionTextData)
    return !!campaignId && campaignId === sessionCampaignId ? text : ""
}

const checkHasVideoEnabled = (storyType: number) => {
    return ([ShareStoryType.text_and_video.value, ShareStoryType.video.value] as number[]).includes(storyType)
}

export const submitStory = async ({ campaign, text, video }: { campaign: Campaign; text: string; video: Video }) => {
    const hasVideoEnabled = checkHasVideoEnabled(campaign.share_story_type)
    const body = {
        campaign: DjangIO.app.grassroots.campaign.models.Campaign.resourceUriFromId(campaign.id),
        supporter: DjangIO.app.grassroots.models.Supporter.resourceUriFromId(window.userdata.id),
        organization: DjangIO.app.userdata.models.Organization.resourceUriFromId(window.organization.id),
        supporter_action_type: campaign.campaign_type,
        action_center: window.action_center_settings.resource_uri,
        points_earned: getPointsForCampaign(campaign),
        ...(hasVideoEnabled && Boolean(video.share_story_video_uuid) ? video : {}),
        ...(text ? { text } : {}),
    }
    return postGrassrootsAction(body)
}

export const StoryForm = withGamificationModal(({ t, ...props }) => {
    const navigate = useNavigate()

    const actionCenterSettings = useGrassrootsActionCenterSettings(props.campaign.action_center_id)

    const [checked, setChecked] = useState(false)
    const [isFinished, setIsFinished] = useState(false)
    const [isRecordModalOpen, setIsRecordModalOpen] = useState(false)
    const [text, setText] = React.useState(getTextFromSession(props.campaign?.id) ?? "")

    const textFieldDirtyRef = useRef(false)
    const isTextFieldDirty = textFieldDirtyRef.current

    const [recorderStatus, recorder, video] = useRecorder(isRecordModalOpen)
    const loadingStatus = [RecorderStatus.LOADING, RecorderStatus.INITIALIZED]
    const doneStatus = [RecorderStatus.PUBLISHED, RecorderStatus.PROCESSED]
    const hiddenModalStatus = [...loadingStatus, ...doneStatus]
    const isOpeningCamera = isRecordModalOpen

    const hasRecordedVideo = video?.videoId
    const hasVideoEnabled = checkHasVideoEnabled(props.campaign.share_story_type)
    const hasOnlyText = props.campaign.share_story_type === ShareStoryType.text.value
    const hasOnlyVideo = props.campaign.share_story_type === ShareStoryType.video.value
    const hasVideoAndText = props.campaign.share_story_type === ShareStoryType.text_and_video.value
    const hasTextEnabled = hasOnlyText || hasVideoAndText

    const submitMutation = useMutation({
        mutationFn: () =>
            submitStory({
                campaign: props.campaign,
                text,
                video: {
                    share_story_video_uuid: video?.videoId,
                },
            }),
        onError: (error) => {
            setIsFinished(true)
            BACKENDERROR(error, swalConfigs.postGrassrootsActionError, true, false)
            throw error
        },
        onSuccess: () => handleSuccess(),
    })

    const handleResetRecording = () => {
        return
    }

    const handleSuccess = () => {
        props.showGamificationLevelUpModalIfEnabled()
        runUserJavascript(props.campaign.custom_thank_you_javascript)
        const redirectDetails =
            props.campaign.success_redirection_url && getPathObject(props.campaign.success_redirection_url)
        if (!props.isEmbedded && redirectDetails) {
            if (redirectDetails.isInternal) {
                navigate(redirectDetails.url)
            } else {
                window.swal({ icon: "success", title: "Thank you! You are now being redirected..." })
                window.location.assign(redirectDetails.url)
            }
        } else {
            setIsFinished(true)
        }
        sessionStorage.removeItem(STORY_TEXT_SESSION_KEY)
        handleResetRecording()
        updateSupporterHasParticipatedInCampaigns()
    }

    const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        textFieldDirtyRef.current = true
        setText(e.target.value)
    }

    const handleRecordClick = () => {
        setIsRecordModalOpen(true)
    }

    const termsOfService = (
        <StyledAnchor href="https://www.quorum.us/global-terms" target="_blank" rel="noopener noreferrer">
            {t("terms_of_service")}
        </StyledAnchor>
    )

    const privacyPolicy = (
        <StyledAnchor href="https://www.quorum.us/privacy-policy/" target="_blank" rel="noopener noreferrer">
            {t("footer.privacy_policy")}
        </StyledAnchor>
    )

    const VideoTermsOfUse = (props) => (
        <Popover width={320}>
            <Popover.Target>
                <StyledAnchor>{props.children}</StyledAnchor>
            </Popover.Target>
            <StyledPopoverDropdown>
                <StyledPopoverTitle>Content Submission Disclaimer </StyledPopoverTitle>
                <span>
                    By recording and uploading your video through this platform and clicking "Send," you grant
                    permission to CameraTag and its provider, Danzig Media; Quorum Grassroots (a product of Quorum
                    Analytics LLC); and the client on whose behalf you are submitting the video to use, share, and
                    publish your submitted content publicly. This includes, but is not limited to, sharing your video,
                    photo, image, or story on websites, social media, or other public platforms as part of their
                    campaigns. You acknowledge that CameraTag and Danzig Media may share your content with the
                    organization to which you are submitting, and all entities listed may use and distribute your
                    content publicly. By clicking "Send," you confirm that you have read, understood, and agreed to
                    CameraTag’s
                    <a href="https://www.cameratag.com/tos" target="blank">
                        {" "}
                        terms of service
                    </a>
                    , and you accept that once shared publicly, your content may be accessed by the general public.
                </span>
            </StyledPopoverDropdown>
        </Popover>
    )

    if (isFinished) {
        const pointsEarned = getPointsForCampaign(props.campaign)
        const thankableProps = getReduxlessThanksDisplayProps(
            actionCenterSettings,
            props.campaign,
            props.isEmbedded,
            pointsEarned,
        )

        return <ThanksDisplay {...thankableProps} />
    }

    return (
        <div className="story-form">
            {hasVideoEnabled && (
                <StyledRecordingArea hasRecordedVideo={hasRecordedVideo}>
                    {hasRecordedVideo ? (
                        <VideoPreview
                            resetRecording={handleResetRecording}
                            campaign={props.campaign}
                            t={t}
                            video={video}
                            recorderStatus={recorderStatus}
                        />
                    ) : (
                        <StyledRecordButton
                            isOpeningCamera={isOpeningCamera}
                            type="button"
                            onClick={handleRecordClick}
                            data-cy="record-story"
                        >
                            {isOpeningCamera ? (
                                <>
                                    <Loader size={14} /> Opening camera...
                                </>
                            ) : (
                                <>
                                    <i className={"fa fa-video-camera"} /> Record
                                </>
                            )}
                        </StyledRecordButton>
                    )}
                </StyledRecordingArea>
            )}
            {hasTextEnabled && (
                <Textarea
                    label={t("campaign.share.text_label")}
                    placeholder={t("campaign.share.text_placeholder")}
                    value={text}
                    autosize
                    minRows={2}
                    maxRows={8}
                    onChange={handleTextChange}
                    error={hasOnlyText && isTextFieldDirty ? requiredFieldValidation(text) : ""}
                />
            )}
            {hasVideoEnabled && (
                <StyledTermsOfServiceContainer>
                    <StyledTermsOfServiceCheckbox
                        checked={checked}
                        onChange={(event) => setChecked(event.currentTarget.checked)}
                        data-cy="story-terms-of-service"
                    />
                    <StyledContrastText isCampaignPage>
                        <Trans
                            i18nKey="campaign.share.privacy_policy_terms_service"
                            values={{
                                terms_of_service: t("terms_of_service"),
                                privacy_policy: t("footer.privacy_policy"),
                                video_terms_of_use: t("video_terms_of_use"),
                            }}
                            components={[
                                termsOfService,
                                privacyPolicy,
                                <VideoTermsOfUse>{t("video_terms_of_use")}</VideoTermsOfUse>,
                            ]}
                        />
                    </StyledContrastText>
                </StyledTermsOfServiceContainer>
            )}
            <StyledButton
                data-cy="submit"
                onClick={submitMutation.mutate}
                disabled={
                    submitMutation.isLoading ||
                    (hasVideoEnabled && (!checked || !hasRecordedVideo)) ||
                    (hasOnlyText && !text) ||
                    (hasVideoAndText && !text && !hasRecordedVideo) ||
                    (hasOnlyVideo && !hasRecordedVideo)
                }
                isCampaignPage
                activateNGGTheme
            >
                {submitMutation.isLoading ? t("form.submitting") : t("form.submit_form")}
            </StyledButton>

            <RecorderModal
                isOpened={isRecordModalOpen && !hiddenModalStatus.includes(recorderStatus)}
                onClose={() => setIsRecordModalOpen(false)}
                recorder={recorder}
                recorderStatus={recorderStatus}
            />
        </div>
    )
})
