import React, { useMemo, useState } from "react"
import { useForm } from "QuorumGrassroots/campaign-forms/hooks/useForm"
import { QuestionsContainer } from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/components/QuestionsContainer"
import { InformationContainer } from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/components/InformationContainer"
import {
    getFormDefaultValues,
    PromptAndResponseCampaignForm,
    validateFormValues,
} from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/PersonalizedMessages.helper"
import { SendMessageContainer } from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/components/SendMessageContainer"
import { Container } from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/PersonalizedMessages.styles"
import { usePersonalizedCampaignGetCampaignMessages } from "QuorumGrassroots/services"
import { StyledContrastText } from "QuorumGrassroots/styled-components/components/StyledContrastText"
import { isFeatureEnabled } from "shared/featureflags/helperFunctions"

export enum Contents {
    information,
    questions,
    sendMessage,
}

const CONTENTS_MAPPER = {
    [Contents.information]: InformationContainer,
    [Contents.questions]: QuestionsContainer,
    [Contents.sendMessage]: SendMessageContainer,
}

export const PersonalizedMessages = (props) => {
    const [currentContentName, setCurrentContentName] = useState(
        isFeatureEnabled("ff_ngg_personalized_messages_design") ? Contents.information : Contents.questions,
    )
    const messagesQuery = usePersonalizedCampaignGetCampaignMessages(String(props.campaign.id))

    const questions = useMemo(() => {
        return props?.campaign?._extra?.personalized_campaign_pages?.[0] ?? []
    }, [props?.campaign?._extra?.personalized_campaign_pages])

    const form = useForm<PromptAndResponseCampaignForm>(
        getFormDefaultValues(props?.campaign),
        (values) => validateFormValues(values, questions),
        true,
    )

    const ContentComponent = CONTENTS_MAPPER[currentContentName]

    const handleChangeContent = (contentName: Contents) => {
        setCurrentContentName(contentName)
    }

    if (messagesQuery.isLoading && currentContentName === Contents.sendMessage) {
        return <StyledContrastText isCampaignPage>Loading...</StyledContrastText>
    }

    if (messagesQuery.isError && currentContentName === Contents.sendMessage) {
        return <StyledContrastText isCampaignPage>Something went wrong</StyledContrastText>
    }

    return (
        <Container isSingleColumnLayout={isFeatureEnabled("ff_ngg_personalized_messages_design")}>
            <ContentComponent form={form} questions={questions} handleChangeContent={handleChangeContent} {...props} />
        </Container>
    )
}
