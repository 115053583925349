import { constants } from "QuorumGrassroots/constants"
import styled from "styled-components"
import { Mention } from "react-mentions"

export const StyledTextareaContainer = styled.div`
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;

    border: 1px solid ${constants.inputFieldBorderColor};

    &:focus-within {
        border: 2px solid ${constants.quorumBlue};
        -webkit-box-shadow:
            inset 0 0px 2px rgba(0, 0, 0, 0.075),
            0 0 4px alpha(#000, 0.1);
        box-shadow:
            inset 0 0px 2px rgba(0, 0, 0, 0.075),
            0 0 4px alpha(#000, 0.1);
    }

    & .highlighted-textarea {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    & .highlighted-textarea,
    & .highlighted-textarea__input,
    & .highlighted-textarea__control,
    & .highlighted-textarea__highlighter {
        min-height: 86px;
    }

    & .highlighted-textarea__highlighter {
        padding: 6px 12px;
        line-height: 20px !important;
        & > * {
            line-height: 20px;
        }
    }

    & .highlighted-textarea__control {
        background-color: white !important;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .highlighted-textarea__input {
        line-height: 20px;
        background-color: transparent !important;
        padding: 6px 12px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &:focus-visible {
            outline: none !important;
        }
        &:focus {
            border: 1px solid ${constants.quorumBlue};
        }
    }
`

export const StyledMention = styled(Mention)`
    background-color: ${constants.colors.gray[2]};
    border-radius: 4px;
    padding: 2px 0 2px 0;
`

export const StyledLabel = styled("label")`
    margin-bottom: 0px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
`
