import React, { useMemo } from "react"

import { CustomField } from "@/types/djangio"
import { useFormReturn } from "QuorumGrassroots/campaign-forms/hooks/useForm"
import { PromptAndResponseCampaignForm } from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/PersonalizedMessages.helper"
import { Select } from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/components/SingleSelectionField.styles"

interface Props {
    question: CustomField
    form: useFormReturn<PromptAndResponseCampaignForm>
}

export const mountOptions = (question) => {
    return Object.keys(question.options_new)
        .map((key) => {
            const option = question.options_new[key]
            return {
                ...option,
                value: key,
                label: option.value,
            }
        })
        .filter((option) => !option.archived)
        .sort((a, b) => a.order - b.order)
}

export const SingleSelectionField = ({ question, form }: Props) => {
    const options = useMemo(() => mountOptions(question), [question])

    const handleChange = (value) => {
        form.setFieldValue(question.slug, value, true)
    }

    return (
        <Select
            label={question.external_name}
            value={form.values[question.slug]}
            onChange={handleChange}
            required={question.is_required}
            error={form.errors[question.slug]}
            data={options}
            clearable
        />
    )
}
