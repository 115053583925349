import styled from "styled-components"
import { Button } from "@mantine/core"
import { constants } from "QuorumGrassroots/constants"

export const MessagePreview = styled.div`
    border-radius: 4px;
    ${(props) => !props.isQueryMode && "margin-top: 16px;"}
    display: flex;
    flex-direction: column;
    color: ${constants.freshDarkGrey};
    transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;

    textarea {
        background-color: white !important;
        border: 0px !important;
        border-radius: 4px 4px 0 0 !important;
    }
`

export const EditVisualizationBar = styled.div`
    background-color: white;
    display: flex;
    padding: 6px 12px;
    border-radius: 0 0 4px 4px;
`

export const VisualizationMode = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    i {
        cursor: pointer;
    }
`

export const EditMode = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 4px 0;

    span {
        margin-right: 5px;
    }
`

export const SaveButton = styled(Button)`
    height: 2rem;
    padding: 6px 8px;
    color: #196227;
    border: 1px #bdf5c6 solid;
    font-weight: 400;
`
