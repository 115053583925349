import styled, { css } from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"
import { flexGap } from "utils/cssHelpers"

export const LabelContainer = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 7px;
`

export const Label = styled.label`
    ${rules.MediumTextRegularWeight}
    color: ${colors.Black};
    margin-bottom: 0;
    ${(props) =>
        props.disabled &&
        css`
            color: ${colors.SilverChalice};
        `}
`

export const SubLabel = styled.div`
    ${rules.MediumTextMediumWeight}
    color: ${colors.Black};
    display: flex;
    align-items: center;
    gap: 0.25rem;
`

export const Description = styled.span`
    display: inline-block;
    margin-top: 5px;

    ${(props) => {
        if (props.hasError) {
            return css`
                ${rules.SmallTextLightWeight}
                color: ${colors.ErrorRed};
            `
        } else if (props.hasWarning) {
            return css`
                ${rules.SmallTextLightWeight}
                color: ${colors.WarningOrange};
            `
        }

        return css`
            font-size: 13px;
            color: ${colors.QuorumGrey4};
        `
    }}

    svg {
        margin-right: 5px;
    }
`

export const MultiValueLabel = styled.div`
    display: flex;
    align-items: center;

    svg {
        margin-left: 7px;
        font-size: 12px;
        color: ${colors.QuorumGrey3};
    }

    &:hover svg {
        color: ${colors.CapeCod};
    }
`

export const MultiSelectOption = styled.div`
    display: flex;
    align-items: center;
    padding: 4px 8px;
    cursor: pointer;
    ${flexGap({ gap: 8 })}

    color: ${colors.QuorumBlue};
    ${rules.SmallText}

    svg {
        margin-right: 8px;
        font-size: 22px;
    }

    ${(props) =>
        props.isSelected &&
        css`
            background-color: ${props.isFocused ? colors.CloudBurst : colors.QuorumBlue};

            color: ${colors.White};

            /* Set the duotone icon colors */
            ${colors.duotoneWhite}
        `}

    ${(props) =>
        props.isFocused &&
        !props.isSelected &&
        css`
            background-color: ${colors.AquaHaze};
            color: ${colors.QuorumBlue};
        `}
`

// Override react-select's default styles
export const selectStyles = {
    control: (provided, state) => ({
        ...provided,
        marginTop: "4px",
        minHeight: "42px",
        maxHeight: "85px",
        overflowY: "auto",
        overflowX: "hidden",
        borderRadius: "4px",
        border: `1px solid var(--mantine-color-gray-5)`,
        boxShadow: "0 2px 7px 0 rgba(0,0,0,0.1)",
        transition: `border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out`,
        "&:hover": {
            border: `1px solid ${colors.QuorumBlue}`,
        },
        /* Nesting under these selectors to boost specificity without using !important */
        "&, &:focus-within, &:hover": {
            ...(state.menuIsOpen && {
                border: "1px solid transparent",
                boxShadow: `0 0 0 2px ${colors.QuorumBlue}`,
                maxHeight: "80px",
                overflowY: "auto",
                overflowX: "hidden",
            }),

            ...(state.isDisabled && {
                border: `1px solid ${colors.FormControlBorderColor}`,
                backgroundColor: colors.unnamedF8F9F9,
            }),

            ...(state.selectProps.hasWarning && {
                border: `1px solid ${colors.WarningOrange}`,
            }),

            ...(state.selectProps.hasError && {
                border: `1px solid ${colors.ErrorRed}`,
            }),
        },
    }),
    placeholder: (provided) => ({
        ...provided,
        color: colors.SilverChalice,
        fontSize: "14px",
        fontStyle: "italic",
        fontWeight: "400",
        whiteSpace: "nowrap",
    }),
    indicatorsContainer: (provided, state) => ({
        alignItems: "center",
        alignSelf: "flex-start",
        display: "flex",
        "&& > svg": {
            width: state.selectProps.isSmall ? "1em" : "1.25em",
        },
        padding: state.selectProps.isSmall ? "8px 2px 8px 0" : "8px 8px 8px 0",
        position: "sticky",
        top: "2px",
        right: 0,
    }),
    clearIndicator: (provided, state) => ({
        ...provided,
        cursor: "pointer",
        padding: 0,
        color: state.selectProps.menuIsOpen ? colors.QuorumBlue : colors.SilverChalice,
        "&:hover": {
            color: colors.CloudBurst,
        },
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        transition: "all .2s ease",
        transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
        cursor: "pointer",
        padding: 0,
        color: state.selectProps.menuIsOpen ? colors.QuorumBlue : colors.SilverChalice,
        "&:hover": {
            color: colors.CloudBurst,
        },
    }),
    input: (provided) => ({
        ...provided,
        fontSize: "14px",
        fontWeight: "400",
    }),
    menu: (provided) => ({
        ...provided,
        marginTop: "4px",
        width: "100%",
        borderRadius: "4px",
        overflow: "clip",
        backgroundColor: colors.White,
        boxShadow: "0 2px 7px 0 rgba(0,0,0,0.3)",
        zIndex: "3",
    }),
    menuList: (provided, state) => ({
        ...provided,
        padding: 0,
        borderRadius: "4px",
        maxHeight: state.selectProps.top ? `calc(100vh - ${state.selectProps.top}px)` : provided.maxHeight,
        minHeight: "35px",
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: colors.QuorumBlue,
        fontSize: "14px",
        fontWeight: "400",
        padding: "0px",
        marginRight: "5px",
    }),
    multiValue: (provided) => ({
        ...provided,
        padding: "4px",
        backgroundColor: colors.FormControlBorderColor,
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        height: "100%",
        padding: "0px",
        color: colors.QuorumBlue,
        "&:hover": { cursor: "pointer" },
    }),
    group: (provided) => ({
        ...provided,
        padding: 0,
    }),
    groupHeading: (provided) => ({
        ...provided,
        color: colors.QuorumGrey3,
        fontSize: "14px",
        fontWeight: "400",
        marginTop: ".5em",
        paddingLeft: "8px",
    }),
    option: (provided, state) => ({
        ...provided,
        color: colors.QuorumBlue,
        fontSize: "14px",
        cursor: "pointer",
        padding: "8px",
        ...(state.isSelected && {
            backgroundColor: colors.QuorumBlue,
            color: colors.White,
        }),
        ...(state.isFocused &&
            !state.isSelected && {
                backgroundColor: colors.AquaHaze,
                color: colors.QuorumBlue,
            }),
    }),
    noOptionsMessage: (provided) => ({
        ...provided,
        fontSize: "14px",
        fontStyle: "italic",
        color: colors.SilverChalice,
    }),
    singleValue: (provided) => ({
        ...provided,
        color: colors.Black,
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: "400",
    }),
}

export const SelectWrapper = styled.div`
    ${(props) =>
        props.fitContent &&
        css`
            min-width: 180px;
            width: max-content;
        `}
`
