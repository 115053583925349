export const DjangIOCache = {
    "ai_assistant": {
        "types": {}
    },
    "app": {
        "bill": {
            "models": {},
            "status": {},
            "types": {
                "language": {}
            }
        },
        "blog": {
            "types": {}
        },
        "client_integration": {
            "iterable": {
                "types": {}
            },
            "types": {}
        },
        "committee": {
            "models": {}
        },
        "custom_data": {
            "manage_data": {
                "models": {
                    "GrassrootsCustomData": {
                        "__is_model": true,
                        "__name__": "GrassrootsCustomData",
                        "data_model": "app.custom_data.manage_data.models.GrassrootsCustomData",
                        "default_exclude_filters": {},
                        "default_filters": { "archived": false },
                        "endpoint": "/api/grassrootscustomdata/",
                        "fields": [],
                        "proxy_endpoint": "/api/customdata/"
                    }
                }
            },
            "types": {}
        },
        "custom_event": {
            "models": {
                "CustomEvent": {
                    "__is_model": true,
                    "__name__": "CustomEvent",
                    "data_model": "app.custom_event.models.CustomEvent",
                    "default_exclude_filters": {},
                    "default_filters": { "archived": false },
                    "endpoint": "/api/customevent/",
                    "fields": []
                },
                "GrassrootsCustomEvent": {
                    "__is_model": true,
                    "__name__": "GrassrootsCustomEvent",
                    "data_model": "app.custom_event.models.GrassrootsCustomEvent",
                    "default_exclude_filters": {},
                    "default_filters": { "archived": false },
                    "endpoint": "/api/grassrootscustomevent/",
                    "fields": [],
                    "proxy_endpoint": "/api/customevent/"
                }
            },
            "types": {
                "CustomEventPageLayout": {
                    "__is_enum": true,
                    "two_columns": { "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/double-active.svg", "column_width": 6, "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/double.svg", "key": "two_columns", "label": "Two Columns", "should_use_icon_path": true, "tip": "Content is split into two columns. Best for events with images, videos, or long descriptions.", "value": 1 },
                    "two_rows": { "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/double-vertical-active.svg", "column_width": 12, "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/double-vertical.svg", "key": "two_rows", "label": "Two Rows", "should_use_icon_path": true, "tip": "Content is split into two rows. Best for events with short descriptions.", "value": 2 }
                },
                "RSVPStatus": {
                    "__is_enum": true,
                    "maybe": { "custom_event_field": "thank_you_page_text_maybe", "key": "maybe", "label": "Maybe", "order": 2, "show_on_rsvp_form": true, "value": 3 },
                    "no": { "custom_event_field": "thank_you_page_text_no", "key": "no", "label": "No", "order": 3, "show_on_rsvp_form": true, "value": 2 },
                    "unknown": { "key": "unknown", "label": "Not Yet Responded", "order": 4, "show_on_rsvp_form": false, "value": 4 },
                    "yes": { "custom_event_field": "thank_you_page_text", "key": "yes", "label": "Yes", "order": 1, "show_on_rsvp_form": true, "value": 1 }
                },
                "StafferRsvpPermissionType": {
                    "__is_enum": true,
                    "both": { "help_text": "Can RSVP for themself and/or their Member of Congress", "key": "both", "label": "Both Themselves and Their Boss", "value": 3 },
                    "member": { "help_text": "Can only RSVP for their Member of Congress", "key": "member", "label": "Their Boss", "value": 2 },
                    "staffer": { "help_text": "Can only RSVP for themselves", "key": "staffer", "label": "Themselves", "value": 1 }
                }
            }
        },
        "deduplication": {
            "types": {}
        },
        "document": {
            "models": {},
            "types": {
                "Language": {
                    "__is_enum": true,
                    "afrikaans": { "abbrev": "af", "extended_abbrev": "afr", "key": "afrikaans", "label": "Afrikaans", "order": 0, "show_in_enumeration": false, "value": 49 },
                    "albanian": { "abbrev": "sq", "extended_abbrev": "alb", "key": "albanian", "label": "Albanian", "order": 0, "show_in_enumeration": false, "value": 51 },
                    "arabic": { "abbrev": "ar", "article_analyzer": "arabic", "extended_abbrev": "ara", "key": "arabic", "label": "Arabic", "order": 15511, "show_in_enumeration": true, "value": 25 },
                    "armenian": { "abbrev": "hy", "extended_abbrev": "hye", "intrado_abbrev": "arm", "key": "armenian", "label": "Armenian", "order": 0, "show_in_enumeration": false, "value": 34 },
                    "bengali": { "extended_abbrev": "ben", "key": "bengali", "label": "Bengali", "order": 67, "show_in_enumeration": false, "value": 30 },
                    "bulgarian": { "abbrev": "bg", "extended_abbrev": "bul", "intrado_abbrev": "bul", "key": "bulgarian", "label": "Bulgarian", "order": 58, "show_in_enumeration": true, "value": 2 },
                    "chinese": { "abbrev": "zh-Hant", "article_analyzer": "smartcn", "extended_abbrev": "zho", "key": "chinese", "label": "Chinese", "order": 70314, "show_in_enumeration": true, "value": 32 },
                    "chinese_simplified": { "abbrev": "zh-Hans", "intrado_abbrev": "zhs", "key": "chinese_simplified", "label": "Chinese (Simplified)", "order": 0, "show_in_enumeration": false, "value": 35 },
                    "croatian": { "abbrev": "hr", "extended_abbrev": "hrv", "key": "croatian", "label": "Croatian", "order": 76, "show_in_enumeration": true, "value": 11 },
                    "czech": { "abbrev": "cs", "extended_abbrev": "ces", "intrado_abbrev": "cze", "key": "czech", "label": "Czech", "order": 91, "show_in_enumeration": true, "value": 4 },
                    "danish": { "abbrev": "da", "extended_abbrev": "dan", "intrado_abbrev": "dan", "key": "danish", "label": "Danish", "order": 214, "show_in_enumeration": true, "value": 5 },
                    "dutch": { "abbrev": "nl", "article_analyzer": "dutch", "extended_abbrev": "nld", "intrado_abbrev": "dut", "key": "dutch", "label": "Dutch", "order": 2697, "show_in_enumeration": true, "value": 17 },
                    "english": { "abbrev": "en", "article_analyzer": "english", "extended_abbrev": "eng", "intrado_abbrev": "eng", "key": "english", "label": "English", "order": 3268508, "show_in_enumeration": true, "value": 1 },
                    "estonian": { "abbrev": "et", "extended_abbrev": "est", "intrado_abbrev": "est", "key": "estonian", "label": "Estonian", "order": 29, "show_in_enumeration": true, "value": 7 },
                    "filipino": { "extended_abbrev": "fil", "key": "filipino", "label": "Filipino", "order": 0, "show_in_enumeration": false, "value": 47 },
                    "finnish": { "abbrev": "fi", "extended_abbrev": "fin", "intrado_abbrev": "fin", "key": "finnish", "label": "Finnish", "order": 37, "show_in_enumeration": true, "value": 22 },
                    "french": { "abbrev": "fr", "article_analyzer": "french", "extended_abbrev": "fra", "intrado_abbrev": "fre", "key": "french", "label": "French", "order": 15566, "show_in_enumeration": true, "value": 9 },
                    "georgian": { "abbrev": "ga", "extended_abbrev": "kat", "key": "georgian", "label": "Georgian", "order": 3, "show_in_enumeration": true, "value": 10 },
                    "german": { "abbrev": "de", "article_analyzer": "german", "extended_abbrev": "deu", "intrado_abbrev": "ger", "key": "german", "label": "German", "order": 24802, "show_in_enumeration": true, "value": 6 },
                    "greek": { "abbrev": "el", "extended_abbrev": "ell", "intrado_abbrev": "gre", "key": "greek", "label": "Greek", "order": 415, "show_in_enumeration": true, "value": 8 },
                    "gujarati": { "abbrev": "gu", "extended_abbrev": "guj", "key": "gujarati", "label": "Gujarati", "order": 0, "show_in_enumeration": false, "value": 52 },
                    "hebrew": { "abbrev": "he", "extended_abbrev": "heb", "key": "hebrew", "label": "Hebrew", "order": 0, "show_in_enumeration": false, "value": 36 },
                    "hindi": { "abbrev": "he", "extended_abbrev": "hin", "intrado_abbrev": "hin", "key": "hindi", "label": "Hindi", "order": 0, "show_in_enumeration": false, "value": 37 },
                    "hungarian": { "abbrev": "hu", "extended_abbrev": "hun", "intrado_abbrev": "hun", "key": "hungarian", "label": "Hungarian", "order": 92, "show_in_enumeration": true, "value": 15 },
                    "icelandic": { "abbrev": "is", "extended_abbrev": "isl", "intrado_abbrev": "ice", "key": "icelandic", "label": "Icelandic", "order": 0, "show_in_enumeration": false, "value": 38 },
                    "indonesian": { "abbrev": "id", "extended_abbrev": "ind", "intrado_abbrev": "ind", "key": "indonesian", "label": "Indonesian", "order": 0, "show_in_enumeration": false, "value": 39 },
                    "italian": { "abbrev": "it", "article_analyzer": "italian", "extended_abbrev": "ita", "intrado_abbrev": "ita", "key": "italian", "label": "Italian", "order": 5491, "show_in_enumeration": true, "value": 12 },
                    "japanese": { "abbrev": "ja", "article_analyzer": "kuromoji", "extended_abbrev": "jpn", "key": "japanese", "label": "Japanese", "order": 16786, "show_in_enumeration": true, "value": 33 },
                    "korean": { "abbrev": "ko", "extended_abbrev": "kor", "intrado_abbrev": "kor", "key": "korean", "label": "Korean", "order": 0, "show_in_enumeration": false, "value": 40 },
                    "latvian": { "abbrev": "lv", "extended_abbrev": "lav", "intrado_abbrev": "lav", "key": "latvian", "label": "Latvian", "order": 81, "show_in_enumeration": true, "value": 13 },
                    "lithuanian": { "abbrev": "lt", "extended_abbrev": "lit", "intrado_abbrev": "lit", "key": "lithuanian", "label": "Lithuanian", "order": 67, "show_in_enumeration": true, "value": 14 },
                    "luxembourgish": { "abbrev": "lb", "extended_abbrev": "ltz", "key": "luxembourgish", "label": "Luxembourish", "order": 0, "show_in_enumeration": false, "value": 41 },
                    "malay": { "abbrev": "ms", "extended_abbrev": "msa", "intrado_abbrev": "may", "key": "malay", "label": "Malay", "order": 0, "show_in_enumeration": false, "value": 42 },
                    "maltan": { "abbrev": "mt", "extended_abbrev": "mit", "key": "maltan", "label": "Maltese", "order": -1, "show_in_enumeration": true, "value": 16 },
                    "marathi": { "abbrev": "mr", "extended_abbrev": "mar", "key": "marathi", "label": "Marathi", "order": 0, "show_in_enumeration": false, "value": 50 },
                    "norwegian": { "abbrev": "no", "extended_abbrev": "nor", "intrado_abbrev": "nor", "key": "norwegian", "label": "Norwegian", "order": 98, "show_in_enumeration": false, "value": 29 },
                    "other": { "abbrev": "other", "article_analyzer": "icu_analyzer", "extended_abbrev": "other", "key": "other", "label": "Other", "order": -2, "show_in_enumeration": true, "value": 9000 },
                    "persian": { "extended_abbrev": "fas", "key": "persian", "label": "Persian", "order": 428, "show_in_enumeration": false, "value": 26 },
                    "polish": { "abbrev": "pl", "extended_abbrev": "pol", "intrado_abbrev": "pol", "key": "polish", "label": "Polish", "order": 259, "show_in_enumeration": true, "value": 18 },
                    "portuguese": { "abbrev": "pt", "article_analyzer": "portuguese", "extended_abbrev": "por", "intrado_abbrev": "por", "key": "portuguese", "label": "Portuguese", "order": 13275, "show_in_enumeration": true, "value": 19 },
                    "punjabi": { "abbrev": "pa", "extended_abbrev": "pan", "key": "punjabi", "label": "Punjabi", "order": 0, "show_in_enumeration": false, "value": 45 },
                    "romanian": { "abbrev": "ro", "extended_abbrev": "ron", "intrado_abbrev": "rum", "key": "romanian", "label": "Romanian", "order": 172, "show_in_enumeration": true, "value": 24 },
                    "russian": { "article_analyzer": "russian", "extended_abbrev": "rus", "intrado_abbrev": "rus", "key": "russian", "label": "Russian", "order": 10754, "show_in_enumeration": true, "value": 27 },
                    "slovakian": { "abbrev": "sk", "extended_abbrev": "slk", "intrado_abbrev": "slo", "key": "slovakian", "label": "Slovak", "order": 63, "show_in_enumeration": true, "value": 20 },
                    "slovenian": { "abbrev": "sl", "extended_abbrev": "slv", "intrado_abbrev": "slv", "key": "slovenian", "label": "Slovenian", "order": 20, "show_in_enumeration": true, "value": 21 },
                    "spanish": { "abbrev": "es", "article_analyzer": "spanish", "extended_abbrev": "spa", "intrado_abbrev": "spa", "key": "spanish", "label": "Spanish", "order": 26781, "show_in_enumeration": true, "value": 3 },
                    "swedish": { "abbrev": "sv", "extended_abbrev": "swe", "intrado_abbrev": "swe", "key": "swedish", "label": "Swedish", "order": 110, "show_in_enumeration": true, "value": 23 },
                    "tamil": { "abbrev": "ta", "extended_abbrev": "tam", "key": "tamil", "label": "Tamil", "order": 0, "show_in_enumeration": false, "value": 46 },
                    "thai": { "abbrev": "th", "extended_abbrev": "tha", "key": "thai", "label": "Thai", "order": 0, "show_in_enumeration": false, "value": 44 },
                    "turkish": { "abbrev": "tr", "extended_abbrev": "tur", "intrado_abbrev": "tur", "key": "turkish", "label": "Turkish", "order": 568, "show_in_enumeration": false, "value": 31 },
                    "urdu": { "extended_abbrev": "urd", "key": "urdu", "label": "Urdu", "order": 70, "show_in_enumeration": false, "value": 28 },
                    "vietnamese": { "abbrev": "vi", "extended_abbrev": "vie", "intrado_abbrev": "vie", "key": "vietnamese", "label": "Vietnamese", "order": 0, "show_in_enumeration": false, "value": 43 },
                    "welsh": { "abbrev": "cy", "extended_abbrev": "cym", "key": "welsh", "label": "Welsh", "order": 0, "show_in_enumeration": false, "value": 48 }
                }
            }
        },
        "events": {
            "models": {},
            "types": {}
        },
        "fields": {},
        "generatepdf": {
            "models": {}
        },
        "geography": {
            "models": {
                "District": {
                    "__is_model": true,
                    "__name__": "District",
                    "data_model": "app.geography.models.District",
                    "default_exclude_filters": {},
                    "default_filters": {},
                    "endpoint": "/api/district/",
                    "fields": []
                },
                "GeoShape": {
                    "__is_model": true,
                    "__name__": "GeoShape",
                    "data_model": "app.geography.models.GeoShape",
                    "default_exclude_filters": {},
                    "default_filters": {},
                    "endpoint": "/api/geoshape/",
                    "fields": []
                },
                "State": {
                    "__is_model": true,
                    "__name__": "State",
                    "data_model": "app.geography.models.State",
                    "default_exclude_filters": {},
                    "default_filters": {},
                    "endpoint": "/api/state/",
                    "fields": []
                }
            },
            "types": {}
        },
        "grassroots": {
            "campaign": {
                "models": {
                    "Campaign": {
                        "__is_model": true,
                        "__name__": "Campaign",
                        "data_model": "app.grassroots.campaign.models.Campaign",
                        "default_exclude_filters": { "status": 0 },
                        "default_filters": { "archived": false },
                        "endpoint": "/api/campaign/",
                        "fields": []
                    },
                    "GrassrootsCampaign": {
                        "__is_model": true,
                        "__name__": "GrassrootsCampaign",
                        "data_model": "app.grassroots.campaign.models.GrassrootsCampaign",
                        "default_exclude_filters": { "status": 0 },
                        "default_filters": { "archived": false },
                        "endpoint": "/api/grassrootscampaign/",
                        "fields": [],
                        "proxy_endpoint": "/api/campaign/"
                    }
                },
                "types": {
                    "CampaignDescriptionAlignment": {
                        "__is_enum": true,
                        "bottom_right": { "key": "bottom_right", "label": "Bottom", "tip": "Campaign description is displayed after the sign up widget. Best for campaigns with images, videos, or long descriptions.", "value": 2 },
                        "top_left": { "key": "top_left", "label": "Top", "tip": "Campaign description is displayed before the sign up widget. Best for campaigns with short descriptions.", "value": 1 }
                    },
                    "CampaignFullBleedHeaderType": {
                        "__is_enum": true,
                        "image": { "key": "image", "label": "Image", "order": 2, "tip": "Show a full bleed image above your campaign", "value": 2 },
                        "none": { "key": "none", "label": "None", "order": 1, "tip": "No full bleed header above your campaign", "value": 1 },
                        "video": { "key": "video", "label": "Video", "order": 3, "tip": "Show a full bleed video above your campaign", "value": 3 }
                    },
                    "CampaignLayoutType": {
                        "__is_enum": true,
                        "embedded": { "description": "This campaign can be embedded separately onto a unique website.", "icon": "code", "iconFamily": "far", "key": "embedded", "label": "3", "title": "Embedded Campaign", "value": 3 },
                        "standalone": { "description": "A one-page campaign that is available via link separate from Action Center.", "icon": "file-alt", "iconFamily": "far", "key": "standalone", "label": "2", "title": "Standalone Campaign", "value": 2 },
                        "standard": { "description": "This campaign resides in the list of the Action Center page and has its own page.", "icon": "bullhorn", "iconFamily": "far", "key": "standard", "label": "1", "title": "Standard Campaign", "value": 1 }
                    },
                    "CampaignNameDisplayOptionType": {
                        "__is_enum": true,
                        "include_in_main_text": { "key": "include_in_main_text", "label": "Include in main text", "order": 3, "tip": "Include your campaign name in the text of your campaign", "value": 3 },
                        "none": { "key": "none", "label": "None", "order": 1, "tip": "Do not display your campaign name on your campaign page", "value": 1 },
                        "overlay_header": { "key": "overlay_header", "label": "Overlay page header", "order": 2, "tip": "Overlay your campaign name on your full bleed header above your campaign", "value": 2 }
                    },
                    "CampaignNavigationBarType": {
                        "__is_enum": true,
                        "logo_only": { "key": "logo_only", "label": "Logo", "order": 1, "tip": "A header only containing your logo, centered horizontally", "value": 2 },
                        "none": { "key": "none", "label": "None", "order": 3, "tip": "No header for your campaign", "value": 3 },
                        "standard": { "key": "standard", "label": "Logo and global navigation", "order": 2, "tip": "The standard navigation bar from other pages of your Action Center", "value": 1 }
                    },
                    "CampaignPageLayout": {
                        "__is_enum": true,
                        "two_columns": { "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsCampaignLayouts/columnsActive.png", "column_width": 6, "icon": "fa-columns", "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsCampaignLayouts/columnsInactive.png", "key": "two_columns", "label": "Two columns", "tip": "Content is split into two columns. Best for campaigns with images, videos, or long descriptions.", "value": 1 },
                        "two_rows": { "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsCampaignLayouts/rowsActive.png", "column_width": 12, "icon": "fa-columns fa-rotate-270", "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsCampaignLayouts/rowsInactive.png", "key": "two_rows", "label": "Two rows", "tip": "Content is split into two rows. Best for campaigns with short descriptions.", "value": 2 }
                    },
                    "CampaignStatus": {
                        "__is_enum": true,
                        "active": { "key": "active", "label": "Active", "value": 2 },
                        "complete": { "key": "complete", "label": "Complete", "value": 3 },
                        "discarded_draft": { "key": "discarded_draft", "label": "Discarded Draft", "value": 0 },
                        "draft": { "key": "draft", "label": "Draft", "value": 1 },
                        "p2aActive": { "key": "p2aActive", "label": "P2A Active", "value": 4 }
                    },
                    "CampaignTargetingType": {
                        "__is_enum": true,
                        "advocate_address": { "key": "advocate_address", "label": "Advocate's address", "order": 1, "tip": "Target based on advocate's address in Quorum", "value": 1 },
                        "advocate_address_and_organizations": { "key": "advocate_address_and_organizations", "label": "Both", "order": 3, "tip": "Target both the advocate's address and related organizations' addresses", "value": 3 },
                        "advocate_organizations_addresses": { "key": "advocate_organizations_addresses", "label": "Advocate's organizations' addresses", "order": 2, "tip": "Target based on the addresses of the advocate's related organizations", "value": 2 }
                    },
                    "CampaignType": {
                        "__is_enum": true,
                        "authorized_contribution": {
                            "allow_one_click_registration": false,
                            "can_earn_gamification_points": false,
                            "canadian_grassroots_enabled": false,
                            "custom_target_optional_address_field": false,
                            "gamification_default_point_value": 0,
                            "icon": "fa-pencil-square-o",
                            "in_combined_campaign": false,
                            "in_multi_action_campaign": false,
                            "interaction_type": 108,
                            "is_enabled_for_campaign_form": false,
                            "key": "authorized_contribution",
                            "label": "Authorized Contribution",
                            "order": 0,
                            "permission_enum": [],
                            "required_registration_fields": [],
                            "targets_members": false,
                            "value": 103
                        },
                        "bills": {
                            "allow_one_click_registration": false,
                            "can_earn_gamification_points": false,
                            "canadian_grassroots_enabled": false,
                            "custom_target_optional_address_field": false,
                            "gamification_default_point_value": 0,
                            "i18n_verb_key": "",
                            "in_combined_campaign": false,
                            "in_multi_action_campaign": false,
                            "is_enabled_for_campaign_form": false,
                            "key": "bills",
                            "label": "Learn About Bills",
                            "order": 0,
                            "permission_enum": [],
                            "required_registration_fields": [4],
                            "targets_members": false,
                            "value": 14
                        },
                        "call": {
                            "allow_one_click_registration": false,
                            "can_earn_gamification_points": true,
                            "canadian_grassroots_enabled": true,
                            "custom_target_optional_address_field": true,
                            "gamification_default_point_value": 10,
                            "i18n_verb_key": "campaign.call.verb",
                            "icon": "fa-phone",
                            "in_combined_campaign": false,
                            "in_multi_action_campaign": true,
                            "interaction_type": 50,
                            "is_enabled_for_campaign_form": true,
                            "key": "call",
                            "label": "Call a Number",
                            "minimal_label": "Call",
                            "minimal_tip": "Grassroots contacts call the capitol or district office of legislators or a custom number.",
                            "new_icon": "phone",
                            "order": 5,
                            "permission_enum": [],
                            "required_data_key": "most_recent_role__phone",
                            "required_registration_fields": [4, 3, 5],
                            "targets_members": true,
                            "value": 5
                        },
                        "combined_campaign": {
                            "allow_one_click_registration": true,
                            "can_earn_gamification_points": false,
                            "canadian_grassroots_enabled": true,
                            "custom_target_optional_address_field": false,
                            "gamification_default_point_value": 0,
                            "i18n_verb_key": "campaign.combined_campaign.verb",
                            "icon": "fa-share-alt",
                            "in_combined_campaign": false,
                            "in_multi_action_campaign": false,
                            "interaction_type": 48,
                            "is_enabled_for_campaign_form": true,
                            "key": "combined_campaign",
                            "label": "Combined Campaign",
                            "minimal_tip": "Grassroots contacts participate in multiple campaigns at once.",
                            "new_icon": "users",
                            "order": 4,
                            "permission_enum": [],
                            "required_registration_fields": [1, 2, 5, 4],
                            "targets_members": false,
                            "value": 19
                        },
                        "comment_on_regulation": {
                            "allow_one_click_registration": true,
                            "can_earn_gamification_points": true,
                            "canadian_grassroots_enabled": false,
                            "custom_target_optional_address_field": false,
                            "gamification_default_point_value": 2,
                            "i18n_verb_key": "campaign.comment_on_regulation.verb",
                            "icon": "fa-comment-o",
                            "in_combined_campaign": true,
                            "in_multi_action_campaign": true,
                            "interaction_type": 44,
                            "is_enabled_for_campaign_form": true,
                            "key": "comment_on_regulation",
                            "label": "Comment on Regulation",
                            "minimal_label": "Comment",
                            "minimal_tip": "Grassroots contacts submit a comment on a pending regulation.",
                            "new_icon": "comment",
                            "order": 7,
                            "permission_enum": [],
                            "required_registration_fields": [4],
                            "targets_members": false,
                            "value": 15
                        },
                        "contact_us": {
                            "allow_one_click_registration": false,
                            "can_earn_gamification_points": false,
                            "canadian_grassroots_enabled": false,
                            "custom_target_optional_address_field": false,
                            "gamification_default_point_value": 0,
                            "icon": "fa-envelope-o",
                            "in_combined_campaign": false,
                            "in_multi_action_campaign": false,
                            "interaction_type": 109,
                            "is_enabled_for_campaign_form": false,
                            "key": "contact_us",
                            "label": "Contact Us",
                            "order": 0,
                            "permission_enum": [],
                            "required_registration_fields": [],
                            "targets_members": false,
                            "value": 26
                        },
                        "custom": {
                            "allow_one_click_registration": false,
                            "can_earn_gamification_points": false,
                            "canadian_grassroots_enabled": false,
                            "custom_target_optional_address_field": false,
                            "gamification_default_point_value": 0,
                            "i18n_verb_key": "campaign.custom.verb",
                            "icon": "fa-user",
                            "in_combined_campaign": false,
                            "in_multi_action_campaign": false,
                            "is_enabled_for_campaign_form": false,
                            "key": "custom",
                            "label": "Custom",
                            "order": 0,
                            "permission_enum": [],
                            "required_registration_fields": [4],
                            "targets_members": true,
                            "value": 1
                        },
                        "custom_points": {
                            "allow_one_click_registration": false,
                            "can_earn_gamification_points": true,
                            "canadian_grassroots_enabled": false,
                            "custom_target_optional_address_field": false,
                            "gamification_default_point_value": 0,
                            "icon": "fa-star",
                            "in_combined_campaign": false,
                            "in_multi_action_campaign": false,
                            "interaction_type": 104,
                            "is_enabled_for_campaign_form": false,
                            "key": "custom_points",
                            "label": "Custom Points",
                            "order": 0,
                            "permission_enum": [],
                            "required_registration_fields": [],
                            "targets_members": false,
                            "value": 102
                        },
                        "donate": {
                            "allow_one_click_registration": false,
                            "can_earn_gamification_points": false,
                            "canadian_grassroots_enabled": false,
                            "custom_target_optional_address_field": false,
                            "gamification_default_point_value": 0,
                            "i18n_verb_key": "campaign.petition.verb",
                            "icon": "fa-usd",
                            "in_combined_campaign": false,
                            "in_multi_action_campaign": false,
                            "is_enabled_for_campaign_form": true,
                            "key": "donate",
                            "label": "Donate",
                            "minimal_tip": "Set a donation form to a campaign.",
                            "new_icon": "dollar-sign",
                            "order": 15,
                            "permission_enum": [],
                            "required_registration_fields": [4],
                            "targets_members": false,
                            "value": 22
                        },
                        "election_info": {
                            "allow_one_click_registration": false,
                            "can_earn_gamification_points": true,
                            "canadian_grassroots_enabled": false,
                            "custom_target_optional_address_field": false,
                            "gamification_default_point_value": 2,
                            "i18n_verb_key": "campaign.election_info.verb",
                            "icon": "fa-sticky-note-o",
                            "in_combined_campaign": false,
                            "in_multi_action_campaign": false,
                            "interaction_type": 107,
                            "is_enabled_for_campaign_form": false,
                            "key": "election_info",
                            "label": "Election Info",
                            "minimal_tip": "Present your grassroots advocates with an Election Info widget.",
                            "order": 0,
                            "permission_enum": [],
                            "required_registration_fields": [4],
                            "targets_members": false,
                            "value": 25
                        },
                        "gotv": {
                            "allow_one_click_registration": false,
                            "can_earn_gamification_points": true,
                            "canadian_grassroots_enabled": false,
                            "custom_target_optional_address_field": false,
                            "gamification_default_point_value": 2,
                            "i18n_verb_key": "campaign.gotv.verb",
                            "icon": "fa-sticky-note-o",
                            "in_combined_campaign": false,
                            "in_multi_action_campaign": false,
                            "interaction_type": 105,
                            "is_enabled_for_campaign_form": true,
                            "key": "gotv",
                            "label": "Get Out the Vote",
                            "minimal_tip": "Present your grassroots advocates with a Get Out the Vote widget.",
                            "new_icon": "file",
                            "order": 12,
                            "permission_enum": [],
                            "required_registration_fields": [4],
                            "targets_members": false,
                            "value": 23
                        },
                        "grassroots_story": {
                            "allow_one_click_registration": false,
                            "can_earn_gamification_points": true,
                            "canadian_grassroots_enabled": true,
                            "custom_target_optional_address_field": false,
                            "gamification_default_point_value": 3,
                            "i18n_verb_key": "campaign.grassroots_story.verb",
                            "icon": "fa-user",
                            "in_combined_campaign": false,
                            "in_multi_action_campaign": false,
                            "interaction_type": 45,
                            "is_enabled_for_campaign_form": true,
                            "key": "grassroots_story",
                            "label": "Share a Story",
                            "minimal_tip": "Grassroots contacts share their stories via video and/or text.",
                            "new_icon": "comment",
                            "order": 8,
                            "permission_enum": [],
                            "required_registration_fields": [4],
                            "targets_members": false,
                            "value": 17
                        },
                        "interaction": {
                            "allow_one_click_registration": false,
                            "can_earn_gamification_points": false,
                            "canadian_grassroots_enabled": false,
                            "custom_target_optional_address_field": false,
                            "gamification_default_point_value": 0,
                            "i18n_verb_key": "",
                            "in_combined_campaign": false,
                            "in_multi_action_campaign": false,
                            "is_enabled_for_campaign_form": false,
                            "key": "interaction",
                            "label": "Interact",
                            "order": 0,
                            "permission_enum": [],
                            "required_registration_fields": [4],
                            "targets_members": false,
                            "value": 3
                        },
                        "issues": {
                            "allow_one_click_registration": false,
                            "can_earn_gamification_points": false,
                            "canadian_grassroots_enabled": false,
                            "custom_target_optional_address_field": false,
                            "gamification_default_point_value": 0,
                            "i18n_verb_key": "",
                            "in_combined_campaign": false,
                            "in_multi_action_campaign": false,
                            "is_enabled_for_campaign_form": false,
                            "key": "issues",
                            "label": "Issues",
                            "order": 0,
                            "permission_enum": [],
                            "required_registration_fields": [4],
                            "targets_members": false,
                            "value": 6
                        },
                        "learn_about_member": {
                            "allow_one_click_registration": false,
                            "can_earn_gamification_points": false,
                            "canadian_grassroots_enabled": false,
                            "custom_target_optional_address_field": false,
                            "gamification_default_point_value": 0,
                            "i18n_verb_key": "",
                            "in_combined_campaign": false,
                            "in_multi_action_campaign": false,
                            "is_enabled_for_campaign_form": false,
                            "key": "learn_about_member",
                            "label": "About Your Member",
                            "order": 0,
                            "permission_enum": [],
                            "required_registration_fields": [4],
                            "targets_members": false,
                            "value": 13
                        },
                        "log_interaction": {
                            "action_center_settings_field_key": "external_interaction_types",
                            "allow_one_click_registration": false,
                            "campaign_field_key": "interaction_types",
                            "can_earn_gamification_points": false,
                            "canadian_grassroots_enabled": true,
                            "custom_target_optional_address_field": false,
                            "gamification_default_point_value": 0,
                            "i18n_verb_key": "campaign.log_interaction.verb",
                            "icon": "fa-handshake-o",
                            "in_combined_campaign": false,
                            "in_multi_action_campaign": false,
                            "interaction_type": 52,
                            "is_enabled_for_campaign_form": true,
                            "key": "log_interaction",
                            "label": "Log Interaction",
                            "minimal_tip": "Log an interaction with a member of congress.",
                            "new_icon": "handshake",
                            "note_field_key": "interaction_type",
                            "order": 9,
                            "permission_enum": [7],
                            "required_registration_fields": [4],
                            "targets_members": false,
                            "value": 20
                        },
                        "log_relationship": {
                            "action_center_settings_field_key": "external_relationship_types",
                            "allow_one_click_registration": false,
                            "campaign_field_key": "relationship_types",
                            "can_earn_gamification_points": false,
                            "canadian_grassroots_enabled": true,
                            "custom_target_optional_address_field": false,
                            "gamification_default_point_value": 0,
                            "i18n_verb_key": "campaign.log_interaction.verb",
                            "icon": "fa-handshake-o",
                            "in_combined_campaign": false,
                            "in_multi_action_campaign": false,
                            "interaction_type": 52,
                            "is_enabled_for_campaign_form": true,
                            "key": "log_relationship",
                            "label": "Log Relationship",
                            "minimal_tip": "Log a relationship with a member of congress.",
                            "new_icon": "handshake",
                            "note_field_key": "relationship_type",
                            "order": 10,
                            "permission_enum": [5],
                            "required_registration_fields": [4],
                            "targets_members": false,
                            "value": 21
                        },
                        "multi_action": {
                            "allow_one_click_registration": false,
                            "can_earn_gamification_points": true,
                            "canadian_grassroots_enabled": true,
                            "custom_target_optional_address_field": false,
                            "gamification_default_point_value": 0,
                            "i18n_verb_key": "campaign.multi_action.verb",
                            "in_combined_campaign": false,
                            "in_multi_action_campaign": false,
                            "is_enabled_for_campaign_form": true,
                            "key": "multi_action",
                            "label": "Multi-Action",
                            "minimal_tip": "Write, Call, Post at Someone, Regulation, Custom Call to Action, and Whitehouse actions at once",
                            "new_icon": "layer-group",
                            "order": 0,
                            "permission_enum": [],
                            "required_registration_fields": [5, 4, 3],
                            "targets_members": false,
                            "value": 27
                        },
                        "no_campaign": {
                            "allow_one_click_registration": false,
                            "can_earn_gamification_points": false,
                            "canadian_grassroots_enabled": false,
                            "custom_target_optional_address_field": false,
                            "gamification_default_point_value": 0,
                            "icon": "fa-pencil-square-o",
                            "in_combined_campaign": false,
                            "in_multi_action_campaign": false,
                            "is_enabled_for_campaign_form": false,
                            "key": "no_campaign",
                            "label": "No Campaign",
                            "order": 0,
                            "permission_enum": [],
                            "required_registration_fields": [],
                            "targets_members": false,
                            "value": 7
                        },
                        "personalized_messages": {
                            "allow_one_click_registration": false,
                            "can_earn_gamification_points": true,
                            "canadian_grassroots_enabled": true,
                            "custom_target_optional_address_field": false,
                            "gamification_default_point_value": 2,
                            "i18n_verb_key": "campaign.personalized_messages.verb",
                            "in_combined_campaign": false,
                            "in_multi_action_campaign": false,
                            "interaction_type": 53,
                            "is_enabled_for_campaign_form": true,
                            "key": "personalized_messages",
                            "label": "Interactive Campaign",
                            "minimal_tip": "Question and response campaigns to send to legislators or be used as surveys.",
                            "new_icon": "magic",
                            "order": 1,
                            "permission_enum": [],
                            "required_registration_fields": [1, 2, 5, 4],
                            "targets_members": true,
                            "value": 28
                        },
                        "petition": {
                            "allow_one_click_registration": true,
                            "can_earn_gamification_points": true,
                            "canadian_grassroots_enabled": true,
                            "custom_target_optional_address_field": false,
                            "gamification_default_point_value": 2,
                            "i18n_verb_key": "campaign.petition.verb",
                            "icon": "fa-pencil",
                            "in_combined_campaign": true,
                            "in_multi_action_campaign": true,
                            "interaction_type": 28,
                            "is_enabled_for_campaign_form": true,
                            "key": "petition",
                            "label": "Custom Call-to-Action",
                            "minimal_label": "Action",
                            "minimal_tip": "Present your grassroots contacts with a custom call to action.",
                            "new_icon": "lightbulb",
                            "order": 3,
                            "permission_enum": [],
                            "required_registration_fields": [4],
                            "targets_members": false,
                            "value": 8
                        },
                        "relationship": {
                            "allow_one_click_registration": false,
                            "can_earn_gamification_points": false,
                            "canadian_grassroots_enabled": false,
                            "custom_target_optional_address_field": false,
                            "gamification_default_point_value": 0,
                            "i18n_verb_key": "",
                            "in_combined_campaign": false,
                            "in_multi_action_campaign": false,
                            "is_enabled_for_campaign_form": false,
                            "key": "relationship",
                            "label": "Relationship",
                            "order": 0,
                            "permission_enum": [],
                            "required_registration_fields": [4],
                            "targets_members": false,
                            "value": 16
                        },
                        "schedule": {
                            "allow_one_click_registration": false,
                            "can_earn_gamification_points": false,
                            "canadian_grassroots_enabled": false,
                            "custom_target_optional_address_field": false,
                            "gamification_default_point_value": 0,
                            "i18n_verb_key": "",
                            "in_combined_campaign": false,
                            "in_multi_action_campaign": false,
                            "interaction_type": 31,
                            "is_enabled_for_campaign_form": false,
                            "key": "schedule",
                            "label": "Scheduled",
                            "order": 0,
                            "permission_enum": [],
                            "required_registration_fields": [4],
                            "targets_members": false,
                            "value": 12
                        },
                        "share": {
                            "allow_one_click_registration": false,
                            "can_earn_gamification_points": true,
                            "canadian_grassroots_enabled": true,
                            "custom_target_optional_address_field": false,
                            "gamification_default_point_value": 1,
                            "i18n_verb_key": "campaign.share.verb",
                            "icon": "fa-bullhorn",
                            "in_combined_campaign": false,
                            "in_multi_action_campaign": false,
                            "interaction_type": 30,
                            "is_enabled_for_campaign_form": true,
                            "key": "share",
                            "label": "Share a Link",
                            "minimal_tip": "Grassroots contacts share a given url on social media.",
                            "new_icon": "comment",
                            "order": 9,
                            "permission_enum": [],
                            "required_registration_fields": [4],
                            "targets_members": false,
                            "value": 11
                        },
                        "test_campaign": {
                            "allow_one_click_registration": false,
                            "can_earn_gamification_points": false,
                            "canadian_grassroots_enabled": false,
                            "custom_target_optional_address_field": false,
                            "gamification_default_point_value": 0,
                            "icon": "fa-pencil-square-o",
                            "in_combined_campaign": false,
                            "in_multi_action_campaign": false,
                            "is_enabled_for_campaign_form": false,
                            "key": "test_campaign",
                            "label": "Test Campaign",
                            "order": 0,
                            "permission_enum": [],
                            "required_registration_fields": [],
                            "targets_members": true,
                            "value": 101
                        },
                        "tweet_at_member": {
                            "allow_one_click_registration": false,
                            "can_earn_gamification_points": true,
                            "canadian_grassroots_enabled": true,
                            "custom_target_optional_address_field": true,
                            "gamification_default_point_value": 2,
                            "i18n_verb_key": "campaign.tweet.verb",
                            "icon": "fa-twitter",
                            "icon_family": "q",
                            "in_combined_campaign": false,
                            "in_multi_action_campaign": true,
                            "interaction_type": 29,
                            "is_enabled_for_campaign_form": true,
                            "key": "tweet_at_member",
                            "label": "Post at Someone",
                            "minimal_label": "Post",
                            "minimal_tip": "Grassroots contacts send a post directly to legislators or a custom handle.",
                            "new_icon": "x-twitter",
                            "order": 6,
                            "permission_enum": [],
                            "required_registration_fields": [4, 5],
                            "targets_members": true,
                            "use_i18n_social_share_key": true,
                            "value": 10
                        },
                        "visit": {
                            "allow_one_click_registration": false,
                            "can_earn_gamification_points": true,
                            "canadian_grassroots_enabled": true,
                            "custom_target_optional_address_field": false,
                            "gamification_default_point_value": 5,
                            "i18n_verb_key": "campaign.visit.verb",
                            "icon": "fa-suitcase",
                            "in_combined_campaign": false,
                            "in_multi_action_campaign": false,
                            "interaction_type": 26,
                            "is_enabled_for_campaign_form": true,
                            "key": "visit",
                            "label": "Request a Visit",
                            "minimal_tip": "Encourage contacts to request to visit the capitol or district office of a legislator.",
                            "new_icon": "paper-plane",
                            "order": 14,
                            "permission_enum": [],
                            "required_data_key": "most_recent_role__address",
                            "required_registration_fields": [4],
                            "targets_members": true,
                            "value": 4
                        },
                        "voter_registration": {
                            "allow_one_click_registration": false,
                            "can_earn_gamification_points": true,
                            "canadian_grassroots_enabled": false,
                            "custom_target_optional_address_field": false,
                            "gamification_default_point_value": 2,
                            "i18n_verb_key": "campaign.voter_registration.verb",
                            "icon": "fa-sticky-note-o",
                            "in_combined_campaign": false,
                            "in_multi_action_campaign": false,
                            "interaction_type": 106,
                            "is_enabled_for_campaign_form": true,
                            "key": "voter_registration",
                            "label": "Voter Registration",
                            "minimal_tip": "Present your grassroots advocates with a Voter Registration widget.",
                            "new_icon": "edit",
                            "order": 13,
                            "permission_enum": [],
                            "required_registration_fields": [4],
                            "targets_members": false,
                            "value": 24
                        },
                        "write_member": {
                            "allow_one_click_registration": true,
                            "can_earn_gamification_points": true,
                            "canadian_grassroots_enabled": true,
                            "custom_target_optional_address_field": true,
                            "gamification_default_point_value": 3,
                            "i18n_verb_key": "campaign.write.verb",
                            "icon": "fa-envelope-o",
                            "in_combined_campaign": true,
                            "in_multi_action_campaign": true,
                            "interaction_type": 25,
                            "is_enabled_for_campaign_form": true,
                            "key": "write_member",
                            "label": "Write a Letter",
                            "minimal_label": "Write",
                            "minimal_tip": "Grassroots contacts send a message or custom email to legislators.",
                            "new_icon": "envelope-open",
                            "order": 2,
                            "permission_enum": [],
                            "required_data_key": "email",
                            "required_registration_fields": [1, 2, 5, 4],
                            "targets_members": true,
                            "use_i18n_social_share_key": true,
                            "value": 2
                        },
                        "write_white_house": {
                            "allow_one_click_registration": true,
                            "can_earn_gamification_points": true,
                            "canadian_grassroots_enabled": false,
                            "custom_target_optional_address_field": false,
                            "gamification_default_point_value": 3,
                            "i18n_verb_key": "campaign.write_white_house.verb",
                            "icon": "fa-envelope-o",
                            "in_combined_campaign": true,
                            "in_multi_action_campaign": true,
                            "interaction_type": 46,
                            "is_enabled_for_campaign_form": true,
                            "key": "write_white_house",
                            "label": "Write to the White House",
                            "minimal_label": "Write",
                            "minimal_tip": "Grassroots contacts send a message to the White House or a custom email.",
                            "new_icon": "pencil-alt",
                            "order": 11,
                            "permission_enum": [],
                            "required_data_key": "email",
                            "required_registration_fields": [4],
                            "targets_members": false,
                            "value": 18
                        }
                    },
                    "CommentCampaignOrganizationTypes": {
                        "__is_enum": true,
                        "default": { "key": "default", "label": "Default (Your organization)", "order": 1, "tip": "All participants will submit a comment on your organization's behalf.", "value": 1 },
                        "none": { "key": "none", "label": "None", "order": 4, "tip": "Do not fill out the organization field. This may cause some comments to not be delivered properly.", "value": 4 },
                        "org_chooses": { "key": "org_chooses", "label": "Same for all", "order": 3, "tip": "Choose a custom organization name that will apply to all participants.", "value": 2 },
                        "supporter_chooses": { "key": "supporter_chooses", "label": "Participant chooses", "order": 2, "tip": "Add a field to the comment submission form that asks each participant for the name of the organization they are part of.", "value": 3 }
                    },
                    "DraftOptions": {
                        "__is_enum": true,
                        "can_edit": { "key": "can_edit", "label": "Full editing", "long_label": "Supporter is Allowed to Edit", "order": 1, "value": 1 },
                        "cannot_edit": { "key": "cannot_edit", "label": "No editing", "long_label": "Supporter is not Allowed to Edit", "order": 3, "value": 3 },
                        "partial_edit": { "key": "partial_edit", "label": "Partial editing", "long_label": "Supporter is Allowed to edit the body", "order": 2, "value": 4 }
                    },
                    "MessageVariationGenerationAiType": {
                        "__is_enum": true,
                        "ai_generated": { "key": "ai_generated", "label": "AI Generated", "order": 1, "tip": "Message generated by AI", "value": 1 },
                        "ai_generated_and_edited": { "key": "ai_generated_and_edited", "label": "AI Generated and Edited", "order": 2, "tip": "Message generated by AI and then edited by a user", "value": 2 }
                    },
                    "MessageVariationsType": {
                        "__is_enum": true,
                        "auto_generated": { "key": "auto_generated", "label": "AI enhanced (content required)", "order": 3, "tip": "Messages generated using AI", "value": 3 },
                        "custom_written": { "key": "custom_written", "label": "Custom written", "order": 2, "tip": "Custom written message variations", "value": 2 },
                        "none": { "key": "none", "label": "None", "order": 1, "tip": "No message variations", "value": 1 }
                    },
                    "ShareStoryType": {
                        "__is_enum": true,
                        "text": { "key": "text", "label": "Text", "value": 1 },
                        "text_and_video": { "key": "text_and_video", "label": "Text and Video", "value": 3 },
                        "video": { "key": "video", "label": "Video", "value": 2 }
                    }
                }
            },
            "enums": {
                "ActionCenterBackgroundStyle": {
                    "__is_enum": true,
                    "color": { "grassroots_admin_label": "Color", "key": "color", "label": "Color", "order": 2, "tip": "Override the default organization design settings background color with a solid color", "value": 2 },
                    "image": { "grassroots_admin_label": "Image", "key": "image", "label": "Image", "order": 3, "tip": "Set Action Center background to be an uploaded image", "value": 1 },
                    "org_design": { "grassroots_admin_label": "Default", "key": "org_design", "label": "Org Design", "order": 1, "tip": "Default to the background color that you have set in your organization design settings", "value": 3 }
                },
                "ActionCenterNavigationBarBackgroundStyle": {
                    "__is_enum": true,
                    "color": { "key": "color", "label": "Color", "tip": "Set Navigation Bar background to be a selected color", "value": 2 },
                    "image": { "key": "image", "label": "Image", "tip": "Set Navigation Bar background to be a selected image", "value": 1 },
                    "transparent": { "key": "transparent", "label": "Transparent", "tip": "Set navigation bar to transparent to allow navigation links to overlay Action Center background", "value": 3 }
                },
                "CallToActionMediaType": {
                    "__is_enum": true,
                    "image": { "key": "image", "label": "Image", "order": 2, "tip": "Display an image within this widget", "value": 2 },
                    "none": { "key": "none", "label": "None", "order": 1, "tip": "Do not display any media within this widget", "value": 1 },
                    "video": { "key": "video", "label": "Video", "order": 3, "tip": "Display a video within this widget", "value": 3 }
                },
                "CardLinkType": {
                    "__is_enum": true,
                    "clickable_button": { "key": "clickable_button", "label": "Clickable Button", "tip": "Card contains a customizable button that links to a chosen page", "value": 3 },
                    "clickable_card": { "key": "clickable_card", "label": "Clickable Card", "tip": "Card is a link to a chosen page", "value": 2 },
                    "static_card": { "key": "static_card", "label": "Static Card", "tip": "Card is not a link", "value": 1 }
                },
                "CardsSectionLayoutType": {
                    "__is_enum": true,
                    "four_cards": { "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsActionCenterWidgetLayouts/cardsSection/fourCardsActive.png", "card_width": 289, "icon_max_height": "60%", "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsActionCenterWidgetLayouts/cardsSection/fourCardsInactive.png", "key": "four_cards", "label": "4 Cards", "num_cards": 4, "order": 4, "value": 4 },
                    "one_card": { "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsActionCenterWidgetLayouts/cardsSection/oneCardActive.png", "card_width": 1210, "icon_max_height": "90%", "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsActionCenterWidgetLayouts/cardsSection/oneCardInactive.png", "key": "one_card", "label": "1 Card", "num_cards": 1, "order": 1, "value": 1 },
                    "three_cards": { "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsActionCenterWidgetLayouts/cardsSection/threeCardsActive.png", "card_width": 391, "icon_max_height": "80%", "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsActionCenterWidgetLayouts/cardsSection/threeCardsInactive.png", "key": "three_cards", "label": "3 Cards", "num_cards": 3, "order": 3, "value": 3 },
                    "two_cards": { "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsActionCenterWidgetLayouts/cardsSection/twoCardsActive.png", "card_width": 596, "icon_max_height": "90%", "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsActionCenterWidgetLayouts/cardsSection/twoCardsInactive.png", "key": "two_cards", "label": "2 Cards", "num_cards": 2, "order": 2, "value": 2 }
                },
                "CustomDataWidgetDisplayType": {
                    "__is_enum": true,
                    "aggregate": {
                        "action_method": "aggregate_custom_data",
                        "action_method_kwargs": ["aggregate_field_and_method_dict", "region_filters"],
                        "key": "aggregate",
                        "label": "Aggregate View",
                        "value": 1,
                        "widget_fields": [1, 2, 3, 17, 4, 5]
                    },
                    "list": {
                        "action_method": "list_custom_data",
                        "action_method_kwargs": ["fields", "custom_data_id", "region_filters"],
                        "key": "list",
                        "label": "List View",
                        "value": 2,
                        "widget_fields": [1, 2, 3, 17, 13, 10, 18, 12, 11, 14]
                    },
                    "map": {
                        "action_method": "map_custom_data",
                        "action_method_kwargs": ["custom_data_id", "region_filters"],
                        "key": "map",
                        "label": "Map View",
                        "value": 3,
                        "widget_fields": [1, 2, 3, 17, 15, 13, 18, 16, 14, 19, 20, 21]
                    }
                },
                "CustomDataWidgetFields": {
                    "__is_enum": true,
                    "aggregate_field_and_method": { "description": "Dict with keys: custom_data_id, field_name, query_operation.field_name is field from numeric_fields on the selected CustomData.query_operation is the value of CustomDataQueryOperations enum item.", "field_name": "aggregate_field_and_method", "is_for_all_display_types": false, "is_required": true, "key": "aggregate_field_and_method", "label": "Aggregated Field and Method", "parent_enum": 5, "validation_type": "dict", "value": 6 },
                    "background_color": { "description": "Map background color.", "field_name": "background_color", "is_for_all_display_types": false, "is_required": false, "key": "background_color", "label": "Map background color", "validation_type": "basestring", "value": 15 },
                    "card_description": { "description": "Optional description field that displays within each card.", "field_name": "description", "is_for_all_display_types": false, "is_required": false, "key": "card_description", "label": "Card Description", "parent_enum": 5, "validation_type": "basestring", "value": 7 },
                    "card_layout": { "description": "The value of CardsSectionLayoutType enum item.", "field_name": "card_layout", "is_for_all_display_types": false, "is_required": true, "key": "card_layout", "label": "Card Layout", "validation_type": "int", "value": 4 },
                    "cards": { "description": "JSON shape mimics that of CardsSection, using a string of index as the key for this dict.", "field_name": "cards", "is_for_all_display_types": false, "is_required": true, "key": "cards", "label": "Cards", "validation_type": "dict", "value": 5 },
                    "custom_data_id": { "description": "ID of the selected CustomData for the widget.", "field_name": "custom_data_id", "is_for_all_display_types": false, "is_required": true, "key": "custom_data_id", "label": "Custom Data", "validation_type": "int", "value": 13 },
                    "display_type": { "description": "The value of CustomDataWidgetDisplayType enum item.", "field_name": "display_type", "is_for_all_display_types": true, "is_required": true, "key": "display_type", "label": "Display Type", "validation_type": "int", "value": 1 },
                    "fa4_icon": { "description": "Icon name should reference the icon selection on Issue edit page in Action Center", "field_name": "fa4_icon", "is_for_all_display_types": false, "is_required": false, "key": "fa4_icon", "label": "FA4 Icon", "parent_enum": 5, "validation_type": "basestring", "value": 8 },
                    "geo_shape_id": { "description": "When provided, only render the single GeoShape inside the provided geoJSON object generated via the geo_shape_type and region fields", "field_name": "geo_shape_id", "is_for_all_display_types": false, "is_required": false, "key": "geo_shape_id", "label": "GeoShapeID", "validation_type": "int", "value": 21 },
                    "geo_shape_type": { "description": "Divisions of the given Region to render(ex: district)", "field_name": "geo_shape_type", "is_for_all_display_types": false, "is_required": true, "key": "geo_shape_type", "label": "GeoShapeType", "validation_type": "int", "value": 20 },
                    "geodata_fields": { "description": "A list of dictionary that has `field_name` and `precedence`.`field_name` are fields on CustomGeoData. They are the same for any custom data: name, address, coordinates, latitude, longitude`precedence` is the order of the field in appearance among all fields.", "field_name": "geodata_fields", "is_for_all_display_types": false, "is_required": false, "key": "geodata_fields", "label": "List column - Geodata", "validation_type": "list", "value": 10 },
                    "height": { "description": "How tall the map or list widget will be in px. Some space will be reserved for padding/margin as well.", "field_name": "height", "is_for_all_display_types": false, "is_required": true, "key": "height", "label": "Height of Widget", "validation_type": "int", "value": 18 },
                    "id": { "description": "Is necessary if you have multiple custom data widgets on the same page.If widget id is not unique and the custom data widgets have the same aggregate field and method but for different regions,the returned data will overwrite each other.", "field_name": "id", "is_for_all_display_types": true, "is_required": false, "key": "id", "label": "Widget Id", "validation_type": "int", "value": 17 },
                    "image_url": { "description": "Image URL to display in place of Icon.When filled, the card will show the image regardless of what is filled in for fa4_icon field", "field_name": "image_url", "is_for_all_display_types": false, "is_required": false, "key": "image_url", "label": "Image URL", "parent_enum": 5, "validation_type": "basestring", "value": 9 },
                    "non_numeric_fields": { "description": "A list of dictionary that has `field_name` and `precedence`.`field_name` from `CustomData.non_numeric_fields` that should be handled as a string.`precedence` is the order of the field in appearance among all fields.", "field_name": "non_numeric_fields", "is_for_all_display_types": false, "is_required": false, "key": "non_numeric_fields", "label": "List column - Non-numeric Fields", "validation_type": "list", "value": 12 },
                    "numeric_fields": { "description": "A list of dictionary that has `field_name` and `precedence`.`field_name` from `CustomData.numeric_fields` that should be handled as a float.`precedence` is the order of the field in appearance among all fields.", "field_name": "numeric_fields", "is_for_all_display_types": false, "is_required": false, "key": "numeric_fields", "label": "List column - Numeric Fields", "validation_type": "list", "value": 11 },
                    "pin_color": { "description": "Map pin Color.", "field_name": "pin_color", "is_for_all_display_types": false, "is_required": false, "key": "pin_color", "label": "Map pin Color", "validation_type": "basestring", "value": 16 },
                    "region": { "description": "Region to render", "field_name": "region", "is_for_all_display_types": false, "is_required": true, "key": "region", "label": "region", "validation_type": "int", "value": 19 },
                    "region_filters": { "description": "Dict based on the smallest region type to display. Available region types are:country_id, state_id, district_id, county_id, upperld_id, lowerld_id.As of Nov 2022, we only support custom data for US region, so country_id should only be USA (id=234).", "field_name": "region_filters", "is_for_all_display_types": true, "is_required": true, "key": "region_filters", "label": "Region", "validation_type": "dict", "value": 3 },
                    "widget_description": { "description": "Widget description that is shown under the list or map.", "field_name": "description", "is_for_all_display_types": false, "is_required": false, "key": "widget_description", "label": "Widget Description", "validation_type": "basestring", "value": 14 },
                    "widget_title": { "description": "Optional title displayed at the top of the widget.", "field_name": "title", "is_for_all_display_types": true, "is_required": false, "key": "widget_title", "label": "Widget Title", "validation_type": "basestring", "value": 2 }
                },
                "CustomDocketLinkStatus": {
                    "__is_enum": true,
                    "created": { "key": "created", "label": "Created", "value": 3 },
                    "in_progress": { "key": "in_progress", "label": "In progress", "value": 2 },
                    "not_possible": { "key": "not_possible", "label": "Not possible", "value": 4 },
                    "requested": { "key": "requested", "label": "Requested", "value": 1 }
                },
                "ImageCollageLayoutType": {
                    "__is_enum": true,
                    "four_images": { "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsActionCenterWidgetLayouts/imageCollage/fourImagesActive.png", "icon_max_height": "100%", "image_height": 390, "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsActionCenterWidgetLayouts/imageCollage/fourImagesInactive.png", "key": "four_images", "label": "4 Images", "num_images": 4, "num_rows": 2, "order": 4, "value": 4 },
                    "three_images_one_row": { "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsActionCenterWidgetLayouts/imageCollage/threeImagesOneRowActive.png", "icon_max_height": "100%", "image_height": 260, "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsActionCenterWidgetLayouts/imageCollage/threeImagesOneRowInactive.png", "key": "three_images_one_row", "label": "3 Images", "num_images": 3, "num_rows": 1, "order": 3, "value": 3 },
                    "three_images_two_rows": { "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsActionCenterWidgetLayouts/imageCollage/threeImagesTwoRowsActive.png", "icon_max_height": "100%", "image_height": 390, "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsActionCenterWidgetLayouts/imageCollage/threeImagesTwoRowsInactive.png", "key": "three_images_two_rows", "label": "3 Images", "num_images": 3, "num_rows": 2, "order": 2, "value": 2 },
                    "two_images": { "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsActionCenterWidgetLayouts/imageCollage/twoImagesActive.png", "icon_max_height": "100%", "image_height": 390, "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsActionCenterWidgetLayouts/imageCollage/twoImagesInactive.png", "key": "two_images", "label": "2 Images", "num_images": 2, "num_rows": 1, "order": 1, "value": 1 }
                },
                "ImageVideoCarouselWidgetType": {
                    "__is_enum": true,
                    "image": { "key": "image", "label": "Image", "value": 1 },
                    "video": { "key": "video", "label": "Video", "value": 2 }
                },
                "NumberStatsLayoutType": {
                    "__is_enum": true,
                    "four_stats": { "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsActionCenterWidgetLayouts/numberStats/fourStatsActive.png", "card_width": "19.3vw", "icon_max_height": "40%", "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsActionCenterWidgetLayouts/numberStats/fourStatsInactive.png", "key": "four_stats", "label": "4 Stats", "num_stats": 4, "order": 4, "stat_description_font_size": "1.9rem", "stat_font_size": "3.8rem", "stat_line_height": "4.5rem", "stat_title_font_size": "2.4rem", "value": 4 },
                    "one_stat": { "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsActionCenterWidgetLayouts/numberStats/oneStatActive.png", "card_width": "26.48vw", "icon_max_height": "80%", "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsActionCenterWidgetLayouts/numberStats/oneStatInactive.png", "key": "one_stat", "label": "1 Stat", "num_stats": 1, "order": 1, "stat_description_font_size": "2rem", "stat_font_size": "4.8rem", "stat_line_height": "6rem", "stat_title_font_size": "2.5rem", "value": 1 },
                    "three_stats": { "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsActionCenterWidgetLayouts/numberStats/threeStatsActive.png", "card_width": "26.48vw", "icon_max_height": "60%", "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsActionCenterWidgetLayouts/numberStats/threeStatsInactive.png", "key": "three_stats", "label": "3 Stats", "num_stats": 3, "order": 3, "stat_description_font_size": "2rem", "stat_font_size": "4.8rem", "stat_line_height": "6rem", "stat_title_font_size": "2.5rem", "value": 3 },
                    "two_stats": { "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsActionCenterWidgetLayouts/numberStats/twoStatsActive.png", "card_width": "26.48vw", "icon_max_height": "80%", "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsActionCenterWidgetLayouts/numberStats/twoStatsInactive.png", "key": "two_stats", "label": "2 Stats", "num_stats": 2, "order": 2, "stat_description_font_size": "2rem", "stat_font_size": "4.8rem", "stat_line_height": "6rem", "stat_title_font_size": "2.5rem", "value": 2 }
                },
                "Phone2ActionActionType": {
                    "__is_enum": true,
                    "connection": { "key": "connection", "label": "Legislator Connection", "value": 1 },
                    "membership": { "key": "membership", "label": "Joined Non-Legislative Campaign", "value": 2 },
                    "membership_email": { "key": "membership_email", "label": "Joined Email Campaign", "value": 3 },
                    "membership_ptc": { "key": "membership_ptc", "label": "Joined PTC Campaign", "value": 5 },
                    "membership_twitter": { "key": "membership_twitter", "label": "Joined Twitter Campaign", "value": 4 }
                },
                "Phone2ActionIntegrationType": {
                    "__is_enum": true,
                    "luminate": { "key": "luminate", "label": "Luminate Integration", "value": 3 },
                    "mailchimp": { "key": "mailchimp", "label": "Mailchimp Integration", "value": 4 },
                    "nationbuilder": { "key": "nationbuilder", "label": "NationBuilder Integration", "value": 2 },
                    "salesforce": { "key": "salesforce", "label": "Salesforce Integration", "value": 1 }
                },
                "SocialMediaType": {
                    "__is_enum": true,
                    "facebook": { "color": "#3B5998", "icon": "fa-facebook-square", "key": "facebook", "label": "Facebook", "supported": true, "value": 1 },
                    "google_plus": { "color": "#dd5044", "key": "google_plus", "label": "Google Plus", "supported": false, "value": 5 },
                    "linkedin": { "color": "#0177b5", "icon": "fa-linkedin-square", "key": "linkedin", "label": "LinkedIn", "supported": true, "value": 3 },
                    "pinterest": { "color": "#d8121d", "icon": "fa-pinterest", "key": "pinterest", "label": "Pinterest", "supported": true, "value": 4 },
                    "twitter": { "color": "#4099FF", "icon": "fa-twitter", "key": "twitter", "label": "Twitter", "supported": true, "value": 2 }
                },
                "SupporterActionType": {
                    "__is_enum": true,
                    "acted": { "key": "acted", "label": "Acted", "past_tense": "acted on a campaign", "value": 1 },
                    "authorized_contribution": { "key": "authorized_contribution", "label": "Authorized Contribution", "past_tense": "submitted an authorized contribution", "value": 103 },
                    "called": { "key": "called", "label": "Called", "past_tense": "called their legislator", "value": 5 },
                    "combined_campaign": { "key": "combined_campaign", "label": "Participated", "past_tense": "participated in a combined campaign", "value": 19 },
                    "commented_on_regulation": { "key": "commented_on_regulation", "label": "Commented", "past_tense": "commented on a regulation", "value": 15 },
                    "custom_points": { "key": "custom_points", "label": "Recieved Custom Points", "past_tense": "received custom points", "value": 102 },
                    "interacted": { "key": "interacted", "label": "Interacted", "past_tense": "logged an interaction", "value": 3 },
                    "personalized_message": { "key": "personalized_message", "label": "Personalized a message", "past_tense": "personalized a message", "value": 28 },
                    "registered": { "key": "registered", "label": "Registered", "past_tense": "registered", "value": 7 },
                    "registered_to_vote": { "key": "registered_to_vote", "label": "Registered to Vote", "past_tense": "registered to vote", "value": 24 },
                    "scheduled": { "key": "scheduled", "label": "Scheduled", "past_tense": "scheduled a visit", "value": 12 },
                    "shared": { "key": "shared", "label": "Shared", "past_tense": "shared on social media", "value": 11 },
                    "shared_story": { "key": "shared_story", "label": "Shared Story", "past_tense": "shared a story", "value": 17 },
                    "signed": { "key": "signed", "label": "Participated", "past_tense": "participated in campaign", "value": 8 },
                    "submit_contact_us_widget": { "key": "submit_contact_us_widget", "label": "Submitted Contact Us Widget", "past_tense": "submitted Contact Us widget", "value": 26 },
                    "submit_gotv_widget": { "key": "submit_gotv_widget", "label": "Submitted GOTV Widget", "past_tense": "submitted GOTV widget", "value": 23 },
                    "test_action": { "key": "test_action", "label": "Took test action", "past_tense": "took test action", "value": 101 },
                    "tweeted": { "key": "tweeted", "label": "Tweeted", "past_tense": "tweeted at their legislator", "value": 10 },
                    "visited": { "key": "visited", "label": "Visited", "past_tense": "visited their legislator", "value": 4 },
                    "wrote": { "key": "wrote", "label": "Wrote", "past_tense": "wrote their legislator", "value": 2 },
                    "wrote_white_house": { "key": "wrote_white_house", "label": "Wrote", "past_tense": "wrote White House", "value": 18 }
                },
                "SupporterTagOptionDisplayType": {
                    "__is_enum": true,
                    "dropdown_display": { "client_facing_label": "Display the options as a dropdown", "column_data_type": "integer", "key": "dropdown_display", "label": "Dropdown", "order": 1, "short_client_facing_label": "Dropdown", "slug": "dropdown", "value": 0 },
                    "list_display": { "client_facing_label": "Display the options as a list", "column_data_type": "integer", "key": "list_display", "label": "List", "order": 2, "short_client_facing_label": "List", "slug": "list", "value": 1 }
                }
            },
            "models": {
                "CustomTag": {
                    "__is_model": true,
                    "__name__": "CustomTag",
                    "data_model": "app.grassroots.models.CustomTag",
                    "default_exclude_filters": {},
                    "default_filters": { "archived": false },
                    "endpoint": "/api/customtag/",
                    "fields": []
                },
                "GrassrootsCustomTag": {
                    "__is_model": true,
                    "__name__": "GrassrootsCustomTag",
                    "data_model": "app.grassroots.models.GrassrootsCustomTag",
                    "default_exclude_filters": {},
                    "default_filters": { "archived": false },
                    "endpoint": "/api/grassrootscustomtag/",
                    "fields": [],
                    "proxy_endpoint": "/api/customtag/"
                },
                "GrassrootsPageVisit": {
                    "__is_model": true,
                    "__name__": "GrassrootsPageVisit",
                    "data_model": "app.grassroots.models.GrassrootsPageVisit",
                    "default_exclude_filters": {},
                    "default_filters": {},
                    "endpoint": "/api/grassrootspagevisit/",
                    "fields": []
                },
                "GrassrootsRegistrationPage": {
                    "__is_model": true,
                    "__name__": "GrassrootsRegistrationPage",
                    "data_model": "app.grassroots.models.GrassrootsRegistrationPage",
                    "default_exclude_filters": {},
                    "default_filters": { "archived": false },
                    "endpoint": "/api/grassrootsregistrationpage/",
                    "fields": []
                },
                "GrassrootsSupporterAction": {
                    "__is_model": true,
                    "__name__": "GrassrootsSupporterAction",
                    "data_model": "app.grassroots.models.GrassrootsSupporterAction",
                    "default_exclude_filters": { "interaction_type": 34 },
                    "default_filters": { "archived": false },
                    "endpoint": "/api/supporteraction/",
                    "fields": []
                },
                "NewSupporter": {
                    "__is_model": true,
                    "__name__": "NewSupporter",
                    "data_model": "app.grassroots.models.NewSupporter",
                    "default_exclude_filters": {},
                    "default_filters": { "archived": false },
                    "endpoint": "/api/newsupporter/",
                    "fields": [],
                    "proxy_endpoint": "/api/supporter/"
                },
                "Supporter": {
                    "__is_model": true,
                    "__name__": "Supporter",
                    "data_model": "app.grassroots.models.Supporter",
                    "default_exclude_filters": {},
                    "default_filters": { "archived": false },
                    "endpoint": "/api/supporter/",
                    "fields": []
                }
            },
            "types": {
                "ActionCenterTheme": {
                    "__is_enum": true,
                    "default": { "image_url": "https://static.quorum.us/global/frontend/images/grassrootsActionCenterThemes/boxy.png", "key": "default", "label": "Classic", "name": "Classic", "top_label": "Self contained, grid oriented with additional padding", "value": 1 },
                    "impact": { "image_url": "https://static.quorum.us/global/frontend/images/grassrootsActionCenterThemes/impact.png", "key": "impact", "label": "Editorial", "name": "Editorial", "top_label": "Full width, high impact storytelling", "value": 2 }
                },
                "DonationFormType": {
                    "__is_enum": true,
                    "nonprofit_fundraising": { "key": "nonprofit_fundraising", "label": "Nonprofit Fundraising", "value": 2 },
                    "pac": { "key": "pac", "label": "PAC", "value": 1 }
                },
                "FormInputType": {
                    "__is_enum": true,
                    "address": { "key": "address", "label": "Address", "value": 5 },
                    "email": { "key": "email", "label": "Email", "value": 8 },
                    "multi_select": { "key": "multi_select", "label": "Multi Select", "value": 4 },
                    "numeric": { "key": "numeric", "label": "Numeric", "value": 6 },
                    "phone_number": { "key": "phone_number", "label": "Phone Number", "value": 7 },
                    "single_select": { "key": "single_select", "label": "Single Select", "value": 3 },
                    "text": { "key": "text", "label": "Text", "value": 1 },
                    "toggle": { "key": "toggle", "label": "Toggle", "value": 2 }
                },
                "GamificationAchievementIconType": {
                    "__is_enum": true,
                    "star": { "fa4_icon": "fa-star", "key": "star", "label": "Star", "value": 2 },
                    "trophy": { "fa4_icon": "fa-trophy", "key": "trophy", "label": "Trophy", "value": 1 }
                },
                "GamificationCampaignPointType": {
                    "__is_enum": true,
                    "custom": { "key": "custom", "label": "Assign Points for Each Campaign Action", "tip": "Specify the amount of points each campaign type is worth", "value": 1 },
                    "same": { "key": "same", "label": "Assign Same Point Value to All Campaign Actions", "tip": "Specify one point value that all campaigns are worth", "value": 2 }
                },
                "GamificationPointsMode": {
                    "__is_enum": true,
                    "custom_points": { "key": "custom_points", "label": "Custom Points", "order": 2, "tip": "Use a custom Gamification points for this campaign", "value": 2 },
                    "default": { "key": "default", "label": "Default", "order": 1, "tip": "Use the default Gamification points for the given campaign type", "value": 1 },
                    "disable": { "key": "disable", "label": "Disable", "order": 3, "tip": "Disable Gamification for this campaign", "value": 3 }
                },
                "GamificationRankType": {
                    "__is_enum": true,
                    "fifteen": { "key": "fifteen", "label": "Top 15%", "percentile": 15, "value": 4 },
                    "fifty": { "key": "fifty", "label": "Top 50%", "percentile": 50, "value": 1 },
                    "five": { "key": "five", "label": "Top 5%", "percentile": 5, "value": 6 },
                    "one": { "key": "one", "label": "Top 1%", "percentile": 1, "value": 7 },
                    "ten": { "key": "ten", "label": "Top 10%", "percentile": 10, "value": 5 },
                    "twenty": { "key": "twenty", "label": "Top 20%", "percentile": 20, "value": 3 },
                    "twenty_five": { "key": "twenty_five", "label": "Top 25%", "percentile": 25, "value": 2 }
                },
                "GrassrootsContextField": {
                    "__is_enum": true,
                    "campaign_list": {
                        "additional_fields_required": false,
                        "detail": false,
                        "key": "campaign_list",
                        "label": "campaign_list",
                        "model": "app.grassroots.campaign.models.GrassrootsCampaign",
                        "order_by": ["-order", "id"],
                        "pagination_amount": 20,
                        "resource": "app.grassroots.campaign.newapi.GrassrootsCampaignResource",
                        "should_paginate": true,
                        "store_key": "campaignList",
                        "update_on_login": false,
                        "value": 1
                    },
                    "custom_fields": { "additional_fields_required": false, "detail": false, "key": "custom_fields", "label": "custom_fields", "model": "app.grassroots.models.GrassrootsCustomTag", "pagination_amount": 20, "resource": "app.grassroots.newapi.GrassrootsCustomTagResource", "should_paginate": false, "store_key": "customFields", "update_on_login": false, "value": 2 },
                    "donation_forms": {
                        "additional_fields_required": false,
                        "detail": false,
                        "feature_flags": ["ff_pac"],
                        "filters": { "grassroots_form_type": 2 },
                        "key": "donation_forms",
                        "label": "donation_forms",
                        "model": "app.grassroots.models.GrassrootsRegistrationPage",
                        "only_fields": ["allowed_donation_methods", "allowed_payroll_donation_types", "disclaimer_text", "donation_amount_credit_prompt_text", "donation_amount_payroll_prompt_text", "donation_amounts", "donation_amounts_check", "donation_amounts_credit", "donation_amounts_payroll", "donation_amounts_check", "donation_form_type", "donation_method_prompt_text", "check_mailing_instruction", "check_mailing_address", "donation_percentages_payroll", "enabled_pac_match", "form_fields", "id", "pac_classic_site", "pac_match_description", "pac_match_select_label", "pac_match_threshold", "payroll_amount_label", "payroll_percentage_label", "payroll_frequency_type", "post_submission_action_type", "post_text", "pre_text", "redirect_url", "submit_button_text", "thank_you_text", "terms_and_conditions_switch", "custom_javascript", "custom_css", "custom_after_registration_javascript"],
                        "pagination_amount": 20,
                        "should_paginate": false,
                        "store_key": "donationForms",
                        "update_on_login": false,
                        "value": 6
                    },
                    "event_list": { "additional_fields_required": false, "detail": false, "key": "event_list", "label": "event_list", "model": "app.custom_event.models.GrassrootsCustomEvent", "pagination_amount": 20, "resource": "app.grassroots.newapi.GrassrootsCustomEventResource", "should_paginate": false, "store_key": "eventList", "update_on_login": false, "value": 5 },
                    "issue_list": {
                        "additional_fields_required": false,
                        "detail": false,
                        "filters": { "archived": false },
                        "key": "issue_list",
                        "label": "issue_list",
                        "model": "app.userdata.models.GrassrootsIssue",
                        "pagination_amount": 20,
                        "resource": "app.grassroots.newapi.GrassrootsIssueResource",
                        "should_paginate": false,
                        "store_key": "issueList",
                        "update_on_login": false,
                        "value": 4
                    },
                    "registration_pages": {
                        "additional_fields_required": false,
                        "detail": false,
                        "filters": { "grassroots_form_type": 1 },
                        "key": "registration_pages",
                        "label": "registration_pages",
                        "model": "app.grassroots.models.GrassrootsRegistrationPage",
                        "only_fields": ["id", "form_fields", "pre_text", "post_text", "post_submission_action_type", "submit_button_text", "thank_you_text", "redirect_url", "custom_javascript", "custom_after_registration_javascript", "custom_css", "should_allow_partial_address"],
                        "pagination_amount": 20,
                        "should_paginate": false,
                        "store_key": "registrationPages",
                        "update_on_login": false,
                        "value": 3
                    }
                },
                "GrassrootsFormType": {
                    "__is_enum": true,
                    "donation": { "key": "donation", "label": "Donation Form", "value": 2 },
                    "registration": { "key": "registration", "label": "Registration Form", "value": 1 }
                },
                "GrassrootsFoundationalRoute": {
                    "__is_enum": true,
                    "forgot_password": { "anonymous_only": true, "key": "forgot_password", "label": "2", "logged_in_only": false, "page_title": "Recover", "path": "/recover/", "private_acs_only": true, "value": 2 },
                    "logout": { "anonymous_only": false, "key": "logout", "label": "1", "logged_in_only": true, "page_title": "Logout", "path": "/logout/", "private_acs_only": false, "value": 1 },
                    "reset_password": { "anonymous_only": false, "key": "reset_password", "label": "3", "logged_in_only": true, "page_title": "Reset Password", "path": "/reset_password/", "private_acs_only": true, "value": 3 }
                },
                "GrassrootsNavigationBarButtonType": {
                    "__is_enum": true,
                    "dropdown_menu": { "exclude_i360_client_with_stakeholder": false, "key": "dropdown_menu", "label": "Dropdown Menu", "value": 1 },
                    "external": { "exclude_i360_client_with_stakeholder": false, "key": "external", "label": "External Link", "value": 7 },
                    "interaction_logger": {
                        "exclude_i360_client_with_stakeholder": false,
                        "key": "interaction_logger",
                        "label": "Link to Interaction Logger",
                        "page_url": "/log/",
                        "permission_enum": [7],
                        "value": 8
                    },
                    "list_campaigns": {
                        "exclude_i360_client_with_stakeholder": true,
                        "key": "list_campaigns",
                        "label": "Link to List of Campaigns",
                        "page_url": "/campaigns/",
                        "permission_enum": [201],
                        "value": 4
                    },
                    "list_custom_events": {
                        "exclude_i360_client_with_stakeholder": false,
                        "key": "list_custom_events",
                        "label": "Link to List of Events",
                        "page_url": "/events/",
                        "permission_enum": [206],
                        "value": 11
                    },
                    "list_issues": {
                        "exclude_i360_client_with_stakeholder": false,
                        "key": "list_issues",
                        "label": "Link to List of Issues",
                        "page_url": "/issues/",
                        "permission_enum": [201, 7, 5],
                        "value": 3
                    },
                    "list_legislators": {
                        "exclude_i360_client_with_stakeholder": false,
                        "key": "list_legislators",
                        "label": "Link to List of Legislators",
                        "page_url": "/officials/",
                        "permission_enum": [201],
                        "value": 6
                    },
                    "relationship_logger": {
                        "exclude_i360_client_with_stakeholder": false,
                        "key": "relationship_logger",
                        "label": "Link to Relationship Logger",
                        "page_url": "/relationship/",
                        "permission_enum": [5],
                        "value": 9
                    },
                    "single_campaign": {
                        "exclude_i360_client_with_stakeholder": true,
                        "key": "single_campaign",
                        "label": "Link to Single Campaign",
                        "permission_enum": [201],
                        "value": 5
                    },
                    "single_custom_event": {
                        "exclude_i360_client_with_stakeholder": false,
                        "key": "single_custom_event",
                        "label": "Link to Single Event",
                        "permission_enum": [206],
                        "value": 10
                    },
                    "single_donation_form": {
                        "exclude_i360_client_with_stakeholder": false,
                        "key": "single_donation_form",
                        "label": "Link to a Donation Form",
                        "permission_enum": [8],
                        "value": 13
                    },
                    "single_issue": {
                        "exclude_i360_client_with_stakeholder": false,
                        "key": "single_issue",
                        "label": "Link to Single Issue",
                        "permission_enum": [201],
                        "value": 12
                    },
                    "single_page": {
                        "exclude_i360_client_with_stakeholder": false,
                        "key": "single_page",
                        "label": "Link to Single Page",
                        "permission_enum": [201],
                        "value": 2
                    }
                },
                "GrassrootsPageWidgetLayoutType": {
                    "__is_enum": true,
                    "eight_rows": {
                        "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout18(8)-Selected.png",
                        "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout18(8)-Neutral.png",
                        "key": "eight_rows",
                        "label": "Eight Rows",
                        "num_widgets": 8,
                        "order": 18,
                        "should_use_icon_path": true,
                        "value": 18,
                        "widget_is_full_width": [true, true, true, true, true, true, true, true]
                    },
                    "five_rows": {
                        "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout15(5)-Selected.png",
                        "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout15(5)-Neutral.png",
                        "key": "five_rows",
                        "label": "Five Rows",
                        "num_widgets": 5,
                        "order": 15,
                        "should_use_icon_path": true,
                        "value": 15,
                        "widget_is_full_width": [true, true, true, true, true]
                    },
                    "four_rows": {
                        "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout14(4)-Selected.png",
                        "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout14(4)-Neutral.png",
                        "key": "four_rows",
                        "label": "Four Rows",
                        "num_widgets": 4,
                        "order": 14,
                        "should_use_icon_path": true,
                        "value": 9,
                        "widget_is_full_width": [true, true, true, true]
                    },
                    "four_square": {
                        "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout7(4)-Selected.png",
                        "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout7(4)-Neutral.png",
                        "key": "four_square",
                        "label": "Four Square",
                        "num_widgets": 4,
                        "order": 7,
                        "should_use_icon_path": true,
                        "value": 10,
                        "widget_is_full_width": [false, false, false, false]
                    },
                    "left_full_column": {
                        "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout4(3)-Selected.png",
                        "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout4(3)-Neutral.png",
                        "key": "left_full_column",
                        "label": "Left Full Column",
                        "num_widgets": 3,
                        "order": 4,
                        "should_use_icon_path": true,
                        "value": 4,
                        "widget_is_full_width": [false, false, false]
                    },
                    "nine_rows": {
                        "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout19(9)-Selected.png",
                        "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout19(9)-Neutral.png",
                        "key": "nine_rows",
                        "label": "Nine Rows",
                        "num_widgets": 9,
                        "order": 19,
                        "should_use_icon_path": true,
                        "value": 19,
                        "widget_is_full_width": [true, true, true, true, true, true, true, true, true]
                    },
                    "one_top_four_bottom": {
                        "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout8(5)-Selected.png",
                        "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout8(5)-Neutral.png",
                        "key": "one_top_four_bottom",
                        "label": "One Top Four Bottom",
                        "num_widgets": 5,
                        "order": 8,
                        "should_use_icon_path": true,
                        "value": 11,
                        "widget_is_full_width": [true, false, false, false, false]
                    },
                    "right_full_column": {
                        "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout3(3)-Selected.png",
                        "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout3(3)-Neutral.png",
                        "key": "right_full_column",
                        "label": "Right Full Column",
                        "num_widgets": 3,
                        "order": 3,
                        "should_use_icon_path": true,
                        "value": 3,
                        "widget_is_full_width": [false, false, false]
                    },
                    "seven_rows": {
                        "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout17(7)-Selected.png",
                        "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout17(7)-Neutral.png",
                        "key": "seven_rows",
                        "label": "Seven Rows",
                        "num_widgets": 7,
                        "order": 17,
                        "should_use_icon_path": true,
                        "value": 17,
                        "widget_is_full_width": [true, true, true, true, true, true, true]
                    },
                    "single_column": {
                        "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout1(1)-Selected.png",
                        "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout1(1)-Neutral.png",
                        "key": "single_column",
                        "label": "Single Column",
                        "num_widgets": 1,
                        "order": 1,
                        "should_use_icon_path": true,
                        "value": 1,
                        "widget_is_full_width": [true]
                    },
                    "six_rows": {
                        "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout16(6)-Selected.png",
                        "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout16(6)-Neutral.png",
                        "key": "six_rows",
                        "label": "Six Rows",
                        "num_widgets": 6,
                        "order": 16,
                        "should_use_icon_path": true,
                        "value": 16,
                        "widget_is_full_width": [true, true, true, true, true, true]
                    },
                    "six_square": {
                        "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout11(6)-Selected.png",
                        "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout11(6)-Neutral.png",
                        "key": "six_square",
                        "label": "Six Square",
                        "num_widgets": 6,
                        "order": 11,
                        "should_use_icon_path": true,
                        "value": 14,
                        "widget_is_full_width": [false, false, false, false, false, false]
                    },
                    "ten_rows": {
                        "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout20(10)-Selected.png",
                        "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout20(10)-Neutral.png",
                        "key": "ten_rows",
                        "label": "Ten Rows",
                        "num_widgets": 10,
                        "order": 20,
                        "should_use_icon_path": true,
                        "value": 20,
                        "widget_is_full_width": [true, true, true, true, true, true, true, true, true, true]
                    },
                    "three_left_two_right": {
                        "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout9(5)-Selected.png",
                        "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout9(5)-Neutral.png",
                        "key": "three_left_two_right",
                        "label": "Three Left Two Right",
                        "num_widgets": 5,
                        "order": 9,
                        "should_use_icon_path": true,
                        "value": 12,
                        "widget_is_full_width": [false, false, false, false, false]
                    },
                    "three_rows": {
                        "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout13(3)-Selected.png",
                        "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout13(3)-Neutral.png",
                        "key": "three_rows",
                        "label": "Three Rows",
                        "num_widgets": 3,
                        "order": 13,
                        "should_use_icon_path": true,
                        "value": 8,
                        "widget_is_full_width": [true, true, true]
                    },
                    "two_columns": {
                        "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout2(2)-Selected.png",
                        "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout2(2)-Neutral.png",
                        "key": "two_columns",
                        "label": "Two Columns",
                        "num_widgets": 2,
                        "order": 2,
                        "should_use_icon_path": true,
                        "value": 2,
                        "widget_is_full_width": [false, false]
                    },
                    "two_left_three_right": {
                        "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout10(5)-Selected.png",
                        "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout10(5)-Neutral.png",
                        "key": "two_left_three_right",
                        "label": "Two Left Three Right",
                        "num_widgets": 5,
                        "order": 10,
                        "should_use_icon_path": true,
                        "value": 13,
                        "widget_is_full_width": [false, false, false, false, false]
                    },
                    "two_rows": {
                        "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout12(2)-Selected.png",
                        "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout12(2)-Neutral.png",
                        "key": "two_rows",
                        "label": "Two Rows",
                        "num_widgets": 2,
                        "order": 12,
                        "should_use_icon_path": true,
                        "value": 5,
                        "widget_is_full_width": [true, true]
                    },
                    "two_rows_half_bottom": {
                        "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout5(3)-Selected.png",
                        "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout5(3)-Neutral.png",
                        "key": "two_rows_half_bottom",
                        "label": "Two Rows Half Bottom",
                        "num_widgets": 3,
                        "order": 5,
                        "should_use_icon_path": true,
                        "value": 6,
                        "widget_is_full_width": [true, false, false]
                    },
                    "two_rows_half_top": {
                        "active_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout6(3)-Selected.png",
                        "inactive_icon_path": "https://static.quorum.us/global/frontend/images/grassrootsPageIcons/2021/Layout6(3)-Neutral.png",
                        "key": "two_rows_half_top",
                        "label": "Two Rows Half Top",
                        "num_widgets": 3,
                        "order": 6,
                        "should_use_icon_path": true,
                        "value": 7,
                        "widget_is_full_width": [false, false, true]
                    }
                },
                "GrassrootsRegistrationField": {
                    "__is_enum": true,
                    "address": {
                        "defaults": { "width": 1 },
                        "disabled": false,
                        "fb_lead_question_type": "STREET_ADDRESS",
                        "i18n_label_key": "registration.address.label",
                        "i18n_placeholder_key": "registration.address.placeholder",
                        "input_type": 5,
                        "key": "address",
                        "label": "Address",
                        "required_props": [],
                        "supporter_field": "input_address",
                        "value": 5
                    },
                    "custom_field": {
                        "defaults": { "width": 0.5 },
                        "disabled": false,
                        "fb_lead_question_type": "CUSTOM",
                        "i18n_label_key": "registration.phone.label",
                        "i18n_placeholder_key": "registration.phone.placeholder",
                        "key": "custom_field",
                        "label": "Custom Field",
                        "required_props": ["id"],
                        "supporter_field": "tag_dict",
                        "value": 100
                    },
                    "email": {
                        "defaults": { "width": 0.5 },
                        "disabled": false,
                        "fb_lead_question_type": "EMAIL",
                        "i18n_label_key": "registration.email.label",
                        "i18n_placeholder_key": "registration.email.placeholder",
                        "input_type": 8,
                        "key": "email",
                        "label": "Email",
                        "required_props": [],
                        "supporter_field": "email",
                        "value": 4
                    },
                    "employer": {
                        "defaults": { "width": 0.5 },
                        "disabled": false,
                        "fb_lead_question_type": "CUSTOM",
                        "i18n_label_key": "registration.employer.label",
                        "i18n_placeholder_key": "registration.employer.placeholder",
                        "input_type": 1,
                        "key": "employer",
                        "label": "Employer",
                        "required_props": [],
                        "supporter_field": "employer",
                        "value": 12
                    },
                    "firstname": {
                        "defaults": { "input_type": 1, "width": 0.5 },
                        "disabled": false,
                        "fb_lead_question_type": "FIRST_NAME",
                        "i18n_label_key": "registration.firstname.label",
                        "i18n_placeholder_key": "registration.firstname.placeholder",
                        "input_type": 1,
                        "key": "firstname",
                        "label": "First Name",
                        "required_props": [],
                        "supporter_field": "firstname",
                        "value": 1
                    },
                    "fullname": {
                        "defaults": { "input_type": 1, "width": 0.5 },
                        "disabled": false,
                        "fb_lead_question_type": "FULL_NAME",
                        "i18n_label_key": "registration.fullname.label",
                        "i18n_placeholder_key": "registration.fullname.placeholder",
                        "input_type": 1,
                        "key": "fullname",
                        "label": "Full Name",
                        "required_props": [],
                        "supporter_field": "name",
                        "value": 13
                    },
                    "lastname": {
                        "defaults": { "input_type": 1, "width": 0.5 },
                        "disabled": false,
                        "fb_lead_question_type": "LAST_NAME",
                        "i18n_label_key": "registration.lastname.label",
                        "i18n_placeholder_key": "registration.lastname.placeholder",
                        "input_type": 1,
                        "key": "lastname",
                        "label": "Last Name",
                        "required_props": [],
                        "supporter_field": "lastname",
                        "value": 2
                    },
                    "occupation": {
                        "defaults": { "width": 0.5 },
                        "disabled": false,
                        "fb_lead_question_type": "CUSTOM",
                        "i18n_label_key": "registration.occupation.label",
                        "i18n_placeholder_key": "registration.occupation.placeholder",
                        "input_type": 1,
                        "key": "occupation",
                        "label": "Occupation",
                        "required_props": [],
                        "supporter_field": "occupation",
                        "value": 11
                    },
                    "organization": {
                        "defaults": { "width": 0.5 },
                        "disabled": false,
                        "fb_lead_question_type": "COMPANY_NAME",
                        "i18n_label_key": "registration.organization.label",
                        "i18n_placeholder_key": "registration.organization.placeholder",
                        "input_type": 1,
                        "key": "organization",
                        "label": "Organization",
                        "required_props": [],
                        "supporter_field": "public_organization_name",
                        "value": 9
                    },
                    "phone_number": {
                        "defaults": { "input_type": 7, "width": 0.5 },
                        "disabled": false,
                        "fb_lead_question_type": "PHONE",
                        "i18n_label_key": "registration.phone_number.label",
                        "i18n_placeholder_key": "registration.phone_number.placeholder",
                        "input_type": 7,
                        "key": "phone_number",
                        "label": "Phone Number",
                        "required_props": [],
                        "supporter_field": "secondary_phone",
                        "value": 3
                    },
                    "prefix": {
                        "defaults": { "width": 0.5 },
                        "disabled": false,
                        "fb_lead_question_type": "CUSTOM",
                        "i18n_label_key": "registration.prefix.label",
                        "i18n_placeholder_key": "registration.prefix.placeholder",
                        "input_type": 1,
                        "key": "prefix",
                        "label": "Prefix",
                        "required_props": [],
                        "supporter_field": "prefix",
                        "value": 7
                    },
                    "suffix": {
                        "defaults": { "width": 0.5 },
                        "disabled": false,
                        "fb_lead_question_type": "CUSTOM",
                        "i18n_label_key": "registration.suffix.label",
                        "i18n_placeholder_key": "registration.suffix.placeholder",
                        "input_type": 1,
                        "key": "suffix",
                        "label": "Suffix",
                        "required_props": [],
                        "supporter_field": "suffix",
                        "value": 8
                    },
                    "text_opt_in": {
                        "defaults": {
                            "input_type": 2,
                            "toggleChoices": [
                                { "i18n_label_key": "registration.text_opt_in.agree_to_sms", "value": true },
                                { "i18n_label_key": "registration.text_opt_in.decline_sms", "value": false }
                            ],
                            "width": 0.5
                        },
                        "disabled": false,
                        "fb_lead_question_options": [
                            { "key": "true", "value": "I agree to receive SMS messages" },
                            { "key": "false", "value": "I do not wish to receive SMS" }
                        ],
                        "fb_lead_question_type": "CUSTOM",
                        "i18n_label_key": "registration.text_opt_in.sms_opt_in_message",
                        "input_type": 2,
                        "key": "text_opt_in",
                        "label": "Texting Opt-In",
                        "required_props": [],
                        "supporter_field": "outbound_texting_opted_in",
                        "value": 10
                    },
                    "title": {
                        "defaults": { "width": 0.5 },
                        "disabled": false,
                        "fb_lead_question_type": "CUSTOM",
                        "i18n_label_key": "registration.title.label",
                        "i18n_placeholder_key": "registration.title.placeholder",
                        "input_type": 1,
                        "key": "title",
                        "label": "Title",
                        "required_props": [],
                        "supporter_field": "title",
                        "value": 6
                    }
                },
                "GrassrootsWidgetType": {
                    "__is_enum": true,
                    "call_to_action": { "anonymous_only": false, "can_be_independent_page": false, "can_be_used_in_page_form": true, "can_use_custom_background_color": true, "exclude_i360_client_with_stakeholder": false, "full_width": true, "key": "call_to_action", "label": "Call to Action", "logged_in_only": false, "order": 16, "page_form_title_placeholder": "", "page_title": "", "requires_login": false, "tip": "Display an image or video alongside text and a call to action button.", "value": 23 },
                    "campaign": {
                        "anonymous_only": false,
                        "can_be_independent_page": true,
                        "can_be_used_in_page_form": false,
                        "can_use_custom_background_color": false,
                        "context_fields": [2, 3, 1],
                        "exclude_i360_client_with_stakeholder": true,
                        "full_width": false,
                        "key": "campaign",
                        "label": "Campaign",
                        "logged_in_only": false,
                        "order": 1,
                        "page_form_title_placeholder": "",
                        "page_title": "",
                        "permissions": [201],
                        "required_props": ["id"],
                        "requires_login": false,
                        "value": 1,
                        "widget_url": "/campaign/",
                        "widget_url_params": ":slug"
                    },
                    "campaign_list": {
                        "anonymous_only": false,
                        "can_be_independent_page": true,
                        "can_be_used_in_page_form": true,
                        "can_use_custom_background_color": false,
                        "context_fields": [1],
                        "exclude_i360_client_with_stakeholder": true,
                        "full_width": false,
                        "key": "campaign_list",
                        "label": "List of Campaigns",
                        "logged_in_only": false,
                        "order": 7,
                        "page_form_title_placeholder": "",
                        "page_title": "Campaigns",
                        "permissions": [201],
                        "requires_login": false,
                        "tip": "This will create a page of all of your active campaigns.",
                        "value": 101,
                        "widget_url": "/campaigns/"
                    },
                    "cards_section": { "anonymous_only": false, "can_be_independent_page": false, "can_be_used_in_page_form": true, "can_use_custom_background_color": true, "exclude_i360_client_with_stakeholder": false, "full_width": true, "key": "cards_section", "label": "Cards Section", "layout_enum": "CardsSectionLayoutType", "logged_in_only": false, "order": 14, "page_form_title_placeholder": "", "page_title": "", "requires_login": false, "tip": "Display a collection of cards the full width of the page", "value": 21 },
                    "contact_us": { "anonymous_only": false, "can_be_independent_page": true, "can_be_used_in_page_form": true, "can_use_custom_background_color": false, "exclude_i360_client_with_stakeholder": false, "full_width": false, "key": "contact_us", "label": "Contact Us", "logged_in_only": false, "order": 18, "page_form_title_placeholder": "", "page_title": "", "requires_login": false, "tip": "Contact the organization", "value": 25, "widget_url": "/contact_us/" },
                    "custom_data_widget": {
                        "anonymous_only": false,
                        "can_be_independent_page": false,
                        "can_be_used_in_page_form": true,
                        "can_use_custom_background_color": false,
                        "exclude_i360_client_with_stakeholder": false,
                        "feature_flags": ["ff_custom_data_grs_widget"],
                        "full_width": true,
                        "key": "custom_data_widget",
                        "label": "Custom Data",
                        "logged_in_only": false,
                        "order": 14,
                        "page_form_title_placeholder": "",
                        "page_title": "",
                        "permissions": [201, 4],
                        "requires_login": false,
                        "tip": "Display a collection of cards the full width of the page",
                        "value": 105
                    },
                    "district_list": {
                        "anonymous_only": false,
                        "can_be_independent_page": false,
                        "can_be_used_in_page_form": true,
                        "can_use_custom_background_color": false,
                        "exclude_i360_client_with_stakeholder": false,
                        "feature_flags": ["ff_gr_region_enhancements"],
                        "full_width": true,
                        "key": "district_list",
                        "label": "List of Districts",
                        "logged_in_only": false,
                        "order": 21,
                        "page_form_title_placeholder": "",
                        "page_title": "",
                        "permissions": [201],
                        "requires_login": false,
                        "tip": "Display a list of districts based on the state exisiting in the query params",
                        "value": 108
                    },
                    "donation_form": {
                        "anonymous_only": false,
                        "can_be_independent_page": true,
                        "can_be_used_in_page_form": true,
                        "can_use_custom_background_color": false,
                        "context_fields": [2, 6],
                        "exclude_i360_client_with_stakeholder": false,
                        "feature_flags": ["ff_pac"],
                        "full_width": false,
                        "key": "donation_form",
                        "label": "Donation Form",
                        "logged_in_only": false,
                        "order": 6,
                        "page_form_title_placeholder": "",
                        "page_title": "",
                        "requires_login": false,
                        "tip": "Add a Donation Form using the drop down menu below (for PAC or nonprofit fundraising)",
                        "value": 17,
                        "widget_url": "/donate/",
                        "widget_url_params": ":donation_form_id"
                    },
                    "donor_history": {
                        "anonymous_only": false,
                        "can_be_independent_page": false,
                        "can_be_used_in_page_form": true,
                        "can_use_custom_background_color": false,
                        "exclude_i360_client_with_stakeholder": false,
                        "feature_flags": ["ff_pac_action_center_enhancements_donation_history"],
                        "full_width": false,
                        "key": "donor_history",
                        "label": "Donor History",
                        "logged_in_only": false,
                        "order": 22,
                        "page_form_title_placeholder": "",
                        "page_title": "",
                        "permissions": [201],
                        "requires_login": false,
                        "tip": "Display the latest donation received and the totals for the last two years",
                        "value": 109
                    },
                    "draft_campaign": {
                        "anonymous_only": false,
                        "can_be_independent_page": true,
                        "can_be_used_in_page_form": false,
                        "can_use_custom_background_color": false,
                        "context_fields": [2, 3, 1],
                        "exclude_i360_client_with_stakeholder": false,
                        "full_width": false,
                        "key": "draft_campaign",
                        "label": "Draft Campaign",
                        "logged_in_only": false,
                        "order": 1,
                        "page_form_title_placeholder": "",
                        "page_title": "",
                        "permissions": [201],
                        "requires_login": false,
                        "value": 13,
                        "widget_url": "/campaign_draft/",
                        "widget_url_params": ":slug"
                    },
                    "embed": { "anonymous_only": false, "can_be_independent_page": false, "can_be_used_in_page_form": true, "can_use_custom_background_color": false, "exclude_i360_client_with_stakeholder": false, "full_width": false, "key": "embed", "label": "Embed Code", "logged_in_only": false, "order": 11, "page_form_title_placeholder": "", "page_title": "", "requires_login": false, "tip": "Use an embed code", "value": 18 },
                    "event": {
                        "anonymous_only": false,
                        "can_be_independent_page": true,
                        "can_be_used_in_page_form": false,
                        "can_use_custom_background_color": false,
                        "context_fields": [3],
                        "exclude_i360_client_with_stakeholder": false,
                        "full_width": false,
                        "key": "event",
                        "label": "Event",
                        "logged_in_only": false,
                        "order": 1,
                        "page_form_title_placeholder": "",
                        "page_title": "",
                        "permissions": [206],
                        "required_props": ["id"],
                        "requires_login": false,
                        "value": 15,
                        "widget_url": "/event/",
                        "widget_url_params": ":id"
                    },
                    "event_list": {
                        "anonymous_only": false,
                        "can_be_independent_page": true,
                        "can_be_used_in_page_form": true,
                        "can_use_custom_background_color": false,
                        "context_fields": [5],
                        "exclude_i360_client_with_stakeholder": false,
                        "full_width": false,
                        "key": "event_list",
                        "label": "List of Events",
                        "logged_in_only": false,
                        "order": 10,
                        "page_form_title_placeholder": "",
                        "page_title": "Events",
                        "permissions": [206],
                        "requires_login": false,
                        "tip": "Creates a list of all of your public events",
                        "value": 104,
                        "widget_url": "/events/"
                    },
                    "flag_and_region_name": {
                        "anonymous_only": false,
                        "can_be_independent_page": false,
                        "can_be_used_in_page_form": true,
                        "can_use_custom_background_color": false,
                        "exclude_i360_client_with_stakeholder": false,
                        "feature_flags": ["ff_gr_region_enhancements"],
                        "full_width": true,
                        "key": "flag_and_region_name",
                        "label": "Flag and Region Name",
                        "logged_in_only": false,
                        "order": 20,
                        "page_form_title_placeholder": "",
                        "page_title": "",
                        "permissions": [201],
                        "requires_login": false,
                        "tip": "Display a flag of a an American state its name based on url parameter",
                        "value": 107
                    },
                    "full_bleed_image": { "anonymous_only": false, "can_be_independent_page": false, "can_be_used_in_page_form": true, "can_use_custom_background_color": false, "exclude_i360_client_with_stakeholder": false, "full_width": true, "key": "full_bleed_image", "label": "Full Bleed Image", "logged_in_only": false, "order": 12, "page_form_title_placeholder": "", "page_title": "", "requires_login": false, "tip": "Display an image the full width of the page", "value": 19 },
                    "gamification_stats": { "anonymous_only": false, "can_be_independent_page": false, "can_be_used_in_page_form": true, "can_use_custom_background_color": false, "exclude_i360_client_with_stakeholder": false, "full_width": false, "key": "gamification_stats", "label": "Gamification Stats", "logged_in_only": false, "order": 5, "page_form_title_placeholder": "Your Adovcacy Activity", "page_title": "", "requires_login": false, "tip": "Display the advocate's Gamification Stats", "value": 12 },
                    "image": { "anonymous_only": false, "can_be_independent_page": false, "can_be_used_in_page_form": true, "can_use_custom_background_color": false, "exclude_i360_client_with_stakeholder": false, "full_width": false, "key": "image", "label": "Image", "logged_in_only": false, "order": 2, "page_form_title_placeholder": "", "page_title": "", "requires_login": false, "tip": "Insert an Image Link to insert an image onto your Page.", "value": 5 },
                    "image_collage": { "anonymous_only": false, "can_be_independent_page": false, "can_be_used_in_page_form": true, "can_use_custom_background_color": true, "exclude_i360_client_with_stakeholder": false, "full_width": true, "key": "image_collage", "label": "Image Collage", "layout_enum": "ImageCollageLayoutType", "logged_in_only": false, "order": 13, "page_form_title_placeholder": "", "page_title": "", "requires_login": false, "tip": "Display a collage of images the full width of the page", "value": 20 },
                    "image_video_carousel": { "anonymous_only": false, "can_be_independent_page": false, "can_be_used_in_page_form": true, "can_use_custom_background_color": true, "exclude_i360_client_with_stakeholder": false, "full_width": false, "key": "image_video_carousel", "label": "Image/Video Carousel", "logged_in_only": false, "order": 17, "page_form_title_placeholder": "", "page_title": "", "requires_login": false, "tip": "Display a carousel of images and/or videos", "value": 24 },
                    "issue": {
                        "anonymous_only": false,
                        "can_be_independent_page": true,
                        "can_be_used_in_page_form": false,
                        "can_use_custom_background_color": false,
                        "exclude_i360_client_with_stakeholder": false,
                        "full_width": false,
                        "key": "issue",
                        "label": "Issue",
                        "logged_in_only": false,
                        "order": 1,
                        "page_form_title_placeholder": "",
                        "page_title": "",
                        "permissions": [201, 7, 5],
                        "required_props": ["id"],
                        "requires_login": false,
                        "value": 2,
                        "widget_url": "/issue/",
                        "widget_url_params": ":slug"
                    },
                    "issue_list": {
                        "anonymous_only": false,
                        "can_be_independent_page": true,
                        "can_be_used_in_page_form": true,
                        "can_use_custom_background_color": false,
                        "context_fields": [4],
                        "exclude_i360_client_with_stakeholder": false,
                        "full_width": false,
                        "key": "issue_list",
                        "label": "List of Issues",
                        "logged_in_only": false,
                        "order": 8,
                        "page_form_title_placeholder": "",
                        "page_title": "Issues",
                        "permissions": [201, 7, 5],
                        "requires_login": false,
                        "tip": "Creates a list of all of your Issues where advocates can click into each Issue to learn more.",
                        "value": 102,
                        "widget_url": "/issues/"
                    },
                    "log_interaction": {
                        "anonymous_only": false,
                        "can_be_independent_page": true,
                        "can_be_used_in_page_form": false,
                        "can_use_custom_background_color": false,
                        "context_fields": [2, 3],
                        "exclude_i360_client_with_stakeholder": false,
                        "full_width": false,
                        "key": "log_interaction",
                        "label": "Log Interaction",
                        "logged_in_only": false,
                        "order": 1,
                        "page_form_title_placeholder": "",
                        "page_title": "Log Interaction",
                        "permissions": [7],
                        "requires_login": false,
                        "value": 10,
                        "widget_url": "/log/"
                    },
                    "log_relationship": {
                        "anonymous_only": false,
                        "can_be_independent_page": true,
                        "can_be_used_in_page_form": false,
                        "can_use_custom_background_color": false,
                        "context_fields": [2, 3],
                        "exclude_i360_client_with_stakeholder": false,
                        "full_width": false,
                        "key": "log_relationship",
                        "label": "Log Relationship",
                        "logged_in_only": false,
                        "order": 1,
                        "page_form_title_placeholder": "",
                        "page_title": "Log Relationship",
                        "permissions": [5],
                        "requires_login": false,
                        "value": 6,
                        "widget_url": "/relationship/"
                    },
                    "number_stats": { "anonymous_only": false, "can_be_independent_page": false, "can_be_used_in_page_form": true, "can_use_custom_background_color": true, "exclude_i360_client_with_stakeholder": false, "full_width": true, "key": "number_stats", "label": "Stats Widget", "layout_enum": "NumberStatsLayoutType", "logged_in_only": false, "order": 15, "page_form_title_placeholder": "", "page_title": "", "requires_login": false, "tip": "Display a statistics in bubbles the full width of the page", "value": 22 },
                    "official": {
                        "anonymous_only": false,
                        "can_be_independent_page": true,
                        "can_be_used_in_page_form": false,
                        "can_use_custom_background_color": false,
                        "exclude_i360_client_with_stakeholder": false,
                        "full_width": false,
                        "key": "official",
                        "label": "Official",
                        "logged_in_only": false,
                        "order": 1,
                        "page_form_title_placeholder": "",
                        "page_title": "",
                        "permissions": [201],
                        "required_props": ["id"],
                        "requires_login": false,
                        "value": 3,
                        "widget_url": "/official/",
                        "widget_url_params": ":id"
                    },
                    "official_list": {
                        "anonymous_only": false,
                        "can_be_independent_page": true,
                        "can_be_used_in_page_form": true,
                        "can_use_custom_background_color": false,
                        "exclude_i360_client_with_stakeholder": false,
                        "full_width": false,
                        "key": "official_list",
                        "label": "List of Officials",
                        "logged_in_only": false,
                        "order": 9,
                        "page_form_title_placeholder": "",
                        "page_title": "Officials",
                        "permissions": [201],
                        "requires_login": false,
                        "tip": "Creates a list of Elected Officials relevant to the advocate based off of the address they register with.",
                        "value": 103,
                        "widget_url": "/officials/"
                    },
                    "public_sheet": { "anonymous_only": false, "can_be_independent_page": false, "can_be_used_in_page_form": true, "can_use_custom_background_color": false, "exclude_i360_client_with_stakeholder": false, "full_width": false, "key": "public_sheet", "label": "Public Sheet", "logged_in_only": false, "order": 6, "page_form_title_placeholder": "", "page_title": "", "requires_login": false, "value": 14 },
                    "region_list": {
                        "anonymous_only": false,
                        "can_be_independent_page": true,
                        "can_be_used_in_page_form": true,
                        "can_use_custom_background_color": false,
                        "exclude_i360_client_with_stakeholder": false,
                        "feature_flags": ["ff_gr_region_enhancements"],
                        "full_width": false,
                        "key": "region_list",
                        "label": "List of Regions",
                        "logged_in_only": false,
                        "order": 19,
                        "page_form_title_placeholder": "",
                        "page_title": "Regions",
                        "permissions": [201],
                        "requires_login": false,
                        "tip": "Creates a list of Regions chosen by the advocate.",
                        "value": 106,
                        "widget_url": "/regions/"
                    },
                    "sign_in": {
                        "anonymous_only": true,
                        "can_be_independent_page": true,
                        "can_be_used_in_page_form": false,
                        "can_use_custom_background_color": false,
                        "context_fields": [2, 3],
                        "exclude_i360_client_with_stakeholder": false,
                        "full_width": false,
                        "key": "sign_in",
                        "label": "Sign In",
                        "logged_in_only": false,
                        "order": 1,
                        "page_form_title_placeholder": "",
                        "page_title": "Sign In",
                        "requires_login": false,
                        "value": 7,
                        "widget_url": "/sign_in/"
                    },
                    "sign_up": {
                        "anonymous_only": false,
                        "can_be_independent_page": true,
                        "can_be_used_in_page_form": true,
                        "can_use_custom_background_color": false,
                        "context_fields": [2, 3],
                        "exclude_i360_client_with_stakeholder": false,
                        "full_width": false,
                        "key": "sign_up",
                        "label": "Registration Form",
                        "logged_in_only": false,
                        "order": 11,
                        "page_form_title_placeholder": "",
                        "page_title": "Sign Up",
                        "permissions": [201, 7, 5, 102, 3],
                        "requires_login": false,
                        "tip": "Add a Registration Form using the drop down menu below to help acquire advocates.",
                        "value": 9,
                        "widget_url": "/sign_up/",
                        "widget_url_params": ":reg_page_id"
                    },
                    "text": { "anonymous_only": false, "can_be_independent_page": false, "can_be_used_in_page_form": true, "can_use_custom_background_color": false, "exclude_i360_client_with_stakeholder": false, "full_width": false, "key": "text", "label": "Text", "logged_in_only": false, "order": 1, "page_form_title_placeholder": "", "page_title": "", "requires_login": false, "tip": "Use a Text box to insert text into your widget.", "value": 4 },
                    "twitter_timeline": { "anonymous_only": false, "can_be_independent_page": false, "can_be_used_in_page_form": true, "can_use_custom_background_color": false, "exclude_i360_client_with_stakeholder": false, "full_width": false, "key": "twitter_timeline", "label": "Twitter Timeline", "logged_in_only": false, "order": 4, "page_form_title_placeholder": "", "page_title": "", "requires_login": false, "tip": "Timeline of Twitter account tweets", "value": 16 },
                    "update_info": {
                        "action_center_route_permissions": ["show_update_info"],
                        "anonymous_only": false,
                        "can_be_independent_page": true,
                        "can_be_used_in_page_form": false,
                        "can_use_custom_background_color": false,
                        "context_fields": [2, 3],
                        "exclude_i360_client_with_stakeholder": false,
                        "full_width": false,
                        "key": "update_info",
                        "label": "Update Information",
                        "logged_in_only": true,
                        "order": 1,
                        "page_form_title_placeholder": "",
                        "page_title": "Update Information",
                        "requires_login": true,
                        "value": 8,
                        "widget_url": "/update_info/"
                    },
                    "video": { "anonymous_only": false, "can_be_independent_page": false, "can_be_used_in_page_form": true, "can_use_custom_background_color": false, "exclude_i360_client_with_stakeholder": false, "full_width": false, "key": "video", "label": "Video", "logged_in_only": false, "order": 3, "page_form_title_placeholder": "", "page_title": "", "requires_login": false, "tip": "Paste a Youtube URL to insert a video onto your Page. Advocates will be able to watch the video on this Page without leaving your Action Center.", "value": 11 }
                },
                "PayrollDonationType": {
                    "__is_enum": true,
                    "amount": { "key": "amount", "label": "Amount", "search_label": "Amount", "value": 1 },
                    "percentage": { "key": "percentage", "label": "Percentage", "search_label": "Payroll Percentage", "value": 2 }
                },
                "RegistrationPostSubmissionAction": {
                    "__is_enum": true,
                    "redirect_to_url": { "key": "redirect_to_url", "label": "Redirect to URL", "value": 2 },
                    "thank_you_message": { "key": "thank_you_message", "label": "Thank You Message", "value": 1 }
                }
            }
        },
        "hubble": {
            "queryset": {}
        },
        "impact_reports": {
            "types": {
                "other": {},
                "template": {},
                "widget": {}
            }
        },
        "ledger": {
            "models": {
                "StripePayment": {
                    "__is_model": true,
                    "__name__": "StripePayment",
                    "data_model": "app.ledger.models.StripePayment",
                    "default_exclude_filters": {},
                    "default_filters": {},
                    "endpoint": "/api/stripepayment/",
                    "fields": []
                },
                "TransactionEvent": {
                    "__is_model": true,
                    "__name__": "TransactionEvent",
                    "data_model": "app.ledger.models.TransactionEvent",
                    "default_exclude_filters": {},
                    "default_filters": {},
                    "endpoint": "/api/transactionevent/",
                    "fields": []
                }
            },
            "types": {
                "PayrollFrequencyType": {
                    "__is_enum": true,
                    "bi_weekly": { "code": "B", "cycles_per_year": 26, "help_text": "A pay cycle occurs the first Friday and every other following Friday of the year", "key": "bi_weekly", "label": "Bi-Weekly", "value": 1 },
                    "monthly": { "code": "M", "cycles_per_year": 12, "help_text": "A pay cycle occurs on the 1st of each month or the following Monday if the 1st is a weekend", "key": "monthly", "label": "Monthly", "value": 4 },
                    "semi_monthly": { "code": "S", "cycles_per_year": 24, "help_text": "A pay cycle occurs on the 1st and 15th of each month or closest weekday within the month if either are weekends", "key": "semi_monthly", "label": "Semi-Monthly", "value": 5 },
                    "weekly": { "code": "W", "cycles_per_year": 52, "help_text": "A pay cycle occurs every Friday in the year", "key": "weekly", "label": "Weekly", "value": 3 }
                },
                "TransactionMethodType": {
                    "__is_enum": true,
                    "ach_transfer": {
                        "donation_form_feature_flags": [],
                        "fa5_icon": "random",
                        "fa5_icon_family": "fal",
                        "is_primary": false,
                        "key": "ach_transfer",
                        "label": "ACH Transfer",
                        "order": 22,
                        "requires_stripe_onboarding": false,
                        "search_label": "ACH Transfer",
                        "used_in_donation_form": false,
                        "valid_disbursement_donor_types": [],
                        "valid_for_disbursements": false,
                        "valid_for_receipts": false,
                        "valid_receipt_donor_types": [],
                        "value": 4
                    },
                    "cash": {
                        "donation_form_feature_flags": [],
                        "fa5_icon": "money-bill-alt",
                        "fa5_icon_family": "fal",
                        "is_primary": false,
                        "key": "cash",
                        "label": "Cash",
                        "order": 40,
                        "pacc_code": "$",
                        "requires_stripe_onboarding": false,
                        "search_label": "Cash",
                        "used_in_donation_form": false,
                        "valid_disbursement_donor_types": [],
                        "valid_for_disbursements": false,
                        "valid_for_receipts": true,
                        "valid_receipt_donor_types": [1001, 1003],
                        "value": 2
                    },
                    "check": {
                        "code": "C",
                        "donation_form_display": "Check",
                        "donation_form_feature_flags": [],
                        "fa5_icon": "money-check-edit-alt",
                        "fa5_icon_family": "fal",
                        "is_primary": true,
                        "key": "check",
                        "label": "Manual Check",
                        "order": 0,
                        "pacc_code": "C",
                        "requires_stripe_onboarding": false,
                        "search_label": "Check",
                        "used_in_donation_form": true,
                        "valid_disbursement_donor_types": [1001, 1, 2, 3, 5, 6, 100, 101, 1016, 1013, 1012, 1014, 1015],
                        "valid_for_disbursements": true,
                        "valid_for_receipts": true,
                        "valid_receipt_donor_types": [6, 1013, 1, 1015, 1001, 101, 2, 5, 3, 1016, 100000, 1014, 100, 1012],
                        "value": 7
                    },
                    "credit_card": {
                        "code": "CC",
                        "donation_form_feature_flags": ["ff_pac_action_center_enable_credit_card"],
                        "fa5_icon": "credit-card",
                        "fa5_icon_family": "fal",
                        "is_primary": true,
                        "key": "credit_card",
                        "label": "Credit Card",
                        "order": 30,
                        "pacc_code": "V",
                        "requires_stripe_onboarding": false,
                        "search_label": "Credit Card",
                        "used_in_donation_form": true,
                        "valid_disbursement_donor_types": [],
                        "valid_for_disbursements": false,
                        "valid_for_receipts": true,
                        "valid_receipt_donor_types": [1001, 100000],
                        "value": 1
                    },
                    "in_kind": {
                        "donation_form_feature_flags": [],
                        "fa5_icon": "money-bill-wave",
                        "fa5_icon_family": "fal",
                        "is_primary": false,
                        "key": "in_kind",
                        "label": "In Kind",
                        "order": 50,
                        "pacc_code": "I",
                        "requires_stripe_onboarding": false,
                        "search_label": "In Kind",
                        "used_in_donation_form": false,
                        "valid_disbursement_donor_types": [],
                        "valid_for_disbursements": false,
                        "valid_for_receipts": false,
                        "valid_receipt_donor_types": [],
                        "value": 6
                    },
                    "memo": {
                        "donation_form_feature_flags": [],
                        "fa5_icon": "sticky-note",
                        "fa5_icon_family": "fal",
                        "is_primary": false,
                        "key": "memo",
                        "label": "Memo",
                        "order": 1000,
                        "pacc_code": "O",
                        "requires_stripe_onboarding": false,
                        "search_label": "Memo",
                        "used_in_donation_form": false,
                        "valid_disbursement_donor_types": [],
                        "valid_for_disbursements": false,
                        "valid_for_receipts": false,
                        "valid_receipt_donor_types": [],
                        "value": 9
                    },
                    "other": {
                        "code": "O",
                        "donation_form_feature_flags": [],
                        "fa5_icon": "random",
                        "fa5_icon_family": "fal",
                        "is_primary": false,
                        "key": "other",
                        "label": "Other",
                        "order": 1000,
                        "pacc_code": "O",
                        "requires_stripe_onboarding": false,
                        "search_label": "Other",
                        "used_in_donation_form": false,
                        "valid_disbursement_donor_types": [1001, 1, 2, 3, 6, 5, 100, 101, 1016, 1013, 1012, 1014, 1015],
                        "valid_for_disbursements": true,
                        "valid_for_receipts": true,
                        "valid_receipt_donor_types": [1001, 1, 2, 3, 6, 5, 100, 101, 1016, 1013, 1012, 1014, 1015, 1004, 100000],
                        "value": 12
                    },
                    "payroll": {
                        "code": "P",
                        "donation_form_feature_flags": [],
                        "fa5_icon": "receipt",
                        "fa5_icon_family": "fal",
                        "is_primary": true,
                        "key": "payroll",
                        "label": "Payroll",
                        "order": 10,
                        "pacc_code": "P",
                        "requires_stripe_onboarding": false,
                        "search_label": "Payroll",
                        "used_in_donation_form": true,
                        "valid_disbursement_donor_types": [],
                        "valid_for_disbursements": false,
                        "valid_for_receipts": true,
                        "valid_receipt_donor_types": [1001, 100000],
                        "value": 5
                    },
                    "printing_check": {
                        "donation_form_feature_flags": [],
                        "fa5_icon": "money-check-edit-alt",
                        "fa5_icon_family": "fal",
                        "is_primary": true,
                        "key": "printing_check",
                        "label": "Auto Printed Check",
                        "order": 11,
                        "pacc_code": "C",
                        "requires_stripe_onboarding": false,
                        "search_label": "Auto Printed Check",
                        "used_in_donation_form": false,
                        "valid_disbursement_donor_types": [1001, 1, 2, 3, 5, 6, 100, 101, 1016, 1012, 1014, 1015, 1013],
                        "valid_for_disbursements": true,
                        "valid_for_receipts": false,
                        "valid_receipt_donor_types": [],
                        "value": 8
                    },
                    "stripe_fee": {
                        "donation_form_feature_flags": [],
                        "fa5_icon": "credit-card",
                        "fa5_icon_family": "fal",
                        "is_primary": false,
                        "key": "stripe_fee",
                        "label": "Stripe Fee",
                        "order": 1000,
                        "requires_stripe_onboarding": false,
                        "search_label": "Stripe Fee",
                        "used_in_donation_form": false,
                        "valid_disbursement_donor_types": [],
                        "valid_for_disbursements": false,
                        "valid_for_receipts": false,
                        "valid_receipt_donor_types": [],
                        "value": 10
                    },
                    "stripe_payment": {
                        "donation_form_display": "Credit Card",
                        "donation_form_feature_flags": [],
                        "fa5_icon": "stripe",
                        "fa5_icon_family": "fab",
                        "is_primary": false,
                        "key": "stripe_payment",
                        "label": "Stripe",
                        "order": 1000,
                        "requires_stripe_onboarding": true,
                        "search_label": "Stripe",
                        "used_in_donation_form": true,
                        "valid_disbursement_donor_types": [],
                        "valid_for_disbursements": false,
                        "valid_for_receipts": false,
                        "valid_receipt_donor_types": [],
                        "value": 11
                    },
                    "wire_transfer": {
                        "donation_form_feature_flags": [],
                        "fa5_icon": "file-invoice-dollar",
                        "fa5_icon_family": "fal",
                        "is_primary": false,
                        "key": "wire_transfer",
                        "label": "Wire Transfer",
                        "order": 1000,
                        "requires_stripe_onboarding": false,
                        "search_label": "Wire Transfer",
                        "used_in_donation_form": false,
                        "valid_disbursement_donor_types": [1013],
                        "valid_for_disbursements": false,
                        "valid_for_receipts": false,
                        "valid_receipt_donor_types": [],
                        "value": 3
                    }
                },
                "TransactionTableFilterType": {
                    "__is_enum": true,
                    "disbursements_only": { "key": "disbursements_only", "label": "Disbursements Only", "value": 3 },
                    "no_filter": { "key": "no_filter", "label": "All", "value": 1 },
                    "receipts_only": { "key": "receipts_only", "label": "Receipts Only", "value": 2 }
                }
            }
        },
        "media_monitoring": {
            "types": {}
        },
        "models": {
            "PermissionType": {
                "__is_enum": true,
                "action_center": { "key": "action_center", "label": "qp_action_center", "value": 201, "visible_in_grassroots": true },
                "action_center_campaigns": { "key": "action_center_campaigns", "label": "qp_action_center_campaigns", "value": 219, "visible_in_grassroots": true },
                "ai_grassroots_messages": { "key": "ai_grassroots_messages", "label": "qp_ai_grassroots_messages", "value": 1033, "visible_in_grassroots": false },
                "ai_outbox_suggestions": { "key": "ai_outbox_suggestions", "label": "qp_ai_outbox_suggestions", "value": 1032, "visible_in_grassroots": false },
                "ai_summaries": { "key": "ai_summaries", "label": "qp_ai_summaries", "value": 1031, "visible_in_grassroots": false },
                "ai_tracking_boards": { "key": "ai_tracking_boards", "label": "qp_ai_tracking_boards", "value": 1034, "visible_in_grassroots": false },
                "campaign_finance": { "key": "campaign_finance", "label": "qp_campaign_finance", "value": 9, "visible_in_grassroots": true },
                "can_access_axios_articles": { "key": "can_access_axios_articles", "label": "can_access_axios_articles", "value": 1013, "visible_in_grassroots": false },
                "can_access_bulk_uploads": { "key": "can_access_bulk_uploads", "label": "can_access_bulk_uploads", "value": 1012, "visible_in_grassroots": false },
                "can_access_campaign_finance": { "key": "can_access_campaign_finance", "label": "can_access_campaign_finance", "value": 1007, "visible_in_grassroots": true },
                "can_access_custom_fields_management": { "key": "can_access_custom_fields_management", "label": "can_access_custom_fields_management", "value": 1004, "visible_in_grassroots": false },
                "can_access_grassroots": { "key": "can_access_grassroots", "label": "can_access_grassroots", "value": 1005, "visible_in_grassroots": false },
                "can_access_issue_management": { "key": "can_access_issue_management", "label": "can_access_issue_management", "value": 1006, "visible_in_grassroots": false },
                "can_access_pac": { "key": "can_access_pac", "label": "can_access_pac", "value": 1011, "visible_in_grassroots": false },
                "can_access_pac_hybrid_dashboard_template": { "key": "can_access_pac_hybrid_dashboard_template", "label": "can_access_pac_hybrid_dashboard_template", "value": 217, "visible_in_grassroots": false },
                "can_alter_pac_transactions": { "key": "can_alter_pac_transactions", "label": "can_alter_pac_transactions", "value": 1010, "visible_in_grassroots": false },
                "can_change_action_center_settings": { "key": "can_change_action_center_settings", "label": "can_change_action_center_settings", "value": 1002, "visible_in_grassroots": false },
                "can_see_all_campaigns": { "key": "can_see_all_campaigns", "label": "can_see_all_campaigns", "value": 1003, "visible_in_grassroots": false },
                "can_see_any_previous_outbox_email": { "key": "can_see_any_previous_outbox_email", "label": "can_see_any_previous_outbox_email", "value": 1001, "visible_in_grassroots": false },
                "can_send_from_any_outbox": { "key": "can_send_from_any_outbox", "label": "can_send_from_any_outbox", "value": 1000, "visible_in_grassroots": false },
                "can_view_pac_disbursements": { "key": "can_view_pac_disbursements", "label": "can_view_pac_disbursements", "value": 1009, "visible_in_grassroots": false },
                "can_view_pac_receipts": { "key": "can_view_pac_receipts", "label": "can_view_pac_receipts", "value": 1008, "visible_in_grassroots": false },
                "consultants": { "key": "consultants", "label": "qp_consultants", "value": 210, "visible_in_grassroots": false },
                "contacts_documents": { "key": "contacts_documents", "label": "qp_contacts_documents", "value": 207, "visible_in_grassroots": false },
                "copilot_analyze": { "key": "copilot_analyze", "label": "qp_copilot_analyze", "value": 1030, "visible_in_grassroots": false },
                "custom_contacts": { "key": "custom_contacts", "label": "qp_custom_contacts", "value": 204, "visible_in_grassroots": false },
                "custom_data": { "key": "custom_data", "label": "qp_custom_data", "value": 4, "visible_in_grassroots": false },
                "custom_docket": { "key": "custom_docket", "label": "qp_custom_docket", "value": 311, "visible_in_grassroots": true },
                "custom_events": { "key": "custom_events", "label": "qp_custom_events", "value": 206, "visible_in_grassroots": true },
                "custom_outbox": { "key": "custom_outbox", "label": "qp_custom_outbox", "value": 205, "visible_in_grassroots": false },
                "eu": { "key": "eu", "label": "qp_eu", "value": 2, "visible_in_grassroots": false },
                "executives": { "key": "executives", "label": "qp_executives", "value": 301, "visible_in_grassroots": false },
                "external_interactions": { "key": "external_interactions", "label": "qp_external_interactions", "value": 7, "visible_in_grassroots": true },
                "external_relationship": { "key": "external_relationship", "label": "qp_external_relationship", "value": 5, "visible_in_grassroots": true },
                "federal": { "key": "federal", "label": "qp_federal", "value": 1, "visible_in_grassroots": false },
                "grassroots": { "key": "grassroots", "label": "qp_grassroots", "value": 102, "visible_in_grassroots": true },
                "internal_relationship": { "key": "internal_relationship", "label": "qp_internal_relationship", "value": 6, "visible_in_grassroots": false },
                "international": { "key": "international", "label": "qp_international", "value": 103, "visible_in_grassroots": false },
                "legislation_page": { "key": "legislation_page", "label": "qp_legislation_page", "value": 203, "visible_in_grassroots": false },
                "legislative_data": { "key": "legislative_data", "label": "qp_legislative_data", "value": 303, "visible_in_grassroots": false },
                "legislative_profiles": { "key": "legislative_profiles", "label": "qp_legislative_profiles", "value": 304, "visible_in_grassroots": false },
                "legislators": { "key": "legislators", "label": "qp_legislators", "value": 300, "visible_in_grassroots": false },
                "live_chat": { "key": "live_chat", "label": "qp_live_chat", "value": 211, "visible_in_grassroots": false },
                "local": { "key": "local", "label": "qp_local", "value": 101, "visible_in_grassroots": false },
                "local_agendas": { "key": "local_agendas", "label": "qp_local_agendas", "value": 212, "visible_in_grassroots": false },
                "media_monitoring": { "key": "media_monitoring", "label": "qp_media_monitoring", "value": 104, "visible_in_grassroots": false },
                "media_relations": { "key": "media_relations", "label": "qp_media_relations", "value": 105, "visible_in_grassroots": false },
                "no_permission_needed": { "key": "no_permission_needed", "label": "qp_no_permission_needed", "value": 0, "visible_in_grassroots": false },
                "not_allowed": { "key": "not_allowed", "label": "not_allowed", "value": 9999, "visible_in_grassroots": false },
                "official_documents": { "key": "official_documents", "label": "qp_official_documents", "value": 305, "visible_in_grassroots": false },
                "officials_outbox": { "key": "officials_outbox", "label": "qp_officials_outbox", "value": 202, "visible_in_grassroots": false },
                "outbound_texting": { "key": "outbound_texting", "label": "qp_outbound_texting", "value": 216, "visible_in_grassroots": false },
                "pac_hybrid": { "key": "pac_hybrid", "label": "qp_pac_hybrid", "value": 215, "visible_in_grassroots": false },
                "pac_hybrid_or_qpac_v1": { "key": "pac_hybrid_or_qpac_v1", "label": "qp_pac_hybrid_or_qpac_v1", "value": 218, "visible_in_grassroots": false },
                "pac_match": { "key": "pac_match", "label": "qp_pac_match", "value": 214, "visible_in_grassroots": true },
                "pac_state_and_local_committees": { "key": "pac_state_and_local_committees", "label": "qp_pac_state_and_local_committees", "value": 213, "visible_in_grassroots": false },
                "project_management": { "key": "project_management", "label": "qp_project_management", "value": 208, "visible_in_grassroots": false },
                "qpac_v1": { "key": "qpac_v1", "label": "qp_qpac_v1", "value": 8, "visible_in_grassroots": true },
                "regulations": { "key": "regulations", "label": "qp_regulations", "value": 310, "visible_in_grassroots": false },
                "school_boards": { "key": "school_boards", "label": "qp_school_boards", "value": 107, "visible_in_grassroots": false },
                "staffers": { "key": "staffers", "label": "qp_staffers", "value": 302, "visible_in_grassroots": false },
                "stakeholder": { "key": "stakeholder", "label": "qp_stakeholder", "value": 3, "visible_in_grassroots": true },
                "states": { "key": "states", "label": "qp_states", "value": 100, "visible_in_grassroots": false },
                "unique_ids_in_downloads": { "key": "unique_ids_in_downloads", "label": "qp_unique_ids_in_downloads", "value": 209, "visible_in_grassroots": false }
            },
            "QuorumDataType": {
                "__is_enum": true,
                "account": {
                    "advanced_search": false,
                    "advanced_search_order": 15,
                    "archivable": true,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.ledger.models.Account",
                    "description": "",
                    "display_key": "id",
                    "download_center": false,
                    "feature": 19,
                    "fts": false,
                    "icon": "fa-pencil",
                    "issues_management": false,
                    "key": "account",
                    "label": "Account",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "Accounts",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Account",
                    "sort_key": "id",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 131
                },
                "account_reconciliation": {
                    "advanced_search": false,
                    "advanced_search_order": 15,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.ledger.models.AccountReconciliation",
                    "description": "",
                    "display_key": "reconciliation_display_key",
                    "download_center": false,
                    "feature": 20,
                    "fts": false,
                    "icon": "fa-money",
                    "issues_management": false,
                    "key": "account_reconciliation",
                    "label": "Reconciliation",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [8],
                    "plural": "Reconciliations",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Reconciliation",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 112
                },
                "action_center_settings": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "data_model": "app.grassroots.models.ActionCenterSettings",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fa5_icon": "bullhorn",
                    "fts": false,
                    "icon": "fa-bullhorn",
                    "iconColor": "#503ABD",
                    "issues_management": false,
                    "key": "action_center_settings",
                    "label": "Action Center",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [201],
                    "plural": "Action Centers",
                    "precision_max_threshold": 50000,
                    "profile": "/action_centers/",
                    "quicksearch": false,
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Action Center",
                    "sort_key": "",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 99
                },
                "alert": {
                    "advanced_search": true,
                    "advanced_search_order": 16,
                    "archivable": true,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.userdata.alerts.models.Alert",
                    "description": "",
                    "display_key": "title",
                    "download_center": false,
                    "fa5_icon": "rss",
                    "fa5_icon_family": "fas",
                    "feature": 5,
                    "fts": false,
                    "icon": "fa-rss",
                    "issues_management": false,
                    "key": "alert",
                    "label": "Alert",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [0],
                    "plural": "Alerts",
                    "precision_max_threshold": 50000,
                    "profile": "/alert/alert/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "alert",
                    "related_pk_field": "alert_id",
                    "search_description": { "1": "All email alerts you've received from Quorum.", "10000": "All email alerts you've received from Quorum.", "10001": "All email alerts you've received from Quorum.", "10002": "All email alerts you've received from Quorum.", "10003": "All email alerts you've received from Quorum.", "10004": "All email alerts you've received from Quorum.", "106": "All email alerts you've received from Quorum.", "9999": "All email alerts you've received from Quorum." },
                    "search_url": "alerts/",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Alert",
                    "sort_key": "title",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": true,
                    "value": 74
                },
                "alignment_type": {
                    "advanced_search": false,
                    "archivable": true,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.userdata.stakeholder.models.AlignmentType",
                    "description": "",
                    "display_key": "label",
                    "download_center": false,
                    "fa5_icon": "thumbs-up",
                    "fts": false,
                    "icon": "fa-thumbs-o-up",
                    "issues_management": false,
                    "key": "alignment_type",
                    "label": "Alignment Type",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "Alignment Types",
                    "precision_max_threshold": 50000,
                    "profile": "/alignmenttypes/edit/",
                    "quicksearch": false,
                    "quicksearch_key": "label",
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Alignment Type",
                    "sort_key": "order",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 116
                },
                "amendment": {
                    "advanced_search": true,
                    "advanced_search_order": 10,
                    "archivable": false,
                    "assignment": true,
                    "comments": "referencing_amendments",
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": true,
                    "data_model": "app.bill.models.Amendment",
                    "description": "",
                    "display_key": "title",
                    "download_center": false,
                    "fa5_icon": "paperclip",
                    "fa5_icon_family": "fal",
                    "fts": true,
                    "icon": "fa-paperclip",
                    "issues_management": true,
                    "key": "amendment",
                    "label": "Amendment",
                    "legislative_priority": false,
                    "legislative_stance": true,
                    "lists": true,
                    "note_through_field": "amendments",
                    "permission_enum": [304],
                    "plural": "Amendments",
                    "precision_max_threshold": 50000,
                    "profile": "/amendment/",
                    "proxy_resource_data_model": "app.bill.models.NewAmendment",
                    "quicksearch": true,
                    "quicksearch_key": "title",
                    "quicksearch_order": 6,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "amendment",
                    "related_pk_field": "amendment_id",
                    "search_description": { "1": "Amendments proposed by Members of Congress.", "10000": "Amendments proposed by Members of Congress.", "10001": "", "10002": "", "10003": "", "10004": "", "106": "", "9999": "Amendments proposed by Members of the US Congress." },
                    "search_url": "amendments/",
                    "sheet_order": 7,
                    "sheet_row_values": ["id", "title"],
                    "sheets": true,
                    "singular": "Amendment",
                    "sort_key": "title",
                    "supported_regions": [1, 10000],
                    "supports_alert": true,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": true,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": true,
                    "undelete": false,
                    "value": 4
                },
                "article_topic": {
                    "advanced_search": false,
                    "advanced_search_order": 15,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.media_monitoring.models.ArticleTopic",
                    "description": "",
                    "display_key": "topic",
                    "download_center": false,
                    "fts": false,
                    "icon": "fa-newspaper-o",
                    "issues_management": false,
                    "key": "article_topic",
                    "label": "Media Topic",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [104],
                    "plural": "News Articles Topics",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "News Article Topic",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 108
                },
                "assignment": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.userdata.models.Assignment",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fa5_icon": "user-circle",
                    "fts": false,
                    "icon": "fa-user-circle",
                    "issues_management": false,
                    "key": "assignment",
                    "label": "Assignment",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [0],
                    "plural": "Assignments",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_order": 100,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "assignment",
                    "search_url": "assignments/",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Assignment",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": true,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 75
                },
                "attachment": {
                    "advanced_search": true,
                    "advanced_search_order": 13,
                    "archivable": true,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": true,
                    "data_model": "app.userdata.models.NonOutboxAttachment",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fa5_icon": "folder-open",
                    "fa5_icon_family": "far",
                    "fts": true,
                    "icon": "fa-folder-open-o",
                    "issues_management": false,
                    "key": "attachment",
                    "label": "Attachment",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "permission_enum": [3],
                    "plural": "Files",
                    "precision_max_threshold": 50000,
                    "profile": "/file/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "attachment",
                    "related_pk_field": "attachment_id",
                    "search_description": { "1": "Files that your team has uploaded to Quorum.", "10000": "Files that your team has uploaded to Quorum.", "10001": "Files that your team has uploaded to Quorum.", "10002": "Files that your team has uploaded to Quorum.", "10003": "Files that your team has uploaded to Quorum.", "10004": "Files that your team has uploaded to Quorum.", "106": "Files that your team has uploaded to Quorum.", "9999": "Files that your team has uploaded to Quorum." },
                    "search_url": "files/",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "File",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": true,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 62
                },
                "bill": {
                    "advanced_search": true,
                    "advanced_search_order": 0,
                    "archivable": false,
                    "assignment": true,
                    "comments": "involving_bills",
                    "custom_fields": true,
                    "custom_fields_on_datadesc": true,
                    "custom_fields_url_pattern": "bill",
                    "custom_lists": true,
                    "data_model": "app.bill.models.Bill",
                    "description": "",
                    "display_key": "title",
                    "download_center": true,
                    "fa5_icon": "file-alt",
                    "fa5_icon_family": "fal",
                    "fts": true,
                    "icon": "fa-file-text-o",
                    "issues_management": true,
                    "key": "bill",
                    "label": "Bill",
                    "legislative_priority": true,
                    "legislative_stance": true,
                    "lists": true,
                    "note_through_field": "bills",
                    "party_frequency_request_key": "for_parties",
                    "permission_enum": [304],
                    "person_frequency_request_key": "sponsored_or_cosponsored_by_or",
                    "plural": "Bills",
                    "precision_max_threshold": 55000,
                    "profile": "/bill/",
                    "proxy_resource_data_model": "app.bill.models.NewBill",
                    "quicksearch": true,
                    "quicksearch_key": "title",
                    "quicksearch_order": 1,
                    "quicksearch_subtitle": ["current_general_status", "current_status_date"],
                    "region_specific_dict": {
                        "Romania": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "argentina": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "australia": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "belgium": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "brazil": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "canada": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "chile": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "china": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "colombia": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "costa_rica": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "czechia": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "denmark": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "dominican_republic": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "eu": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "france": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "germany": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "greece": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "hong_kong": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "india": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "indonesia": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "international": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "ireland": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "israel": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "italy": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "japan": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "malaysia": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "mexico": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "netherlands": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "new_zealand": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "panama": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "philippines": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "poland": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "saudi_arabia": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "singapore": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "south_africa": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "south_korea": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "spain": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "sweden": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "taiwan": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "thailand": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "turkey": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "united_arab_emirates": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "united_kingdom": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "user_global": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" },
                        "vietnam": { "label": "Legislation", "plural": "Legislation", "singular": "Legislation" }
                    },
                    "related_field": "bill",
                    "related_pk_field": "bill_id",
                    "search_description": { "1": "Bills and resolutions introduced in the US Congress.", "10000": "Bills and resolutions introduced in the US Congress, the US states, DC, and Puerto Rico.", "10001": "Bills and resolutions introduced in the US states, DC, and Puerto Rico.", "10002": "Legislation from your available regions.", "10003": "Legislation introduced at the national level.", "10004": "Bills and resolutions introduced in the US states, DC, and Puerto Rico.", "106": "Directives, regulations, and decisions from the European Union.", "9999": "Legislation from your available regions." },
                    "search_url": "bills/",
                    "sheet_order": 5,
                    "sheet_row_values": ["id", "title"],
                    "sheets": true,
                    "singular": "Bill",
                    "sort_key": "number",
                    "supported_regions": [1, 10000, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000, 106, 201, 203, 204, 208, 205, 211, 1000],
                    "supports_alert": true,
                    "supports_alert_changes": true,
                    "supports_alert_edit_saved_search": true,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": true,
                    "undelete": false,
                    "value": 2
                },
                "bill_tracking_project": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.userdata.models.BillTrackingProject",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fts": false,
                    "icon": "fa-map-signs",
                    "issues_management": false,
                    "key": "bill_tracking_project",
                    "label": "Bill Tracking Project",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "note_through_field": "projects",
                    "permission_enum": [304],
                    "plural": "Bill Tracking Projects",
                    "precision_max_threshold": 50000,
                    "profile": "/bill_tracking_project/",
                    "quicksearch": false,
                    "quicksearch_key": "name",
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "project",
                    "related_pk_field": "project_id",
                    "sheet_row_values": ["id", "name"],
                    "sheets": false,
                    "singular": "Bill Tracking Project",
                    "sort_key": "name",
                    "supported_regions": [1, 10000, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 72
                },
                "blank_download": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "data_model": "app.pdfdownload.models.NewDownloadTemplate",
                    "description": "",
                    "download_center": true,
                    "fa5_icon": "file",
                    "fts": false,
                    "icon": "fa-columns",
                    "issues_management": false,
                    "key": "blank_download",
                    "label": "Blank",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [304, 4],
                    "plural": "Blank",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Blank",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 102
                },
                "bulk_action": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.search.bulkactions.models.BulkAction",
                    "description": "",
                    "download_center": false,
                    "fts": false,
                    "icon": "fa-tasks",
                    "issues_management": false,
                    "key": "bulk_action",
                    "label": "Bulk Action",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "Bulk Actions",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Bulk Action",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 90
                },
                "bulk_email": {
                    "advanced_search": false,
                    "archivable": true,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.userdata.outbox.models.BulkEmail",
                    "description": "",
                    "display_key": "subject",
                    "download_center": false,
                    "fa5_icon": "envelope",
                    "fa5_icon_family": "fas",
                    "feature": 8,
                    "fts": false,
                    "icon": "fa-envelope",
                    "issues_management": true,
                    "key": "bulk_email",
                    "label": "Bulk Email",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "note_through_field": "bulk_emails",
                    "permission_enum": [202, 205],
                    "plural": "Outbox",
                    "precision_max_threshold": 50000,
                    "profile": "/outbox/details/",
                    "quicksearch": false,
                    "quicksearch_key": "subject",
                    "quicksearch_subtitle": ["bulk_email"],
                    "region_specific_dict": {},
                    "related_field": "bulk_email",
                    "related_pk_field": "bulk_email_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Outbox",
                    "sort_key": "subject",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": true,
                    "tracking_dashboards": false,
                    "undelete": true,
                    "value": 44
                },
                "bulk_sms": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.texting.models.BulkSMS",
                    "description": "",
                    "display_key": "description",
                    "download_center": false,
                    "fa5_icon": "mobile-alt",
                    "fa5_icon_family": "fas",
                    "feature": 17,
                    "fts": false,
                    "icon": "fa-mobile",
                    "issues_management": true,
                    "key": "bulk_sms",
                    "label": "Bulk SMS",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "note_through_field": "bulksms_messages",
                    "permission_enum": [216],
                    "plural": "Texts",
                    "precision_max_threshold": 50000,
                    "profile": "/texting/details/",
                    "quicksearch": false,
                    "quicksearch_key": "description",
                    "quicksearch_subtitle": ["bulk_sms"],
                    "region_specific_dict": {},
                    "related_field": "bulk_sms",
                    "related_pk_field": "bulk_sms_id",
                    "search_url": "texting/",
                    "sheet_order": 16,
                    "sheet_row_values": ["id", "description"],
                    "sheets": true,
                    "singular": "Text",
                    "sort_key": "description",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": true,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 113
                },
                "bulk_upload": {
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.custom_data.models.BulkUploadFile",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fa5_icon": "upload",
                    "feature": 14,
                    "fts": false,
                    "icon": "fa-pencil",
                    "issues_management": false,
                    "key": "bulk_upload",
                    "label": "Bulk Upload",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "plural": "Bulk Uploads",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "bulk_upload_file",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Bulk Upload",
                    "sort_key": "name",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": true,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 70
                },
                "campaign": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.grassroots.campaign.models.Campaign",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fa5_icon": "bullhorn",
                    "fa5_icon_family": "fas",
                    "feature": 15,
                    "fts": false,
                    "icon": "fa-bullhorn",
                    "issues_management": false,
                    "key": "campaign",
                    "label": "Campaign",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "note_through_field": "campaigns",
                    "permission_enum": [219],
                    "plural": "Campaigns",
                    "precision_max_threshold": 50000,
                    "profile": "/grassroots_campaign/",
                    "quicksearch": false,
                    "quicksearch_key": "name",
                    "quicksearch_subtitle": ["name"],
                    "region_specific_dict": {},
                    "related_field": "campaign",
                    "related_pk_field": "campaign_id",
                    "sheet_order": 9,
                    "sheet_row_values": ["id", "name"],
                    "sheets": true,
                    "singular": "Campaign",
                    "sort_key": "name",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": true,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 47
                },
                "caucus": {
                    "advanced_search": true,
                    "advanced_search_order": 12,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": true,
                    "data_model": "app.committee.models.Caucus",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fa5_icon": "edit",
                    "fa5_icon_family": "far",
                    "fts": false,
                    "icon": "fa-pencil-square-o",
                    "issues_management": true,
                    "key": "caucus",
                    "label": "Caucus",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "permission_enum": [300],
                    "plural": "Caucuses",
                    "precision_max_threshold": 50000,
                    "profile": "/caucus/",
                    "quicksearch": true,
                    "quicksearch_key": "name",
                    "quicksearch_order": 4,
                    "quicksearch_subtitle": ["chairs_string"],
                    "region_specific_dict": {},
                    "related_field": "committee",
                    "related_pk_field": "committee_id",
                    "search_description": { "1": "Congressional caucuses.", "10000": "Congressional caucuses.", "10001": "", "10002": "", "10003": "", "10004": "", "106": "", "9999": "US Congrsessional caucuses." },
                    "search_url": "caucuses/",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Caucus",
                    "supported_regions": [1, 10000],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 16
                },
                "cbo_report": {
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.document.models.CBOReport",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fts": true,
                    "icon": "fa-file-text-o",
                    "issues_management": false,
                    "key": "cbo_report",
                    "label": "CBO Report",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [305],
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "supported_regions": [1, 10000],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 23
                },
                "cf_committee": {
                    "advanced_search": false,
                    "advanced_search_order": 15,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.moneyball.models.CommitteesSuggester",
                    "description": "",
                    "display_key": "cf_committee_id",
                    "download_center": false,
                    "fa5_icon": "gavel",
                    "fts": false,
                    "icon": "fa-gavel",
                    "issues_management": false,
                    "key": "cf_committee",
                    "label": "CF_Committee",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [9, 1007],
                    "plural": "Committees",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Committee",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 133
                },
                "committee": {
                    "advanced_search": true,
                    "advanced_search_order": 11,
                    "archivable": false,
                    "assignment": true,
                    "comments": false,
                    "custom_fields": true,
                    "custom_fields_on_datadesc": true,
                    "custom_fields_url_pattern": "committee",
                    "custom_lists": true,
                    "data_model": "app.committee.models.Committee",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fa5_icon": "gavel",
                    "fts": false,
                    "icon": "fa-gavel",
                    "issues_management": true,
                    "key": "committee",
                    "label": "Committee",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "note_through_field": "committees",
                    "permission_enum": [300],
                    "plural": "Committees",
                    "precision_max_threshold": 50000,
                    "profile": "/committee/",
                    "quicksearch": true,
                    "quicksearch_key": "fullname",
                    "quicksearch_order": 3,
                    "quicksearch_subtitle": ["chairs_string"],
                    "region_specific_dict": {},
                    "related_field": "committee",
                    "related_pk_field": "committee_id",
                    "search_description": { "1": "Congressional committees and subcomittees.", "10000": "Congressional and state legislative committees and subcomittees.", "10001": "State legislative committees and subcomittees.", "10002": "", "10003": "", "10004": "State legislative committees and subcomittees.", "106": "European Parliament Committees.", "9999": "US congressional and state legislative committees and subcomittees." },
                    "search_url": "committees/",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Committee",
                    "sort_key": "name",
                    "supported_regions": [1, 10000, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000, 106],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 15
                },
                "committee_document": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.document.models.Document",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fts": false,
                    "icon": "fa-file-text-o",
                    "is_subset_of": 25,
                    "issues_management": false,
                    "key": "committee_document",
                    "label": "Committee Hearing Document",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [305],
                    "plural": "Hearings",
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "quicksearch": false,
                    "quicksearch_order": 12,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "supported_regions": [1, 10000, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 17
                },
                "committee_meeting": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.document.models.Document",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fts": false,
                    "is_subset_of": 25,
                    "issues_management": false,
                    "key": "committee_meeting",
                    "label": "Committee Meeting",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "quicksearch": false,
                    "quicksearch_order": 13,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "supported_regions": [1, 10000],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 18
                },
                "committee_opinion": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.document.models.CommitteeOpinion",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fts": true,
                    "icon": "fa-microphone",
                    "issues_management": false,
                    "key": "committee_opinion",
                    "label": "Committee Opinion",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "note_through_field": "documents",
                    "plural": "Committee Opinions",
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "quicksearch": false,
                    "quicksearch_order": 15,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "document",
                    "related_pk_field": "document_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Committee Opinion",
                    "sort_key": "title",
                    "supported_regions": [106],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 55
                },
                "committee_report": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.document.models.Document",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fts": true,
                    "icon": "fa-file-text-o",
                    "is_subset_of": 25,
                    "issues_management": false,
                    "key": "committee_report",
                    "label": "Committee Report",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [304],
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "supported_regions": [1, 10000, 106],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 34
                },
                "constituent_email": {
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.document.models.ConstituentEmail",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fts": true,
                    "icon": "fa-inbox",
                    "issues_management": false,
                    "key": "constituent_email",
                    "label": "Newsletter to Constituents",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "permission_enum": [305],
                    "plural": "Newsletters to Constituents",
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "document",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Newsletter to Constituents",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 24
                },
                "crs_report": {
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.document.models.CRSReport",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fts": true,
                    "icon": "fa-file-text-o",
                    "issues_management": false,
                    "key": "crs_report",
                    "label": "CRS Report",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [305],
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "supported_regions": [1, 10000],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 22
                },
                "custom_data": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "data_model": "app.custom_data.manage_data.models.CustomData",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fa5_icon": "cubes",
                    "fts": false,
                    "icon": "fa-cubes",
                    "issues_management": false,
                    "key": "custom_data",
                    "label": "Footprint Data",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [4],
                    "plural": "Footprint Data",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "region_specific_dict": {},
                    "related_field": "custom_data",
                    "sheet_order": 10,
                    "sheet_row_values": ["id", "name", "additional_info"],
                    "sheets": true,
                    "singular": "Footprint Data",
                    "sort_key": "",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": true,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 79
                },
                "custom_event": {
                    "advanced_search": false,
                    "archivable": true,
                    "assignment": true,
                    "comments": false,
                    "custom_fields": true,
                    "custom_fields_on_datadesc": false,
                    "custom_fields_url_pattern": "custom_event",
                    "custom_lists": false,
                    "data_model": "app.custom_event.models.CustomEvent",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fa5_icon": "calendar-check",
                    "fa5_icon_family": "far",
                    "feature": 6,
                    "fts": false,
                    "icon": "fa-calendar-check-o",
                    "issues_management": true,
                    "key": "custom_event",
                    "label": "Custom Event",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "note_through_field": "custom_event",
                    "permission_enum": [206],
                    "plural": "Events",
                    "precision_max_threshold": 50000,
                    "profile": "/custom_event/",
                    "quicksearch": false,
                    "quicksearch_key": "name",
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "custom_event",
                    "related_pk_field": "custom_event_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Event",
                    "sort_key": "name",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": true,
                    "tracking_dashboards": false,
                    "undelete": true,
                    "value": 85
                },
                "custom_event_participant": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.custom_event.models.CustomEventParticipant",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fts": false,
                    "icon": "fa-calendar-check-o",
                    "issues_management": false,
                    "key": "custom_event_participant",
                    "label": "Custom Event Participant",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "Event Participants",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_key": "name",
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "search_url": "custom_event/",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Event Participant",
                    "sort_key": "name",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 86
                },
                "custom_field": {
                    "advanced_search": false,
                    "archivable": true,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.grassroots.models.CustomTag",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fa5_icon": "address-card",
                    "fa5_icon_family": "far",
                    "feature": 3,
                    "fts": false,
                    "icon": "fa-address-card-o",
                    "issues_management": false,
                    "key": "custom_field",
                    "label": "Custom Field",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "Custom Fields",
                    "precision_max_threshold": 50000,
                    "profile": "/customfields/edit/",
                    "quicksearch": false,
                    "quicksearch_order": 15,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "custom_field",
                    "related_pk_field": "custom_field_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Custom Field",
                    "sort_key": "name",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": true,
                    "tracking_dashboards": false,
                    "undelete": true,
                    "value": 73
                },
                "custom_field_group": {
                    "advanced_search": false,
                    "advanced_search_order": 15,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.custom_field.models.CustomFieldGroup",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fa5_icon": "layer-group",
                    "fa5_icon_family": "far",
                    "feature": 24,
                    "fts": false,
                    "icon": "fa-layer-group",
                    "issues_management": false,
                    "key": "custom_field_group",
                    "label": "Custom Field Group",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "Custom Field Groups",
                    "precision_max_threshold": 50000,
                    "profile": "/custom_field_groups/edit/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Custom Field Group",
                    "sort_key": "name",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 124
                },
                "custom_interaction_type": {
                    "advanced_search": false,
                    "archivable": true,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.userdata.notes.models.CustomInteractionType",
                    "description": "",
                    "display_key": "title",
                    "download_center": false,
                    "fa5_icon": "handshake",
                    "fts": false,
                    "icon": "fa-handshake-o",
                    "issues_management": false,
                    "key": "custom_interaction_type",
                    "label": "Custom Interaction Type",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "Interaction Types",
                    "precision_max_threshold": 50000,
                    "profile": "/interactiontypes/edit/",
                    "quicksearch": false,
                    "quicksearch_key": "title",
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Interaction Type",
                    "sort_key": "order",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": true,
                    "value": 106
                },
                "custom_region": {
                    "advanced_search": false,
                    "archivable": true,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "data_model": "app.userdata.models.CustomRegionSelection",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fa5_icon": "map-marked-alt",
                    "fa5_icon_family": "fas",
                    "feature": 12,
                    "fts": false,
                    "icon": "fa-star",
                    "issues_management": false,
                    "key": "custom_region",
                    "label": "Custom Region",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "Custom Regions",
                    "precision_max_threshold": 50000,
                    "profile": "/custom_regions/",
                    "quicksearch": false,
                    "region_specific_dict": {},
                    "related_field": "custom_region_selection",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Custom Region",
                    "sort_key": "name",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": true,
                    "tracking_dashboards": false,
                    "undelete": true,
                    "value": 87
                },
                "dashboard": {
                    "advanced_search": false,
                    "archivable": true,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.widgets.models.Dashboard",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fa5_icon": "dashboard-small",
                    "fa5_icon_family": "q",
                    "feature": 16,
                    "fts": false,
                    "icon": "fa-dashboard",
                    "issues_management": false,
                    "key": "dashboard",
                    "label": "Dashboard",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "Dashboards",
                    "precision_max_threshold": 50000,
                    "profile": "/dashboard/",
                    "quicksearch": false,
                    "quicksearch_key": "name",
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Dashboard",
                    "sort_key": "name",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": true,
                    "value": 104
                },
                "dc_letter": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.document.models.DCLetter",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fts": true,
                    "icon": "fa-paper-plane-o",
                    "issues_management": false,
                    "key": "dc_letter",
                    "label": "Dear Colleague Letter",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "note_through_field": "documents",
                    "permission_enum": [305],
                    "plural": "Dear Colleague Letters",
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "quicksearch": false,
                    "quicksearch_order": 11,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "document",
                    "related_pk_field": "document_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Dear Colleague Letter",
                    "sort_key": "title",
                    "supported_regions": [1, 10000],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 10
                },
                "disbursement": {
                    "advanced_search": true,
                    "advanced_search_order": 23,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": true,
                    "data_model": "app.moneyball.models.Disbursement",
                    "description": "",
                    "display_key": "spender_name",
                    "download_center": false,
                    "fa5_icon": "dollar-sign",
                    "fts": false,
                    "icon": "fa-money",
                    "issues_management": false,
                    "key": "disbursement",
                    "label": "Disbursement",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "permission_enum": [9, 1007],
                    "plural": "Disbursements",
                    "precision_max_threshold": 50000,
                    "profile": "/disbursement/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "search_description": { "1": "Campaign finance disbursements.", "10000": "Campaign finance disbursements.", "10001": "Campaign finance disbursements.", "10002": "Campaign finance disbursements.", "10003": "Campaign finance disbursements.", "10004": "Campaign finance disbursements.", "106": "Campaign finance disbursements.", "9999": "" },
                    "search_url": "disbursements/",
                    "sheet_row_values": ["id", "spender_name"],
                    "sheets": false,
                    "singular": "Disburser",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 125
                },
                "district": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.geography.models.District",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fa5_icon": "building",
                    "fts": false,
                    "icon": "fa-building",
                    "issues_management": false,
                    "key": "district",
                    "label": "District",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "Districts",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_order": 14,
                    "sheet_row_values": ["id", "name"],
                    "sheets": true,
                    "singular": "District",
                    "supported_regions": [1, 10000, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 109
                },
                "docket": {
                    "advanced_search": true,
                    "advanced_search_order": 20,
                    "archivable": false,
                    "assignment": true,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_fields_url_pattern": "docket",
                    "custom_lists": false,
                    "data_model": "app.reg.models.Docket",
                    "description": "",
                    "display_key": "title",
                    "download_center": false,
                    "fa5_icon": "folders",
                    "fts": true,
                    "icon": "fa-folder-open",
                    "issues_management": false,
                    "key": "docket",
                    "label": "Docket",
                    "legislative_priority": true,
                    "legislative_stance": true,
                    "lists": false,
                    "note_through_field": "dockets",
                    "permission_enum": [310],
                    "plural": "Dockets",
                    "precision_max_threshold": 50000,
                    "profile": "/docket/",
                    "quicksearch": false,
                    "quicksearch_key": "title",
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "docket",
                    "related_pk_field": "docket_id",
                    "search_description": { "1": "Nonrulemaking and rulemaking dockets", "10000": "Federal nonrulemaking and rulemaking dockets.", "10001": "", "10002": "", "10003": "", "10004": "", "106": "", "9999": "US Federal nonrulemaking and rulemaking dockets." },
                    "search_url": "dockets/",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Docket",
                    "sort_key": "updated",
                    "supported_regions": [1],
                    "supports_alert": true,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": true,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 88
                },
                "document": {
                    "advanced_search": true,
                    "advanced_search_order": 1,
                    "archivable": false,
                    "assignment": false,
                    "comments": true,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": true,
                    "data_model": "app.document.models.Document",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fa5_icon": "file-audio",
                    "fts": true,
                    "icon": "fa-file-audio-o",
                    "issues_management": true,
                    "key": "document",
                    "label": "Document",
                    "legislative_priority": false,
                    "legislative_stance": true,
                    "lists": true,
                    "note_through_field": "documents",
                    "party_frequency_request_key": "party",
                    "permission_enum": [305, 207, 105],
                    "person_frequency_request_key": "source_members",
                    "plural": "Documents",
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "proxy_resource_data_model": "app.document.models.NewDocument",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_frequency_request_key": "for_regions",
                    "region_specific_dict": {},
                    "related_field": "document",
                    "related_pk_field": "document_id",
                    "search_description": { "1": "Statements, social media, reports, executive orders, and other document types.", "10000": "Statements, social media, reports, executive orders, and other document types.", "10001": "Statements, social media, executive orders, newsletters to constiuents, and other document types.", "10002": "Statements, social media, newsletters to constiuents, and other document types.", "10003": "Social media, including twitter, facebook, and youtube posts.", "10004": "Statements, social media, executive orders, newsletters to constiuents, and other document types.", "106": "Statements, Social Media, Reports, and dozens of other document types.", "9999": "Statements, social media, reports, and more from stakeholders." },
                    "search_url": "documents/",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Document",
                    "sort_key": "title",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": true,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": true,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": true,
                    "undelete": false,
                    "value": 25
                },
                "document_section": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.document.models.DocumentSection",
                    "description": "",
                    "display_key": "text",
                    "download_center": false,
                    "fts": false,
                    "icon": "fa-file-audio-o",
                    "issues_management": false,
                    "key": "document_section",
                    "label": "Document Section",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "supported_regions": [1, 10000],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 36
                },
                "election": {
                    "advanced_search": true,
                    "advanced_search_order": 15,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.pac.models.election.Election",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fa5_icon": "podium",
                    "fa5_icon_family": "fad",
                    "fts": false,
                    "icon": "fad-podium",
                    "issues_management": false,
                    "key": "election",
                    "label": "Election",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [8, 9],
                    "plural": "Elections",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "election",
                    "related_pk_field": "election_id",
                    "search_description": { "1": "US elections.", "10000": "US elections.", "10001": "US elections.", "10002": "", "10003": "", "10004": "US elections.", "106": "", "9999": "" },
                    "search_url": "elections/",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Election",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 123
                },
                "email_template": {
                    "advanced_search": false,
                    "archivable": true,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "data_model": "app.userdata.outbox.models.EmailTemplate",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fa5_icon": "copy",
                    "fa5_icon_family": "fal",
                    "feature": 8,
                    "fts": false,
                    "icon": "fa-copy",
                    "issues_management": false,
                    "key": "email_template",
                    "label": "Email Template",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [202, 205],
                    "plural": "Email Templates",
                    "precision_max_threshold": 50000,
                    "profile": "/outbox/create_template/",
                    "quicksearch": false,
                    "quicksearch_key": "name",
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Email Template",
                    "sort_key": "name",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": true,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 118
                },
                "event": {
                    "advanced_search": true,
                    "advanced_search_order": 7,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": true,
                    "data_model": "app.events.models.Event",
                    "description": "",
                    "display_key": "subject",
                    "download_center": false,
                    "fa5_icon": "calendar-alt",
                    "fts": false,
                    "icon": "fa-calendar",
                    "issues_management": true,
                    "key": "event",
                    "label": "Official Event",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "note_through_field": "events",
                    "permission_enum": [304],
                    "plural": "Official Events",
                    "precision_max_threshold": 1e+06,
                    "profile": "/event/",
                    "proxy_resource_data_model": "app.events.models.NewEvent",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "event",
                    "related_pk_field": "event_id",
                    "search_description": { "1": "Congressional floor schedule, committee meetings, session dates, election dates, and other events.", "10000": "Congressional and state legislative committee meetings, session dates and deadlines, election dates, city and county government meetings, and other events.", "10001": "State legislative committee meetings, session dates and deadlines, election dates, and other events.", "10002": "City council meetings, county board meetings, and other local government events.", "10003": "", "10004": "State legislative committee meetings, session and elections dates, cities, counties, and city councils.", "106": "European Council and Council of the EU Official Events.", "9999": "" },
                    "search_url": "events/",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Official Event",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 10000, 10001, 10002],
                    "supports_alert": true,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": true,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 28
                },
                "event_participant": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.custom_event.models.CustomEventParticipant",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fts": false,
                    "icon": "fa-user",
                    "issues_management": false,
                    "key": "event_participant",
                    "label": "Event Participant",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "Participants",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Participant",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 58
                },
                "executive": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.person.models.Executive",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fts": false,
                    "icon": "fa-user",
                    "issues_management": false,
                    "key": "executive",
                    "label": "Executive",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [301],
                    "plural": "Executives",
                    "precision_max_threshold": 50000,
                    "profile": "/official/",
                    "proxy_resource_data_model": "app.person.models.NewPerson",
                    "quicksearch": false,
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Executive",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 30
                },
                "executive_order": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.document.models.ExecutiveOrder",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fts": true,
                    "icon": "fa-file-audio-o",
                    "issues_management": false,
                    "key": "executive_order",
                    "label": "Executive Order",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "note_through_field": "documents",
                    "permission_enum": [305],
                    "plural": "Executive Orders",
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "quicksearch": false,
                    "quicksearch_order": 15,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "document",
                    "related_pk_field": "document_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Executive Order",
                    "sort_key": "title",
                    "supported_regions": [1, 10000],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 64
                },
                "facebook_post": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.document.models.FacebookPost",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fts": true,
                    "icon": "fa-facebook",
                    "issues_management": false,
                    "key": "facebook_post",
                    "label": "Facebook Post",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "note_through_field": "documents",
                    "permission_enum": [305],
                    "plural": "Facebook Posts",
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "quicksearch": false,
                    "quicksearch_order": 14,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "document",
                    "related_pk_field": "document_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Facebook Post",
                    "sort_key": "content",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 9
                },
                "feature": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.blog.models.FeatureType",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fts": false,
                    "icon": "fa-tasks",
                    "issues_management": false,
                    "key": "feature",
                    "label": "Feature",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "Features",
                    "precision_max_threshold": 50000,
                    "profile": "/help/",
                    "quicksearch": false,
                    "quicksearch_key": "name",
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Features",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 93
                },
                "feature_flag": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.featureflags.models.FeatureFlag",
                    "description": "",
                    "display_key": "slug",
                    "download_center": false,
                    "fa5_icon": "flag-alt",
                    "fa5_icon_family": "fas",
                    "fts": false,
                    "icon": "fa-flag",
                    "issues_management": false,
                    "key": "feature_flag",
                    "label": "Feature Flag",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "Feature Flags",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_key": "slug",
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Feature Flag",
                    "sort_key": "slug",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 84
                },
                "floor_schedule": {
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.events.models.FloorSchedule",
                    "description": "",
                    "display_key": "subject",
                    "download_center": false,
                    "fts": false,
                    "icon": "fa-calendar-check-o",
                    "issues_management": false,
                    "key": "floor_schedule",
                    "label": "Floor Schedule",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "Floor Schedules",
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Floor Schedule",
                    "supported_regions": [1, 10000],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 26
                },
                "floor_statement": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.document.models.FloorStatement",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fts": true,
                    "icon": "fa-microphone",
                    "issues_management": false,
                    "key": "floor_statement",
                    "label": "Floor Statement",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "note_through_field": "documents",
                    "permission_enum": [305],
                    "plural": "Floor Statements",
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "quicksearch": false,
                    "quicksearch_order": 9,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "document",
                    "related_pk_field": "document_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Floor Statement",
                    "sort_key": "title",
                    "supported_regions": [1, 10000],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 7
                },
                "gao_report": {
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.document.models.GAOReport",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fts": true,
                    "icon": "fa-file-text-o",
                    "issues_management": false,
                    "key": "gao_report",
                    "label": "GAO Report",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [305],
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "supported_regions": [1, 10000],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 21
                },
                "geoshape": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "data_model": "app.geography.models.GeoShape",
                    "description": "",
                    "display_key": "name",
                    "download_center": true,
                    "fa5_icon": "compass",
                    "fts": false,
                    "icon": "fa-compass",
                    "issues_management": false,
                    "key": "geoshape",
                    "label": "GeoShape",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "Geographies",
                    "precision_max_threshold": 50000,
                    "profile": "/geoshape/",
                    "quicksearch": false,
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Geography",
                    "sort_key": "",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 97
                },
                "grassroots_action": {
                    "advanced_search": true,
                    "advanced_search_order": 6,
                    "archivable": true,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": true,
                    "data_model": "app.grassroots.models.GrassrootsSupporterAction",
                    "description": "",
                    "display_key": "raw_content",
                    "download_center": false,
                    "fa5_icon": "comments",
                    "fts": false,
                    "icon": "fa-comments-o",
                    "issues_management": false,
                    "key": "grassroots_action",
                    "label": "Action",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "permission_enum": [201],
                    "person_frequency_request_key": "involving_member",
                    "plural": "Actions",
                    "precision_max_threshold": 50000,
                    "profile": "/grassrootsaction/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_frequency_request_key": "for_regions",
                    "region_specific_dict": {},
                    "search_description": { "1": "Grassroots Actions Taken by Your Organization's Advocates.", "10000": "Grassroots Actions Taken by Your Organization's Advocates.", "10001": "Grassroots Actions Taken by Your Organization's Advocates.", "10002": "Grassroots Actions Taken by Your Organization's Advocates.", "10003": "Grassroots Actions Taken by Your Organization's Advocates.", "10004": "Grassroots Actions Taken by Your Organization's Advocates.", "106": "Grassroots Actions Taken by Your Organization's Advocates.", "9999": "Grassroots Actions Taken by Your Organization's Advocates." },
                    "search_url": "grassroots/",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Action",
                    "sort_key": "text",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "type_frequency_request_key": "interaction_type__in",
                    "undelete": false,
                    "value": 69
                },
                "grassroots_page": {
                    "advanced_search": false,
                    "archivable": true,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.grassroots.models.QuorumGrassrootsPage",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fa5_icon": "file",
                    "fts": false,
                    "icon": "fa-files-o",
                    "issues_management": false,
                    "key": "grassroots_page",
                    "label": "Grassroots Page",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "note_through_field": "pages",
                    "permission_enum": [201],
                    "plural": "Pages",
                    "precision_max_threshold": 50000,
                    "profile": "/pages/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "pages",
                    "related_pk_field": "page_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Page",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": true,
                    "tracking_dashboards": false,
                    "undelete": true,
                    "value": 98
                },
                "grassroots_registration_page": {
                    "advanced_search": false,
                    "archivable": true,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.grassroots.models.GrassrootsRegistrationPage",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fa5_icon": "edit",
                    "fa5_icon_family": "fas",
                    "fts": false,
                    "icon": "fa-pencil-square-o",
                    "issues_management": false,
                    "key": "grassroots_registration_page",
                    "label": "Grassroots Registration Page",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "note_through_field": "registration_pages",
                    "permission_enum": [201],
                    "plural": "Forms",
                    "precision_max_threshold": 50000,
                    "profile": "/registration_forms/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "registration_pages",
                    "related_pk_field": "registration_page_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Form",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": true,
                    "tracking_dashboards": false,
                    "undelete": true,
                    "value": 96
                },
                "help_center_post": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.blog.models.HelpCenterPost",
                    "description": "",
                    "display_key": "post",
                    "download_center": false,
                    "fts": false,
                    "icon": "fa-newspaper-o",
                    "issues_management": false,
                    "key": "help_center_post",
                    "label": "Help Center Post",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "Posts",
                    "precision_max_threshold": 50000,
                    "profile": "/help/how-to/",
                    "quicksearch": true,
                    "quicksearch_key": "title",
                    "quicksearch_order": 0,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Post",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 77
                },
                "impact_report_download": {
                    "advanced_search": true,
                    "advanced_search_order": 15,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.impact_reports.models.download.Download",
                    "description": "",
                    "display_key": "title",
                    "download_center": false,
                    "fa5_icon": "building",
                    "feature": 25,
                    "fts": false,
                    "icon": "fa-building",
                    "issues_management": false,
                    "key": "impact_report_download",
                    "label": "Brief Builder Download",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "Brief Builder Downloads",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "download",
                    "related_pk_field": "download_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Brief Builder Download",
                    "sort_key": "id",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 141
                },
                "instagram_post": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.document.models.InstagramPost",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fts": true,
                    "icon": "fa-instagram",
                    "issues_management": false,
                    "key": "instagram_post",
                    "label": "Instagram Post",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "note_through_field": "documents",
                    "permission_enum": [305],
                    "plural": "Instagram Posts",
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "document",
                    "related_pk_field": "document_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Instagram Post",
                    "sort_key": "content",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 39
                },
                "internal_cspan_event": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.events.models.InternalCspanEvent",
                    "description": "",
                    "display_key": "title",
                    "download_center": false,
                    "fts": false,
                    "icon": "fa-calendar",
                    "issues_management": false,
                    "key": "internal_cspan_event",
                    "label": "InternalCspanEvent",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "precision_max_threshold": 50000,
                    "profile": "/event_cspan/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "supported_regions": [1, 10000],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 37
                },
                "issue": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.bill.models.BillTerm",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fts": false,
                    "icon": "fa-tasks",
                    "issues_management": false,
                    "key": "issue",
                    "label": "Legislative Issues",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [304],
                    "plural": "Issues",
                    "precision_max_threshold": 50000,
                    "profile": "/issue/",
                    "quicksearch": true,
                    "quicksearch_key": "name",
                    "quicksearch_order": 2,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Issue",
                    "supported_regions": [1, 10000],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 14
                },
                "issue_management": {
                    "advanced_search": false,
                    "advanced_search_order": 17,
                    "archivable": true,
                    "assignment": false,
                    "comments": "for_project_and_subprojects",
                    "custom_fields": true,
                    "custom_fields_on_datadesc": false,
                    "custom_fields_url_pattern": "issue",
                    "custom_lists": false,
                    "data_model": "app.userdata.models.IssueManagement",
                    "description": "",
                    "display_key": "name",
                    "download_center": true,
                    "fa5_icon": "thumbtack",
                    "fa5_icon_family": "fas",
                    "feature": 7,
                    "fts": false,
                    "icon": "fa-thumb-tack",
                    "issues_management": false,
                    "key": "issue_management",
                    "label": "Issue Management",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "note_through_field": "projects",
                    "plural": "Issues",
                    "precision_max_threshold": 50000,
                    "profile": "/issue_management/",
                    "quicksearch": false,
                    "quicksearch_key": "name",
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "project",
                    "related_pk_field": "project_id",
                    "sheet_order": 12,
                    "sheet_row_values": ["id", "name"],
                    "sheets": true,
                    "singular": "Issue",
                    "sort_key": "name",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": true,
                    "team_permissioning": true,
                    "tracking_dashboards": false,
                    "undelete": true,
                    "value": 83
                },
                "learning_module": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.blog.models.LearningModule",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fts": false,
                    "icon": "fa-graduation-cap",
                    "issues_management": false,
                    "key": "learning_module",
                    "label": "Learning Module",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "Learning Modules",
                    "precision_max_threshold": 50000,
                    "profile": "/help/module/",
                    "quicksearch": true,
                    "quicksearch_key": "name",
                    "quicksearch_order": 1,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Learning Module",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 92
                },
                "ledger_settings": {
                    "advanced_search": false,
                    "advanced_search_order": 15,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.ledger.models.LedgerSettings",
                    "description": "",
                    "display_key": "ledger_settings",
                    "download_center": false,
                    "fa5_icon": "file",
                    "feature": 19,
                    "fts": false,
                    "icon": "fa-pencil",
                    "issues_management": false,
                    "key": "ledger_settings",
                    "label": "Ledger Settings",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [8],
                    "plural": "Ledger Settings'",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "ledger_settings",
                    "related_pk_field": "ledger_settings_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Ledger Settings",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 128
                },
                "leg_session": {
                    "advanced_search": false,
                    "advanced_search_order": 15,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.models.LegSession",
                    "description": "",
                    "display_key": "title",
                    "download_center": false,
                    "fa5_icon": "building",
                    "feature": 9,
                    "fts": false,
                    "icon": "fa-building",
                    "issues_management": false,
                    "key": "leg_session",
                    "label": "Legislative Session",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "Legislative Sessions",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "session",
                    "related_pk_field": "session_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Legislative Session",
                    "sort_key": "id",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 142
                },
                "legislator": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": true,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.person.models.Person",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "event_participant_key": "person_participant",
                    "fts": false,
                    "icon": "fa-address-book",
                    "is_subset_of": 1,
                    "issues_management": true,
                    "key": "legislator",
                    "label": "Legislator",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "note_through_field": "members",
                    "permission_enum": [300],
                    "plural": "Officials",
                    "precision_max_threshold": 50000,
                    "profile": "/official/",
                    "proxy_resource_data_model": "app.person.models.NewPerson",
                    "quicksearch": false,
                    "quicksearch_key": "quicksearch_name",
                    "quicksearch_order": 16,
                    "quicksearch_subtitle": ["most_recent_person_type", "committee_string", "title", "primary_organization_name"],
                    "region_specific_dict": {},
                    "related_field": "person",
                    "related_pk_field": "person_id",
                    "sheet_row_values": ["id", "name", "image_url", "most_recent_party"],
                    "sheets": false,
                    "singular": "Official",
                    "sort_key": "lastname",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 66
                },
                "letter": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "data_model": "app.document.models.Letter",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fts": true,
                    "icon": "fa-envelope",
                    "issues_management": false,
                    "key": "letter",
                    "label": "Letter",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "permission_enum": [305],
                    "plural": "Letters",
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Letter",
                    "supported_regions": [1, 10000, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 40
                },
                "live_transcript_document": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.events.models.Event",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fts": true,
                    "icon": "fa-file-video-o",
                    "issues_management": false,
                    "key": "live_transcript_document",
                    "label": "Live Transcript Document",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [1],
                    "plural": "Live Transcript Documents",
                    "precision_max_threshold": 50000,
                    "profile": "/event_cspan/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Live Transcript Document",
                    "supported_regions": [1, 10000],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 35
                },
                "local_member": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "data_model": "app.person.models.LocalMember",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fts": false,
                    "icon": "fa-user",
                    "issues_management": false,
                    "key": "local_member",
                    "label": "Local Member",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [300],
                    "precision_max_threshold": 50000,
                    "profile": "/official/",
                    "proxy_resource_data_model": "app.person.models.NewPerson",
                    "quicksearch": false,
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "supported_regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 33
                },
                "media": {
                    "advanced_search": true,
                    "advanced_search_order": 21,
                    "archivable": false,
                    "assignment": false,
                    "comments": true,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": true,
                    "data_model": "app.media_monitoring.models.Article",
                    "description": "",
                    "display_key": "title",
                    "download_center": false,
                    "fa5_icon": "newspaper",
                    "fts": false,
                    "icon": "fa-newspaper-o",
                    "issues_management": true,
                    "key": "media",
                    "label": "News",
                    "legislative_priority": true,
                    "legislative_stance": true,
                    "lists": true,
                    "note_through_field": "articles",
                    "permission_enum": [104],
                    "plural": "News Articles",
                    "precision_max_threshold": 50000,
                    "profile": "/article/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "article",
                    "related_pk_field": "article_id",
                    "search_description": { "1": "News Articles, Blogs, Print, Broadcast Transcripts, Podcast stored by Quorum.", "10000": "News Articles, Blogs, Print, Broadcast Transcripts, Podcast stored by Quorum.", "10001": "News Articles, Blogs, Print, Broadcast Transcripts, Podcast stored by Quorum.", "10002": "News Articles, Blogs, Print, Broadcast Transcripts, Podcast stored by Quorum.", "10003": "News Articles, Blogs, Print, Broadcast Transcripts, Podcast stored by Quorum.", "10004": "News Articles, Blogs, Print, Broadcast Transcripts, Podcast stored by Quorum.", "106": "News Articles, Blogs, Print, Broadcast Transcripts, Podcast stored by Quorum.", "9999": "News articles, including print and online, broadcast transcripts, blogs, and more, from your available regions." },
                    "search_url": "articles/",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "News Article",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": true,
                    "supports_alert_changes": true,
                    "supports_alert_edit_saved_search": true,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": true,
                    "undelete": false,
                    "value": 105
                },
                "medium_post": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.document.models.MediumPost",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fts": true,
                    "icon": "fa-medium",
                    "issues_management": false,
                    "key": "medium_post",
                    "label": "Medium Post",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "note_through_field": "documents",
                    "permission_enum": [305],
                    "plural": "Medium Posts",
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "document",
                    "related_pk_field": "document_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Medium Post",
                    "sort_key": "content",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 38
                },
                "member": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": true,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.person.models.Person",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fts": false,
                    "icon": "fa-street-view",
                    "is_subset_of": 1,
                    "issues_management": true,
                    "key": "member",
                    "label": "Member",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "note_through_field": "members",
                    "permission_enum": [300],
                    "precision_max_threshold": 50000,
                    "profile": "/official/",
                    "proxy_resource_data_model": "app.person.models.NewPerson",
                    "quicksearch": false,
                    "region_specific_dict": {},
                    "related_field": "person",
                    "related_pk_field": "person_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 29
                },
                "memorandum": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.document.models.Memorandum",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fts": true,
                    "icon": "fa-file-audio-o",
                    "issues_management": false,
                    "key": "memorandum",
                    "label": "Presidential Memorandum",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "permission_enum": [305],
                    "plural": "Presidential Memoranda",
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "quicksearch": false,
                    "quicksearch_order": 15,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "document",
                    "related_pk_field": "document_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Presidential Memorandum",
                    "sort_key": "title",
                    "supported_regions": [1, 10000],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 68
                },
                "message_event": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.userdata.outbox.models.MessageEvent",
                    "description": "",
                    "display_key": "event_name",
                    "download_center": false,
                    "fts": false,
                    "icon": "fa-envelope-o",
                    "issues_management": false,
                    "key": "message_event",
                    "label": "Message Event",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "note_through_field": "message_events",
                    "plural": "Message Events",
                    "precision_max_threshold": 50000,
                    "profile": "/message_event/",
                    "quicksearch": false,
                    "quicksearch_key": "event_name",
                    "quicksearch_subtitle": ["message_event"],
                    "region_specific_dict": {},
                    "related_field": "message_event",
                    "related_pk_field": "message_event_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Message Events",
                    "sort_key": "event_name",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 46
                },
                "motion_for_resolution": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.document.models.MotionForResolution",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fts": true,
                    "icon": "fa-microphone",
                    "issues_management": false,
                    "key": "motion_for_resolution",
                    "label": "Motion for Resolution",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "plural": "Motions for Resolution",
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "quicksearch": false,
                    "quicksearch_order": 15,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "document",
                    "related_pk_field": "document_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Motion for Resolution",
                    "sort_key": "title",
                    "supported_regions": [106],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 54
                },
                "new_download_template": {
                    "advanced_search": false,
                    "archivable": true,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "data_model": "app.pdfdownload.models.NewDownloadTemplate",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fa5_icon": "cloud-download-alt",
                    "fa5_icon_family": "fas",
                    "feature": 4,
                    "fts": false,
                    "icon": "fa-columns",
                    "issues_management": false,
                    "key": "new_download_template",
                    "label": "Download",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [304, 4],
                    "plural": "Downloads",
                    "precision_max_threshold": 50000,
                    "profile": "/downloadcenter/",
                    "quicksearch": false,
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Download",
                    "sort_key": "name",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": true,
                    "tracking_dashboards": false,
                    "undelete": true,
                    "value": 100
                },
                "new_list": {
                    "advanced_search": false,
                    "archivable": true,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.userdata.models.NewList",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fts": false,
                    "icon": "fa-list",
                    "issues_management": false,
                    "key": "new_list",
                    "label": "New List",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "Lists",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "List",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": true,
                    "value": 91
                },
                "new_pdf_email": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.pdfdownload.models.NewPDFEmail",
                    "description": "",
                    "display_key": "title",
                    "download_center": false,
                    "fa5_icon": "download",
                    "feature": 4,
                    "fts": false,
                    "icon": "fa-envelope-o",
                    "issues_management": false,
                    "key": "new_pdf_email",
                    "label": "New Pdf Email",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "PDF Downloads",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "PDF Download",
                    "sort_key": "title",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 103
                },
                "nomination": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.document.models.Nomination",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fts": true,
                    "icon": "fa-file-audio-o",
                    "issues_management": false,
                    "key": "nomination",
                    "label": "Presidential Nomination",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "permission_enum": [304],
                    "plural": "Presidential Nominations",
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "quicksearch": false,
                    "quicksearch_order": 15,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "document",
                    "related_pk_field": "document_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Presidential Nomination",
                    "sort_key": "title",
                    "supported_regions": [1, 10000],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 71
                },
                "note": {
                    "advanced_search": true,
                    "advanced_search_order": 5,
                    "archivable": true,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": true,
                    "custom_fields_on_datadesc": false,
                    "custom_fields_url_pattern": "note",
                    "custom_lists": true,
                    "data_model": "app.userdata.notes.models.Note",
                    "description": "",
                    "display_key": "raw_content",
                    "download_center": false,
                    "fa5_icon": "pencil-alt",
                    "fts": false,
                    "icon": "fa-pencil",
                    "issues_management": true,
                    "key": "note",
                    "label": "Note",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "note_through_field": "notes",
                    "permission_enum": [6, 5, 7],
                    "person_frequency_request_key": "involving_member",
                    "plural": "Notes",
                    "precision_max_threshold": 50000,
                    "profile": "/note/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_frequency_request_key": "for_regions",
                    "region_specific_dict": {},
                    "related_field": "note",
                    "related_pk_field": "note_id",
                    "search_description": { "1": "Interactions, Meetings, Relationships, Comments and Custom Notes Logged by Your Team.", "10000": "Interactions, Meetings, Relationships, Comments and Custom Notes Logged by Your Team.", "10001": "Interactions, Meetings, Relationships, Comments and Custom Notes Logged by Your Team.", "10002": "Interactions, Meetings, Relationships, Comments and Custom Notes Logged by Your Team.", "10003": "Interactions, Meetings, Relationships, Comments and Custom Notes Logged by Your Team.", "10004": "Interactions, Meetings, Relationships, Comments and Custom Notes Logged by Your Team.", "106": "Interactions, Meetings, Relationships, Comments and Custom Notes Logged by Your Team.", "9999": "Interactions, Meetings, Relationships, Comments and Custom Notes Logged by Your Team." },
                    "search_url": "notes/",
                    "sheet_order": 15,
                    "sheet_row_values": ["id", "raw_content", "cache_json", "note_type"],
                    "sheets": true,
                    "singular": "Note",
                    "sort_key": "text",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": true,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": true,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": true,
                    "tracking_dashboards": false,
                    "type_frequency_request_key": "interaction_type__in",
                    "undelete": true,
                    "value": 20
                },
                "organization": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.userdata.models.Organization",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fa5_icon": "building",
                    "fts": false,
                    "icon": "fa-building",
                    "issues_management": false,
                    "key": "organization",
                    "label": "Organization",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "Organizations",
                    "precision_max_threshold": 50000,
                    "profile": "/edit_org/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Organization",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 63
                },
                "outlet": {
                    "advanced_search": false,
                    "advanced_search_order": 25,
                    "archivable": false,
                    "assignment": false,
                    "comments": "involving_public_organization_and_associated_individuals",
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_fields_url_pattern": "outlet",
                    "custom_lists": false,
                    "data_model": "app.person.models.PublicOrganization",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "event_participant_key": "public_organization_participant",
                    "fa5_icon": "building",
                    "fa5_icon_family": "far",
                    "fts": false,
                    "icon": "fa-building-o",
                    "issues_management": false,
                    "key": "outlet",
                    "label": "Outlet",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "note_through_field": "public_organizations",
                    "permission_enum": [105, 104],
                    "plural": "Outlets",
                    "precision_max_threshold": 50000,
                    "profile": "/outlet/",
                    "quicksearch": false,
                    "quicksearch_key": "name",
                    "quicksearch_order": 23,
                    "quicksearch_subtitle": ["public_organization"],
                    "region_specific_dict": {},
                    "related_field": "public_organization",
                    "related_pk_field": "public_organization_id",
                    "search_url": "outlets/",
                    "sheet_row_values": ["id"],
                    "sheets": false,
                    "singular": "Outlet",
                    "sort_key": "name",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 121
                },
                "pac_filings": {
                    "advanced_search": false,
                    "advanced_search_order": 15,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.pac.models.pac_file.PacFile",
                    "description": "",
                    "display_key": "filing_display_key",
                    "download_center": false,
                    "fa5_icon": "file-alt",
                    "feature": 18,
                    "fts": false,
                    "icon": "fa-file",
                    "issues_management": false,
                    "key": "pac_filings",
                    "label": "PAC Filing",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [8],
                    "plural": "Filings",
                    "precision_max_threshold": 50000,
                    "profile": "/filing/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "pac_file",
                    "related_pk_field": "pac_file_id",
                    "search_url": "filings/",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Filing",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 110
                },
                "pac_jurisdiction": {
                    "advanced_search": false,
                    "advanced_search_order": 15,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.ledger.models.LedgerReportingJurisdiction",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fa5_icon": "file",
                    "feature": 19,
                    "fts": false,
                    "icon": "fa-pencil",
                    "issues_management": false,
                    "key": "pac_jurisdiction",
                    "label": "Reporting Jurisdiction",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [8],
                    "plural": "Reporting Jurisdictions",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "reporting_jurisdictions",
                    "related_pk_field": "reporting_jurisdictions_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Reporting Jurisdiction",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 132
                },
                "pacc_bank_account": {
                    "advanced_search": false,
                    "advanced_search_order": 15,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.pac_classic.models.bank_account.PacClassicBankAccount",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fa5_icon": "pencil",
                    "fa5_icon_family": "far",
                    "feature": 26,
                    "fts": false,
                    "icon": "fa-pencil",
                    "issues_management": false,
                    "key": "pacc_bank_account",
                    "label": "Bank Account (PAC)",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [215],
                    "plural": "Bank Accounts (PAC)",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "bank_account",
                    "related_pk_field": "bank_account_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Bank Account",
                    "sort_key": "id",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 136
                },
                "pacc_election": {
                    "advanced_search": false,
                    "advanced_search_order": 15,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.pac_classic.models.election.PacClassicElection",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fa5_icon": "podium",
                    "fa5_icon_family": "fad",
                    "feature": 26,
                    "fts": false,
                    "icon": "fad-podium",
                    "issues_management": false,
                    "key": "pacc_election",
                    "label": "Election (PAC)",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [215],
                    "plural": "Elections (PAC)",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "election",
                    "related_pk_field": "election_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Election",
                    "sort_key": "id",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 137
                },
                "pacc_organization": {
                    "advanced_search": false,
                    "advanced_search_order": 15,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.pac_classic.models.organization.PacClassicOrganization",
                    "description": "",
                    "display_key": "full_name",
                    "download_center": false,
                    "fa5_icon": "building",
                    "feature": 26,
                    "fts": false,
                    "icon": "fa-building",
                    "issues_management": false,
                    "key": "pacc_organization",
                    "label": "Organization (PAC)",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [215],
                    "plural": "Organizations (PAC)",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "pac_classic_organization",
                    "related_pk_field": "pac_classic_organization_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Organization",
                    "sort_key": "id",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 139
                },
                "pacc_pac_account": {
                    "advanced_search": false,
                    "advanced_search_order": 15,
                    "archivable": true,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.pac.models.pacc_pac_account.PACCPACAccount",
                    "description": "",
                    "display_key": "id",
                    "download_center": false,
                    "feature": 19,
                    "fts": false,
                    "icon": "fa-pencil",
                    "issues_management": false,
                    "key": "pacc_pac_account",
                    "label": "PACC PAC Account",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "PACC PAC Accounts",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "PACC PAC Account",
                    "sort_key": "id",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 130
                },
                "pacc_pac_office": {
                    "advanced_search": true,
                    "advanced_search_order": 15,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.pac_classic.models.office.PacClassicPacOffice",
                    "description": "",
                    "display_key": "name_with_juris",
                    "download_center": false,
                    "fa5_icon": "building",
                    "feature": 26,
                    "fts": false,
                    "icon": "fa-building",
                    "issues_management": false,
                    "key": "pacc_pac_office",
                    "label": "Elected Office (PAC)",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [215],
                    "plural": "Elected Offices (PAC)",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "pac_classic_pac_office",
                    "related_pk_field": "pac_classic_pac_office_id",
                    "search_url": "elected-offices/",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Elected Office",
                    "sort_key": "id",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 140
                },
                "pacc_pac_organization": {
                    "advanced_search": true,
                    "advanced_search_order": 15,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.pac_classic.models.pac_organization.PacClassicPacOrganization",
                    "description": "",
                    "display_key": "organization_name",
                    "download_center": false,
                    "fa5_icon": "dollar-sign",
                    "fa5_icon_family": "far",
                    "feature": 26,
                    "fts": false,
                    "icon": "fa-usd",
                    "issues_management": false,
                    "key": "pacc_pac_organization",
                    "label": "PAC Organization (PAC)",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [215],
                    "plural": "PAC Organizations",
                    "precision_max_threshold": 50000,
                    "profile": "/pac-organization/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "pac_classic_pac_organization",
                    "related_pk_field": "pac_classic_pac_organization_id",
                    "search_url": "pac-organization",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "PAC Organization",
                    "sort_key": "id",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 138
                },
                "pacc_pac_transaction": {
                    "advanced_search": true,
                    "advanced_search_order": 15,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": true,
                    "data_model": "app.pac_classic.models.transaction.PacClassicPacTransaction",
                    "description": "",
                    "display_key": "entity_name",
                    "download_center": false,
                    "fa5_icon": "money-bill",
                    "fa5_icon_family": "far",
                    "feature": 26,
                    "fts": false,
                    "icon": "fa-money",
                    "issues_management": false,
                    "key": "pacc_pac_transaction",
                    "label": "Transaction (PAC)",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [215],
                    "plural": "PAC Transactions",
                    "precision_max_threshold": 50000,
                    "profile": "/pac-transaction/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "search_description": { "1": "PAC 2.0 Receipts, Disbursements, and Refunds.", "10000": "PAC 2.0 Receipts, Disbursements, and Refunds.", "10001": "PAC 2.0 Receipts, Disbursements, and Refunds.", "10002": "PAC 2.0 Receipts, Disbursements, and Refunds.", "10004": "PAC 2.0 Receipts, Disbursements, and Refunds.", "9999": "PAC 2.0 Receipts, Disbursements, and Refunds." },
                    "search_url": "pac-transactions/",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Name",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 135
                },
                "pacc_report_request": {
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.pac.state_pac.models.pacc_report_request.PACCReportRequest",
                    "description": "",
                    "display_key": "id",
                    "download_center": false,
                    "fa5_icon": "upload",
                    "feature": 19,
                    "fts": false,
                    "icon": "fa-pencil",
                    "issues_management": false,
                    "key": "pacc_report_request",
                    "label": "PACC Report Request",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "PACC Report Requests",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "pacc_report_request",
                    "related_pk_field": "pacc_report_request_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "PACC Report Requests",
                    "sort_key": "id",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 129
                },
                "pacc_sync_log": {
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.pac.pacc_sync.models.PACCSyncLog",
                    "description": "",
                    "display_key": "id",
                    "download_center": false,
                    "fa5_icon": "upload",
                    "feature": 19,
                    "fts": false,
                    "icon": "fa-pencil",
                    "issues_management": false,
                    "key": "pacc_sync_log",
                    "label": "PACC Sync Log",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "PACC Sync Logs",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "pacc_sync_log",
                    "related_pk_field": "pacc_sync_log_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "PACC Sync Log",
                    "sort_key": "id",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 127
                },
                "parliamentary_question": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.document.models.ParliamentaryQuestion",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fts": true,
                    "icon": "fa-microphone",
                    "issues_management": false,
                    "key": "parliamentary_question",
                    "label": "Parliamentary Question",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "plural": "Parliamentary Questions",
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "quicksearch": false,
                    "quicksearch_order": 15,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "document",
                    "related_pk_field": "document_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Parliamentary Question",
                    "sort_key": "title",
                    "supported_regions": [106],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 50
                },
                "pdf_email": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.generatepdf.models.PDFEmail",
                    "description": "",
                    "display_key": "title",
                    "download_center": false,
                    "fa5_icon": "download",
                    "feature": 13,
                    "fts": false,
                    "icon": "fa-envelope-o",
                    "issues_management": false,
                    "key": "pdf_email",
                    "label": "Pdf Email",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "note_through_field": "send_emails",
                    "plural": "PDF Downloads",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_key": "title",
                    "quicksearch_subtitle": ["send_email"],
                    "region_specific_dict": {},
                    "related_field": "send_email",
                    "related_pk_field": "send_email_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "PDF Download",
                    "sort_key": "title",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 48
                },
                "person": {
                    "advanced_search": true,
                    "advanced_search_order": 2,
                    "archivable": false,
                    "assignment": true,
                    "comments": "involving_member",
                    "custom_fields": true,
                    "custom_fields_on_datadesc": true,
                    "custom_fields_url_pattern": "official",
                    "custom_lists": true,
                    "data_model": "app.person.models.Person",
                    "description": "",
                    "display_key": "name",
                    "download_center": true,
                    "event_participant_key": "person_participant",
                    "fa5_icon": "users",
                    "fts": false,
                    "icon": "fa-users",
                    "issues_management": true,
                    "key": "person",
                    "label": "Person",
                    "legislative_priority": true,
                    "legislative_stance": false,
                    "lists": true,
                    "note_through_field": "members",
                    "outbox_selection_styling": "blue-tag-2",
                    "permission_enum": [300],
                    "plural": "Officials",
                    "precision_max_threshold": 1e+06,
                    "profile": "/official/",
                    "proxy_resource_data_model": "app.person.models.NewPerson",
                    "quicksearch": true,
                    "quicksearch_key": "quicksearch_name",
                    "quicksearch_order": 0,
                    "quicksearch_subtitle": ["most_recent_person_type", "committee_string", "title", "primary_organization_name"],
                    "region_specific_dict": {},
                    "related_field": "person",
                    "related_pk_field": "person_id",
                    "search_description": { "1": "Members of Congress and officials who work in the executive branch.", "10000": "Members of Congress, state legislators, executive branch officials, city and county officials, and other officials.", "10001": "State legislators, statewide elected officials, and other state officials.", "10002": "City and county officials.", "10003": "Elected and appointed officials.", "10004": "State legislators, statewide elected officials, and other state officials.", "106": "Officials and Staff from the European Union institutions, including MEPs, Commissioners, Council Ministers and Permanent Representation Attach\u00e9s as well as relevant staff members.", "9999": "Elected and appointed officials from your available regions." },
                    "search_url": "officials/",
                    "sheet_order": 0,
                    "sheet_row_values": ["id", "name", "image_url", "most_recent_party", "organization"],
                    "sheets": true,
                    "singular": "Official",
                    "sort_key": "lastname",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": true,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": true,
                    "supports_stakeholder_alignment": true,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 1
                },
                "person_biography": {
                    "advanced_search": false,
                    "advanced_search_order": 15,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.person.models.PersonBiography",
                    "description": "",
                    "display_key": "person",
                    "download_center": false,
                    "fa5_icon": "building",
                    "feature": 9,
                    "fts": false,
                    "icon": "fa-building",
                    "issues_management": false,
                    "key": "person_biography",
                    "label": "Person Role",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "Person Biography",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "biography",
                    "related_pk_field": "biography_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Person Biography",
                    "sort_key": "id",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 144
                },
                "person_role": {
                    "advanced_search": false,
                    "advanced_search_order": 15,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.person.models.PersonRole",
                    "description": "",
                    "display_key": "title",
                    "download_center": false,
                    "fa5_icon": "building",
                    "feature": 9,
                    "fts": false,
                    "icon": "fa-building",
                    "issues_management": false,
                    "key": "person_role",
                    "label": "Person Role",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "Person Role",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "most_recent_role",
                    "related_pk_field": "most_recent_role_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Person Role",
                    "sort_key": "id",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 143
                },
                "political_committee": {
                    "advanced_search": true,
                    "advanced_search_order": 15,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": true,
                    "data_model": "app.pac.models.political_committee.PoliticalCommittee",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fa5_icon": "dollar-sign",
                    "fa5_icon_family": "far",
                    "feature": 19,
                    "fts": false,
                    "icon": "fa-usd",
                    "issues_management": false,
                    "key": "political_committee",
                    "label": "Political Committee",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "permission_enum": [8, 9],
                    "plural": "Political Committees",
                    "precision_max_threshold": 50000,
                    "profile": "/political_committee/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "political_committee",
                    "related_pk_field": "political_committee_id",
                    "search_description": { "1": "PACs, Candidate Committees, Party Committees, JFC's and more.", "10000": "PACs, Candidate Committees, Party Committees, JFC's and more.", "10001": "PACs, Candidate Committees, Party Committees, JFC's and more.", "10002": "PACs, Candidate Committees, Party Committees, JFC's and more.", "10003": "PACs, Candidate Committees, Party Committees, JFC's and more.", "10004": "PACs, Candidate Committees, Party Committees, JFC's and more.", "106": "PACs, Candidate Committees, Party Committees, JFC's and more.", "9999": "US PACs, Candidate Committees, Party Committees, JFC's, and more." },
                    "search_url": "political_committees/",
                    "sheet_order": 42,
                    "sheet_row_values": ["id", "name"],
                    "sheets": true,
                    "singular": "Political Committee",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 114
                },
                "potential_duplicates": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.deduplication.models.PotentialDuplicate",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fa5_icon": "object-ungroup",
                    "fa5_icon_family": "fal",
                    "feature": 22,
                    "fts": false,
                    "icon": "fa-object-ungroup",
                    "issues_management": false,
                    "key": "potential_duplicates",
                    "label": "Potential Duplicate",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "Potential Duplicates",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Potential Duplicate",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 117
                },
                "press_briefing": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.document.models.PressBriefing",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fts": true,
                    "icon": "fa-file-audio-o",
                    "issues_management": false,
                    "key": "press_briefing",
                    "label": "Press Briefing",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "note_through_field": "documents",
                    "permission_enum": [305],
                    "plural": "Press Briefings",
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "quicksearch": false,
                    "quicksearch_order": 15,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "document",
                    "related_pk_field": "document_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Press Briefing",
                    "sort_key": "title",
                    "supported_regions": [1, 10000],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 65
                },
                "press_contact": {
                    "advanced_search": true,
                    "advanced_search_order": 24,
                    "archivable": false,
                    "assignment": true,
                    "comments": "involving_member",
                    "custom_fields": true,
                    "custom_fields_on_datadesc": true,
                    "custom_fields_url_pattern": "press",
                    "custom_lists": true,
                    "data_model": "app.person.models.Person",
                    "description": "",
                    "display_key": "name",
                    "download_center": true,
                    "fa5_icon": "microphone",
                    "fa5_icon_family": "fas",
                    "fts": false,
                    "icon": "fa-microphone",
                    "issues_management": true,
                    "key": "press_contact",
                    "label": "Press Contact",
                    "legislative_priority": true,
                    "legislative_stance": false,
                    "lists": true,
                    "note_through_field": "members",
                    "outbox_selection_styling": "blue-tag-2",
                    "permission_enum": [105],
                    "plural": "Press Contacts",
                    "precision_max_threshold": 50000,
                    "profile": "/press/",
                    "proxy_resource_data_model": "app.person.models.NewPerson",
                    "quicksearch": false,
                    "quicksearch_key": "quicksearch_name",
                    "quicksearch_order": 0,
                    "quicksearch_subtitle": ["most_recent_person_type"],
                    "region_specific_dict": {},
                    "related_field": "person",
                    "related_pk_field": "person_id",
                    "search_description": { "1": "Reporters, Editors, Producers, News Anchors, and other members of the media.", "10000": "Reporters, Editors, Producers, News Anchors, and other members of the media.", "10001": "Reporters, Editors, Producers, News Anchors, and other members of the media.", "10002": "Reporters, Editors, Producers, News Anchors, and other members of the media.", "10003": "Reporters, Editors, Producers, News Anchors, and other members of the media.", "10004": "Reporters, Editors, Producers, News Anchors, and other members of the media.", "106": "Reporters, Editors, Producers, News Anchors, and other members of the media.", "9999": "Reporters, Editors, Producers, News Anchors, and other members of the media." },
                    "search_url": "press/",
                    "sheet_order": 2,
                    "sheet_row_values": ["id", "image_url", "name"],
                    "sheets": true,
                    "singular": "Press",
                    "sort_key": "lastname",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": true,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": true,
                    "supports_stakeholder_alignment": true,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 120
                },
                "press_release": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.document.models.PressRelease",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fts": true,
                    "icon": "fa-file-audio-o",
                    "issues_management": false,
                    "key": "press_release",
                    "label": "Press Release",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "note_through_field": "documents",
                    "permission_enum": [305],
                    "plural": "Press Releases",
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "quicksearch": false,
                    "quicksearch_order": 15,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "document",
                    "related_pk_field": "document_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Press Release",
                    "sort_key": "title",
                    "supported_regions": [1, 10000, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000, 106],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 5
                },
                "proclamation": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.document.models.Proclamation",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fts": true,
                    "icon": "fa-file-audio-o",
                    "issues_management": false,
                    "key": "proclamation",
                    "label": "White House Proclamation",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "permission_enum": [305],
                    "plural": "White House Proclamations",
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "quicksearch": false,
                    "quicksearch_order": 15,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "document",
                    "related_pk_field": "document_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "White House Proclamation",
                    "sort_key": "title",
                    "supported_regions": [1, 10000],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 67
                },
                "project": {
                    "advanced_search": false,
                    "advanced_search_order": 17,
                    "archivable": true,
                    "assignment": true,
                    "comments": "for_project_and_subprojects",
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.userdata.models.Project",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fts": false,
                    "icon": "fa-map-signs",
                    "issues_management": false,
                    "key": "project",
                    "label": "Issue",
                    "legislative_priority": true,
                    "legislative_stance": true,
                    "lists": false,
                    "note_through_field": "projects",
                    "plural": "Issues",
                    "precision_max_threshold": 50000,
                    "profile": "/issue_management/",
                    "quicksearch": false,
                    "quicksearch_key": "name",
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "project",
                    "related_pk_field": "project_id",
                    "sheet_row_values": ["id", "name"],
                    "sheets": false,
                    "singular": "Issue",
                    "sort_key": "name",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": true,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 49
                },
                "public_organization": {
                    "advanced_search": true,
                    "advanced_search_order": 14,
                    "archivable": true,
                    "assignment": true,
                    "comments": "involving_public_organization_and_associated_individuals",
                    "custom_fields": true,
                    "custom_fields_on_datadesc": true,
                    "custom_fields_url_pattern": "organization",
                    "custom_lists": true,
                    "data_model": "app.person.models.PublicOrganization",
                    "description": "",
                    "display_key": "name",
                    "download_center": true,
                    "event_participant_key": "public_organization_participant",
                    "fa5_icon": "building",
                    "fa5_icon_family": "far",
                    "fts": false,
                    "icon": "fa-building-o",
                    "issues_management": true,
                    "key": "public_organization",
                    "label": "Organization",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "note_through_field": "public_organizations",
                    "permission_enum": [301, 204, 105, 104],
                    "plural": "Organizations",
                    "precision_max_threshold": 1e+06,
                    "profile": "/organization/",
                    "quicksearch": false,
                    "quicksearch_key": "name",
                    "quicksearch_order": 8,
                    "quicksearch_subtitle": ["public_organization"],
                    "region_specific_dict": {},
                    "related_field": "public_organization",
                    "related_pk_field": "public_organization_id",
                    "search_description": { "1": "Federal departments, independent agencies, other legislative or government bodies, and custom orgs.", "10000": "Legislatures, departments, agencies, cities, counties, city councils, county boards, other legislative or government bodies, and custom orgs.", "10001": "State legislatures, departments, agencies, other legislative or government bodies, and custom orgs.", "10002": "Cities, counties, city councils, county boards, other local government bodies, and custom orgs.", "10003": "National governments, legislative bodies, political parties, and other official and custom orgs.", "10004": "State legislatures, departments, agencies, other legislative or government bodies, and custom orgs.", "106": "Official European Union Organizations and Custom Organizations.", "9999": "" },
                    "search_url": "orgs/",
                    "sheet_order": 3,
                    "sheet_row_values": ["id", "name"],
                    "sheets": true,
                    "singular": "Organization",
                    "sort_key": "name",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": true,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 42
                },
                "receipt": {
                    "advanced_search": true,
                    "advanced_search_order": 22,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": true,
                    "data_model": "app.moneyball.models.Receipt",
                    "description": "",
                    "display_key": "donor_name",
                    "download_center": false,
                    "fa5_icon": "dollar-sign",
                    "fts": false,
                    "icon": "fa-dollar",
                    "issues_management": false,
                    "key": "receipt",
                    "label": "Receipt",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "permission_enum": [9, 1007],
                    "plural": "Receipts",
                    "precision_max_threshold": 50000,
                    "profile": "/receipt/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "search_description": { "1": "Campaign finance receipts.", "10000": "Campaign finance receipts.", "10001": "Campaign finance receipts.", "10002": "Campaign finance receipts.", "10003": "Campaign finance receipts.", "10004": "Campaign finance receipts.", "106": "Campaign finance receipts.", "9999": "" },
                    "search_url": "receipts/",
                    "sheet_row_values": ["id", "donor_name"],
                    "sheets": false,
                    "singular": "Contributor",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 126
                },
                "reg_comment": {
                    "advanced_search": false,
                    "advanced_search_order": 19,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_fields_url_pattern": "reg_comment",
                    "custom_lists": false,
                    "data_model": "app.reg.models.RegComment",
                    "description": "",
                    "display_key": "title",
                    "download_center": false,
                    "fa5_icon": "comment-edit",
                    "fts": true,
                    "icon": "fa-bullhorn",
                    "issues_management": false,
                    "key": "reg_comment",
                    "label": "Comment on Regulation",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [310],
                    "plural": "Comments on Regulations",
                    "precision_max_threshold": 50000,
                    "profile": "/reg_comment/",
                    "quicksearch": false,
                    "quicksearch_key": "title",
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "reg_comment",
                    "related_pk_field": "reg_comment_id",
                    "search_url": "reg_comments/",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Comment on Regulation",
                    "sort_key": "posted_by_agency_datetime",
                    "supported_regions": [1],
                    "supports_alert": true,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": true,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 95
                },
                "register": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.reg.models.Register",
                    "description": "",
                    "display_key": "title",
                    "download_center": false,
                    "fts": false,
                    "issues_management": false,
                    "key": "register",
                    "label": "Register",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "Registers",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Register",
                    "sort_key": "date",
                    "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 1],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 81
                },
                "regulation": {
                    "advanced_search": true,
                    "advanced_search_order": 10,
                    "archivable": false,
                    "assignment": true,
                    "comments": true,
                    "custom_fields": true,
                    "custom_fields_on_datadesc": true,
                    "custom_fields_url_pattern": "regulation",
                    "custom_lists": true,
                    "data_model": "app.reg.models.Regulation",
                    "description": "",
                    "display_key": "title",
                    "download_center": true,
                    "fa5_icon": "book",
                    "fts": true,
                    "icon": "fa-book",
                    "issues_management": true,
                    "key": "regulation",
                    "label": "Regulation",
                    "legislative_priority": true,
                    "legislative_stance": true,
                    "lists": true,
                    "note_through_field": "regulations",
                    "permission_enum": [310],
                    "plural": "Regulations",
                    "precision_max_threshold": 50000,
                    "profile": "/regulation/",
                    "quicksearch": false,
                    "quicksearch_key": "title",
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "regulation",
                    "related_pk_field": "regulation_id",
                    "search_description": { "1": "Federal rules, proposed rules, notices, and other regulatory documents.", "10000": "Federal and state rules, proposed rules, and other regulatory documents.", "10001": "State rules, proposed rules, and other regulatory documents.", "10002": "", "10003": "Proposed or adopted rules and regulations at the national level.", "10004": "State rules, proposed rules, and other regulatory documents.", "106": "", "201": "Canada statutory instruments.", "9999": "US federal and state rules, proposed rules, and other regulatory documents." },
                    "search_url": "regs/",
                    "sheet_order": 13,
                    "sheet_row_values": ["id", "title"],
                    "sheets": true,
                    "singular": "Regulation",
                    "sort_key": "proposal_date",
                    "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 1, 204, 201, 203, 208, 205, 211],
                    "supports_alert": true,
                    "supports_alert_changes": true,
                    "supports_alert_edit_saved_search": true,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": true,
                    "undelete": false,
                    "value": 80
                },
                "reply_email": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.userdata.outbox.models.ReplyEmail",
                    "description": "",
                    "display_key": "subject",
                    "download_center": false,
                    "fa5_icon": "envelope-square",
                    "fts": false,
                    "icon": "fa-envelope-square",
                    "issues_management": false,
                    "key": "reply_email",
                    "label": "Reply Email",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "note_through_field": "replies",
                    "permission_enum": [202, 205],
                    "plural": "Replies",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_key": "subject",
                    "quicksearch_subtitle": ["reply_email"],
                    "region_specific_dict": {},
                    "related_field": "reply_email",
                    "related_pk_field": "reply_email_id",
                    "search_url": "replies/",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Reply",
                    "sort_key": "subject",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 101
                },
                "saved_search": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.userdata.models.SafedSearch",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fts": false,
                    "icon": "fa-search",
                    "issues_management": false,
                    "key": "saved_search",
                    "label": "Saved Search",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "Searches",
                    "precision_max_threshold": 50000,
                    "profile": "/search/",
                    "quicksearch": false,
                    "quicksearch_order": 0,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Search",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 76
                },
                "send_email": {
                    "advanced_search": true,
                    "advanced_search_order": 18,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": true,
                    "data_model": "app.userdata.outbox.models.SendEmail",
                    "description": "Filter individuals based on the emails you\u2019ve sent them via Outbox over the last 12 months - whether they\u2019ve opened, clicked or replied to your emails.",
                    "display_key": "recipient_name",
                    "download_center": false,
                    "fa5_icon": "envelope",
                    "fts": false,
                    "icon": "fa-envelope-o",
                    "issues_management": false,
                    "key": "send_email",
                    "label": "Send Email",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "note_through_field": "send_emails",
                    "permission_enum": [202, 205, 105],
                    "plural": "Outbox (Recipients)",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_key": "recipient_name",
                    "quicksearch_subtitle": ["send_email"],
                    "region_specific_dict": {},
                    "related_field": "send_email",
                    "related_pk_field": "send_email_id",
                    "search_description": { "1": "Outbox emails that your organization has sent.", "10000": "Outbox emails that your organization has sent.", "10001": "Outbox emails that your organization has sent.", "10002": "Outbox emails that your organization has sent.", "10003": "Outbox emails that your organization has sent.", "10004": "Outbox emails that your organization has sent.", "106": "Outbox emails that your organization has sent.", "9999": "Outbox emails that your organization has sent." },
                    "search_url": "outbox/",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Outbox (Recipient)",
                    "sort_key": "recipient_name",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": true,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": true,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 45
                },
                "send_email_archived": {
                    "advanced_search": true,
                    "advanced_search_order": 15,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": true,
                    "data_model": "app.userdata.outbox.models.SendEmailArchived",
                    "description": "Filter individuals based on the emails you\u2019ve sent them via Outbox beyond the last 12 months - whether they\u2019ve opened, clicked or replied to your emails.",
                    "display_key": "recipient_name",
                    "download_center": false,
                    "fa5_icon": "envelope",
                    "fts": false,
                    "icon": "fa-envelope-o",
                    "issues_management": false,
                    "key": "send_email_archived",
                    "label": "Send Email Archived",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "note_through_field": "archived_send_emails",
                    "permission_enum": [202, 205, 105],
                    "plural": "Outbox (Recipients) Archived",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_key": "recipient_name",
                    "quicksearch_subtitle": ["archived_send_email"],
                    "region_specific_dict": {},
                    "related_field": "archived_send_email",
                    "related_pk_field": "archived_send_email_id",
                    "search_description": { "1": "Outbox emails that your organization has sent beyond the last 12 months.", "10000": "Outbox emails that your organization has sent beyond the last 12 months.", "10001": "Outbox emails that your organization has sent beyond the last 12 months.", "10002": "Outbox emails that your organization has sent beyond the last 12 months.", "10003": "Outbox emails that your organization has sent beyond the last 12 months.", "10004": "Outbox emails that your organization has sent beyond the last 12 months.", "106": "Outbox emails that your organization has sent beyond the last 12 months.", "9999": "Outbox emails that your organization has sent beyond the last 12 months." },
                    "search_url": "outbox/",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Outbox (Recipient) Archived",
                    "sort_key": "recipient_name",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 134
                },
                "send_sms": {
                    "advanced_search": true,
                    "advanced_search_order": 15,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": true,
                    "data_model": "app.texting.models.SendSMS",
                    "description": "",
                    "display_key": "supporter_name",
                    "download_center": false,
                    "fa5_icon": "mobile-alt",
                    "fa5_icon_family": "fas",
                    "feature": 17,
                    "fts": false,
                    "icon": "fa-mobile",
                    "issues_management": false,
                    "key": "send_sms",
                    "label": "Send SMS",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "note_through_field": "sendsms_messages",
                    "permission_enum": [216],
                    "plural": "Outbound Texting (Recipients)",
                    "precision_max_threshold": 50000,
                    "profile": "/send_sms/",
                    "quicksearch": false,
                    "quicksearch_key": "recipient_name",
                    "quicksearch_subtitle": ["bulk_sms"],
                    "region_specific_dict": {},
                    "related_field": "sendsms_message",
                    "related_pk_field": "sendsms_message_id",
                    "search_url": "send_sms/",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Outbound Texting (Recipients)",
                    "sort_key": "recipient_name",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": true,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 119
                },
                "sheet": {
                    "advanced_search": false,
                    "archivable": true,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.userdata.spreadsheet.models.Sheet",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fa5_icon": "sort-amount-down",
                    "fa5_icon_family": "far",
                    "feature": 10,
                    "fts": false,
                    "icon": "fa-sort-amount-desc",
                    "issues_management": false,
                    "key": "sheet",
                    "label": "Sheet",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "Sheets",
                    "precision_max_threshold": 50000,
                    "profile": "/sheet/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "sheet",
                    "sheet_row_values": ["id", "name"],
                    "sheets": false,
                    "singular": "Sheet",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": true,
                    "tracking_dashboards": false,
                    "undelete": true,
                    "value": 78
                },
                "sms_message": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.texting.models.SMSMessage",
                    "description": "",
                    "display_key": "associated_number",
                    "download_center": false,
                    "fa5_icon": "mobile-alt",
                    "fa5_icon_family": "fas",
                    "feature": 17,
                    "fts": false,
                    "icon": "fa-mobile",
                    "issues_management": false,
                    "key": "sms_message",
                    "label": "SMS Message",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "note_through_field": "sms_messages",
                    "permission_enum": [201],
                    "plural": "Outbound Texting (Recipients and Replies)",
                    "precision_max_threshold": 50000,
                    "profile": "/sms_message/",
                    "quicksearch": false,
                    "quicksearch_key": "recipient_name",
                    "quicksearch_subtitle": ["bulk_sms"],
                    "region_specific_dict": {},
                    "related_field": "sms_message",
                    "related_pk_field": "sms_message_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Outbound Texting (Recipients and Replies)",
                    "sort_key": "recipient_name",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": true,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 115
                },
                "speech": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.document.models.Speech",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fts": true,
                    "icon": "fa-file-audio-o",
                    "issues_management": false,
                    "key": "speech",
                    "label": "Speech",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "note_through_field": "documents",
                    "permission_enum": [305],
                    "plural": "Speeches",
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "quicksearch": false,
                    "quicksearch_order": 15,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "document",
                    "related_pk_field": "document_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Speech",
                    "sort_key": "title",
                    "supported_regions": [106],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 43
                },
                "speech_in_plenary": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.document.models.SpeechInPlenary",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fts": true,
                    "icon": "fa-microphone",
                    "issues_management": false,
                    "key": "speech_in_plenary",
                    "label": "Speech in Plenary",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "plural": "Speeches in Plenary",
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "quicksearch": false,
                    "quicksearch_order": 15,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "document",
                    "related_pk_field": "document_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Speech in Plenary",
                    "sort_key": "title",
                    "supported_regions": [106],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 56
                },
                "staffer": {
                    "advanced_search": true,
                    "advanced_search_order": 3,
                    "archivable": false,
                    "assignment": true,
                    "comments": "involving_member",
                    "custom_fields": true,
                    "custom_fields_on_datadesc": true,
                    "custom_fields_url_pattern": "staffer",
                    "custom_lists": true,
                    "data_model": "app.person.models.Person",
                    "description": "",
                    "display_key": "name",
                    "download_center": true,
                    "fa5_icon": "address-card",
                    "fa5_icon_family": "far",
                    "fts": false,
                    "icon": "fa-address-card-o",
                    "is_subset_of": 1,
                    "issues_management": true,
                    "key": "staffer",
                    "label": "Staffer",
                    "legislative_priority": true,
                    "legislative_stance": false,
                    "lists": false,
                    "note_through_field": "members",
                    "outbox_selection_styling": "blue-tag-2",
                    "permission_enum": [302],
                    "plural": "Staffers",
                    "precision_max_threshold": 50000,
                    "profile": "/official/",
                    "proxy_resource_data_model": "app.person.models.NewPerson",
                    "quicksearch": false,
                    "quicksearch_key": "name",
                    "quicksearch_order": 3,
                    "quicksearch_subtitle": ["title", "member", "committee"],
                    "region_specific_dict": {
                        "Romania": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "argentina": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "australia": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "belgium": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "brazil": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "canada": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "chile": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "china": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "colombia": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "costa_rica": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "czechia": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "denmark": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "dominican_republic": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "eu": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "france": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "germany": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "greece": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "hong_kong": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "india": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "indonesia": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "international": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "ireland": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "israel": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "italy": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "japan": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "malaysia": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "mexico": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "netherlands": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "new_zealand": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "panama": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "philippines": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "poland": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "saudi_arabia": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "singapore": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "south_africa": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "south_korea": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "spain": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "sweden": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "taiwan": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "thailand": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "turkey": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "united_arab_emirates": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "united_kingdom": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "user_global": { "label": "Staff", "plural": "Staff", "singular": "Staff" },
                        "vietnam": { "label": "Staff", "plural": "Staff", "singular": "Staff" }
                    },
                    "related_field": "person",
                    "related_pk_field": "person_id",
                    "search_description": { "1": "Legislative, committee, executive, and leadership staffers.", "10000": "Legislative, committee, executive, and leadership staffers.", "10001": "Legislative, committee, and executive staffers.", "10002": "", "10003": "", "10004": "Legislative, committee, and executive staffers.", "106": "", "9999": "High-level United States legislative, committee, executive, and leadership staffers." },
                    "search_url": "staffers/",
                    "sheet_order": 1,
                    "sheet_row_values": ["id", "name", "image_url", "most_recent_party"],
                    "sheets": true,
                    "singular": "Staffer",
                    "sort_key": "lastname",
                    "supported_regions": [1, 10000, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                    "supports_alert": true,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": true,
                    "supports_stakeholder_alignment": true,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 8
                },
                "state": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "data_model": "app.geography.models.State",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fa5_icon": "compass",
                    "fts": false,
                    "icon": "fa-compass",
                    "issues_management": false,
                    "key": "state",
                    "label": "State",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "States",
                    "precision_max_threshold": 50000,
                    "profile": "",
                    "quicksearch": false,
                    "region_specific_dict": {},
                    "sheet_order": 11,
                    "sheet_row_values": ["id", "name"],
                    "sheets": true,
                    "singular": "State",
                    "sort_key": "",
                    "supported_regions": [1, 10000, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 82
                },
                "state_bill_supporting_document": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.document.models.Document",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fts": true,
                    "icon": "fa-file-text-o",
                    "is_subset_of": 25,
                    "issues_management": false,
                    "key": "state_bill_supporting_document",
                    "label": "State Bill Supporting Document",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [304],
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 27
                },
                "statistic": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.stats.models.StatName",
                    "description": "",
                    "display_key": "full_name",
                    "download_center": false,
                    "fts": false,
                    "icon": "fa-bar-chart",
                    "issues_management": false,
                    "key": "statistic",
                    "label": "StatName",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "Statistics",
                    "precision_max_threshold": 50000,
                    "profile": "/statistics/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Statistic",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 57
                },
                "supporter": {
                    "advanced_search": true,
                    "advanced_search_order": 4,
                    "archivable": true,
                    "assignment": true,
                    "comments": "involving_supporter",
                    "custom_fields": true,
                    "custom_fields_on_datadesc": false,
                    "custom_fields_url_pattern": "contact",
                    "custom_lists": true,
                    "data_model": "app.grassroots.models.Supporter",
                    "description": "",
                    "display_key": "name",
                    "download_center": true,
                    "event_participant_key": "supporter_participant",
                    "fa5_icon": "child",
                    "fa5_icon_family": "far",
                    "fts": false,
                    "icon": "fa-child",
                    "issues_management": true,
                    "key": "supporter",
                    "label": "Supporter",
                    "legislative_priority": true,
                    "legislative_stance": false,
                    "lists": true,
                    "note_through_field": "supporters",
                    "outbox_selection_styling": "purple-tag-2",
                    "permission_enum": [204],
                    "plural": "Contacts",
                    "precision_max_threshold": 1e+06,
                    "profile": "/contact/",
                    "proxy_resource_data_model": "app.grassroots.models.NewSupporter",
                    "quicksearch": true,
                    "quicksearch_key": "name",
                    "quicksearch_order": 7,
                    "quicksearch_subtitle": ["supp"],
                    "region_specific_dict": {},
                    "related_field": "supporter",
                    "related_pk_field": "supporter_id",
                    "search_description": { "1": "Contact information for your stakeholders, advocates, and more that you provide to Quorum.", "10000": "Contact information for your stakeholders, advocates, and more that you provide to Quorum.", "10001": "Contact information for your stakeholders, advocates, and more that you provide to Quorum.", "10002": "Contact information for your stakeholders, advocates, and more that you provide to Quorum.", "10003": "Contact information for your stakeholders, advocates, and more that you provide to Quorum.", "10004": "Contact information for your stakeholders, advocates, and more that you provide to Quorum.", "106": "Contact information for your stakeholders, advocates, and more that you provide to Quorum.", "9999": "Contact database for your organization's stakeholders, advocates, and more that you add to Quorum." },
                    "search_url": "contacts/",
                    "sheet_order": 4,
                    "sheet_row_values": ["id", "name"],
                    "sheets": true,
                    "singular": "Contact",
                    "sort_key": "lastname",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": true,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": true,
                    "supports_stakeholder_alignment": true,
                    "team_permissioning": true,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 12
                },
                "team": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.userdata.models.Team",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "event_participant_key": "team_participant",
                    "fa5_icon": "users",
                    "feature": 23,
                    "fts": false,
                    "icon": "fa-users",
                    "issues_management": false,
                    "key": "team",
                    "label": "Team",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "note_through_field": "teams",
                    "plural": "Teams",
                    "precision_max_threshold": 50000,
                    "profile": "/edit_team/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Team",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 60
                },
                "tracking_dashboard": {
                    "advanced_search": false,
                    "archivable": true,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.tracking_dashboards.models.TrackingDashboard",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fa5_icon": "tachometer-alt",
                    "fa5_icon_family": "fas",
                    "feature": 11,
                    "fts": false,
                    "icon": "fa-dashboard",
                    "issues_management": false,
                    "key": "tracking_dashboard",
                    "label": "Tracking Board",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "plural": "Tracking Boards",
                    "precision_max_threshold": 50000,
                    "profile": "/tracking_board/",
                    "quicksearch": false,
                    "quicksearch_key": "name",
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Tracking Board",
                    "sort_key": "name",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": true,
                    "value": 89
                },
                "transaction": {
                    "advanced_search": true,
                    "advanced_search_order": 15,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": true,
                    "data_model": "app.ledger.models.Transaction",
                    "description": "",
                    "display_key": "entity_associated_with_transaction",
                    "download_center": false,
                    "fa5_icon": "money-bill",
                    "fa5_icon_family": "far",
                    "feature": 21,
                    "fts": false,
                    "icon": "fa-money",
                    "issues_management": false,
                    "key": "transaction",
                    "label": "Transaction",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "permission_enum": [8],
                    "plural": "PAC Transactions",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "search_description": { "1": "Receipts, Disbursements, and Refunds.", "10000": "Receipts, Disbursements, and Refunds.", "10001": "Receipts, Disbursements, and Refunds.", "10002": "Receipts, Disbursements, and Refunds.", "10003": "Receipts, Disbursements, and Refunds.", "10004": "Receipts, Disbursements, and Refunds.", "106": "Receipts, Disbursements, and Refunds.", "9999": "" },
                    "search_url": "transactions/",
                    "sheet_order": 43,
                    "sheet_row_values": ["entity_associated_with_transaction", "id", "transaction_type"],
                    "sheets": true,
                    "singular": "Transaction",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 107
                },
                "transaction_batch": {
                    "advanced_search": false,
                    "advanced_search_order": 15,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.ledger.models.TransactionBatch",
                    "description": "",
                    "display_key": "name",
                    "download_center": false,
                    "fa5_icon": "dollar-sign",
                    "feature": 19,
                    "fts": false,
                    "icon": "fa-money",
                    "issues_management": false,
                    "key": "transaction_batch",
                    "label": "Transaction Batch",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [8],
                    "plural": "Transaction Batches",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Transaction Batch",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 122
                },
                "transaction_errors": {
                    "advanced_search": false,
                    "advanced_search_order": 15,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.ledger.models.AccountStateError",
                    "description": "",
                    "display_key": "error_display_key",
                    "download_center": false,
                    "feature": 21,
                    "fts": false,
                    "icon": "fa-exclamation-triangle",
                    "issues_management": false,
                    "key": "transaction_errors",
                    "label": "Transaction Errors",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [8],
                    "plural": "Errors",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Error",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 111
                },
                "tweet": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.document.models.Tweet",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fts": true,
                    "icon": "fa-twitter",
                    "is_subset_of": 25,
                    "issues_management": false,
                    "key": "tweet",
                    "label": "Tweet",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "note_through_field": "documents",
                    "permission_enum": [305, 105],
                    "plural": "Tweets",
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "quicksearch": false,
                    "quicksearch_order": 10,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "document",
                    "related_pk_field": "document_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Tweet",
                    "sort_key": "content",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 6
                },
                "user": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.userdata.models.QuorumUser",
                    "description": "",
                    "display_key": "user_info__full_name",
                    "download_center": false,
                    "event_participant_key": "user_participant",
                    "fa5_icon": "users",
                    "fts": false,
                    "icon": "fa-user",
                    "issues_management": false,
                    "key": "user",
                    "label": "User",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "note_through_field": "team_members",
                    "plural": "Team Members",
                    "precision_max_threshold": 50000,
                    "profile": "/edit_user/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "user",
                    "related_pk_field": "user_id",
                    "sheet_order": 8,
                    "sheet_row_values": ["id", "first_name", "last_name", "user_info__full_name"],
                    "sheets": true,
                    "singular": "Team Member",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 59
                },
                "vote": {
                    "advanced_search": true,
                    "advanced_search_order": 9,
                    "archivable": false,
                    "assignment": true,
                    "comments": "referencing_votes",
                    "custom_fields": false,
                    "custom_lists": true,
                    "data_model": "app.vote.models.Vote",
                    "description": "",
                    "display_key": "display_name",
                    "download_center": false,
                    "fa5_icon": "check",
                    "fts": false,
                    "icon": "fa-check",
                    "issues_management": true,
                    "key": "vote",
                    "label": "Vote",
                    "legislative_priority": false,
                    "legislative_stance": true,
                    "lists": true,
                    "note_through_field": "votes",
                    "permission_enum": [304],
                    "plural": "Votes",
                    "precision_max_threshold": 50000,
                    "profile": "/vote/",
                    "quicksearch": false,
                    "quicksearch_key": "question",
                    "quicksearch_order": 5,
                    "quicksearch_subtitle": ["result", "created"],
                    "region_specific_dict": {},
                    "related_field": "vote",
                    "related_pk_field": "vote_id",
                    "search_description": { "1": "Congressional votes on legislation, amendments, confirmations, and other procedures.", "10000": "Congressional and state legislative votes on legislation, amendments, and other procedures.", "10001": "State legislative floor and committee votes.", "10002": "", "10003": "", "10004": "State legislative floor and committee votes.", "106": "", "9999": "Congressional and state legislative votes on legislation, amendments, and other procedures." },
                    "search_url": "votes/",
                    "sheet_order": 6,
                    "sheet_row_values": ["id", "question", "majority_result"],
                    "sheets": true,
                    "singular": "Vote",
                    "sort_key": "question",
                    "supported_regions": [1, 10000, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                    "supports_alert": true,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": true,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 3
                },
                "vote_explanation": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.document.models.VoteExplanation",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fts": true,
                    "icon": "fa-microphone",
                    "issues_management": false,
                    "key": "vote_explanation",
                    "label": "Vote Explanation",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "plural": "Vote Explanations",
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "quicksearch": false,
                    "quicksearch_order": 15,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "document",
                    "related_pk_field": "document_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Vote Explanation",
                    "sort_key": "title",
                    "supported_regions": [106],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 52
                },
                "vote_result": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.vote.models.VoteResult",
                    "description": "",
                    "download_center": false,
                    "fa5_icon": "check",
                    "fts": false,
                    "icon": "fa-check",
                    "issues_management": false,
                    "key": "vote_result",
                    "label": "Vote Result",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": false,
                    "permission_enum": [304],
                    "plural": "Votes",
                    "precision_max_threshold": 50000,
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Vote",
                    "supported_regions": [1, 10000, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 94
                },
                "written_declaration": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.document.models.WrittenDeclaration",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fts": true,
                    "icon": "fa-microphone",
                    "issues_management": false,
                    "key": "written_declaration",
                    "label": "Written Declaration",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "plural": "Written Declarations",
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "quicksearch": false,
                    "quicksearch_order": 15,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "document",
                    "related_pk_field": "document_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Written Declaration",
                    "sort_key": "title",
                    "supported_regions": [106],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 53
                },
                "youtube_video": {
                    "advanced_search": false,
                    "archivable": false,
                    "assignment": false,
                    "comments": false,
                    "custom_fields": false,
                    "custom_fields_on_datadesc": false,
                    "custom_lists": false,
                    "data_model": "app.document.models.YouTubeVideo",
                    "description": "",
                    "display_key": "display_text",
                    "download_center": false,
                    "fts": true,
                    "icon": "fa-youtube-play",
                    "issues_management": false,
                    "key": "youtube_video",
                    "label": "YouTube Video",
                    "legislative_priority": false,
                    "legislative_stance": false,
                    "lists": true,
                    "note_through_field": "documents",
                    "permission_enum": [305],
                    "plural": "Youtube Videos",
                    "precision_max_threshold": 50000,
                    "profile": "/document/",
                    "quicksearch": false,
                    "quicksearch_subtitle": [],
                    "region_specific_dict": {},
                    "related_field": "document",
                    "related_pk_field": "document_id",
                    "sheet_row_values": [],
                    "sheets": false,
                    "singular": "Youtube Video",
                    "sort_key": "content",
                    "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                    "supports_alert": false,
                    "supports_alert_changes": false,
                    "supports_alert_edit_saved_search": false,
                    "supports_stakeholder_alignment": false,
                    "team_permissioning": false,
                    "tracking_dashboards": false,
                    "undelete": false,
                    "value": 41
                }
            },
            "Region": {
                "Romania": {
                    "abbrev": "rou",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 189,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "RO",
                    "key": "Romania",
                    "label": "Romanian Parliament",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 237,
                    "region_name": "Romania",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "UTC+2",
                    "value": 237
                },
                "__is_enum": true,
                "alabama": {
                    "abbrev": "al",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 1,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 1,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-al'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-AL",
                    "key": "alabama",
                    "label": "Alabama Legislature",
                    "leg_type": 3,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 2,
                    "region_name": "Alabama",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 2
                },
                "alabama_local": {
                    "abbrev": "al_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 1,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-al'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-AL",
                    "key": "alabama_local",
                    "label": "Alabama Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 54,
                    "region_name": "Alabama Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 54
                },
                "alaska": {
                    "abbrev": "ak",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 2,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 2,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-ak'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-AK",
                    "key": "alaska",
                    "label": "Alaska State Legislature",
                    "leg_type": 1,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 3,
                    "region_name": "Alaska",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "AKST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 3
                },
                "alaska_local": {
                    "abbrev": "ak_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 2,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-ak'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-AK",
                    "key": "alaska_local",
                    "label": "Alaska Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 55,
                    "region_name": "Alaska Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "AKST",
                    "value": 55
                },
                "american_samoa": {
                    "abbrev": "as",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 56,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 60,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": true,
                    "iso_code": "AS",
                    "key": "american_samoa",
                    "label": "American Samoa Fono",
                    "leg_type": 2,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 108,
                    "region_name": "American Samoa",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "SST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 108
                },
                "argentina": {
                    "abbrev": "arg",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 9,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "AR",
                    "key": "argentina",
                    "label": "The National Congress of Argentina",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 224,
                    "region_name": "Argentina",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "GMT-3",
                    "value": 224
                },
                "arizona": {
                    "abbrev": "az",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 3,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 4,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-az'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-AZ",
                    "key": "arizona",
                    "label": "Arizona State Legislature",
                    "leg_type": 2,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 4,
                    "region_name": "Arizona",
                    "show_in_selector": true,
                    "show_reg_uids": false,
                    "timezone": "MST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 4
                },
                "arizona_local": {
                    "abbrev": "az_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 4,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-az'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-AZ",
                    "key": "arizona_local",
                    "label": "Arizona Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 56,
                    "region_name": "Arizona Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "MST",
                    "value": 56
                },
                "arkansas": {
                    "abbrev": "ar",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 4,
                    "error_type": 2,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 5,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-ar'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-AR",
                    "key": "arkansas",
                    "label": "Arkansas General Assembly",
                    "leg_type": 3,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 5,
                    "region_name": "Arkansas",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 5
                },
                "arkansas_local": {
                    "abbrev": "ar_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 5,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-ar'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-AR",
                    "key": "arkansas_local",
                    "label": "Arkansas Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 57,
                    "region_name": "Arkansas Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 57
                },
                "australia": {
                    "abbrev": "aus",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 15,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "AU",
                    "key": "australia",
                    "label": "Parliament of Australia",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 200,
                    "region_name": "Australia",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "AEST",
                    "value": 200
                },
                "belgium": {
                    "abbrev": "bel",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 19,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": true,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "BE",
                    "key": "belgium",
                    "label": "Belgian Federal Parliament",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 226,
                    "region_name": "Belgium",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "CET",
                    "value": 226
                },
                "brazil": {
                    "abbrev": "bra",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 32,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": true,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "BR",
                    "key": "brazil",
                    "label": "Federal Government of Brazil",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 208,
                    "region_name": "Brazil",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "BRST",
                    "value": 208
                },
                "california": {
                    "abbrev": "ca",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 5,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 6,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-ca'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-CA",
                    "key": "california",
                    "label": "California State Legislature",
                    "leg_type": 2,
                    "lower_chamber_title": "Assembly",
                    "lower_member_title": "Assembly Member",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 6,
                    "region_name": "California",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "PST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 6
                },
                "california_local": {
                    "abbrev": "ca_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 6,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-ca'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-CA",
                    "key": "california_local",
                    "label": "California Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 58,
                    "region_name": "California Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "PST",
                    "value": 58
                },
                "canada": {
                    "abbrev": "can",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 39,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": true,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "CA",
                    "key": "canada",
                    "label": "Parliament of Canada",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 201,
                    "region_name": "Canada",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "EST",
                    "value": 201
                },
                "chile": {
                    "abbrev": "chl",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 42,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "CL",
                    "key": "chile",
                    "label": "National Congress of Chile",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 233,
                    "region_name": "Chile",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "GMT-4",
                    "value": 233
                },
                "china": {
                    "abbrev": "chn",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 43,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "CN",
                    "key": "china",
                    "label": "National People's Congress of the People's Republic of China",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 209,
                    "region_name": "China",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "CST",
                    "value": 209
                },
                "colombia": {
                    "abbrev": "col",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 49,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "CO",
                    "key": "colombia",
                    "label": "Congress of the Republic of Colombia",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 213,
                    "region_name": "Colombia",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "COT",
                    "value": 213
                },
                "colorado": {
                    "abbrev": "co",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 6,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 8,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-co'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-CO",
                    "key": "colorado",
                    "label": "Colorado General Assembly",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 7,
                    "region_name": "Colorado",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "MST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 7
                },
                "colorado_local": {
                    "abbrev": "co_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 8,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-co'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-CO",
                    "key": "colorado_local",
                    "label": "Colorado Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 59,
                    "region_name": "Colorado Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "MST",
                    "value": 59
                },
                "connecticut": {
                    "abbrev": "ct",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 7,
                    "error_type": 2,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 9,
                    "has_bills": true,
                    "has_committee_cosponsors": true,
                    "has_committee_sponsors": true,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-ct'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-CT",
                    "key": "connecticut",
                    "label": "Connecticut General Assembly",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 8,
                    "region_name": "Connecticut",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 8
                },
                "connecticut_local": {
                    "abbrev": "ct_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 9,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-ct'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-CT",
                    "key": "connecticut_local",
                    "label": "Connecticut Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 60,
                    "region_name": "Connecticut Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 60
                },
                "costa_rica": {
                    "abbrev": "cri",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 52,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "CR",
                    "key": "costa_rica",
                    "label": "Republic of Costa Rica",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 232,
                    "region_name": "Costa Rica",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "GMT-6",
                    "value": 232
                },
                "czechia": {
                    "abbrev": "cze",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 58,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": true,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "CZ",
                    "key": "czechia",
                    "label": "Parliament of Czechia",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 238,
                    "region_name": "Czechia",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "UTC+1",
                    "value": 238
                },
                "dc_local": {
                    "abbrev": "dc_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 11,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-dc'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-DC",
                    "key": "dc_local",
                    "label": "District of Columbia Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 62,
                    "region_name": "District of Columbia Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 62
                },
                "delaware": {
                    "abbrev": "de",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 8,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 10,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-de'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-DE",
                    "key": "delaware",
                    "label": "Delaware General Assembly",
                    "leg_type": 2,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 9,
                    "region_name": "Delaware",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 9
                },
                "delaware_local": {
                    "abbrev": "de_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 10,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-de'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-DE",
                    "key": "delaware_local",
                    "label": "Delaware Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 61,
                    "region_name": "Delaware Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 61
                },
                "denmark": {
                    "abbrev": "dnk",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 62,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": true,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "DK",
                    "key": "denmark",
                    "label": "Folketing",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 217,
                    "region_name": "Denmark",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "CET",
                    "value": 217
                },
                "district_of_columbia": {
                    "abbrev": "dc",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 9,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 11,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-dc'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": true,
                    "iso_code": "US-DC",
                    "key": "district_of_columbia",
                    "label": "Council of the District of Columbia",
                    "leg_type": 2,
                    "lower_chamber_title": "",
                    "lower_member_title": "",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 10,
                    "region_name": "District of Columbia",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Council",
                    "upper_member_title": "Council Member",
                    "value": 10
                },
                "dominican_republic": {
                    "abbrev": "dom",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 63,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "DO",
                    "key": "dominican_republic",
                    "label": "Congress of the Dominican Republic",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 235,
                    "region_name": "Dominican Republic",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "GMT-4",
                    "value": 235
                },
                "eu": {
                    "abbrev": "eu",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "key": "eu",
                    "label": "European Union",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 0,
                    "region_name": "European Union",
                    "show_in_selector": false,
                    "show_reg_uids": true,
                    "timezone": "CET",
                    "value": 106
                },
                "federal": {
                    "abbrev": "us",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-us'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US",
                    "key": "federal",
                    "label": "US Congress",
                    "leg_type": 1,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": [],
                    "odd_to_even_carryover": false,
                    "order": 1,
                    "region_name": "US Federal",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 1
                },
                "florida": {
                    "abbrev": "fl",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 10,
                    "error_type": 2,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 12,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-fl'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-FL",
                    "key": "florida",
                    "label": "Florida Legislature",
                    "leg_type": 3,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 11,
                    "region_name": "Florida",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 11
                },
                "florida_local": {
                    "abbrev": "fl_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 12,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-fl'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-FL",
                    "key": "florida_local",
                    "label": "Florida Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 63,
                    "region_name": "Florida Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 63
                },
                "france": {
                    "abbrev": "fra",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 75,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": true,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": true,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "FR",
                    "key": "france",
                    "label": "French Parliament",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 205,
                    "region_name": "France",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "CET",
                    "value": 205
                },
                "georgia": {
                    "abbrev": "ga",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 11,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 13,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-ga'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-GA",
                    "key": "georgia",
                    "label": "Georgia General Assembly",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 12,
                    "region_name": "Georgia",
                    "show_in_selector": true,
                    "show_reg_uids": false,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 12
                },
                "georgia_local": {
                    "abbrev": "ga_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 13,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-ga'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-GA",
                    "key": "georgia_local",
                    "label": "Georgia Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 64,
                    "region_name": "Georgia Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 64
                },
                "germany": {
                    "abbrev": "deu",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 59,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": true,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "DE",
                    "key": "germany",
                    "label": "The German Bundestag",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 203,
                    "region_name": "Germany",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "CEST",
                    "value": 203
                },
                "global_region": {
                    "abbrev": "glb",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-us'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "key": "global_region",
                    "label": "Global",
                    "leg_type": 1,
                    "maintainers": [],
                    "odd_to_even_carryover": false,
                    "order": 1,
                    "region_name": "Global Region",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "UTC",
                    "value": 1000
                },
                "greece": {
                    "abbrev": "grc",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 89,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "GR",
                    "key": "greece",
                    "label": "Hellenic Republic",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 234,
                    "region_name": "Greece",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "UTC+3",
                    "value": 234
                },
                "guam": {
                    "abbrev": "gu",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 57,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 66,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": true,
                    "iso_code": "GU",
                    "key": "guam",
                    "label": "Legislature of Guam",
                    "leg_type": 2,
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 109,
                    "region_name": "Guam",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "ChST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 109
                },
                "hawaii": {
                    "abbrev": "hi",
                    "annual_sessions": true,
                    "bills_carryover": true,
                    "database_id": 12,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 15,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-hi'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-HI",
                    "key": "hawaii",
                    "label": "Hawaii State Legislature",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 13,
                    "region_name": "Hawaii",
                    "show_in_selector": true,
                    "show_reg_uids": false,
                    "timezone": "HST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 13
                },
                "hawaii_local": {
                    "abbrev": "hi_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 15,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-hi'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-HI",
                    "key": "hawaii_local",
                    "label": "Hawaii Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 65,
                    "region_name": "Hawaii Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "HST",
                    "value": 65
                },
                "hong_kong": {
                    "abbrev": "hkg",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 96,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "HK",
                    "key": "hong_kong",
                    "label": "Legislative Council of Hong Kong",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 227,
                    "region_name": "Hong Kong",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "GMT+8",
                    "value": 227
                },
                "idaho": {
                    "abbrev": "id",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 13,
                    "error_type": 3,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 16,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-id'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-ID",
                    "key": "idaho",
                    "label": "Idaho State Legislature",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 14,
                    "region_name": "Idaho",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "MST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 14
                },
                "idaho_local": {
                    "abbrev": "id_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 16,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-id'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-ID",
                    "key": "idaho_local",
                    "label": "Idaho Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 66,
                    "region_name": "Idaho Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "MST",
                    "value": 66
                },
                "illinois": {
                    "abbrev": "il",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 14,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 17,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-il'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-IL",
                    "key": "illinois",
                    "label": "Illinois General Assembly",
                    "leg_type": 2,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 15,
                    "region_name": "Illinois",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 15
                },
                "illinois_local": {
                    "abbrev": "il_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 17,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-il'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-IL",
                    "key": "illinois_local",
                    "label": "Illinois Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 67,
                    "region_name": "Illinois Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 67
                },
                "india": {
                    "abbrev": "ind",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 104,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "IN",
                    "key": "india",
                    "label": "Parliament of India",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 206,
                    "region_name": "India",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "IST",
                    "value": 206
                },
                "indiana": {
                    "abbrev": "in",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 15,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 18,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-in'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-IN",
                    "key": "indiana",
                    "label": "Indiana General Assembly",
                    "leg_type": 2,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 16,
                    "region_name": "Indiana",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 16
                },
                "indiana_local": {
                    "abbrev": "in_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 18,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-in'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-IN",
                    "key": "indiana_local",
                    "label": "Indiana Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 68,
                    "region_name": "Indiana Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 68
                },
                "indonesia": {
                    "abbrev": "idn",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 102,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "ID",
                    "key": "indonesia",
                    "label": "People's Consultative Assembly of the Republic of Indonesia",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 222,
                    "region_name": "Indonesia",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "WIB",
                    "value": 222
                },
                "iowa": {
                    "abbrev": "ia",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 16,
                    "error_type": 3,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 19,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-ia'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-IA",
                    "key": "iowa",
                    "label": "Iowa General Assembly",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 17,
                    "region_name": "Iowa",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 17
                },
                "iowa_local": {
                    "abbrev": "ia_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 19,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-ia'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-IA",
                    "key": "iowa_local",
                    "label": "Iowa Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 69,
                    "region_name": "Iowa Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 69
                },
                "ireland": {
                    "abbrev": "irl",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 106,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": true,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "IE",
                    "key": "ireland",
                    "label": "The Oireachtas",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 215,
                    "region_name": "Ireland",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "GMT",
                    "value": 215
                },
                "israel": {
                    "abbrev": "isr",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 110,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "IL",
                    "key": "israel",
                    "label": "State of Israel",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 240,
                    "region_name": "Israel",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "UTC+2",
                    "value": 240
                },
                "italy": {
                    "abbrev": "ita",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 111,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": true,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "IT",
                    "key": "italy",
                    "label": "Italian Parliament",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 207,
                    "region_name": "Italy",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "CET",
                    "value": 207
                },
                "japan": {
                    "abbrev": "jpn",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 115,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "JP",
                    "key": "japan",
                    "label": "National Diet of Japan",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 210,
                    "region_name": "Japan",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "JST",
                    "value": 210
                },
                "kansas": {
                    "abbrev": "ks",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 17,
                    "error_type": 3,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 20,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-ks'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-KS",
                    "key": "kansas",
                    "label": "Kansas State Legislature",
                    "leg_type": 1,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 18,
                    "region_name": "Kansas",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 18
                },
                "kansas_local": {
                    "abbrev": "ks_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 20,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-ks'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-KS",
                    "key": "kansas_local",
                    "label": "Kansas Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 70,
                    "region_name": "Kansas Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 70
                },
                "kentucky": {
                    "abbrev": "ky",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 18,
                    "error_type": 6,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 21,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-ky'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-KY",
                    "key": "kentucky",
                    "label": "Kentucky General Assembly",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 19,
                    "region_name": "Kentucky",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 19
                },
                "kentucky_local": {
                    "abbrev": "ky_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 21,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-ky'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-KY",
                    "key": "kentucky_local",
                    "label": "Kentucky Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 71,
                    "region_name": "Kentucky Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 71
                },
                "louisiana": {
                    "abbrev": "la",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 19,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 22,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-la'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-LA",
                    "key": "louisiana",
                    "label": "Louisiana Legislature",
                    "leg_type": 1,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 20,
                    "region_name": "Louisiana",
                    "show_in_selector": true,
                    "show_reg_uids": false,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 20
                },
                "louisiana_local": {
                    "abbrev": "la_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 22,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-la'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-LA",
                    "key": "louisiana_local",
                    "label": "Louisiana Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 72,
                    "region_name": "Louisiana Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 72
                },
                "maine": {
                    "abbrev": "me",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 20,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 23,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-me'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-ME",
                    "key": "maine",
                    "label": "Maine Legislature",
                    "leg_type": 1,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 21,
                    "region_name": "Maine",
                    "show_in_selector": true,
                    "show_reg_uids": false,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 21
                },
                "maine_local": {
                    "abbrev": "me_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 23,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-me'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-ME",
                    "key": "maine_local",
                    "label": "Maine Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 73,
                    "region_name": "Maine Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 73
                },
                "malaysia": {
                    "abbrev": "mys",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 158,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "MY",
                    "key": "malaysia",
                    "label": "Parliament of Malaysia",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 228,
                    "region_name": "Malaysia",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "UTC+8",
                    "value": 228
                },
                "maryland": {
                    "abbrev": "md",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 21,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 24,
                    "has_bills": true,
                    "has_committee_cosponsors": true,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-md'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-MD",
                    "key": "maryland",
                    "label": "Maryland General Assembly",
                    "leg_type": 1,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Delegate",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 22,
                    "region_name": "Maryland",
                    "show_in_selector": true,
                    "show_reg_uids": false,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 22
                },
                "maryland_local": {
                    "abbrev": "md_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 24,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-md'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-MD",
                    "key": "maryland_local",
                    "label": "Maryland Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 74,
                    "region_name": "Maryland Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 74
                },
                "massachusetts": {
                    "abbrev": "ma",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 22,
                    "error_type": 4,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 25,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-ma'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-MA",
                    "key": "massachusetts",
                    "label": "Massachusetts General Court",
                    "leg_type": 3,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 23,
                    "region_name": "Massachusetts",
                    "show_in_selector": true,
                    "show_reg_uids": false,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 23
                },
                "massachusetts_local": {
                    "abbrev": "ma_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 25,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-ma'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-MA",
                    "key": "massachusetts_local",
                    "label": "Massachusetts Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 75,
                    "region_name": "Massachusetts Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 75
                },
                "mexico": {
                    "abbrev": "mex",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 143,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "MX",
                    "key": "mexico",
                    "label": "General Congress of the United Mexican States",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 202,
                    "region_name": "Mexico",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "CST",
                    "value": 202
                },
                "michigan": {
                    "abbrev": "mi",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 23,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 26,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-mi'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-MI",
                    "key": "michigan",
                    "label": "Michigan Legislature",
                    "leg_type": 1,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 24,
                    "region_name": "Michigan",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 24
                },
                "michigan_local": {
                    "abbrev": "mi_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 26,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-mi'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-MI",
                    "key": "michigan_local",
                    "label": "Michigan Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 76,
                    "region_name": "Michigan Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 76
                },
                "minnesota": {
                    "abbrev": "mn",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 24,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 27,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-mn'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-MN",
                    "key": "minnesota",
                    "label": "Minnesota State Legislature",
                    "leg_type": 1,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 25,
                    "region_name": "Minnesota",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 25
                },
                "minnesota_local": {
                    "abbrev": "mn_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 27,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-mn'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-MN",
                    "key": "minnesota_local",
                    "label": "Minnesota Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 77,
                    "region_name": "Minnesota Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 77
                },
                "mississippi": {
                    "abbrev": "ms",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 25,
                    "error_type": 4,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 28,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-ms'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-MS",
                    "key": "mississippi",
                    "label": "Mississippi Legislature",
                    "leg_type": 1,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 26,
                    "region_name": "Mississippi",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 26
                },
                "mississippi_local": {
                    "abbrev": "ms_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 28,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-ms'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-MS",
                    "key": "mississippi_local",
                    "label": "Mississippi Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 78,
                    "region_name": "Mississippi Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 78
                },
                "missouri": {
                    "abbrev": "mo",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 26,
                    "error_type": 4,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 29,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-mo'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-MO",
                    "key": "missouri",
                    "label": "Missouri General Assembly",
                    "leg_type": 3,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 27,
                    "region_name": "Missouri",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 27
                },
                "missouri_local": {
                    "abbrev": "mo_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 29,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-mo'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-MO",
                    "key": "missouri_local",
                    "label": "Missouri Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 79,
                    "region_name": "Missouri Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 79
                },
                "montana": {
                    "abbrev": "mt",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 27,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 30,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-mt'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-MT",
                    "key": "montana",
                    "label": "Montana Legislature",
                    "leg_type": 3,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 28,
                    "region_name": "Montana",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "MST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 28
                },
                "montana_local": {
                    "abbrev": "mt_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 30,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-mt'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-MT",
                    "key": "montana_local",
                    "label": "Montana Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 80,
                    "region_name": "Montana Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "MST",
                    "value": 80
                },
                "nebraska": {
                    "abbrev": "ne",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 28,
                    "error_type": 8,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 31,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-ne'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-NE",
                    "key": "nebraska",
                    "label": "Nebraska Legislature",
                    "leg_type": 3,
                    "lower_chamber_title": "",
                    "lower_member_title": "",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 29,
                    "region_name": "Nebraska",
                    "show_in_selector": true,
                    "show_reg_uids": false,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 29
                },
                "nebraska_local": {
                    "abbrev": "ne_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 31,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-ne'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-NE",
                    "key": "nebraska_local",
                    "label": "Nebraska Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 81,
                    "region_name": "Nebraska Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 81
                },
                "netherlands": {
                    "abbrev": "nld",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 167,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": true,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "NL",
                    "key": "netherlands",
                    "label": "States General of the Netherlands",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 219,
                    "region_name": "Netherlands",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "CET",
                    "value": 219
                },
                "nevada": {
                    "abbrev": "nv",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 29,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 32,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-nv'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-NV",
                    "key": "nevada",
                    "label": "Nevada Legislature",
                    "leg_type": 2,
                    "lower_chamber_title": "Assembly",
                    "lower_member_title": "Assembly Member",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 30,
                    "region_name": "Nevada",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "PST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 30
                },
                "nevada_local": {
                    "abbrev": "nv_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 32,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-nv'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-NV",
                    "key": "nevada_local",
                    "label": "Nevada Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 82,
                    "region_name": "Nevada Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "PST",
                    "value": 82
                },
                "new_hampshire": {
                    "abbrev": "nh",
                    "annual_sessions": true,
                    "bills_carryover": true,
                    "database_id": 30,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 33,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-nh'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-NH",
                    "key": "new_hampshire",
                    "label": "New Hampshire General Court",
                    "leg_type": 1,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 31,
                    "region_name": "New Hampshire",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 31
                },
                "new_hampshire_local": {
                    "abbrev": "nh_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 33,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-nh'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-NH",
                    "key": "new_hampshire_local",
                    "label": "New Hampshire Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 83,
                    "region_name": "New Hampshire Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 83
                },
                "new_jersey": {
                    "abbrev": "nj",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 31,
                    "error_type": 1,
                    "even_to_odd_carryover": true,
                    "even_year_sessions": true,
                    "fips_code": 34,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-nj'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-NJ",
                    "key": "new_jersey",
                    "label": "New Jersey Legislature",
                    "leg_type": 2,
                    "lower_chamber_title": "Assembly",
                    "lower_member_title": "Assembly Member",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 32,
                    "region_name": "New Jersey",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 32
                },
                "new_jersey_local": {
                    "abbrev": "nj_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 34,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-nj'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-NJ",
                    "key": "new_jersey_local",
                    "label": "New Jersey Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 84,
                    "region_name": "New Jersey Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 84
                },
                "new_mexico": {
                    "abbrev": "nm",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 32,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 35,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-nm'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-NM",
                    "key": "new_mexico",
                    "label": "New Mexico Legislature",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 33,
                    "region_name": "New Mexico",
                    "show_in_selector": true,
                    "show_reg_uids": false,
                    "timezone": "MST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 33
                },
                "new_mexico_local": {
                    "abbrev": "nm_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 35,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-nm'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-NM",
                    "key": "new_mexico_local",
                    "label": "New Mexico Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 85,
                    "region_name": "New Mexico Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "MST",
                    "value": 85
                },
                "new_york": {
                    "abbrev": "ny",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 33,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 36,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-ny'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-NY",
                    "key": "new_york",
                    "label": "New York Legislature",
                    "leg_type": 1,
                    "lower_chamber_title": "Assembly",
                    "lower_member_title": "Assembly Member",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 34,
                    "region_name": "New York",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 34
                },
                "new_york_local": {
                    "abbrev": "ny_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 36,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-ny'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-NY",
                    "key": "new_york_local",
                    "label": "New York Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 86,
                    "region_name": "New York Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 86
                },
                "new_zealand": {
                    "abbrev": "nzl",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 171,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "NZ",
                    "key": "new_zealand",
                    "label": "New Zealand Parliament",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 231,
                    "region_name": "New Zealand",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "GMT+13",
                    "value": 231
                },
                "north_carolina": {
                    "abbrev": "nc",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 34,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 37,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-nc'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-NC",
                    "key": "north_carolina",
                    "label": "North Carolina General Assembly",
                    "leg_type": 2,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 35,
                    "region_name": "North Carolina",
                    "show_in_selector": true,
                    "show_reg_uids": false,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 35
                },
                "north_carolina_local": {
                    "abbrev": "nc_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 37,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-nc'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-NC",
                    "key": "north_carolina_local",
                    "label": "North Carolina Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 87,
                    "region_name": "North Carolina Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 87
                },
                "north_dakota": {
                    "abbrev": "nd",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 35,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 38,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-nd'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-ND",
                    "key": "north_dakota",
                    "label": "North Dakota Legislative Assembly",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 36,
                    "region_name": "North Dakota",
                    "show_in_selector": true,
                    "show_reg_uids": false,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 36
                },
                "north_dakota_local": {
                    "abbrev": "nd_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 38,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-nd'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-ND",
                    "key": "north_dakota_local",
                    "label": "North Dakota Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 88,
                    "region_name": "North Dakota Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 88
                },
                "northern_mariana_islands": {
                    "abbrev": "mp",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 55,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 69,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": true,
                    "iso_code": "MP",
                    "key": "northern_mariana_islands",
                    "label": "Northern Mariana Islands Commonwealth Legislature",
                    "leg_type": 2,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 110,
                    "region_name": "Northern Mariana Islands",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "ChST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 110
                },
                "ohio": {
                    "abbrev": "oh",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 36,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 39,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-oh'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-OH",
                    "key": "ohio",
                    "label": "Ohio General Assembly",
                    "leg_type": 2,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 37,
                    "region_name": "Ohio",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 37
                },
                "ohio_local": {
                    "abbrev": "oh_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 39,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-oh'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-OH",
                    "key": "ohio_local",
                    "label": "Ohio Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 89,
                    "region_name": "Ohio Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 89
                },
                "oklahoma": {
                    "abbrev": "ok",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 37,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 40,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-ok'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-OK",
                    "key": "oklahoma",
                    "label": "Oklahoma Legislature",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 38,
                    "region_name": "Oklahoma",
                    "show_in_selector": true,
                    "show_reg_uids": false,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 38
                },
                "oklahoma_local": {
                    "abbrev": "ok_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 40,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-ok'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-OK",
                    "key": "oklahoma_local",
                    "label": "Oklahoma Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 90,
                    "region_name": "Oklahoma Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 90
                },
                "oregon": {
                    "abbrev": "or",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 38,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 41,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-or'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-OR",
                    "key": "oregon",
                    "label": "Oregon Legislative Assembly",
                    "leg_type": 2,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 39,
                    "region_name": "Oregon",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "PST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 39
                },
                "oregon_local": {
                    "abbrev": "or_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 41,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-or'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-OR",
                    "key": "oregon_local",
                    "label": "Oregon Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 91,
                    "region_name": "Oregon Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "PST",
                    "value": 91
                },
                "panama": {
                    "abbrev": "pan",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 174,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "PA",
                    "key": "panama",
                    "label": "Legislative Branch of Panama",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 236,
                    "region_name": "Panama",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "UTC-5",
                    "value": 236
                },
                "pennsylvania": {
                    "abbrev": "pa",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 39,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 42,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-pa'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-PA",
                    "key": "pennsylvania",
                    "label": "Pennsylvania General Assembly",
                    "leg_type": 1,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 40,
                    "region_name": "Pennsylvania",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 40
                },
                "pennsylvania_local": {
                    "abbrev": "pa_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 42,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-pa'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-PA",
                    "key": "pennsylvania_local",
                    "label": "Pennsylvania Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 92,
                    "region_name": "Pennsylvania Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 92
                },
                "philippines": {
                    "abbrev": "phl",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 177,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "PH",
                    "key": "philippines",
                    "label": "Congress of the Philippines",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 229,
                    "region_name": "Philippines",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "GMT+8",
                    "value": 229
                },
                "poland": {
                    "abbrev": "pol",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 180,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": true,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "PL",
                    "key": "poland",
                    "label": "Parliament of Poland",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 216,
                    "region_name": "Poland",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "CET",
                    "value": 216
                },
                "puerto_rico": {
                    "abbrev": "pr",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 54,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 72,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-pr'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": true,
                    "iso_code": "US-PR",
                    "key": "puerto_rico",
                    "label": "Legislative Assembly of Puerto Rico",
                    "leg_type": 2,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 41,
                    "region_name": "Puerto Rico",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "AST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 41
                },
                "puerto_rico_local": {
                    "abbrev": "pr_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 72,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-pr'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-PR",
                    "key": "puerto_rico_local",
                    "label": "Puerto Rico Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 93,
                    "region_name": "Puerto Rico Local",
                    "show_in_selector": false,
                    "show_reg_uids": true,
                    "timezone": "AST",
                    "value": 93
                },
                "rhode_island": {
                    "abbrev": "ri",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 40,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 44,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-ri'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-RI",
                    "key": "rhode_island",
                    "label": "Rhode Island General Assembly",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 42,
                    "region_name": "Rhode Island",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 42
                },
                "rhode_island_local": {
                    "abbrev": "ri_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 44,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-ri'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-RI",
                    "key": "rhode_island_local",
                    "label": "Rhode Island Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 94,
                    "region_name": "Rhode Island Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 94
                },
                "saudi_arabia": {
                    "abbrev": "sau",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 192,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "SA",
                    "key": "saudi_arabia",
                    "label": "Consultative Assembly of Saudi Arabia",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 212,
                    "region_name": "Saudi Arabia",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "SAST",
                    "value": 212
                },
                "singapore": {
                    "abbrev": "sgp",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 195,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "SG",
                    "key": "singapore",
                    "label": "Parliament of Singapore",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 223,
                    "region_name": "Singapore",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "SGT",
                    "value": 223
                },
                "south_africa": {
                    "abbrev": "zaf",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 246,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "ZA",
                    "key": "south_africa",
                    "label": "Parliament of South Africa",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 214,
                    "region_name": "South Africa",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "SAST",
                    "value": 214
                },
                "south_carolina": {
                    "abbrev": "sc",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 41,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 45,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-sc'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-SC",
                    "key": "south_carolina",
                    "label": "South Carolina Legislature",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 43,
                    "region_name": "South Carolina",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 43
                },
                "south_carolina_local": {
                    "abbrev": "sc_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 45,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-sc'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-SC",
                    "key": "south_carolina_local",
                    "label": "South Carolina Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 95,
                    "region_name": "South Carolina Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 95
                },
                "south_dakota": {
                    "abbrev": "sd",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 42,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 46,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-sd'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-SD",
                    "key": "south_dakota",
                    "label": "South Dakota State Legislature",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 44,
                    "region_name": "South Dakota",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 44
                },
                "south_dakota_local": {
                    "abbrev": "sd_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 46,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-sd'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-SD",
                    "key": "south_dakota_local",
                    "label": "South Dakota Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 96,
                    "region_name": "South Dakota Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 96
                },
                "south_korea": {
                    "abbrev": "kor",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 122,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "KR",
                    "key": "south_korea",
                    "label": "National Assembly of the Republic of Korea",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 221,
                    "region_name": "South Korea",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "KST",
                    "value": 221
                },
                "spain": {
                    "abbrev": "esp",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 69,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": true,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": true,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "ES",
                    "key": "spain",
                    "label": "Las Cortes Generales",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 211,
                    "region_name": "Spain",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "CEST",
                    "value": 211
                },
                "sweden": {
                    "abbrev": "swe",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 210,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": true,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "SE",
                    "key": "sweden",
                    "label": "Riksdag",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 218,
                    "region_name": "Sweden",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "CET",
                    "value": 218
                },
                "taiwan": {
                    "abbrev": "twn",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 228,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "TW",
                    "key": "taiwan",
                    "label": "Legislative Yuan",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 225,
                    "region_name": "Taiwan",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "CST",
                    "value": 225
                },
                "tennessee": {
                    "abbrev": "tn",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 43,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 47,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-tn'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-TN",
                    "key": "tennessee",
                    "label": "Tennessee General Assembly",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 45,
                    "region_name": "Tennessee",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 45
                },
                "tennessee_local": {
                    "abbrev": "tn_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 47,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-tn'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-TN",
                    "key": "tennessee_local",
                    "label": "Tennessee Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 97,
                    "region_name": "Tennessee Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 97
                },
                "texas": {
                    "abbrev": "tx",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 44,
                    "error_type": 5,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 48,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-tx'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-TX",
                    "key": "texas",
                    "label": "Texas Legislature",
                    "leg_type": 2,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 46,
                    "region_name": "Texas",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 46
                },
                "texas_local": {
                    "abbrev": "tx_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 48,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-tx'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-TX",
                    "key": "texas_local",
                    "label": "Texas Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 98,
                    "region_name": "Texas Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 98
                },
                "thailand": {
                    "abbrev": "tha",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 218,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "TH",
                    "key": "thailand",
                    "label": "National Assembly of Thailand",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 220,
                    "region_name": "Thailand",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "ICT",
                    "value": 220
                },
                "turkey": {
                    "abbrev": "tur",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 226,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "TR",
                    "key": "turkey",
                    "label": "Parliament of Turkey",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 239,
                    "region_name": "Turkey",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "UTC+3",
                    "value": 239
                },
                "united_arab_emirates": {
                    "abbrev": "uae",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 8,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "AE",
                    "key": "united_arab_emirates",
                    "label": "The United Arab Emirates",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 241,
                    "region_name": "United Arab Emirates",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "UTC+4",
                    "value": 241
                },
                "united_kingdom": {
                    "abbrev": "gbr",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 79,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": true,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "GB",
                    "key": "united_kingdom",
                    "label": "Parliament of the United Kingdom of Great Britain and Northern Ireland",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 204,
                    "region_name": "United Kingdom",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "GMT",
                    "value": 204
                },
                "utah": {
                    "abbrev": "ut",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 45,
                    "error_type": 10,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 49,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-ut'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-UT",
                    "key": "utah",
                    "label": "Utah State Legislature",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 47,
                    "region_name": "Utah",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "MST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 47
                },
                "utah_local": {
                    "abbrev": "ut_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 49,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-ut'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-UT",
                    "key": "utah_local",
                    "label": "Utah Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 99,
                    "region_name": "Utah Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "MST",
                    "value": 99
                },
                "vermont": {
                    "abbrev": "vt",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 46,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 50,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-vt'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-VT",
                    "key": "vermont",
                    "label": "Vermont General Assembly",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 48,
                    "region_name": "Vermont",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 48
                },
                "vermont_local": {
                    "abbrev": "vt_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 50,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-vt'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-VT",
                    "key": "vermont_local",
                    "label": "Vermont Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 100,
                    "region_name": "Vermont Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 100
                },
                "vietnam": {
                    "abbrev": "vnm",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 241,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "VN",
                    "key": "vietnam",
                    "label": "National Assembly of the Socialist Republic of Vietnam",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 230,
                    "region_name": "Vietnam",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "GMT+7",
                    "value": 230
                },
                "virgin_islands": {
                    "abbrev": "vi",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 53,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 78,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": true,
                    "iso_code": "VI",
                    "key": "virgin_islands",
                    "label": "Legislature of the Virgin Islands",
                    "leg_type": 2,
                    "lower_chamber_title": "",
                    "lower_member_title": "",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 107,
                    "region_name": "Virgin Islands",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "AST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 107
                },
                "virginia": {
                    "abbrev": "va",
                    "annual_sessions": true,
                    "bills_carryover": true,
                    "database_id": 47,
                    "error_type": 1,
                    "even_to_odd_carryover": true,
                    "even_year_sessions": true,
                    "fips_code": 51,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-va'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-VA",
                    "key": "virginia",
                    "label": "Virginia General Assembly",
                    "leg_type": 1,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Delegate",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 49,
                    "region_name": "Virginia",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 49
                },
                "virginia_local": {
                    "abbrev": "va_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 51,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-va'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-VA",
                    "key": "virginia_local",
                    "label": "Virginia Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 101,
                    "region_name": "Virginia Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 101
                },
                "washington": {
                    "abbrev": "wa",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 48,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 53,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-wa'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-WA",
                    "key": "washington",
                    "label": "Washington State Legislature",
                    "leg_type": 2,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 50,
                    "region_name": "Washington",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "PST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 50
                },
                "washington_local": {
                    "abbrev": "wa_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 53,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-wa'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-WA",
                    "key": "washington_local",
                    "label": "Washington Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 102,
                    "region_name": "Washington Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "PST",
                    "value": 102
                },
                "west_virginia": {
                    "abbrev": "wv",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 49,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 54,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-wv'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-WV",
                    "key": "west_virginia",
                    "label": "West Virginia Legislature",
                    "leg_type": 1,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Delegate",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 51,
                    "region_name": "West Virginia",
                    "show_in_selector": true,
                    "show_reg_uids": false,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 51
                },
                "west_virginia_local": {
                    "abbrev": "wv_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 54,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-wv'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-WV",
                    "key": "west_virginia_local",
                    "label": "West Virginia Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 103,
                    "region_name": "West Virginia Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 103
                },
                "wisconsin": {
                    "abbrev": "wi",
                    "annual_sessions": true,
                    "bills_carryover": true,
                    "database_id": 50,
                    "error_type": 11,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 55,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-wi'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-WI",
                    "key": "wisconsin",
                    "label": "Wisconsin State Legislature",
                    "leg_type": 4,
                    "lower_chamber_title": "Assembly",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 52,
                    "region_name": "Wisconsin",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 52
                },
                "wisconsin_local": {
                    "abbrev": "wi_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 55,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-wi'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-WI",
                    "key": "wisconsin_local",
                    "label": "Wisconsin Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 104,
                    "region_name": "Wisconsin Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 104
                },
                "wyoming": {
                    "abbrev": "wy",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 51,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 56,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-wy'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-WY",
                    "key": "wyoming",
                    "label": "Wyoming State Legislature",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 53,
                    "region_name": "Wyoming",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "MST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 53
                },
                "wyoming_local": {
                    "abbrev": "wy_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 56,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-wy'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-WY",
                    "key": "wyoming_local",
                    "label": "Wyoming Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 105,
                    "region_name": "Wyoming Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "MST",
                    "value": 105
                }
            },
            "SupportedRegion": {
                "Romania": {
                    "abbrev": "rou",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 189,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "RO",
                    "key": "Romania",
                    "label": "Romanian Parliament",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 237,
                    "region_name": "Romania",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "UTC+2",
                    "value": 237
                },
                "__is_enum": true,
                "alabama": {
                    "abbrev": "al",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 1,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 1,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-al'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-AL",
                    "key": "alabama",
                    "label": "Alabama Legislature",
                    "leg_type": 3,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 2,
                    "region_name": "Alabama",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 2
                },
                "alabama_local": {
                    "abbrev": "al_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 1,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-al'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-AL",
                    "key": "alabama_local",
                    "label": "Alabama Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 54,
                    "region_name": "Alabama Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 54
                },
                "alaska": {
                    "abbrev": "ak",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 2,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 2,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-ak'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-AK",
                    "key": "alaska",
                    "label": "Alaska State Legislature",
                    "leg_type": 1,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 3,
                    "region_name": "Alaska",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "AKST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 3
                },
                "alaska_local": {
                    "abbrev": "ak_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 2,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-ak'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-AK",
                    "key": "alaska_local",
                    "label": "Alaska Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 55,
                    "region_name": "Alaska Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "AKST",
                    "value": 55
                },
                "all_regions": {
                    "abbrev": "all",
                    "child_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110],
                    "group_label": "All Regions",
                    "international_region_ready": false,
                    "key": "all_regions",
                    "label": "All Regions",
                    "order": -1000,
                    "region_name": "Entire US",
                    "show_reg_uids": true,
                    "value": 10000
                },
                "american_samoa": {
                    "abbrev": "as",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 56,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 60,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": true,
                    "iso_code": "AS",
                    "key": "american_samoa",
                    "label": "American Samoa Fono",
                    "leg_type": 2,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 108,
                    "region_name": "American Samoa",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "SST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 108
                },
                "argentina": {
                    "abbrev": "arg",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 9,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "AR",
                    "key": "argentina",
                    "label": "The National Congress of Argentina",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 224,
                    "region_name": "Argentina",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "GMT-3",
                    "value": 224
                },
                "arizona": {
                    "abbrev": "az",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 3,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 4,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-az'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-AZ",
                    "key": "arizona",
                    "label": "Arizona State Legislature",
                    "leg_type": 2,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 4,
                    "region_name": "Arizona",
                    "show_in_selector": true,
                    "show_reg_uids": false,
                    "timezone": "MST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 4
                },
                "arizona_local": {
                    "abbrev": "az_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 4,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-az'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-AZ",
                    "key": "arizona_local",
                    "label": "Arizona Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 56,
                    "region_name": "Arizona Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "MST",
                    "value": 56
                },
                "arkansas": {
                    "abbrev": "ar",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 4,
                    "error_type": 2,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 5,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-ar'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-AR",
                    "key": "arkansas",
                    "label": "Arkansas General Assembly",
                    "leg_type": 3,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 5,
                    "region_name": "Arkansas",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 5
                },
                "arkansas_local": {
                    "abbrev": "ar_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 5,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-ar'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-AR",
                    "key": "arkansas_local",
                    "label": "Arkansas Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 57,
                    "region_name": "Arkansas Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 57
                },
                "australia": {
                    "abbrev": "aus",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 15,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "AU",
                    "key": "australia",
                    "label": "Parliament of Australia",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 200,
                    "region_name": "Australia",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "AEST",
                    "value": 200
                },
                "belgium": {
                    "abbrev": "bel",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 19,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": true,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "BE",
                    "key": "belgium",
                    "label": "Belgian Federal Parliament",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 226,
                    "region_name": "Belgium",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "CET",
                    "value": 226
                },
                "brazil": {
                    "abbrev": "bra",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 32,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": true,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "BR",
                    "key": "brazil",
                    "label": "Federal Government of Brazil",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 208,
                    "region_name": "Brazil",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "BRST",
                    "value": 208
                },
                "california": {
                    "abbrev": "ca",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 5,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 6,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-ca'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-CA",
                    "key": "california",
                    "label": "California State Legislature",
                    "leg_type": 2,
                    "lower_chamber_title": "Assembly",
                    "lower_member_title": "Assembly Member",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 6,
                    "region_name": "California",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "PST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 6
                },
                "california_local": {
                    "abbrev": "ca_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 6,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-ca'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-CA",
                    "key": "california_local",
                    "label": "California Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 58,
                    "region_name": "California Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "PST",
                    "value": 58
                },
                "canada": {
                    "abbrev": "can",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 39,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": true,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "CA",
                    "key": "canada",
                    "label": "Parliament of Canada",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 201,
                    "region_name": "Canada",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "EST",
                    "value": 201
                },
                "chile": {
                    "abbrev": "chl",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 42,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "CL",
                    "key": "chile",
                    "label": "National Congress of Chile",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 233,
                    "region_name": "Chile",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "GMT-4",
                    "value": 233
                },
                "china": {
                    "abbrev": "chn",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 43,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "CN",
                    "key": "china",
                    "label": "National People's Congress of the People's Republic of China",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 209,
                    "region_name": "China",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "CST",
                    "value": 209
                },
                "colombia": {
                    "abbrev": "col",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 49,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "CO",
                    "key": "colombia",
                    "label": "Congress of the Republic of Colombia",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 213,
                    "region_name": "Colombia",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "COT",
                    "value": 213
                },
                "colorado": {
                    "abbrev": "co",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 6,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 8,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-co'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-CO",
                    "key": "colorado",
                    "label": "Colorado General Assembly",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 7,
                    "region_name": "Colorado",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "MST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 7
                },
                "colorado_local": {
                    "abbrev": "co_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 8,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-co'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-CO",
                    "key": "colorado_local",
                    "label": "Colorado Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 59,
                    "region_name": "Colorado Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "MST",
                    "value": 59
                },
                "connecticut": {
                    "abbrev": "ct",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 7,
                    "error_type": 2,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 9,
                    "has_bills": true,
                    "has_committee_cosponsors": true,
                    "has_committee_sponsors": true,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-ct'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-CT",
                    "key": "connecticut",
                    "label": "Connecticut General Assembly",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 8,
                    "region_name": "Connecticut",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 8
                },
                "connecticut_local": {
                    "abbrev": "ct_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 9,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-ct'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-CT",
                    "key": "connecticut_local",
                    "label": "Connecticut Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 60,
                    "region_name": "Connecticut Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 60
                },
                "costa_rica": {
                    "abbrev": "cri",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 52,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "CR",
                    "key": "costa_rica",
                    "label": "Republic of Costa Rica",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 232,
                    "region_name": "Costa Rica",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "GMT-6",
                    "value": 232
                },
                "czechia": {
                    "abbrev": "cze",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 58,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": true,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "CZ",
                    "key": "czechia",
                    "label": "Parliament of Czechia",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 238,
                    "region_name": "Czechia",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "UTC+1",
                    "value": 238
                },
                "dc_local": {
                    "abbrev": "dc_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 11,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-dc'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-DC",
                    "key": "dc_local",
                    "label": "District of Columbia Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 62,
                    "region_name": "District of Columbia Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 62
                },
                "delaware": {
                    "abbrev": "de",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 8,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 10,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-de'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-DE",
                    "key": "delaware",
                    "label": "Delaware General Assembly",
                    "leg_type": 2,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 9,
                    "region_name": "Delaware",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 9
                },
                "delaware_local": {
                    "abbrev": "de_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 10,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-de'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-DE",
                    "key": "delaware_local",
                    "label": "Delaware Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 61,
                    "region_name": "Delaware Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 61
                },
                "denmark": {
                    "abbrev": "dnk",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 62,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": true,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "DK",
                    "key": "denmark",
                    "label": "Folketing",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 217,
                    "region_name": "Denmark",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "CET",
                    "value": 217
                },
                "district_of_columbia": {
                    "abbrev": "dc",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 9,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 11,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-dc'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": true,
                    "iso_code": "US-DC",
                    "key": "district_of_columbia",
                    "label": "Council of the District of Columbia",
                    "leg_type": 2,
                    "lower_chamber_title": "",
                    "lower_member_title": "",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 10,
                    "region_name": "District of Columbia",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Council",
                    "upper_member_title": "Council Member",
                    "value": 10
                },
                "dominican_republic": {
                    "abbrev": "dom",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 63,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "DO",
                    "key": "dominican_republic",
                    "label": "Congress of the Dominican Republic",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 235,
                    "region_name": "Dominican Republic",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "GMT-4",
                    "value": 235
                },
                "eu": {
                    "abbrev": "eu",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "key": "eu",
                    "label": "European Union",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 0,
                    "region_name": "European Union",
                    "show_in_selector": false,
                    "show_reg_uids": true,
                    "timezone": "CET",
                    "value": 106
                },
                "eu_member_states": {
                    "abbrev": "eu-members",
                    "child_regions": [203, 205, 207, 211, 215, 216, 217, 218, 219, 226, 238],
                    "group_label": "EU Member States",
                    "international_region_ready": false,
                    "key": "eu_member_states",
                    "label": "EU Member States",
                    "order": -995,
                    "region_name": "EU Member States",
                    "show_reg_uids": true,
                    "value": 10005
                },
                "federal": {
                    "abbrev": "us",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-us'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US",
                    "key": "federal",
                    "label": "US Congress",
                    "leg_type": 1,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": [],
                    "odd_to_even_carryover": false,
                    "order": 1,
                    "region_name": "US Federal",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 1
                },
                "florida": {
                    "abbrev": "fl",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 10,
                    "error_type": 2,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 12,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-fl'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-FL",
                    "key": "florida",
                    "label": "Florida Legislature",
                    "leg_type": 3,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 11,
                    "region_name": "Florida",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 11
                },
                "florida_local": {
                    "abbrev": "fl_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 12,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-fl'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-FL",
                    "key": "florida_local",
                    "label": "Florida Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 63,
                    "region_name": "Florida Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 63
                },
                "france": {
                    "abbrev": "fra",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 75,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": true,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": true,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "FR",
                    "key": "france",
                    "label": "French Parliament",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 205,
                    "region_name": "France",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "CET",
                    "value": 205
                },
                "georgia": {
                    "abbrev": "ga",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 11,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 13,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-ga'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-GA",
                    "key": "georgia",
                    "label": "Georgia General Assembly",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 12,
                    "region_name": "Georgia",
                    "show_in_selector": true,
                    "show_reg_uids": false,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 12
                },
                "georgia_local": {
                    "abbrev": "ga_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 13,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-ga'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-GA",
                    "key": "georgia_local",
                    "label": "Georgia Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 64,
                    "region_name": "Georgia Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 64
                },
                "germany": {
                    "abbrev": "deu",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 59,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": true,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "DE",
                    "key": "germany",
                    "label": "The German Bundestag",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 203,
                    "region_name": "Germany",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "CEST",
                    "value": 203
                },
                "global_region": {
                    "abbrev": "glb",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-us'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "key": "global_region",
                    "label": "Global",
                    "leg_type": 1,
                    "maintainers": [],
                    "odd_to_even_carryover": false,
                    "order": 1,
                    "region_name": "Global Region",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "UTC",
                    "value": 1000
                },
                "greece": {
                    "abbrev": "grc",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 89,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "GR",
                    "key": "greece",
                    "label": "Hellenic Republic",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 234,
                    "region_name": "Greece",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "UTC+3",
                    "value": 234
                },
                "guam": {
                    "abbrev": "gu",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 57,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 66,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": true,
                    "iso_code": "GU",
                    "key": "guam",
                    "label": "Legislature of Guam",
                    "leg_type": 2,
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 109,
                    "region_name": "Guam",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "ChST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 109
                },
                "hawaii": {
                    "abbrev": "hi",
                    "annual_sessions": true,
                    "bills_carryover": true,
                    "database_id": 12,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 15,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-hi'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-HI",
                    "key": "hawaii",
                    "label": "Hawaii State Legislature",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 13,
                    "region_name": "Hawaii",
                    "show_in_selector": true,
                    "show_reg_uids": false,
                    "timezone": "HST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 13
                },
                "hawaii_local": {
                    "abbrev": "hi_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 15,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-hi'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-HI",
                    "key": "hawaii_local",
                    "label": "Hawaii Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 65,
                    "region_name": "Hawaii Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "HST",
                    "value": 65
                },
                "hong_kong": {
                    "abbrev": "hkg",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 96,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "HK",
                    "key": "hong_kong",
                    "label": "Legislative Council of Hong Kong",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 227,
                    "region_name": "Hong Kong",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "GMT+8",
                    "value": 227
                },
                "idaho": {
                    "abbrev": "id",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 13,
                    "error_type": 3,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 16,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-id'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-ID",
                    "key": "idaho",
                    "label": "Idaho State Legislature",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 14,
                    "region_name": "Idaho",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "MST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 14
                },
                "idaho_local": {
                    "abbrev": "id_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 16,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-id'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-ID",
                    "key": "idaho_local",
                    "label": "Idaho Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 66,
                    "region_name": "Idaho Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "MST",
                    "value": 66
                },
                "illinois": {
                    "abbrev": "il",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 14,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 17,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-il'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-IL",
                    "key": "illinois",
                    "label": "Illinois General Assembly",
                    "leg_type": 2,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 15,
                    "region_name": "Illinois",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 15
                },
                "illinois_local": {
                    "abbrev": "il_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 17,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-il'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-IL",
                    "key": "illinois_local",
                    "label": "Illinois Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 67,
                    "region_name": "Illinois Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 67
                },
                "india": {
                    "abbrev": "ind",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 104,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "IN",
                    "key": "india",
                    "label": "Parliament of India",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 206,
                    "region_name": "India",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "IST",
                    "value": 206
                },
                "indiana": {
                    "abbrev": "in",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 15,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 18,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-in'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-IN",
                    "key": "indiana",
                    "label": "Indiana General Assembly",
                    "leg_type": 2,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 16,
                    "region_name": "Indiana",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 16
                },
                "indiana_local": {
                    "abbrev": "in_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 18,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-in'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-IN",
                    "key": "indiana_local",
                    "label": "Indiana Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 68,
                    "region_name": "Indiana Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 68
                },
                "indonesia": {
                    "abbrev": "idn",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 102,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "ID",
                    "key": "indonesia",
                    "label": "People's Consultative Assembly of the Republic of Indonesia",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 222,
                    "region_name": "Indonesia",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "WIB",
                    "value": 222
                },
                "international": {
                    "abbrev": "international",
                    "child_regions": [200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241],
                    "group_label": "International",
                    "international_region_ready": false,
                    "key": "international",
                    "label": "International",
                    "order": -997,
                    "region_name": "International",
                    "show_reg_uids": true,
                    "value": 10003
                },
                "iowa": {
                    "abbrev": "ia",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 16,
                    "error_type": 3,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 19,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-ia'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-IA",
                    "key": "iowa",
                    "label": "Iowa General Assembly",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 17,
                    "region_name": "Iowa",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 17
                },
                "iowa_local": {
                    "abbrev": "ia_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 19,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-ia'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-IA",
                    "key": "iowa_local",
                    "label": "Iowa Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 69,
                    "region_name": "Iowa Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 69
                },
                "ireland": {
                    "abbrev": "irl",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 106,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": true,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "IE",
                    "key": "ireland",
                    "label": "The Oireachtas",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 215,
                    "region_name": "Ireland",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "GMT",
                    "value": 215
                },
                "israel": {
                    "abbrev": "isr",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 110,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "IL",
                    "key": "israel",
                    "label": "State of Israel",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 240,
                    "region_name": "Israel",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "UTC+2",
                    "value": 240
                },
                "italy": {
                    "abbrev": "ita",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 111,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": true,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "IT",
                    "key": "italy",
                    "label": "Italian Parliament",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 207,
                    "region_name": "Italy",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "CET",
                    "value": 207
                },
                "japan": {
                    "abbrev": "jpn",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 115,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "JP",
                    "key": "japan",
                    "label": "National Diet of Japan",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 210,
                    "region_name": "Japan",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "JST",
                    "value": 210
                },
                "kansas": {
                    "abbrev": "ks",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 17,
                    "error_type": 3,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 20,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-ks'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-KS",
                    "key": "kansas",
                    "label": "Kansas State Legislature",
                    "leg_type": 1,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 18,
                    "region_name": "Kansas",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 18
                },
                "kansas_local": {
                    "abbrev": "ks_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 20,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-ks'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-KS",
                    "key": "kansas_local",
                    "label": "Kansas Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 70,
                    "region_name": "Kansas Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 70
                },
                "kentucky": {
                    "abbrev": "ky",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 18,
                    "error_type": 6,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 21,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-ky'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-KY",
                    "key": "kentucky",
                    "label": "Kentucky General Assembly",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 19,
                    "region_name": "Kentucky",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 19
                },
                "kentucky_local": {
                    "abbrev": "ky_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 21,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-ky'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-KY",
                    "key": "kentucky_local",
                    "label": "Kentucky Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 71,
                    "region_name": "Kentucky Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 71
                },
                "local": {
                    "abbrev": "local",
                    "child_regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105],
                    "group_label": "US Local",
                    "international_region_ready": false,
                    "key": "local",
                    "label": "Local",
                    "order": -998,
                    "region_name": "US Local",
                    "show_reg_uids": true,
                    "value": 10002
                },
                "louisiana": {
                    "abbrev": "la",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 19,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 22,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-la'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-LA",
                    "key": "louisiana",
                    "label": "Louisiana Legislature",
                    "leg_type": 1,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 20,
                    "region_name": "Louisiana",
                    "show_in_selector": true,
                    "show_reg_uids": false,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 20
                },
                "louisiana_local": {
                    "abbrev": "la_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 22,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-la'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-LA",
                    "key": "louisiana_local",
                    "label": "Louisiana Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 72,
                    "region_name": "Louisiana Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 72
                },
                "maine": {
                    "abbrev": "me",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 20,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 23,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-me'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-ME",
                    "key": "maine",
                    "label": "Maine Legislature",
                    "leg_type": 1,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 21,
                    "region_name": "Maine",
                    "show_in_selector": true,
                    "show_reg_uids": false,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 21
                },
                "maine_local": {
                    "abbrev": "me_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 23,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-me'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-ME",
                    "key": "maine_local",
                    "label": "Maine Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 73,
                    "region_name": "Maine Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 73
                },
                "malaysia": {
                    "abbrev": "mys",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 158,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "MY",
                    "key": "malaysia",
                    "label": "Parliament of Malaysia",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 228,
                    "region_name": "Malaysia",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "UTC+8",
                    "value": 228
                },
                "maryland": {
                    "abbrev": "md",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 21,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 24,
                    "has_bills": true,
                    "has_committee_cosponsors": true,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-md'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-MD",
                    "key": "maryland",
                    "label": "Maryland General Assembly",
                    "leg_type": 1,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Delegate",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 22,
                    "region_name": "Maryland",
                    "show_in_selector": true,
                    "show_reg_uids": false,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 22
                },
                "maryland_local": {
                    "abbrev": "md_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 24,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-md'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-MD",
                    "key": "maryland_local",
                    "label": "Maryland Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 74,
                    "region_name": "Maryland Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 74
                },
                "massachusetts": {
                    "abbrev": "ma",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 22,
                    "error_type": 4,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 25,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-ma'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-MA",
                    "key": "massachusetts",
                    "label": "Massachusetts General Court",
                    "leg_type": 3,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 23,
                    "region_name": "Massachusetts",
                    "show_in_selector": true,
                    "show_reg_uids": false,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 23
                },
                "massachusetts_local": {
                    "abbrev": "ma_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 25,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-ma'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-MA",
                    "key": "massachusetts_local",
                    "label": "Massachusetts Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 75,
                    "region_name": "Massachusetts Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 75
                },
                "mexico": {
                    "abbrev": "mex",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 143,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "MX",
                    "key": "mexico",
                    "label": "General Congress of the United Mexican States",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 202,
                    "region_name": "Mexico",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "CST",
                    "value": 202
                },
                "michigan": {
                    "abbrev": "mi",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 23,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 26,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-mi'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-MI",
                    "key": "michigan",
                    "label": "Michigan Legislature",
                    "leg_type": 1,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 24,
                    "region_name": "Michigan",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 24
                },
                "michigan_local": {
                    "abbrev": "mi_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 26,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-mi'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-MI",
                    "key": "michigan_local",
                    "label": "Michigan Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 76,
                    "region_name": "Michigan Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 76
                },
                "minnesota": {
                    "abbrev": "mn",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 24,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 27,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-mn'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-MN",
                    "key": "minnesota",
                    "label": "Minnesota State Legislature",
                    "leg_type": 1,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 25,
                    "region_name": "Minnesota",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 25
                },
                "minnesota_local": {
                    "abbrev": "mn_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 27,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-mn'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-MN",
                    "key": "minnesota_local",
                    "label": "Minnesota Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 77,
                    "region_name": "Minnesota Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 77
                },
                "mississippi": {
                    "abbrev": "ms",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 25,
                    "error_type": 4,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 28,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-ms'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-MS",
                    "key": "mississippi",
                    "label": "Mississippi Legislature",
                    "leg_type": 1,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 26,
                    "region_name": "Mississippi",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 26
                },
                "mississippi_local": {
                    "abbrev": "ms_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 28,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-ms'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-MS",
                    "key": "mississippi_local",
                    "label": "Mississippi Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 78,
                    "region_name": "Mississippi Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 78
                },
                "missouri": {
                    "abbrev": "mo",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 26,
                    "error_type": 4,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 29,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-mo'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-MO",
                    "key": "missouri",
                    "label": "Missouri General Assembly",
                    "leg_type": 3,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 27,
                    "region_name": "Missouri",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 27
                },
                "missouri_local": {
                    "abbrev": "mo_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 29,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-mo'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-MO",
                    "key": "missouri_local",
                    "label": "Missouri Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 79,
                    "region_name": "Missouri Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 79
                },
                "montana": {
                    "abbrev": "mt",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 27,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 30,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-mt'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-MT",
                    "key": "montana",
                    "label": "Montana Legislature",
                    "leg_type": 3,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 28,
                    "region_name": "Montana",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "MST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 28
                },
                "montana_local": {
                    "abbrev": "mt_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 30,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-mt'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-MT",
                    "key": "montana_local",
                    "label": "Montana Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 80,
                    "region_name": "Montana Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "MST",
                    "value": 80
                },
                "nebraska": {
                    "abbrev": "ne",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 28,
                    "error_type": 8,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 31,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-ne'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-NE",
                    "key": "nebraska",
                    "label": "Nebraska Legislature",
                    "leg_type": 3,
                    "lower_chamber_title": "",
                    "lower_member_title": "",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 29,
                    "region_name": "Nebraska",
                    "show_in_selector": true,
                    "show_reg_uids": false,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 29
                },
                "nebraska_local": {
                    "abbrev": "ne_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 31,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-ne'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-NE",
                    "key": "nebraska_local",
                    "label": "Nebraska Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 81,
                    "region_name": "Nebraska Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 81
                },
                "netherlands": {
                    "abbrev": "nld",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 167,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": true,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "NL",
                    "key": "netherlands",
                    "label": "States General of the Netherlands",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 219,
                    "region_name": "Netherlands",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "CET",
                    "value": 219
                },
                "nevada": {
                    "abbrev": "nv",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 29,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 32,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-nv'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-NV",
                    "key": "nevada",
                    "label": "Nevada Legislature",
                    "leg_type": 2,
                    "lower_chamber_title": "Assembly",
                    "lower_member_title": "Assembly Member",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 30,
                    "region_name": "Nevada",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "PST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 30
                },
                "nevada_local": {
                    "abbrev": "nv_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 32,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-nv'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-NV",
                    "key": "nevada_local",
                    "label": "Nevada Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 82,
                    "region_name": "Nevada Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "PST",
                    "value": 82
                },
                "new_hampshire": {
                    "abbrev": "nh",
                    "annual_sessions": true,
                    "bills_carryover": true,
                    "database_id": 30,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 33,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-nh'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-NH",
                    "key": "new_hampshire",
                    "label": "New Hampshire General Court",
                    "leg_type": 1,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 31,
                    "region_name": "New Hampshire",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 31
                },
                "new_hampshire_local": {
                    "abbrev": "nh_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 33,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-nh'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-NH",
                    "key": "new_hampshire_local",
                    "label": "New Hampshire Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 83,
                    "region_name": "New Hampshire Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 83
                },
                "new_jersey": {
                    "abbrev": "nj",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 31,
                    "error_type": 1,
                    "even_to_odd_carryover": true,
                    "even_year_sessions": true,
                    "fips_code": 34,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-nj'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-NJ",
                    "key": "new_jersey",
                    "label": "New Jersey Legislature",
                    "leg_type": 2,
                    "lower_chamber_title": "Assembly",
                    "lower_member_title": "Assembly Member",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 32,
                    "region_name": "New Jersey",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 32
                },
                "new_jersey_local": {
                    "abbrev": "nj_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 34,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-nj'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-NJ",
                    "key": "new_jersey_local",
                    "label": "New Jersey Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 84,
                    "region_name": "New Jersey Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 84
                },
                "new_mexico": {
                    "abbrev": "nm",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 32,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 35,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-nm'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-NM",
                    "key": "new_mexico",
                    "label": "New Mexico Legislature",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 33,
                    "region_name": "New Mexico",
                    "show_in_selector": true,
                    "show_reg_uids": false,
                    "timezone": "MST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 33
                },
                "new_mexico_local": {
                    "abbrev": "nm_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 35,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-nm'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-NM",
                    "key": "new_mexico_local",
                    "label": "New Mexico Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 85,
                    "region_name": "New Mexico Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "MST",
                    "value": 85
                },
                "new_york": {
                    "abbrev": "ny",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 33,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 36,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-ny'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-NY",
                    "key": "new_york",
                    "label": "New York Legislature",
                    "leg_type": 1,
                    "lower_chamber_title": "Assembly",
                    "lower_member_title": "Assembly Member",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 34,
                    "region_name": "New York",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 34
                },
                "new_york_local": {
                    "abbrev": "ny_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 36,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-ny'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-NY",
                    "key": "new_york_local",
                    "label": "New York Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 86,
                    "region_name": "New York Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 86
                },
                "new_zealand": {
                    "abbrev": "nzl",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 171,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "NZ",
                    "key": "new_zealand",
                    "label": "New Zealand Parliament",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 231,
                    "region_name": "New Zealand",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "GMT+13",
                    "value": 231
                },
                "north_carolina": {
                    "abbrev": "nc",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 34,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 37,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-nc'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-NC",
                    "key": "north_carolina",
                    "label": "North Carolina General Assembly",
                    "leg_type": 2,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 35,
                    "region_name": "North Carolina",
                    "show_in_selector": true,
                    "show_reg_uids": false,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 35
                },
                "north_carolina_local": {
                    "abbrev": "nc_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 37,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-nc'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-NC",
                    "key": "north_carolina_local",
                    "label": "North Carolina Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 87,
                    "region_name": "North Carolina Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 87
                },
                "north_dakota": {
                    "abbrev": "nd",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 35,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 38,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-nd'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-ND",
                    "key": "north_dakota",
                    "label": "North Dakota Legislative Assembly",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 36,
                    "region_name": "North Dakota",
                    "show_in_selector": true,
                    "show_reg_uids": false,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 36
                },
                "north_dakota_local": {
                    "abbrev": "nd_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 38,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-nd'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-ND",
                    "key": "north_dakota_local",
                    "label": "North Dakota Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 88,
                    "region_name": "North Dakota Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 88
                },
                "northern_mariana_islands": {
                    "abbrev": "mp",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 55,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 69,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": true,
                    "iso_code": "MP",
                    "key": "northern_mariana_islands",
                    "label": "Northern Mariana Islands Commonwealth Legislature",
                    "leg_type": 2,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 110,
                    "region_name": "Northern Mariana Islands",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "ChST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 110
                },
                "ohio": {
                    "abbrev": "oh",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 36,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 39,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-oh'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-OH",
                    "key": "ohio",
                    "label": "Ohio General Assembly",
                    "leg_type": 2,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 37,
                    "region_name": "Ohio",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 37
                },
                "ohio_local": {
                    "abbrev": "oh_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 39,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-oh'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-OH",
                    "key": "ohio_local",
                    "label": "Ohio Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 89,
                    "region_name": "Ohio Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 89
                },
                "oklahoma": {
                    "abbrev": "ok",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 37,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 40,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-ok'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-OK",
                    "key": "oklahoma",
                    "label": "Oklahoma Legislature",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 38,
                    "region_name": "Oklahoma",
                    "show_in_selector": true,
                    "show_reg_uids": false,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 38
                },
                "oklahoma_local": {
                    "abbrev": "ok_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 40,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-ok'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-OK",
                    "key": "oklahoma_local",
                    "label": "Oklahoma Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 90,
                    "region_name": "Oklahoma Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 90
                },
                "oregon": {
                    "abbrev": "or",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 38,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 41,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-or'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-OR",
                    "key": "oregon",
                    "label": "Oregon Legislative Assembly",
                    "leg_type": 2,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 39,
                    "region_name": "Oregon",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "PST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 39
                },
                "oregon_local": {
                    "abbrev": "or_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 41,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-or'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-OR",
                    "key": "oregon_local",
                    "label": "Oregon Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 91,
                    "region_name": "Oregon Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "PST",
                    "value": 91
                },
                "panama": {
                    "abbrev": "pan",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 174,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "PA",
                    "key": "panama",
                    "label": "Legislative Branch of Panama",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 236,
                    "region_name": "Panama",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "UTC-5",
                    "value": 236
                },
                "pennsylvania": {
                    "abbrev": "pa",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 39,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 42,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-pa'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-PA",
                    "key": "pennsylvania",
                    "label": "Pennsylvania General Assembly",
                    "leg_type": 1,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 40,
                    "region_name": "Pennsylvania",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 40
                },
                "pennsylvania_local": {
                    "abbrev": "pa_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 42,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-pa'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-PA",
                    "key": "pennsylvania_local",
                    "label": "Pennsylvania Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 92,
                    "region_name": "Pennsylvania Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 92
                },
                "philippines": {
                    "abbrev": "phl",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 177,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "PH",
                    "key": "philippines",
                    "label": "Congress of the Philippines",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 229,
                    "region_name": "Philippines",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "GMT+8",
                    "value": 229
                },
                "poland": {
                    "abbrev": "pol",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 180,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": true,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "PL",
                    "key": "poland",
                    "label": "Parliament of Poland",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 216,
                    "region_name": "Poland",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "CET",
                    "value": 216
                },
                "puerto_rico": {
                    "abbrev": "pr",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 54,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 72,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-pr'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": true,
                    "iso_code": "US-PR",
                    "key": "puerto_rico",
                    "label": "Legislative Assembly of Puerto Rico",
                    "leg_type": 2,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 41,
                    "region_name": "Puerto Rico",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "AST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 41
                },
                "puerto_rico_local": {
                    "abbrev": "pr_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 72,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-pr'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-PR",
                    "key": "puerto_rico_local",
                    "label": "Puerto Rico Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 93,
                    "region_name": "Puerto Rico Local",
                    "show_in_selector": false,
                    "show_reg_uids": true,
                    "timezone": "AST",
                    "value": 93
                },
                "rhode_island": {
                    "abbrev": "ri",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 40,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 44,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-ri'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-RI",
                    "key": "rhode_island",
                    "label": "Rhode Island General Assembly",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 42,
                    "region_name": "Rhode Island",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 42
                },
                "rhode_island_local": {
                    "abbrev": "ri_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 44,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-ri'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-RI",
                    "key": "rhode_island_local",
                    "label": "Rhode Island Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 94,
                    "region_name": "Rhode Island Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 94
                },
                "saudi_arabia": {
                    "abbrev": "sau",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 192,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "SA",
                    "key": "saudi_arabia",
                    "label": "Consultative Assembly of Saudi Arabia",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 212,
                    "region_name": "Saudi Arabia",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "SAST",
                    "value": 212
                },
                "singapore": {
                    "abbrev": "sgp",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 195,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "SG",
                    "key": "singapore",
                    "label": "Parliament of Singapore",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 223,
                    "region_name": "Singapore",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "SGT",
                    "value": 223
                },
                "south_africa": {
                    "abbrev": "zaf",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 246,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "ZA",
                    "key": "south_africa",
                    "label": "Parliament of South Africa",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 214,
                    "region_name": "South Africa",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "SAST",
                    "value": 214
                },
                "south_carolina": {
                    "abbrev": "sc",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 41,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 45,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-sc'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-SC",
                    "key": "south_carolina",
                    "label": "South Carolina Legislature",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 43,
                    "region_name": "South Carolina",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 43
                },
                "south_carolina_local": {
                    "abbrev": "sc_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 45,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-sc'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-SC",
                    "key": "south_carolina_local",
                    "label": "South Carolina Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 95,
                    "region_name": "South Carolina Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 95
                },
                "south_dakota": {
                    "abbrev": "sd",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 42,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 46,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-sd'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-SD",
                    "key": "south_dakota",
                    "label": "South Dakota State Legislature",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 44,
                    "region_name": "South Dakota",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 44
                },
                "south_dakota_local": {
                    "abbrev": "sd_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 46,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-sd'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-SD",
                    "key": "south_dakota_local",
                    "label": "South Dakota Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 96,
                    "region_name": "South Dakota Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 96
                },
                "south_korea": {
                    "abbrev": "kor",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 122,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "KR",
                    "key": "south_korea",
                    "label": "National Assembly of the Republic of Korea",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 221,
                    "region_name": "South Korea",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "KST",
                    "value": 221
                },
                "spain": {
                    "abbrev": "esp",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 69,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": true,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": true,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "ES",
                    "key": "spain",
                    "label": "Las Cortes Generales",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 211,
                    "region_name": "Spain",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "CEST",
                    "value": 211
                },
                "states": {
                    "abbrev": "states",
                    "child_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110],
                    "group_label": "US States and Territories",
                    "international_region_ready": false,
                    "key": "states",
                    "label": "States",
                    "order": -999,
                    "region_name": "US States",
                    "show_reg_uids": true,
                    "value": 10001
                },
                "states_and_local": {
                    "abbrev": "states-and-local",
                    "child_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105],
                    "group_label": "US States & Local",
                    "international_region_ready": false,
                    "key": "states_and_local",
                    "label": "US States & Local",
                    "order": -996,
                    "region_name": "US States & Local",
                    "show_reg_uids": true,
                    "value": 10004
                },
                "sweden": {
                    "abbrev": "swe",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 210,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": true,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "SE",
                    "key": "sweden",
                    "label": "Riksdag",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 218,
                    "region_name": "Sweden",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "CET",
                    "value": 218
                },
                "taiwan": {
                    "abbrev": "twn",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 228,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "TW",
                    "key": "taiwan",
                    "label": "Legislative Yuan",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 225,
                    "region_name": "Taiwan",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "CST",
                    "value": 225
                },
                "tennessee": {
                    "abbrev": "tn",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 43,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 47,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-tn'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-TN",
                    "key": "tennessee",
                    "label": "Tennessee General Assembly",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 45,
                    "region_name": "Tennessee",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 45
                },
                "tennessee_local": {
                    "abbrev": "tn_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 47,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-tn'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-TN",
                    "key": "tennessee_local",
                    "label": "Tennessee Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 97,
                    "region_name": "Tennessee Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 97
                },
                "texas": {
                    "abbrev": "tx",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 44,
                    "error_type": 5,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 48,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-tx'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-TX",
                    "key": "texas",
                    "label": "Texas Legislature",
                    "leg_type": 2,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 46,
                    "region_name": "Texas",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 46
                },
                "texas_local": {
                    "abbrev": "tx_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 48,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-tx'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-TX",
                    "key": "texas_local",
                    "label": "Texas Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 98,
                    "region_name": "Texas Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 98
                },
                "thailand": {
                    "abbrev": "tha",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 218,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "TH",
                    "key": "thailand",
                    "label": "National Assembly of Thailand",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 220,
                    "region_name": "Thailand",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "ICT",
                    "value": 220
                },
                "turkey": {
                    "abbrev": "tur",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 226,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "TR",
                    "key": "turkey",
                    "label": "Parliament of Turkey",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 239,
                    "region_name": "Turkey",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "UTC+3",
                    "value": 239
                },
                "united_arab_emirates": {
                    "abbrev": "uae",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 8,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "AE",
                    "key": "united_arab_emirates",
                    "label": "The United Arab Emirates",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 241,
                    "region_name": "United Arab Emirates",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "UTC+4",
                    "value": 241
                },
                "united_kingdom": {
                    "abbrev": "gbr",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 79,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": true,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "GB",
                    "key": "united_kingdom",
                    "label": "Parliament of the United Kingdom of Great Britain and Northern Ireland",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 204,
                    "region_name": "United Kingdom",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "GMT",
                    "value": 204
                },
                "user_global": {
                    "abbrev": "global",
                    "child_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000],
                    "group_label": "Global",
                    "international_region_ready": false,
                    "key": "user_global",
                    "label": "Global",
                    "order": -1001,
                    "region_name": "Global",
                    "show_reg_uids": true,
                    "value": 9999
                },
                "utah": {
                    "abbrev": "ut",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 45,
                    "error_type": 10,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 49,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-ut'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-UT",
                    "key": "utah",
                    "label": "Utah State Legislature",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 47,
                    "region_name": "Utah",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "MST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 47
                },
                "utah_local": {
                    "abbrev": "ut_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 49,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-ut'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-UT",
                    "key": "utah_local",
                    "label": "Utah Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 99,
                    "region_name": "Utah Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "MST",
                    "value": 99
                },
                "vermont": {
                    "abbrev": "vt",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 46,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 50,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-vt'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-VT",
                    "key": "vermont",
                    "label": "Vermont General Assembly",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 48,
                    "region_name": "Vermont",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 48
                },
                "vermont_local": {
                    "abbrev": "vt_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 50,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-vt'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-VT",
                    "key": "vermont_local",
                    "label": "Vermont Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 100,
                    "region_name": "Vermont Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 100
                },
                "vietnam": {
                    "abbrev": "vnm",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "database_id": 241,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "",
                    "international_region_ready": true,
                    "is_eu_member_state": false,
                    "is_international_region": true,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "VN",
                    "key": "vietnam",
                    "label": "National Assembly of the Socialist Republic of Vietnam",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 230,
                    "region_name": "Vietnam",
                    "show_in_selector": false,
                    "show_reg_uids": false,
                    "timezone": "GMT+7",
                    "value": 230
                },
                "virgin_islands": {
                    "abbrev": "vi",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 53,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 78,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": false,
                    "is_state_region": true,
                    "iso_code": "VI",
                    "key": "virgin_islands",
                    "label": "Legislature of the Virgin Islands",
                    "leg_type": 2,
                    "lower_chamber_title": "",
                    "lower_member_title": "",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 107,
                    "region_name": "Virgin Islands",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "AST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 107
                },
                "virginia": {
                    "abbrev": "va",
                    "annual_sessions": true,
                    "bills_carryover": true,
                    "database_id": 47,
                    "error_type": 1,
                    "even_to_odd_carryover": true,
                    "even_year_sessions": true,
                    "fips_code": 51,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-va'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-VA",
                    "key": "virginia",
                    "label": "Virginia General Assembly",
                    "leg_type": 1,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Delegate",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 49,
                    "region_name": "Virginia",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 49
                },
                "virginia_local": {
                    "abbrev": "va_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 51,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-va'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-VA",
                    "key": "virginia_local",
                    "label": "Virginia Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 101,
                    "region_name": "Virginia Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 101
                },
                "washington": {
                    "abbrev": "wa",
                    "annual_sessions": false,
                    "bills_carryover": true,
                    "database_id": 48,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 53,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-wa'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-WA",
                    "key": "washington",
                    "label": "Washington State Legislature",
                    "leg_type": 2,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 50,
                    "region_name": "Washington",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "PST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 50
                },
                "washington_local": {
                    "abbrev": "wa_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 53,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-wa'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-WA",
                    "key": "washington_local",
                    "label": "Washington Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 102,
                    "region_name": "Washington Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "PST",
                    "value": 102
                },
                "west_virginia": {
                    "abbrev": "wv",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 49,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 54,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": true,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-wv'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-WV",
                    "key": "west_virginia",
                    "label": "West Virginia Legislature",
                    "leg_type": 1,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Delegate",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 51,
                    "region_name": "West Virginia",
                    "show_in_selector": true,
                    "show_reg_uids": false,
                    "timezone": "EST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 51
                },
                "west_virginia_local": {
                    "abbrev": "wv_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 54,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-wv'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-WV",
                    "key": "west_virginia_local",
                    "label": "West Virginia Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 103,
                    "region_name": "West Virginia Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "EST",
                    "value": 103
                },
                "wisconsin": {
                    "abbrev": "wi",
                    "annual_sessions": true,
                    "bills_carryover": true,
                    "database_id": 50,
                    "error_type": 11,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 55,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-wi'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-WI",
                    "key": "wisconsin",
                    "label": "Wisconsin State Legislature",
                    "leg_type": 4,
                    "lower_chamber_title": "Assembly",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": true,
                    "order": 52,
                    "region_name": "Wisconsin",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 52
                },
                "wisconsin_local": {
                    "abbrev": "wi_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 55,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-wi'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-WI",
                    "key": "wisconsin_local",
                    "label": "Wisconsin Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 104,
                    "region_name": "Wisconsin Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "CST",
                    "value": 104
                },
                "wyoming": {
                    "abbrev": "wy",
                    "annual_sessions": true,
                    "bills_carryover": false,
                    "database_id": 51,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": true,
                    "fips_code": 56,
                    "has_bills": true,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": true,
                    "has_cosponsors": false,
                    "has_resolutions": true,
                    "has_sponsors": true,
                    "icon_element": "<span class='stateface stateface-wy'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": false,
                    "is_single_us_state": true,
                    "is_state_region": true,
                    "iso_code": "US-WY",
                    "key": "wyoming",
                    "label": "Wyoming State Legislature",
                    "leg_type": 4,
                    "lower_chamber_title": "House",
                    "lower_member_title": "Representative",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 53,
                    "region_name": "Wyoming",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "MST",
                    "upper_chamber_title": "Senate",
                    "upper_member_title": "Senator",
                    "value": 53
                },
                "wyoming_local": {
                    "abbrev": "wy_local",
                    "annual_sessions": false,
                    "bills_carryover": false,
                    "error_type": 1,
                    "even_to_odd_carryover": false,
                    "even_year_sessions": false,
                    "fips_code": 56,
                    "has_bills": false,
                    "has_committee_cosponsors": false,
                    "has_committee_sponsors": false,
                    "has_cosponsors": false,
                    "has_resolutions": false,
                    "has_sponsors": false,
                    "icon_element": "<span class='stateface stateface-wy'></span>",
                    "international_region_ready": false,
                    "is_eu_member_state": false,
                    "is_international_region": false,
                    "is_local_region": true,
                    "is_single_us_state": false,
                    "is_state_region": false,
                    "iso_code": "US-WY",
                    "key": "wyoming_local",
                    "label": "Wyoming Local",
                    "maintainers": ["dev-data@quorum.us"],
                    "odd_to_even_carryover": false,
                    "order": 105,
                    "region_name": "Wyoming Local",
                    "show_in_selector": true,
                    "show_reg_uids": true,
                    "timezone": "MST",
                    "value": 105
                }
            }
        },
        "onboarding": {
            "models": {}
        },
        "pac": {
            "e_advocacy": {
                "types": {}
            },
            "models": {
                "political_committee": {
                    "PoliticalCommittee": {
                        "__is_model": true,
                        "__name__": "PoliticalCommittee",
                        "data_model": "app.pac.models.political_committee.PoliticalCommittee",
                        "default_exclude_filters": {},
                        "default_filters": { "archived": false },
                        "endpoint": "/api/politicalcommittee/",
                        "fields": []
                    }
                }
            },
            "reports": {
                "types": {}
            },
            "state_pac": {
                "types": {}
            },
            "trusted_login": {
                "types": {}
            },
            "types": {}
        },
        "pac_classic": {
            "types": {}
        },
        "pdfdownload": {
            "types": {}
        },
        "person": {
            "models": {
                "GrassrootsPerson": {
                    "__is_model": true,
                    "__name__": "GrassrootsPerson",
                    "data_model": "app.person.models.GrassrootsPerson",
                    "default_exclude_filters": {},
                    "default_filters": { "archived": false, "show_in_quicksearch": true },
                    "endpoint": "/api/grassrootsperson/",
                    "fields": [
                        {
                            "__class__": { "__name__": "ForeignKey" },
                            "field_type": 7,
                            "name": "biography",
                            "related_model": { "__module__": "app.person.models", "__name__": "PersonBiography" },
                            "sheet_column": true
                        },
                        {
                            "__class__": { "__name__": "ForeignKey" },
                            "field_type": 7,
                            "name": "most_recent_role",
                            "related_model": { "__module__": "app.person.models", "__name__": "PersonRole" },
                            "sheet_column": true
                        }
                    ],
                    "proxy_endpoint": "/api/person/"
                },
                "NewPerson": {
                    "__is_model": true,
                    "__name__": "NewPerson",
                    "data_model": "app.person.models.NewPerson",
                    "default_exclude_filters": {},
                    "default_filters": { "archived": false, "show_in_quicksearch": true },
                    "endpoint": "/api/newperson/",
                    "fields": [
                        {
                            "__class__": { "__name__": "ForeignKey" },
                            "field_type": 7,
                            "name": "biography",
                            "related_model": { "__module__": "app.person.models", "__name__": "PersonBiography" },
                            "sheet_column": true
                        },
                        {
                            "__class__": { "__name__": "ForeignKey" },
                            "field_type": 7,
                            "name": "most_recent_role",
                            "related_model": { "__module__": "app.person.models", "__name__": "PersonRole" },
                            "sheet_column": true
                        }
                    ],
                    "proxy_endpoint": "/api/person/"
                },
                "Person": {
                    "__is_model": true,
                    "__name__": "Person",
                    "data_model": "app.person.models.Person",
                    "default_exclude_filters": {},
                    "default_filters": { "archived": false, "show_in_quicksearch": true },
                    "endpoint": "/api/person/",
                    "fields": [
                        {
                            "__class__": { "__name__": "ForeignKey" },
                            "field_type": 7,
                            "name": "biography",
                            "related_model": { "__module__": "app.person.models", "__name__": "PersonBiography" },
                            "sheet_column": true
                        },
                        {
                            "__class__": { "__name__": "ForeignKey" },
                            "field_type": 7,
                            "name": "most_recent_role",
                            "related_model": { "__module__": "app.person.models", "__name__": "PersonRole" },
                            "sheet_column": true
                        }
                    ]
                }
            },
            "newtypes": {
                "MinorRoleType": {
                    "__is_enum": true,
                    "administrative_director": {
                        "key": "administrative_director",
                        "label": "Administrative Director",
                        "parent": 41,
                        "precedence": 700,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 228
                    },
                    "analyst": {
                        "key": "analyst",
                        "label": "Analyst",
                        "parent": 47,
                        "precedence": 1300,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 229
                    },
                    "anchor": {
                        "key": "anchor",
                        "label": "Anchor/Host",
                        "parent": 47,
                        "precedence": 1300,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 230
                    },
                    "arg_deputy": {
                        "key": "arg_deputy",
                        "label": "Deputy",
                        "parent": 12,
                        "precedence": 250,
                        "rank": 1000,
                        "regions": [224],
                        "show_in_quicksearch": false,
                        "supported_regions": [224],
                        "value": 347
                    },
                    "arg_executive_branch_member": {
                        "key": "arg_executive_branch_member",
                        "label": "Executive",
                        "parent": 10,
                        "precedence": 270,
                        "rank": 1000,
                        "regions": [224],
                        "show_in_quicksearch": false,
                        "supported_regions": [224],
                        "value": 344
                    },
                    "arg_minister": {
                        "key": "arg_minister",
                        "label": "Minister",
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [224],
                        "show_in_quicksearch": false,
                        "supported_regions": [224],
                        "value": 342
                    },
                    "arg_president": {
                        "key": "arg_president",
                        "label": "President",
                        "parent": 2,
                        "precedence": 110,
                        "rank": 1000,
                        "regions": [224],
                        "show_in_quicksearch": false,
                        "supported_regions": [224],
                        "value": 340
                    },
                    "arg_provincial_governor": {
                        "key": "arg_provincial_governor",
                        "label": "Provincial Governor",
                        "parent": 16,
                        "precedence": 310,
                        "rank": 1000,
                        "regions": [224],
                        "show_in_quicksearch": false,
                        "supported_regions": [224],
                        "value": 345
                    },
                    "arg_secretary": {
                        "key": "arg_secretary",
                        "label": "Secretary",
                        "parent": 5,
                        "precedence": 160,
                        "rank": 1000,
                        "regions": [224],
                        "show_in_quicksearch": false,
                        "supported_regions": [224],
                        "value": 343
                    },
                    "arg_senator": {
                        "key": "arg_senator",
                        "label": "Senator",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [224],
                        "show_in_quicksearch": false,
                        "supported_regions": [224],
                        "value": 346
                    },
                    "arg_top_level_judge": {
                        "key": "arg_top_level_judge",
                        "label": "Supreme Court Justice",
                        "parent": 21,
                        "precedence": 350,
                        "rank": 1000,
                        "regions": [224],
                        "show_in_quicksearch": false,
                        "supported_regions": [224],
                        "value": 348
                    },
                    "arg_vice_president": {
                        "key": "arg_vice_president",
                        "label": "Vice-President",
                        "parent": 3,
                        "precedence": 115,
                        "rank": 1000,
                        "regions": [224],
                        "show_in_quicksearch": false,
                        "supported_regions": [224],
                        "value": 341
                    },
                    "assignment_editor": {
                        "key": "assignment_editor",
                        "label": "Assignment Editor",
                        "parent": 40,
                        "precedence": 600,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 231
                    },
                    "assignment_manager": {
                        "key": "assignment_manager",
                        "label": "Assignment Manager",
                        "parent": 47,
                        "precedence": 1300,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 232
                    },
                    "assistant_director": {
                        "key": "assistant_director",
                        "label": "Assistant Director",
                        "parent": 41,
                        "precedence": 700,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 233
                    },
                    "assistant_editor": {
                        "key": "assistant_editor",
                        "label": "Assistant Editor",
                        "parent": 40,
                        "precedence": 600,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 234
                    },
                    "assistant_managing_editor": {
                        "key": "assistant_managing_editor",
                        "label": "Assistant Managing Editor",
                        "parent": 40,
                        "precedence": 600,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 235
                    },
                    "assistant_producer": {
                        "key": "assistant_producer",
                        "label": "Assistant Producer",
                        "parent": 42,
                        "precedence": 800,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 236
                    },
                    "assistant_program_director": {
                        "key": "assistant_program_director",
                        "label": "Assistant Program Director",
                        "parent": 41,
                        "precedence": 700,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 237
                    },
                    "assistant_publisher": {
                        "key": "assistant_publisher",
                        "label": "Assistant Publisher",
                        "parent": 44,
                        "precedence": 1000,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 238
                    },
                    "assistant_to_the_editor": {
                        "key": "assistant_to_the_editor",
                        "label": "Assistant to Editor",
                        "parent": 45,
                        "precedence": 1100,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 239
                    },
                    "associate_director": {
                        "key": "associate_director",
                        "label": "Associate Director",
                        "parent": 41,
                        "precedence": 700,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 240
                    },
                    "associate_editor": {
                        "key": "associate_editor",
                        "label": "Associate Editor",
                        "parent": 40,
                        "precedence": 600,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 241
                    },
                    "associate_producer": {
                        "key": "associate_producer",
                        "label": "Associate Producer",
                        "parent": 42,
                        "precedence": 800,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 242
                    },
                    "associate_publisher": {
                        "key": "associate_publisher",
                        "label": "Associate Publisher",
                        "parent": 44,
                        "precedence": 1000,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 243
                    },
                    "aus_deputy_minister": {
                        "key": "aus_deputy_minister",
                        "label": "Deputy Minister",
                        "parent": 5,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [200],
                        "show_in_quicksearch": false,
                        "supported_regions": [200],
                        "value": 102
                    },
                    "aus_deputy_prime_minister": {
                        "key": "aus_deputy_prime_minister",
                        "label": "Deputy Prime Minister",
                        "parent": 3,
                        "precedence": 115,
                        "rank": 1000,
                        "regions": [200],
                        "show_in_quicksearch": false,
                        "supported_regions": [200],
                        "value": 98
                    },
                    "aus_governor": {
                        "key": "aus_governor",
                        "label": "Governor",
                        "parent": 14,
                        "precedence": 300,
                        "rank": 1000,
                        "regions": [200],
                        "show_in_quicksearch": false,
                        "supported_regions": [200],
                        "value": 104
                    },
                    "aus_governor_general": {
                        "key": "aus_governor_general",
                        "label": "Governor General",
                        "parent": 1,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [200],
                        "show_in_quicksearch": false,
                        "supported_regions": [200],
                        "value": 96
                    },
                    "aus_lt_governor": {
                        "key": "aus_lt_governor",
                        "label": "Lt. Governor",
                        "parent": 15,
                        "precedence": 305,
                        "rank": 1000,
                        "regions": [200],
                        "show_in_quicksearch": false,
                        "supported_regions": [200],
                        "value": 105
                    },
                    "aus_minister": {
                        "key": "aus_minister",
                        "label": "Minister",
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [200],
                        "show_in_quicksearch": false,
                        "supported_regions": [200],
                        "value": 99
                    },
                    "aus_mp": {
                        "key": "aus_mp",
                        "label": "Member of Parliament",
                        "parent": 12,
                        "precedence": 250,
                        "rank": 1000,
                        "regions": [200],
                        "show_in_quicksearch": false,
                        "supported_regions": [200],
                        "value": 101
                    },
                    "aus_parliament_secretary": {
                        "key": "aus_parliament_secretary",
                        "label": "Parliament Secretary",
                        "parent": 6,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [200],
                        "show_in_quicksearch": false,
                        "supported_regions": [200],
                        "value": 103
                    },
                    "aus_premier": {
                        "key": "aus_premier",
                        "label": "Premier",
                        "parent": 16,
                        "precedence": 310,
                        "rank": 1000,
                        "regions": [200],
                        "show_in_quicksearch": false,
                        "supported_regions": [200],
                        "value": 106
                    },
                    "aus_prime_minister": {
                        "key": "aus_prime_minister",
                        "label": "Prime Minister",
                        "parent": 2,
                        "precedence": 110,
                        "rank": 1000,
                        "regions": [200],
                        "show_in_quicksearch": false,
                        "supported_regions": [200],
                        "value": 97
                    },
                    "aus_senator": {
                        "key": "aus_senator",
                        "label": "Senator",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [200],
                        "show_in_quicksearch": false,
                        "supported_regions": [200],
                        "value": 100
                    },
                    "aus_sta_lower_body_legislator": {
                        "key": "aus_sta_lower_body_legislator",
                        "label": "Legislative Assembly",
                        "parent": 19,
                        "precedence": 785,
                        "rank": 1000,
                        "regions": [200],
                        "show_in_quicksearch": false,
                        "supported_regions": [200],
                        "value": 222
                    },
                    "aus_sta_upper_body_legislator": {
                        "key": "aus_sta_upper_body_legislator",
                        "label": "Legislative Council",
                        "parent": 18,
                        "precedence": 780,
                        "rank": 1000,
                        "regions": [200],
                        "show_in_quicksearch": false,
                        "supported_regions": [200],
                        "value": 221
                    },
                    "bel_deputy": {
                        "key": "bel_deputy",
                        "label": "Deputy",
                        "parent": 12,
                        "precedence": 250,
                        "rank": 1000,
                        "regions": [226],
                        "show_in_quicksearch": false,
                        "supported_regions": [226],
                        "value": 385
                    },
                    "bel_deputy_prime_minister": {
                        "key": "bel_deputy_prime_minister",
                        "label": "Deputy Prime Minister",
                        "parent": 3,
                        "precedence": 115,
                        "rank": 1000,
                        "regions": [226],
                        "show_in_quicksearch": false,
                        "supported_regions": [226],
                        "value": 380
                    },
                    "bel_executive_branch_member": {
                        "key": "bel_executive_branch_member",
                        "label": "Executive Branch Member",
                        "parent": 10,
                        "precedence": 275,
                        "rank": 1000,
                        "regions": [226],
                        "show_in_quicksearch": false,
                        "supported_regions": [226],
                        "value": 383
                    },
                    "bel_judge": {
                        "key": "bel_judge",
                        "label": "Judge",
                        "parent": 22,
                        "precedence": 360,
                        "rank": 1000,
                        "regions": [226],
                        "show_in_quicksearch": false,
                        "supported_regions": [226],
                        "value": 388
                    },
                    "bel_king": {
                        "key": "bel_king",
                        "label": "King",
                        "parent": 1,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [226],
                        "show_in_quicksearch": false,
                        "supported_regions": [226],
                        "value": 378
                    },
                    "bel_legislative_chamber_leader": {
                        "key": "bel_legislative_chamber_leader",
                        "label": "Legislative Chamber Leader",
                        "parent": 24,
                        "precedence": 400,
                        "rank": 1000,
                        "regions": [226],
                        "show_in_quicksearch": false,
                        "supported_regions": [226],
                        "value": 386
                    },
                    "bel_magistrate": {
                        "key": "bel_magistrate",
                        "label": "Magistrate",
                        "parent": 21,
                        "precedence": 350,
                        "rank": 1000,
                        "regions": [226],
                        "show_in_quicksearch": false,
                        "supported_regions": [226],
                        "value": 387
                    },
                    "bel_minister": {
                        "key": "bel_minister",
                        "label": "Minister",
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [226],
                        "show_in_quicksearch": false,
                        "supported_regions": [226],
                        "value": 381
                    },
                    "bel_prime_minister": {
                        "key": "bel_prime_minister",
                        "label": "Prime Minister",
                        "parent": 2,
                        "precedence": 110,
                        "rank": 1000,
                        "regions": [226],
                        "show_in_quicksearch": false,
                        "supported_regions": [226],
                        "value": 379
                    },
                    "bel_secretary_of_state": {
                        "key": "bel_secretary_of_state",
                        "label": "Secretary of State",
                        "parent": 5,
                        "precedence": 160,
                        "rank": 1000,
                        "regions": [226],
                        "show_in_quicksearch": false,
                        "supported_regions": [226],
                        "value": 382
                    },
                    "bel_senator": {
                        "key": "bel_senator",
                        "label": "Senator",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [226],
                        "show_in_quicksearch": false,
                        "supported_regions": [226],
                        "value": 384
                    },
                    "blogger": {
                        "key": "blogger",
                        "label": "Blogger",
                        "parent": 47,
                        "precedence": 1300,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 244
                    },
                    "booker": {
                        "key": "booker",
                        "label": "Booker",
                        "parent": 47,
                        "precedence": 1300,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 245
                    },
                    "bra_deputy": {
                        "key": "bra_deputy",
                        "label": "Deputy",
                        "parent": 12,
                        "precedence": 250,
                        "rank": 1000,
                        "regions": [208],
                        "show_in_quicksearch": false,
                        "supported_regions": [208],
                        "value": 121
                    },
                    "bra_executive_secretariat": {
                        "key": "bra_executive_secretariat",
                        "label": "Executive Secretariat",
                        "parent": 6,
                        "precedence": 810,
                        "rank": 1000,
                        "regions": [208],
                        "show_in_quicksearch": false,
                        "supported_regions": [208],
                        "value": 224
                    },
                    "bra_governor": {
                        "key": "bra_governor",
                        "label": "Governor",
                        "parent": 14,
                        "precedence": 790,
                        "rank": 1000,
                        "regions": [208],
                        "show_in_quicksearch": false,
                        "supported_regions": [208],
                        "value": 225
                    },
                    "bra_justice": {
                        "key": "bra_justice",
                        "label": "Justice",
                        "parent": 21,
                        "precedence": 350,
                        "rank": 1000,
                        "regions": [208],
                        "show_in_quicksearch": false,
                        "supported_regions": [208],
                        "value": 122
                    },
                    "bra_minister": {
                        "key": "bra_minister",
                        "label": "Minister",
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [208],
                        "show_in_quicksearch": false,
                        "supported_regions": [208],
                        "value": 119
                    },
                    "bra_president": {
                        "key": "bra_president",
                        "label": "President",
                        "parent": 2,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [208],
                        "show_in_quicksearch": false,
                        "supported_regions": [208],
                        "value": 117
                    },
                    "bra_secretariat": {
                        "key": "bra_secretariat",
                        "label": "Secretariat",
                        "parent": 5,
                        "precedence": 815,
                        "rank": 1000,
                        "regions": [208],
                        "show_in_quicksearch": false,
                        "supported_regions": [208],
                        "value": 223
                    },
                    "bra_senator": {
                        "key": "bra_senator",
                        "label": "Senator",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [208],
                        "show_in_quicksearch": false,
                        "supported_regions": [208],
                        "value": 120
                    },
                    "bra_sta_lower_body_legislator": {
                        "key": "bra_sta_lower_body_legislator",
                        "label": "State Assembly",
                        "parent": 19,
                        "precedence": 805,
                        "rank": 1000,
                        "regions": [208],
                        "show_in_quicksearch": false,
                        "supported_regions": [208],
                        "value": 227
                    },
                    "bra_sta_upper_body_legislator": {
                        "key": "bra_sta_upper_body_legislator",
                        "label": "State Deputy",
                        "parent": 18,
                        "precedence": 800,
                        "rank": 1000,
                        "regions": [208],
                        "show_in_quicksearch": false,
                        "supported_regions": [208],
                        "value": 226
                    },
                    "bra_vice_president": {
                        "key": "bra_vice_president",
                        "label": "Vice President",
                        "parent": 3,
                        "precedence": 110,
                        "rank": 1000,
                        "regions": [208],
                        "show_in_quicksearch": false,
                        "supported_regions": [208],
                        "value": 118
                    },
                    "bureau_chief": {
                        "key": "bureau_chief",
                        "label": "Bureau Chief",
                        "parent": 47,
                        "precedence": 1300,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 246
                    },
                    "cadet": {
                        "key": "cadet",
                        "label": "Cadet",
                        "parent": 46,
                        "precedence": 1300,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 377
                    },
                    "calendar_editor": {
                        "key": "calendar_editor",
                        "label": "Calendar Editor",
                        "parent": 40,
                        "precedence": 600,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 247
                    },
                    "can_deputy_minister": {
                        "key": "can_deputy_minister",
                        "label": "Deputy Minister",
                        "parent": 5,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [201],
                        "show_in_quicksearch": false,
                        "supported_regions": [201],
                        "value": 113
                    },
                    "can_deputy_prime_minister": {
                        "key": "can_deputy_prime_minister",
                        "label": "Deputy Prime Minister",
                        "parent": 3,
                        "precedence": 115,
                        "rank": 1000,
                        "regions": [201],
                        "show_in_quicksearch": false,
                        "supported_regions": [201],
                        "value": 109
                    },
                    "can_governor_general": {
                        "key": "can_governor_general",
                        "label": "Governor General",
                        "parent": 1,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [201],
                        "show_in_quicksearch": false,
                        "supported_regions": [201],
                        "value": 107
                    },
                    "can_lt_governor": {
                        "key": "can_lt_governor",
                        "label": "Lt. Governor",
                        "parent": 14,
                        "precedence": 300,
                        "rank": 1000,
                        "regions": [201],
                        "show_in_quicksearch": false,
                        "supported_regions": [201],
                        "value": 115
                    },
                    "can_minister": {
                        "key": "can_minister",
                        "label": "Minister",
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [201],
                        "show_in_quicksearch": false,
                        "supported_regions": [201],
                        "value": 110
                    },
                    "can_mp": {
                        "key": "can_mp",
                        "label": "Member of Parliament",
                        "parent": 12,
                        "precedence": 250,
                        "rank": 1000,
                        "regions": [201],
                        "show_in_quicksearch": false,
                        "supported_regions": [201],
                        "value": 112
                    },
                    "can_parliament_secretary": {
                        "key": "can_parliament_secretary",
                        "label": "Parliament Secretary",
                        "parent": 6,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [201],
                        "show_in_quicksearch": false,
                        "supported_regions": [201],
                        "value": 114
                    },
                    "can_premier": {
                        "key": "can_premier",
                        "label": "Premier",
                        "parent": 16,
                        "precedence": 310,
                        "rank": 1000,
                        "regions": [201],
                        "show_in_quicksearch": false,
                        "supported_regions": [201],
                        "value": 116
                    },
                    "can_prime_minister": {
                        "key": "can_prime_minister",
                        "label": "Prime Minister",
                        "parent": 2,
                        "precedence": 110,
                        "rank": 1000,
                        "regions": [201],
                        "show_in_quicksearch": false,
                        "supported_regions": [201],
                        "value": 108
                    },
                    "can_senator": {
                        "key": "can_senator",
                        "label": "Senator",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [201],
                        "show_in_quicksearch": false,
                        "supported_regions": [201],
                        "value": 111
                    },
                    "can_sta_upper_body_legislator": {
                        "key": "can_sta_upper_body_legislator",
                        "label": "Legislative Assembly Member",
                        "parent": 18,
                        "precedence": 800,
                        "rank": 1000,
                        "regions": [201],
                        "show_in_quicksearch": false,
                        "supported_regions": [201],
                        "value": 371
                    },
                    "chl_deputy": {
                        "key": "chl_deputy",
                        "label": "Deputy",
                        "parent": 12,
                        "precedence": 250,
                        "rank": 1000,
                        "regions": [233],
                        "show_in_quicksearch": false,
                        "supported_regions": [233],
                        "value": 447
                    },
                    "chl_executive_branch_member": {
                        "key": "chl_executive_branch_member",
                        "label": "Executive Branch Member",
                        "parent": 10,
                        "precedence": 275,
                        "rank": 1000,
                        "regions": [233],
                        "show_in_quicksearch": false,
                        "supported_regions": [233],
                        "value": 450
                    },
                    "chl_member_of_court_of_appeals": {
                        "key": "chl_member_of_court_of_appeals",
                        "label": "Member of the Court of Appeals",
                        "parent": 22,
                        "precedence": 360,
                        "rank": 1000,
                        "regions": [233],
                        "show_in_quicksearch": false,
                        "supported_regions": [233],
                        "value": 452
                    },
                    "chl_member_of_supreme_court_justice": {
                        "key": "chl_member_of_supreme_court_justice",
                        "label": "Member of the Supreme Court of Justice",
                        "parent": 21,
                        "precedence": 350,
                        "rank": 1000,
                        "regions": [233],
                        "show_in_quicksearch": false,
                        "supported_regions": [233],
                        "value": 451
                    },
                    "chl_minister": {
                        "key": "chl_minister",
                        "label": "Minister",
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [233],
                        "show_in_quicksearch": false,
                        "supported_regions": [233],
                        "value": 448
                    },
                    "chl_president": {
                        "key": "chl_president",
                        "label": "President",
                        "parent": 1,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [233],
                        "show_in_quicksearch": false,
                        "supported_regions": [233],
                        "value": 444
                    },
                    "chl_senator": {
                        "key": "chl_senator",
                        "label": "Senator",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [233],
                        "show_in_quicksearch": false,
                        "supported_regions": [233],
                        "value": 446
                    },
                    "chl_undersecretary": {
                        "key": "chl_undersecretary",
                        "label": "Undersecretary",
                        "parent": 5,
                        "precedence": 160,
                        "rank": 1000,
                        "regions": [233],
                        "show_in_quicksearch": false,
                        "supported_regions": [233],
                        "value": 449
                    },
                    "chl_vice_president": {
                        "key": "chl_vice_president",
                        "label": "Vice President",
                        "parent": 3,
                        "precedence": 115,
                        "rank": 1000,
                        "regions": [233],
                        "show_in_quicksearch": false,
                        "supported_regions": [233],
                        "value": 445
                    },
                    "chn_deputy": {
                        "key": "chn_deputy",
                        "label": "Deputy",
                        "parent": 11,
                        "precedence": 200,
                        "regions": [209],
                        "show_in_quicksearch": false,
                        "supported_regions": [209],
                        "value": 542
                    },
                    "col_magistrate": {
                        "key": "col_magistrate",
                        "label": "Magistrate",
                        "parent": 21,
                        "precedence": 350,
                        "rank": 1000,
                        "regions": [213],
                        "show_in_quicksearch": false,
                        "supported_regions": [213],
                        "value": 141
                    },
                    "col_minister": {
                        "key": "col_minister",
                        "label": "Minister",
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [213],
                        "show_in_quicksearch": false,
                        "supported_regions": [213],
                        "value": 138
                    },
                    "col_president": {
                        "key": "col_president",
                        "label": "President",
                        "parent": 2,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [213],
                        "show_in_quicksearch": false,
                        "supported_regions": [213],
                        "value": 136
                    },
                    "col_representative": {
                        "key": "col_representative",
                        "label": "Representative",
                        "parent": 12,
                        "precedence": 250,
                        "rank": 1000,
                        "regions": [213],
                        "show_in_quicksearch": false,
                        "supported_regions": [213],
                        "value": 140
                    },
                    "col_senator": {
                        "key": "col_senator",
                        "label": "Senator",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [213],
                        "show_in_quicksearch": false,
                        "supported_regions": [213],
                        "value": 139
                    },
                    "col_vice_president": {
                        "key": "col_vice_president",
                        "label": "Vice President",
                        "parent": 3,
                        "precedence": 110,
                        "rank": 1000,
                        "regions": [213],
                        "show_in_quicksearch": false,
                        "supported_regions": [213],
                        "value": 137
                    },
                    "columnist": {
                        "key": "columnist",
                        "label": "Columnist",
                        "parent": 43,
                        "precedence": 900,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 248
                    },
                    "community_affairs_director": {
                        "key": "community_affairs_director",
                        "label": "Community Affairs Director",
                        "parent": 41,
                        "precedence": 700,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 249
                    },
                    "consultant": {
                        "key": "consultant",
                        "label": "Consultant",
                        "parent": 47,
                        "precedence": 1300,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 250
                    },
                    "content_director": {
                        "key": "content_director",
                        "label": "Content Director",
                        "parent": 41,
                        "precedence": 700,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 251
                    },
                    "content_editor": {
                        "key": "content_editor",
                        "label": "Content Editor",
                        "parent": 40,
                        "precedence": 600,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 252
                    },
                    "content_manager": {
                        "key": "content_manager",
                        "label": "Content Manager",
                        "parent": 47,
                        "precedence": 1300,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 253
                    },
                    "content_producer": {
                        "key": "content_producer",
                        "label": "Content Producer",
                        "parent": 42,
                        "precedence": 800,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 254
                    },
                    "contributing_editor": {
                        "key": "contributing_editor",
                        "label": "Contributing Editor",
                        "parent": 40,
                        "precedence": 600,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 255
                    },
                    "contributor": {
                        "key": "contributor",
                        "label": "Contributor",
                        "parent": 47,
                        "precedence": 1300,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 256
                    },
                    "coordinator": {
                        "key": "coordinator",
                        "label": "Coordinator",
                        "parent": 47,
                        "precedence": 1300,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 257
                    },
                    "copy_editor": {
                        "key": "copy_editor",
                        "label": "Copy Editor",
                        "parent": 40,
                        "precedence": 600,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 258
                    },
                    "correspondent": {
                        "key": "correspondent",
                        "label": "Correspondent",
                        "parent": 46,
                        "precedence": 1200,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 259
                    },
                    "cri_deputy": {
                        "key": "cri_deputy",
                        "label": "Deputy",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [232],
                        "show_in_quicksearch": false,
                        "supported_regions": [232],
                        "value": 439
                    },
                    "cri_executive_branch_member": {
                        "key": "cri_executive_branch_member",
                        "label": "Executive Branch Member",
                        "parent": 10,
                        "precedence": 275,
                        "rank": 1000,
                        "regions": [232],
                        "show_in_quicksearch": false,
                        "supported_regions": [232],
                        "value": 442
                    },
                    "cri_magistrate": {
                        "key": "cri_magistrate",
                        "label": "Magistrate",
                        "parent": 21,
                        "precedence": 350,
                        "rank": 1000,
                        "regions": [232],
                        "show_in_quicksearch": false,
                        "supported_regions": [232],
                        "value": 443
                    },
                    "cri_president": {
                        "key": "cri_president",
                        "label": "President",
                        "parent": 1,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [232],
                        "show_in_quicksearch": false,
                        "supported_regions": [232],
                        "value": 437
                    },
                    "cri_second_level_minister": {
                        "key": "cri_second_level_minister",
                        "label": "Vice Minister",
                        "parent": 5,
                        "precedence": 160,
                        "rank": 1000,
                        "regions": [232],
                        "show_in_quicksearch": false,
                        "supported_regions": [232],
                        "value": 441
                    },
                    "cri_top_level_minister": {
                        "key": "cri_top_level_minister",
                        "label": "Minister",
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [232],
                        "show_in_quicksearch": false,
                        "supported_regions": [232],
                        "value": 440
                    },
                    "cri_vice_president": {
                        "key": "cri_vice_president",
                        "label": "Vice President",
                        "parent": 3,
                        "precedence": 115,
                        "rank": 1000,
                        "regions": [232],
                        "show_in_quicksearch": false,
                        "supported_regions": [232],
                        "value": 438
                    },
                    "critic": {
                        "key": "critic",
                        "label": "Critic",
                        "parent": 47,
                        "precedence": 1300,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 260
                    },
                    "cze_chief_director": {
                        "key": "cze_chief_director",
                        "label": "Chief Director",
                        "parent": 6,
                        "precedence": 170,
                        "rank": 1000,
                        "regions": [238],
                        "show_in_quicksearch": false,
                        "supported_regions": [238],
                        "value": 495
                    },
                    "cze_committee_leader": {
                        "key": "cze_committee_leader",
                        "label": "Committee Leader",
                        "parent": 55,
                        "precedence": 385,
                        "rank": 1000,
                        "regions": [238],
                        "show_in_quicksearch": false,
                        "supported_regions": [238],
                        "value": 501
                    },
                    "cze_deputy": {
                        "key": "cze_deputy",
                        "label": "Deputy",
                        "parent": 12,
                        "precedence": 250,
                        "rank": 1000,
                        "regions": [238],
                        "show_in_quicksearch": false,
                        "supported_regions": [238],
                        "value": 499
                    },
                    "cze_deputy_chief_director": {
                        "key": "cze_deputy_chief_director",
                        "label": "Deputy Chief Director",
                        "parent": 6,
                        "precedence": 170,
                        "rank": 1000,
                        "regions": [238],
                        "show_in_quicksearch": false,
                        "supported_regions": [238],
                        "value": 496
                    },
                    "cze_deputy_minister": {
                        "key": "cze_deputy_minister",
                        "label": "Minister, Deputy Minister",
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [238],
                        "show_in_quicksearch": false,
                        "supported_regions": [238],
                        "value": 493
                    },
                    "cze_deputy_prime_minister": {
                        "key": "cze_deputy_prime_minister",
                        "label": "Deputy Prime Minister",
                        "parent": 3,
                        "precedence": 115,
                        "rank": 1000,
                        "regions": [238],
                        "show_in_quicksearch": false,
                        "supported_regions": [238],
                        "value": 492
                    },
                    "cze_executive_branch_member": {
                        "key": "cze_executive_branch_member",
                        "label": "Executive Branch Member",
                        "parent": 10,
                        "precedence": 275,
                        "rank": 1000,
                        "regions": [238],
                        "show_in_quicksearch": false,
                        "supported_regions": [238],
                        "value": 497
                    },
                    "cze_leg_chamber_leader": {
                        "key": "cze_leg_chamber_leader",
                        "label": "Legislative Chamber Leader",
                        "parent": 54,
                        "precedence": 385,
                        "rank": 1000,
                        "regions": [238],
                        "show_in_quicksearch": false,
                        "supported_regions": [238],
                        "value": 500
                    },
                    "cze_party_leader": {
                        "key": "cze_party_leader",
                        "label": "Party Leader",
                        "parent": 56,
                        "precedence": 395,
                        "rank": 1000,
                        "regions": [238],
                        "show_in_quicksearch": false,
                        "supported_regions": [238],
                        "value": 502
                    },
                    "cze_president": {
                        "key": "cze_president",
                        "label": "President",
                        "parent": 1,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [238],
                        "show_in_quicksearch": false,
                        "supported_regions": [238],
                        "value": 490
                    },
                    "cze_prime_minister": {
                        "key": "cze_prime_minister",
                        "label": "Prime Minister",
                        "parent": 2,
                        "precedence": 110,
                        "rank": 1000,
                        "regions": [238],
                        "show_in_quicksearch": false,
                        "supported_regions": [238],
                        "value": 491
                    },
                    "cze_senator": {
                        "key": "cze_senator",
                        "label": "Senator",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [238],
                        "show_in_quicksearch": false,
                        "supported_regions": [238],
                        "value": 498
                    },
                    "cze_staff": {
                        "key": "cze_staff",
                        "label": "Staffer",
                        "parent": 23,
                        "precedence": 395,
                        "rank": 1000,
                        "regions": [238],
                        "show_in_quicksearch": false,
                        "supported_regions": [238],
                        "value": 503
                    },
                    "cze_state_secretary": {
                        "key": "cze_state_secretary",
                        "label": "State Secretary",
                        "parent": 5,
                        "precedence": 160,
                        "rank": 1000,
                        "regions": [238],
                        "show_in_quicksearch": false,
                        "supported_regions": [238],
                        "value": 494
                    },
                    "den_parl_member": {
                        "key": "den_parl_member",
                        "label": "Member of the Danish Parliament",
                        "parent": 11,
                        "precedence": 200,
                        "regions": [217],
                        "show_in_quicksearch": false,
                        "supported_regions": [217],
                        "value": 543
                    },
                    "deputy_editor": {
                        "key": "deputy_editor",
                        "label": "Deputy Editor",
                        "parent": 40,
                        "precedence": 600,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 261
                    },
                    "deputy_managing_editor": {
                        "key": "deputy_managing_editor",
                        "label": "Deputy Managing Editor",
                        "parent": 40,
                        "precedence": 600,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 262
                    },
                    "desk": {
                        "key": "desk",
                        "label": "Desk",
                        "parent": 47,
                        "precedence": 1300,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 373
                    },
                    "desk_contact": {
                        "key": "desk_contact",
                        "label": "Desk Contact",
                        "parent": 47,
                        "precedence": 1300,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 263
                    },
                    "desk_editor": {
                        "key": "desk_editor",
                        "label": "Desk Editor",
                        "parent": 40,
                        "precedence": 600,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 264
                    },
                    "deu_cabinet_minister": {
                        "key": "deu_cabinet_minister",
                        "label": "Cabinet Minister",
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [203],
                        "show_in_quicksearch": false,
                        "supported_regions": [203],
                        "value": 65
                    },
                    "deu_federal_chancellor": {
                        "key": "deu_federal_chancellor",
                        "label": "Federal Chancellor",
                        "parent": 1,
                        "precedence": 110,
                        "rank": 1000,
                        "regions": [203],
                        "show_in_quicksearch": false,
                        "supported_regions": [203],
                        "value": 64
                    },
                    "deu_federal_president": {
                        "key": "deu_federal_president",
                        "label": "Federal President",
                        "parent": 2,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [203],
                        "show_in_quicksearch": false,
                        "supported_regions": [203],
                        "value": 63
                    },
                    "deu_judge": {
                        "key": "deu_judge",
                        "label": "Judge",
                        "parent": 21,
                        "precedence": 350,
                        "rank": 1000,
                        "regions": [203],
                        "show_in_quicksearch": false,
                        "supported_regions": [203],
                        "value": 68
                    },
                    "deu_lay_judge": {
                        "key": "deu_lay_judge",
                        "label": "Lay Judge",
                        "parent": 22,
                        "precedence": 360,
                        "rank": 1000,
                        "regions": [203],
                        "show_in_quicksearch": false,
                        "supported_regions": [203],
                        "value": 69
                    },
                    "deu_member_bundesrag": {
                        "key": "deu_member_bundesrag",
                        "label": "Member of Bundesrag",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [203],
                        "show_in_quicksearch": false,
                        "supported_regions": [203],
                        "value": 66
                    },
                    "deu_member_bundestag": {
                        "key": "deu_member_bundestag",
                        "label": "Member of Bundestag",
                        "parent": 12,
                        "precedence": 250,
                        "rank": 1000,
                        "regions": [203],
                        "show_in_quicksearch": false,
                        "supported_regions": [203],
                        "value": 67
                    },
                    "deu_minister_president": {
                        "key": "deu_minister_president",
                        "label": "Minister-President",
                        "parent": 16,
                        "precedence": 310,
                        "rank": 1000,
                        "regions": [203],
                        "show_in_quicksearch": false,
                        "supported_regions": [203],
                        "value": 70
                    },
                    "deu_parliamentary_state_secretary": {
                        "key": "deu_parliamentary_state_secretary",
                        "label": "Parliamentary State Secretary",
                        "parent": 6,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [203],
                        "show_in_quicksearch": false,
                        "supported_regions": [203],
                        "value": 207
                    },
                    "deu_state_secretary": {
                        "key": "deu_state_secretary",
                        "label": "State Secretary",
                        "parent": 5,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [203],
                        "show_in_quicksearch": false,
                        "supported_regions": [203],
                        "value": 206
                    },
                    "director": {
                        "key": "director",
                        "label": "Director",
                        "parent": 41,
                        "precedence": 700,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 312
                    },
                    "disc_jockey": {
                        "key": "disc_jockey",
                        "label": "Disc Jockey",
                        "parent": 47,
                        "precedence": 1300,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 265
                    },
                    "dom_deputy": {
                        "key": "dom_deputy",
                        "label": "Deputy",
                        "parent": 12,
                        "precedence": 250,
                        "rank": 1000,
                        "regions": [235],
                        "show_in_quicksearch": false,
                        "supported_regions": [235],
                        "value": 470
                    },
                    "dom_director": {
                        "key": "dom_director",
                        "label": "Director",
                        "parent": 5,
                        "precedence": 160,
                        "rank": 1000,
                        "regions": [235],
                        "show_in_quicksearch": false,
                        "supported_regions": [235],
                        "value": 472
                    },
                    "dom_executive_branch_member": {
                        "key": "dom_executive_branch_member",
                        "label": "Executive Branch Member",
                        "parent": 10,
                        "precedence": 275,
                        "rank": 1000,
                        "regions": [235],
                        "show_in_quicksearch": false,
                        "supported_regions": [235],
                        "value": 473
                    },
                    "dom_minister": {
                        "key": "dom_minister",
                        "label": "Minister",
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [235],
                        "show_in_quicksearch": false,
                        "supported_regions": [235],
                        "value": 471
                    },
                    "dom_president": {
                        "key": "dom_president",
                        "label": "President",
                        "parent": 2,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [235],
                        "show_in_quicksearch": false,
                        "supported_regions": [235],
                        "value": 467
                    },
                    "dom_senator": {
                        "key": "dom_senator",
                        "label": "Senator",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [235],
                        "show_in_quicksearch": false,
                        "supported_regions": [235],
                        "value": 469
                    },
                    "dom_vice_president": {
                        "key": "dom_vice_president",
                        "label": "Vice President",
                        "parent": 3,
                        "precedence": 115,
                        "rank": 1000,
                        "regions": [235],
                        "show_in_quicksearch": false,
                        "supported_regions": [235],
                        "value": 468
                    },
                    "due_member_landtag": {
                        "key": "due_member_landtag",
                        "label": "Member of Landtag",
                        "parent": 20,
                        "precedence": 265,
                        "rank": 1000,
                        "regions": [203],
                        "show_in_quicksearch": false,
                        "supported_regions": [203],
                        "value": 71
                    },
                    "editor": {
                        "key": "editor",
                        "label": "Editor",
                        "parent": 40,
                        "precedence": 600,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 266
                    },
                    "editor_at_large": {
                        "key": "editor_at_large",
                        "label": "Editor-at-large",
                        "parent": 40,
                        "precedence": 600,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 268
                    },
                    "editor_emeritus": {
                        "key": "editor_emeritus",
                        "label": "Editor Emeritus",
                        "parent": 40,
                        "precedence": 600,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 267
                    },
                    "editor_in_chief": {
                        "key": "editor_in_chief",
                        "label": "Editor-in-chief",
                        "parent": 40,
                        "precedence": 600,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 269
                    },
                    "editorial_assistant": {
                        "key": "editorial_assistant",
                        "label": "Editorial Assistant",
                        "parent": 40,
                        "precedence": 600,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 270
                    },
                    "editorial_director": {
                        "key": "editorial_director",
                        "label": "Editorial Director",
                        "parent": 41,
                        "precedence": 700,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 271
                    },
                    "editorial_head": {
                        "key": "editorial_head",
                        "label": "Editorial Head",
                        "parent": 40,
                        "precedence": 600,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 272
                    },
                    "esp_deputy": {
                        "key": "esp_deputy",
                        "label": "Deputy",
                        "parent": 12,
                        "precedence": 250,
                        "rank": 1000,
                        "regions": [211],
                        "show_in_quicksearch": false,
                        "supported_regions": [211],
                        "value": 154
                    },
                    "esp_deputy_prime_minister": {
                        "key": "esp_deputy_prime_minister",
                        "label": "Deputy Prime Minister",
                        "parent": 3,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [211],
                        "show_in_quicksearch": false,
                        "supported_regions": [211],
                        "value": 210
                    },
                    "esp_minister": {
                        "key": "esp_minister",
                        "label": "Minister",
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [211],
                        "show_in_quicksearch": false,
                        "supported_regions": [211],
                        "value": 151
                    },
                    "esp_monarch": {
                        "key": "esp_monarch",
                        "label": "Monarch",
                        "parent": 1,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [211],
                        "show_in_quicksearch": false,
                        "supported_regions": [211],
                        "value": 149
                    },
                    "esp_prime_minister": {
                        "key": "esp_prime_minister",
                        "label": "Prime Minister",
                        "parent": 2,
                        "precedence": 110,
                        "rank": 1000,
                        "regions": [211],
                        "show_in_quicksearch": false,
                        "supported_regions": [211],
                        "value": 150
                    },
                    "esp_secretary_of_state": {
                        "key": "esp_secretary_of_state",
                        "label": "Secretary of State",
                        "parent": 5,
                        "precedence": 160,
                        "rank": 1000,
                        "regions": [211],
                        "show_in_quicksearch": false,
                        "supported_regions": [211],
                        "value": 152
                    },
                    "esp_senator": {
                        "key": "esp_senator",
                        "label": "Senator",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [211],
                        "show_in_quicksearch": false,
                        "supported_regions": [211],
                        "value": 153
                    },
                    "esp_top_level_judge": {
                        "key": "esp_top_level_judge",
                        "label": "Judge",
                        "parent": 21,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [211],
                        "show_in_quicksearch": false,
                        "supported_regions": [211],
                        "value": 155
                    },
                    "eu_agency_committee_members": {
                        "key": "eu_agency_committee_members",
                        "label": "Committee Member",
                        "order": 35,
                        "parent": 24,
                        "precedence": 400,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 366
                    },
                    "eu_agency_executive": {
                        "key": "eu_agency_executive",
                        "label": "Executive",
                        "order": 9,
                        "parent": 10,
                        "precedence": 275,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 362
                    },
                    "eu_agency_management": {
                        "key": "eu_agency_management",
                        "label": "Management",
                        "order": 10,
                        "parent": 10,
                        "precedence": 275,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 363
                    },
                    "eu_agency_press_contacts": {
                        "key": "eu_agency_press_contacts",
                        "label": "Press Contact",
                        "order": 37,
                        "parent": 24,
                        "precedence": 400,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 368
                    },
                    "eu_agency_staff": {
                        "key": "eu_agency_staff",
                        "label": "Staff",
                        "order": 38,
                        "parent": 24,
                        "precedence": 400,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 369
                    },
                    "eu_agency_stakeholder_group_member": {
                        "key": "eu_agency_stakeholder_group_member",
                        "label": "Stakeholder Group Member",
                        "order": 36,
                        "parent": 24,
                        "precedence": 400,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 367
                    },
                    "eu_com_advisory_board_chair": {
                        "description": "\n            This position-type includes only the Chair of the 2 advisory bodies within\n            the European Commission: Regulatory Scrutiny Board and European Fiscal Board (EFB)\n        ",
                        "key": "eu_com_advisory_board_chair",
                        "label": "Advisory Board Chair",
                        "parent": 10,
                        "precedence": 275,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 529
                    },
                    "eu_com_advisory_board_member": {
                        "description": "\n            This position-type includes only the Members of the 2 advisory bodies within\n            the European Commission: Regulatory Scrutiny Board and European Fiscal Board (EFB)\n        ",
                        "key": "eu_com_advisory_board_member",
                        "label": "Advisory Board Member",
                        "parent": 10,
                        "precedence": 275,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 530
                    },
                    "eu_com_cabinet_staff": {
                        "description": "wiw",
                        "key": "eu_com_cabinet_staff",
                        "label": "Commissioner Cabinet",
                        "order": 11,
                        "parent": 24,
                        "precedence": 400,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "source_enum": "StafferType",
                        "source_enum_value": 106,
                        "supported_regions": [106],
                        "value": 45
                    },
                    "eu_com_desk_officer": {
                        "key": "eu_com_desk_officer",
                        "label": "Desk Officer",
                        "order": 16,
                        "parent": 24,
                        "precedence": 400,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 364
                    },
                    "eu_com_dg_director": {
                        "description": "\n            Directors report to the Directors-General, and are accountable for the management of\n            financial and human resources within their Directorates.\n        ",
                        "key": "eu_com_dg_director",
                        "label": "Director",
                        "order": 14,
                        "parent": 24,
                        "precedence": 400,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 325
                    },
                    "eu_com_dg_headofunit": {
                        "description": "\n            Heads of Unit are the equivalent of the line managers of the\n            Commission - they are crucial in the effective delivery of the Commission's\n            political priorities.\n        ",
                        "key": "eu_com_dg_headofunit",
                        "label": "Head of Unit",
                        "order": 15,
                        "parent": 24,
                        "precedence": 400,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 326
                    },
                    "eu_com_dg_press_and_communication_officers": {
                        "description": "\n            Press Contacts inside the Commission include the Spokespersons' Service (spokesperson's + press\n            officers of the EU Commission) and the Press Officers in the National\n            EC Representations\n        ",
                        "key": "eu_com_dg_press_and_communication_officers",
                        "label": "Press and Communication Contact",
                        "order": 17,
                        "parent": 24,
                        "precedence": 400,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 327
                    },
                    "eu_com_director_general": {
                        "description": "\n            The Commission is divided into policy departments - these are known as\n            Directorates-Generals (DGs), which are responsible for different policy areas, and are led\n            by 1 Director General\n        ",
                        "key": "eu_com_director_general",
                        "label": "Director General",
                        "order": 13,
                        "parent": 10,
                        "precedence": 270,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 324
                    },
                    "eu_com_secretariat": {
                        "description": "\n            The Secretariat-General supports the whole Commission, and is responsible for the overall\n            coherence of the Commission's work - in shaping new policies and in\n            steering them though the other EU institutions\n        ",
                        "key": "eu_com_secretariat",
                        "label": "Commission Secretariat General",
                        "parent": 10,
                        "precedence": 270,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 323
                    },
                    "eu_com_secretariat_staff": {
                        "key": "eu_com_secretariat_staff",
                        "label": "Commission Secretariat",
                        "order": 12,
                        "parent": 24,
                        "precedence": 400,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 370
                    },
                    "eu_com_sg_director": {
                        "description": "\n            This person-type includes the Directors and Deputy Directors which are heads of\n            the Directorates (Sub-org) in the Secretariat General Organization\n        ",
                        "key": "eu_com_sg_director",
                        "label": "Secretariat-General Director",
                        "parent": 10,
                        "precedence": 275,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 527
                    },
                    "eu_com_sg_headofunit": {
                        "description": "\n            This person-type includes the Heads of Units which fall under the Directorate\n            of the Secretariat-General of the EU Commission\n        ",
                        "key": "eu_com_sg_headofunit",
                        "label": "Secretariat-General Head of Unit",
                        "parent": 10,
                        "precedence": 275,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 528
                    },
                    "eu_com_sg_leadership": {
                        "description": "This position includes the following titles: Secretary-General and Deputy Secretary-General",
                        "key": "eu_com_sg_leadership",
                        "label": "Secretariat-General Leadership",
                        "parent": 10,
                        "precedence": 275,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 526
                    },
                    "eu_com_staff": {
                        "description": "wiw",
                        "key": "eu_com_staff",
                        "label": "Commission Staff",
                        "order": 18,
                        "parent": 24,
                        "precedence": 400,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "source_enum": "StafferType",
                        "source_enum_value": 107,
                        "supported_regions": [106],
                        "value": 46
                    },
                    "eu_commissioner": {
                        "description": "wiw",
                        "key": "eu_commissioner",
                        "label": "Commissioner",
                        "order": 1,
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "source_enum": "RoleType",
                        "source_enum_value": 25,
                        "supported_regions": [106],
                        "unique_identifier": "EU_COMM",
                        "value": 34
                    },
                    "eu_committee_secretariat": {
                        "description": "wiw",
                        "key": "eu_committee_secretariat",
                        "label": "Committee Secretariat",
                        "parent": 24,
                        "precedence": 400,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "source_enum": "StafferType",
                        "source_enum_value": 103,
                        "supported_regions": [106],
                        "value": 42
                    },
                    "eu_consil_cabinet_staff": {
                        "description": "wiw",
                        "key": "eu_consil_cabinet_staff",
                        "label": "Council President Cabinet Staff",
                        "order": 30,
                        "parent": 24,
                        "precedence": 400,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "source_enum": "StafferType",
                        "source_enum_value": 108,
                        "supported_regions": [106],
                        "value": 47
                    },
                    "eu_council_director": {
                        "description": "\n            This person-type includes the heads of Directorates known as \"Directors\" under the\n            Directorates-General\n        ",
                        "key": "eu_council_director",
                        "label": "Director",
                        "parent": 10,
                        "precedence": 275,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 540
                    },
                    "eu_council_director_general": {
                        "description": "\n            This person-type includes the Directors-General in the General Secretariat of the Council\n            of the EU\n        ",
                        "key": "eu_council_director_general",
                        "label": "Director-General",
                        "parent": 10,
                        "precedence": 275,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 539
                    },
                    "eu_council_head_of_unit": {
                        "description": "This person-type includes the heads of Units under the Directorates",
                        "key": "eu_council_head_of_unit",
                        "label": "Head of Unit",
                        "parent": 10,
                        "precedence": 275,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 541
                    },
                    "eu_council_minister": {
                        "description": "wiw",
                        "key": "eu_council_minister",
                        "label": "Minister of the Council of the EU",
                        "order": 5,
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "source_enum": "RoleType",
                        "source_enum_value": 27,
                        "supported_regions": [106],
                        "unique_identifier": "EU_CCMIN",
                        "value": 36
                    },
                    "eu_council_president": {
                        "description": "This person-type is for the President of the European Council",
                        "key": "eu_council_president",
                        "label": "EU Council President",
                        "parent": 10,
                        "precedence": 275,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 525
                    },
                    "eu_council_press": {
                        "description": "\n            Contacts of the press services of the European Parliament, the President and\n            the Political Groups\n        ",
                        "key": "eu_council_press",
                        "label": "Press Contacts",
                        "parent": 10,
                        "precedence": 275,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 537
                    },
                    "eu_council_press_contacts": {
                        "description": "\n            These Press Contacts work throughout the EU Council and the Council of\n            the EU.\n        ",
                        "key": "eu_council_press_contacts",
                        "label": "Press Contact",
                        "order": 33,
                        "parent": 24,
                        "precedence": 400,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 330
                    },
                    "eu_council_secretariat": {
                        "description": "wiw",
                        "key": "eu_council_secretariat",
                        "label": "General Secretariat of the Council",
                        "order": 31,
                        "parent": 10,
                        "precedence": 270,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "source_enum": "StafferType",
                        "source_enum_value": 109,
                        "supported_regions": [106],
                        "value": 48
                    },
                    "eu_council_secretary_general": {
                        "description": "This person-type is for the Secretary-General of the Council of the EU",
                        "key": "eu_council_secretary_general",
                        "label": "Secretary-General",
                        "parent": 10,
                        "precedence": 275,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 538
                    },
                    "eu_councilmember": {
                        "description": "wiw",
                        "key": "eu_councilmember",
                        "label": "EU Council Member",
                        "order": 3,
                        "parent": 1,
                        "precedence": 190,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "source_enum": "RoleType",
                        "source_enum_value": 26,
                        "supported_regions": [106],
                        "unique_identifier": "EU_CCMEM",
                        "value": 35
                    },
                    "eu_ep_cabinet_staff": {
                        "description": "\n            The President of the EU Parliament has its Cabinet that supports them\n            in their daily work.\n        ",
                        "key": "eu_ep_cabinet_staff",
                        "label": "President's Cabinet",
                        "order": 19,
                        "parent": 24,
                        "precedence": 400,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 331
                    },
                    "eu_ep_press_contacts": {
                        "description": "\n            Contacts of the press services of the European Parliament, the President and\n            the Political Groups.\n        ",
                        "key": "eu_ep_press_contacts",
                        "label": "Press Contact",
                        "order": 28,
                        "parent": 24,
                        "precedence": 400,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 332
                    },
                    "eu_ep_secretariat": {
                        "description": "wiw",
                        "key": "eu_ep_secretariat",
                        "label": "Parliament Secretariat",
                        "order": 20,
                        "parent": 10,
                        "precedence": 270,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "source_enum": "StafferType",
                        "source_enum_value": 104,
                        "supported_regions": [106],
                        "value": 43
                    },
                    "eu_ep_staff": {
                        "key": "eu_ep_staff",
                        "label": "Parliament Staff",
                        "order": 29,
                        "parent": 24,
                        "precedence": 400,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 333
                    },
                    "eu_mep": {
                        "description": "eu_parl",
                        "key": "eu_mep",
                        "label": "MEP",
                        "order": 2,
                        "parent": 12,
                        "precedence": 250,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "source_enum": "RoleType",
                        "source_enum_value": 28,
                        "supported_regions": [106],
                        "unique_identifier": "EU_MEP",
                        "value": 37
                    },
                    "eu_mep_can": {
                        "description": "mep_candidates",
                        "key": "eu_mep_can",
                        "label": "MEP Candidate",
                        "parent": 28,
                        "precedence": 250,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "source_enum": "RoleType",
                        "source_enum_value": 38,
                        "supported_regions": [106],
                        "unique_identifier": "EU_MEP_CAN",
                        "value": 39
                    },
                    "eu_other_mep_staff": {
                        "description": "eu_parliament",
                        "key": "eu_other_mep_staff",
                        "label": "Other MEP Staff",
                        "parent": 23,
                        "precedence": 400,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "source_enum": "StafferType",
                        "source_enum_value": 105,
                        "supported_regions": [106],
                        "value": 44
                    },
                    "eu_other_orgs_exec": {
                        "key": "eu_other_orgs_exec",
                        "label": "Executive",
                        "order": 7,
                        "parent": 10,
                        "precedence": 275,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 360
                    },
                    "eu_other_orgs_main_officials": {
                        "description": "\n            The ECB is the central bank of the 19 European Union countries\n            which use the euro\n        ",
                        "key": "eu_other_orgs_main_officials",
                        "label": "Main EU Officials",
                        "parent": 10,
                        "precedence": 270,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 334
                    },
                    "eu_other_orgs_management": {
                        "key": "eu_other_orgs_management",
                        "label": "Management",
                        "order": 8,
                        "parent": 10,
                        "precedence": 275,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 361
                    },
                    "eu_other_orgs_staff": {
                        "key": "eu_other_orgs_staff",
                        "label": "Staff",
                        "order": 34,
                        "parent": 24,
                        "precedence": 400,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 365
                    },
                    "eu_parl_committee_leadership": {
                        "key": "eu_parl_committee_leadership",
                        "label": "Committee Leadership",
                        "order": 22,
                        "parent": 12,
                        "precedence": 250,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 335
                    },
                    "eu_parl_committee_staff": {
                        "key": "eu_parl_committee_staff",
                        "label": "Committee Staff",
                        "order": 23,
                        "parent": 24,
                        "precedence": 400,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 338
                    },
                    "eu_parl_delegation_leadership": {
                        "key": "eu_parl_delegation_leadership",
                        "label": "Delegation Leadership",
                        "order": 24,
                        "parent": 12,
                        "precedence": 250,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 336
                    },
                    "eu_parl_delegation_staff": {
                        "key": "eu_parl_delegation_staff",
                        "label": "Delegation Staff",
                        "order": 25,
                        "parent": 24,
                        "precedence": 400,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 339
                    },
                    "eu_parl_director": {
                        "description": "\n            This position includes only the title \"Director\" of the different units below\n            a DG\n        ",
                        "key": "eu_parl_director",
                        "label": "Director",
                        "parent": 12,
                        "precedence": 250,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 533
                    },
                    "eu_parl_director_general": {
                        "description": "This position includes all the Director-General of the European Parliament DGs",
                        "key": "eu_parl_director_general",
                        "label": "Director-General",
                        "parent": 12,
                        "precedence": 250,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 532
                    },
                    "eu_parl_governing_body_member": {
                        "key": "eu_parl_governing_body_member",
                        "label": "EU Parliament Governing Body Member",
                        "parent": 12,
                        "precedence": 400,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 523
                    },
                    "eu_parl_jurisconsult": {
                        "description": "This is the head of the Secretariat's Legal Service",
                        "key": "eu_parl_jurisconsult",
                        "label": "Jurisconsult of the European Parliament",
                        "parent": 12,
                        "precedence": 250,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 534
                    },
                    "eu_parl_leadership": {
                        "key": "eu_parl_leadership",
                        "label": "EU Parliament Leadership",
                        "parent": 12,
                        "precedence": 250,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 522
                    },
                    "eu_parl_political_group_leadership": {
                        "key": "eu_parl_political_group_leadership",
                        "label": "Political Group Leadership",
                        "order": 26,
                        "parent": 12,
                        "precedence": 250,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 337
                    },
                    "eu_parl_pres_cabinet_staff": {
                        "key": "eu_parl_pres_cabinet_staff",
                        "label": "President's Cabinet",
                        "parent": 24,
                        "precedence": 400,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 524
                    },
                    "eu_parl_sg_leadership": {
                        "description": "This position includes Secretary-General and Deputies Secretary-General",
                        "key": "eu_parl_sg_leadership",
                        "label": "Secretariat-General Leadership",
                        "parent": 12,
                        "precedence": 250,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 531
                    },
                    "eu_parl_subcom_leadership": {
                        "description": "This position includes the Chair and Vice CHair of Subcommittees",
                        "key": "eu_parl_subcom_leadership",
                        "label": "Subcommittee Leadership",
                        "parent": 12,
                        "precedence": 250,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 535
                    },
                    "eu_parl_subcom_staff": {
                        "description": "This position includes the Members and Substitute Members in Subcommittees",
                        "key": "eu_parl_subcom_staff",
                        "label": "Subcommittee Member",
                        "parent": 24,
                        "precedence": 400,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 536
                    },
                    "eu_parliamentary_assistant": {
                        "description": "eu_parliament",
                        "key": "eu_parliamentary_assistant",
                        "label": "Accredited Parliamentary Assistant",
                        "order": 21,
                        "parent": 23,
                        "precedence": 400,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "source_enum": "StafferType",
                        "source_enum_value": 101,
                        "supported_regions": [106],
                        "value": 40
                    },
                    "eu_perm_rep_staff": {
                        "description": "wiw",
                        "key": "eu_perm_rep_staff",
                        "label": "Permanent Representation Staff",
                        "parent": 24,
                        "precedence": 300,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "source_enum": "StafferType",
                        "source_enum_value": 110,
                        "supported_regions": [106],
                        "value": 49
                    },
                    "eu_permanent_representative": {
                        "description": "wiw",
                        "key": "eu_permanent_representative",
                        "label": "Permanent Representation",
                        "order": 6,
                        "parent": 24,
                        "precedence": 250,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "source_enum": "RoleType",
                        "source_enum_value": 30,
                        "supported_regions": [106],
                        "unique_identifier": "EU_PREP",
                        "value": 38
                    },
                    "eu_political_group_staff": {
                        "description": "wiw",
                        "key": "eu_political_group_staff",
                        "label": "Political Group Staff",
                        "order": 27,
                        "parent": 24,
                        "precedence": 400,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "source_enum": "StafferType",
                        "source_enum_value": 102,
                        "supported_regions": [106],
                        "value": 41
                    },
                    "eu_rotating_presidency": {
                        "key": "eu_rotating_presidency",
                        "label": "Council Rotating Presidency",
                        "order": 4,
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 328
                    },
                    "eu_staff_council_minister": {
                        "key": "eu_staff_council_minister",
                        "label": "Staff of Minister of Council of the EU",
                        "order": 32,
                        "parent": 5,
                        "precedence": 160,
                        "rank": 1000,
                        "regions": [106],
                        "show_in_quicksearch": false,
                        "supported_regions": [106],
                        "value": 329
                    },
                    "events_manager": {
                        "key": "events_manager",
                        "label": "Events Manager",
                        "parent": 47,
                        "precedence": 1300,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 273
                    },
                    "exec_branch_member_minor": {
                        "key": "exec_branch_member_minor",
                        "label": "Executive Branch Member",
                        "parent": 10,
                        "precedence": 275,
                        "rank": 1000,
                        "regions": [200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241],
                        "show_in_quicksearch": false,
                        "supported_regions": [200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241],
                        "value": 158
                    },
                    "executives": {
                        "key": "executives",
                        "label": "Executives",
                        "parent": 47,
                        "precedence": 1300,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 274
                    },
                    "features_editor": {
                        "key": "features_editor",
                        "label": "Features Editor",
                        "parent": 40,
                        "precedence": 600,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 275
                    },
                    "filter_groups": [
                        {
                            "display": "list",
                            "sub_group": [
                                {
                                    "display": "list",
                                    "regions": "European Union",
                                    "title": "EU Institutions",
                                    "values": [34, 522, 523, 37, 35, 328, 36, 38]
                                },
                                {
                                    "display": "list",
                                    "regions": "European Union",
                                    "title": "Other EU Governing Bodies",
                                    "values": [360, 361]
                                },
                                {
                                    "display": "list",
                                    "regions": "European Union",
                                    "title": "EU Agencies",
                                    "values": [362, 363]
                                }
                            ],
                            "title": "Officials",
                            "values": [34, 522, 523, 37, 35, 328, 36, 38, 360, 361, 362, 363]
                        },
                        {
                            "display": "list",
                            "sub_group": [
                                {
                                    "display": "list",
                                    "regions": "European Union",
                                    "title": "Commission",
                                    "values": [45, 370, 324, 325, 326, 364, 327, 46]
                                },
                                {
                                    "display": "list",
                                    "regions": "European Union",
                                    "title": "Parliament",
                                    "values": [524, 43, 40, 335, 336, 337, 332, 333]
                                },
                                {
                                    "display": "list",
                                    "regions": "European Union",
                                    "title": "EU Council",
                                    "values": [47]
                                },
                                {
                                    "display": "list",
                                    "regions": "European Union",
                                    "title": "Council of the EU",
                                    "values": [48, 329, 330]
                                },
                                {
                                    "display": "list",
                                    "regions": "European Union",
                                    "title": "Other EU Governing Bodies",
                                    "values": [365]
                                },
                                {
                                    "display": "list",
                                    "regions": "European Union",
                                    "title": "EU Agencies",
                                    "values": [366, 367, 368, 369]
                                }
                            ],
                            "title": "Staff",
                            "values": [45, 370, 324, 325, 326, 364, 327, 46, 524, 43, 40, 335, 336, 337, 332, 333, 47, 48, 329, 330, 365, 366, 367, 368, 369]
                        },
                        {
                            "display": "list",
                            "title": "Legislative",
                            "values": [5, 6, 7, 8, 9, 199]
                        },
                        {
                            "display": "list",
                            "title": "Executive",
                            "values": [11, 12, 13, 14, 15, 16, 17, 21, 22, 186, 187, 189, 191, 193, 194, 196, 453, 198, 489, 504]
                        },
                        {
                            "collapseGroup": true,
                            "display": "list",
                            "title": "Legislative Staff",
                            "values": [30, 160]
                        },
                        {
                            "collapseGroup": true,
                            "display": "list",
                            "title": "Committee Staff",
                            "values": [32, 162]
                        },
                        {
                            "collapseGroup": true,
                            "display": "list",
                            "title": "Executive Staff",
                            "values": [163, 20]
                        },
                        {
                            "collapseGroup": true,
                            "display": "list",
                            "title": "Leadership Staff",
                            "values": [31, 161, 170, 171, 172, 173]
                        }
                    ],
                    "fra_chief_justice": {
                        "key": "fra_chief_justice",
                        "label": "Chief Justice",
                        "parent": 21,
                        "precedence": 350,
                        "rank": 1000,
                        "regions": [205],
                        "show_in_quicksearch": false,
                        "supported_regions": [205],
                        "value": 78
                    },
                    "fra_deputy": {
                        "key": "fra_deputy",
                        "label": "Deputy",
                        "parent": 12,
                        "precedence": 250,
                        "rank": 1000,
                        "regions": [205],
                        "show_in_quicksearch": false,
                        "supported_regions": [205],
                        "value": 77
                    },
                    "fra_junior_minister": {
                        "key": "fra_junior_minister",
                        "label": "Junior Minister",
                        "parent": 5,
                        "precedence": 160,
                        "rank": 1000,
                        "regions": [205],
                        "show_in_quicksearch": false,
                        "supported_regions": [205],
                        "value": 75
                    },
                    "fra_president": {
                        "key": "fra_president",
                        "label": "President",
                        "parent": 1,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [205],
                        "show_in_quicksearch": false,
                        "supported_regions": [205],
                        "value": 72
                    },
                    "fra_prime_minister": {
                        "key": "fra_prime_minister",
                        "label": "Prime Minister",
                        "parent": 2,
                        "precedence": 110,
                        "rank": 1000,
                        "regions": [205],
                        "show_in_quicksearch": false,
                        "supported_regions": [205],
                        "value": 73
                    },
                    "fra_senator": {
                        "key": "fra_senator",
                        "label": "Senator",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [205],
                        "show_in_quicksearch": false,
                        "supported_regions": [205],
                        "value": 76
                    },
                    "fra_senior_minister": {
                        "key": "fra_senior_minister",
                        "label": "Senior Minister",
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [205],
                        "show_in_quicksearch": false,
                        "supported_regions": [205],
                        "value": 74
                    },
                    "freelance_journalist": {
                        "key": "freelance_journalist",
                        "label": "Freelance Journalist",
                        "parent": 43,
                        "precedence": 900,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 276
                    },
                    "gbr_civil_servant": {
                        "key": "gbr_civil_servant",
                        "label": "Civil Servant",
                        "parent": 6,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [204],
                        "show_in_quicksearch": false,
                        "supported_regions": [204],
                        "value": 92
                    },
                    "gbr_lord": {
                        "key": "gbr_lord",
                        "label": "Lord",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [204],
                        "show_in_quicksearch": false,
                        "supported_regions": [204],
                        "value": 93
                    },
                    "gbr_minister": {
                        "key": "gbr_minister",
                        "label": "Minister",
                        "parent": 5,
                        "precedence": 160,
                        "rank": 1000,
                        "regions": [204],
                        "show_in_quicksearch": false,
                        "supported_regions": [204],
                        "value": 91
                    },
                    "gbr_monarch": {
                        "key": "gbr_monarch",
                        "label": "Monarch",
                        "parent": 1,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [204],
                        "show_in_quicksearch": false,
                        "supported_regions": [204],
                        "value": 88
                    },
                    "gbr_mp": {
                        "key": "gbr_mp",
                        "label": "Member of Parliament",
                        "parent": 12,
                        "precedence": 250,
                        "rank": 1000,
                        "regions": [204],
                        "show_in_quicksearch": false,
                        "supported_regions": [204],
                        "value": 94
                    },
                    "gbr_prime_minister": {
                        "key": "gbr_prime_minister",
                        "label": "Prime Minister",
                        "parent": 2,
                        "precedence": 110,
                        "rank": 1000,
                        "regions": [204],
                        "show_in_quicksearch": false,
                        "supported_regions": [204],
                        "value": 89
                    },
                    "gbr_secretary_of_state": {
                        "key": "gbr_secretary_of_state",
                        "label": "Secretary of State",
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [204],
                        "show_in_quicksearch": false,
                        "supported_regions": [204],
                        "value": 90
                    },
                    "gbr_supreme_court_justice": {
                        "key": "gbr_supreme_court_justice",
                        "label": "Supreme Court Justice",
                        "parent": 21,
                        "precedence": 350,
                        "rank": 1000,
                        "regions": [204],
                        "show_in_quicksearch": false,
                        "supported_regions": [204],
                        "value": 95
                    },
                    "general_manager": {
                        "key": "general_manager",
                        "label": "General Manager",
                        "parent": 47,
                        "precedence": 1300,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 277
                    },
                    "grc_deputy_minister": {
                        "key": "grc_deputy_minister",
                        "label": "Deputy Minister",
                        "parent": 5,
                        "precedence": 160,
                        "rank": 1000,
                        "regions": [234],
                        "show_in_quicksearch": false,
                        "supported_regions": [234],
                        "value": 465
                    },
                    "grc_executive_branch_member": {
                        "key": "grc_executive_branch_member",
                        "label": "Executive Branch Member",
                        "parent": 10,
                        "precedence": 275,
                        "rank": 1000,
                        "regions": [234],
                        "show_in_quicksearch": false,
                        "supported_regions": [234],
                        "value": 466
                    },
                    "grc_member_of_hellenic_parliament": {
                        "key": "grc_member_of_hellenic_parliament",
                        "label": "Member of the Hellenic Parliament",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [234],
                        "show_in_quicksearch": false,
                        "supported_regions": [234],
                        "value": 463
                    },
                    "grc_minister": {
                        "key": "grc_minister",
                        "label": "Minister",
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [234],
                        "show_in_quicksearch": false,
                        "supported_regions": [234],
                        "value": 464
                    },
                    "grc_president": {
                        "key": "grc_president",
                        "label": "President",
                        "parent": 1,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [234],
                        "show_in_quicksearch": false,
                        "supported_regions": [234],
                        "value": 460
                    },
                    "grc_prime_minister": {
                        "key": "grc_prime_minister",
                        "label": "Prime Minister",
                        "parent": 2,
                        "precedence": 110,
                        "rank": 1000,
                        "regions": [234],
                        "show_in_quicksearch": false,
                        "supported_regions": [234],
                        "value": 461
                    },
                    "grc_vice_president": {
                        "key": "grc_vice_president",
                        "label": "Vice President",
                        "parent": 3,
                        "precedence": 115,
                        "rank": 1000,
                        "regions": [234],
                        "show_in_quicksearch": false,
                        "supported_regions": [234],
                        "value": 462
                    },
                    "hkg_chief_executive": {
                        "key": "hkg_chief_executive",
                        "label": "Chief Executive ",
                        "parent": 2,
                        "precedence": 110,
                        "rank": 1000,
                        "regions": [227],
                        "show_in_quicksearch": false,
                        "supported_regions": [227],
                        "value": 389
                    },
                    "hkg_deputy_secretary": {
                        "key": "hkg_deputy_secretary",
                        "label": "Deputy Secretary",
                        "parent": 6,
                        "precedence": 170,
                        "rank": 1000,
                        "regions": [227],
                        "show_in_quicksearch": false,
                        "supported_regions": [227],
                        "value": 392
                    },
                    "hkg_executive_branch_member": {
                        "key": "hkg_executive_branch_member",
                        "label": "Executive Branch Member",
                        "parent": 10,
                        "precedence": 275,
                        "rank": 1000,
                        "regions": [227],
                        "show_in_quicksearch": false,
                        "supported_regions": [227],
                        "value": 393
                    },
                    "hkg_executive_council_member": {
                        "key": "hkg_executive_council_member",
                        "label": "Executive Council Member",
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [227],
                        "show_in_quicksearch": false,
                        "supported_regions": [227],
                        "value": 390
                    },
                    "hkg_judge_of_the_court": {
                        "key": "hkg_judge_of_the_court",
                        "label": "Judge of the Court of Final Appeal",
                        "parent": 21,
                        "precedence": 350,
                        "rank": 1000,
                        "regions": [227],
                        "show_in_quicksearch": false,
                        "supported_regions": [227],
                        "value": 395
                    },
                    "hkg_judge_of_the_high_court": {
                        "key": "hkg_judge_of_the_high_court",
                        "label": "Judge of the High Court",
                        "parent": 22,
                        "precedence": 360,
                        "rank": 1000,
                        "regions": [227],
                        "show_in_quicksearch": false,
                        "supported_regions": [227],
                        "value": 396
                    },
                    "hkg_legislative_council_member": {
                        "key": "hkg_legislative_council_member",
                        "label": "Legislative Council Member",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [227],
                        "show_in_quicksearch": false,
                        "supported_regions": [227],
                        "value": 394
                    },
                    "hkg_permanent_secretary": {
                        "key": "hkg_permanent_secretary",
                        "label": "Permanent Secretary",
                        "parent": 5,
                        "precedence": 160,
                        "rank": 1000,
                        "regions": [227],
                        "show_in_quicksearch": false,
                        "supported_regions": [227],
                        "value": 391
                    },
                    "idn_rep": {
                        "key": "idn_rep",
                        "label": "Member of the House of Representatives",
                        "parent": 12,
                        "precedence": 250,
                        "regions": [222],
                        "show_in_quicksearch": false,
                        "supported_regions": [222],
                        "value": 545
                    },
                    "idn_rep_council_member": {
                        "key": "idn_rep_council_member",
                        "label": "Member of the Regional Representative Council",
                        "parent": 11,
                        "precedence": 200,
                        "regions": [222],
                        "show_in_quicksearch": false,
                        "supported_regions": [222],
                        "value": 544
                    },
                    "ind_cabinet_minister": {
                        "key": "ind_cabinet_minister",
                        "label": "Cabinet Minister",
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [206],
                        "show_in_quicksearch": false,
                        "supported_regions": [206],
                        "value": 126
                    },
                    "ind_chief_minister": {
                        "key": "ind_chief_minister",
                        "label": "Chief Minister",
                        "parent": 16,
                        "precedence": 310,
                        "rank": 1000,
                        "regions": [206],
                        "show_in_quicksearch": false,
                        "supported_regions": [206],
                        "value": 133
                    },
                    "ind_deputy_prime_minister": {
                        "key": "ind_deputy_prime_minister",
                        "label": "Deputy Prime Minister",
                        "parent": 3,
                        "precedence": 110,
                        "rank": 1000,
                        "regions": [206],
                        "show_in_quicksearch": false,
                        "supported_regions": [206],
                        "value": 125
                    },
                    "ind_governor": {
                        "key": "ind_governor",
                        "label": "Governor",
                        "parent": 14,
                        "precedence": 300,
                        "rank": 1000,
                        "regions": [206],
                        "show_in_quicksearch": false,
                        "supported_regions": [206],
                        "value": 131
                    },
                    "ind_high_court_justice": {
                        "key": "ind_high_court_justice",
                        "label": "High Court Chief Justice",
                        "parent": 22,
                        "precedence": 360,
                        "rank": 1000,
                        "regions": [206],
                        "show_in_quicksearch": false,
                        "supported_regions": [206],
                        "value": 135
                    },
                    "ind_lok_sabha": {
                        "key": "ind_lok_sabha",
                        "label": "Member of Parliament, Lok Sabha",
                        "parent": 12,
                        "precedence": 250,
                        "rank": 1000,
                        "regions": [206],
                        "show_in_quicksearch": false,
                        "supported_regions": [206],
                        "value": 128
                    },
                    "ind_lt_governor_administrator": {
                        "key": "ind_lt_governor_administrator",
                        "label": "Lt. Governor/Administrator",
                        "parent": 15,
                        "precedence": 305,
                        "rank": 1000,
                        "regions": [206],
                        "show_in_quicksearch": false,
                        "supported_regions": [206],
                        "value": 132
                    },
                    "ind_minister_of_state": {
                        "key": "ind_minister_of_state",
                        "label": "Minister of State",
                        "parent": 5,
                        "precedence": 160,
                        "rank": 1000,
                        "regions": [206],
                        "show_in_quicksearch": false,
                        "supported_regions": [206],
                        "value": 129
                    },
                    "ind_president": {
                        "key": "ind_president",
                        "label": "President",
                        "parent": 1,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [206],
                        "show_in_quicksearch": false,
                        "supported_regions": [206],
                        "value": 123
                    },
                    "ind_prime_minister": {
                        "key": "ind_prime_minister",
                        "label": "Prime Minister",
                        "parent": 2,
                        "precedence": 110,
                        "rank": 1000,
                        "regions": [206],
                        "show_in_quicksearch": false,
                        "supported_regions": [206],
                        "value": 124
                    },
                    "ind_rajya_sabha": {
                        "key": "ind_rajya_sabha",
                        "label": "Member of Parliament, Rajya Sabha",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [206],
                        "show_in_quicksearch": false,
                        "supported_regions": [206],
                        "value": 127
                    },
                    "ind_secretary": {
                        "key": "ind_secretary",
                        "label": "Secretary",
                        "parent": 6,
                        "precedence": 170,
                        "rank": 1000,
                        "regions": [206],
                        "show_in_quicksearch": false,
                        "supported_regions": [206],
                        "value": 130
                    },
                    "ind_supreme_court_justice": {
                        "key": "ind_supreme_court_justice",
                        "label": "Supreme Court Justice",
                        "parent": 21,
                        "precedence": 350,
                        "rank": 1000,
                        "regions": [206],
                        "show_in_quicksearch": false,
                        "supported_regions": [206],
                        "value": 134
                    },
                    "irl_judge": {
                        "key": "irl_judge",
                        "label": "Judge",
                        "parent": 21,
                        "precedence": 350,
                        "rank": 1000,
                        "regions": [215],
                        "show_in_quicksearch": false,
                        "supported_regions": [215],
                        "value": 218
                    },
                    "irl_minister": {
                        "key": "irl_minister",
                        "label": "Minister",
                        "parent": 4,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [215],
                        "show_in_quicksearch": false,
                        "supported_regions": [215],
                        "value": 214
                    },
                    "irl_minister_of_state": {
                        "key": "irl_minister_of_state",
                        "label": "Minister of State",
                        "parent": 5,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [215],
                        "show_in_quicksearch": false,
                        "supported_regions": [215],
                        "value": 217
                    },
                    "irl_president": {
                        "key": "irl_president",
                        "label": "President",
                        "parent": 1,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [215],
                        "show_in_quicksearch": false,
                        "supported_regions": [215],
                        "value": 211
                    },
                    "irl_senator": {
                        "key": "irl_senator",
                        "label": "Senator",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [215],
                        "show_in_quicksearch": false,
                        "supported_regions": [215],
                        "value": 215
                    },
                    "irl_tanaiste": {
                        "key": "irl_tanaiste",
                        "label": "Tanaiste",
                        "parent": 3,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [215],
                        "show_in_quicksearch": false,
                        "supported_regions": [215],
                        "value": 213
                    },
                    "irl_taoiseach": {
                        "key": "irl_taoiseach",
                        "label": "Taoiseach",
                        "parent": 2,
                        "precedence": 110,
                        "rank": 1000,
                        "regions": [215],
                        "show_in_quicksearch": false,
                        "supported_regions": [215],
                        "value": 212
                    },
                    "irl_td": {
                        "key": "irl_td",
                        "label": "TD",
                        "parent": 12,
                        "precedence": 250,
                        "rank": 1000,
                        "regions": [215],
                        "show_in_quicksearch": false,
                        "supported_regions": [215],
                        "value": 216
                    },
                    "isr_dep_prime_minister": {
                        "key": "isr_dep_prime_minister",
                        "label": "Deputy Prime Minister",
                        "parent": 3,
                        "precedence": 115,
                        "rank": 1000,
                        "regions": [240],
                        "show_in_quicksearch": false,
                        "supported_regions": [240],
                        "value": 558
                    },
                    "isr_knesset_member": {
                        "key": "isr_knesset_member",
                        "label": "Knesset Member",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [240],
                        "show_in_quicksearch": false,
                        "supported_regions": [240],
                        "value": 560
                    },
                    "isr_minister": {
                        "key": "isr_minister",
                        "label": "Minister",
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [240],
                        "show_in_quicksearch": false,
                        "supported_regions": [240],
                        "value": 559
                    },
                    "isr_president": {
                        "key": "isr_president",
                        "label": "President",
                        "parent": 1,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [240],
                        "show_in_quicksearch": false,
                        "supported_regions": [240],
                        "value": 556
                    },
                    "isr_prime_minister": {
                        "key": "isr_prime_minister",
                        "label": "Prime Minister",
                        "parent": 2,
                        "precedence": 110,
                        "rank": 1000,
                        "regions": [240],
                        "show_in_quicksearch": false,
                        "supported_regions": [240],
                        "value": 557
                    },
                    "ita_cabinet_minister": {
                        "key": "ita_cabinet_minister",
                        "label": "Cabinet Minister",
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [207],
                        "show_in_quicksearch": false,
                        "supported_regions": [207],
                        "value": 81
                    },
                    "ita_chief_justice": {
                        "key": "ita_chief_justice",
                        "label": "Chief Justice",
                        "parent": 21,
                        "precedence": 350,
                        "rank": 1000,
                        "regions": [207],
                        "show_in_quicksearch": false,
                        "supported_regions": [207],
                        "value": 84
                    },
                    "ita_deputy": {
                        "key": "ita_deputy",
                        "label": "Deputy",
                        "parent": 12,
                        "precedence": 250,
                        "rank": 1000,
                        "regions": [207],
                        "show_in_quicksearch": false,
                        "supported_regions": [207],
                        "value": 83
                    },
                    "ita_president": {
                        "key": "ita_president",
                        "label": "President",
                        "parent": 1,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [207],
                        "show_in_quicksearch": false,
                        "supported_regions": [207],
                        "value": 79
                    },
                    "ita_prime_minister": {
                        "key": "ita_prime_minister",
                        "label": "Prime Minister",
                        "parent": 2,
                        "precedence": 110,
                        "rank": 1000,
                        "regions": [207],
                        "show_in_quicksearch": false,
                        "supported_regions": [207],
                        "value": 80
                    },
                    "ita_regional_committee_president": {
                        "key": "ita_regional_committee_president",
                        "label": "President of Regional Committee",
                        "parent": 16,
                        "precedence": 310,
                        "rank": 1000,
                        "regions": [207],
                        "show_in_quicksearch": false,
                        "supported_regions": [207],
                        "value": 86
                    },
                    "ita_regional_councilmember": {
                        "key": "ita_regional_councilmember",
                        "label": "Regional Councilmember",
                        "parent": 20,
                        "precedence": 350,
                        "rank": 1000,
                        "regions": [207],
                        "show_in_quicksearch": false,
                        "supported_regions": [207],
                        "value": 87
                    },
                    "ita_regional_president": {
                        "key": "ita_regional_president",
                        "label": "Regional President",
                        "parent": 14,
                        "precedence": 300,
                        "rank": 1000,
                        "regions": [207],
                        "show_in_quicksearch": false,
                        "supported_regions": [207],
                        "value": 85
                    },
                    "ita_senator": {
                        "key": "ita_senator",
                        "label": "Senator",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [207],
                        "show_in_quicksearch": false,
                        "supported_regions": [207],
                        "value": 82
                    },
                    "ita_state_secretary": {
                        "key": "ita_state_secretary",
                        "label": "State Secretary",
                        "parent": 5,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [207],
                        "show_in_quicksearch": false,
                        "supported_regions": [207],
                        "value": 208
                    },
                    "ita_undersecretary": {
                        "key": "ita_undersecretary",
                        "label": "Undersecretary",
                        "parent": 6,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [207],
                        "show_in_quicksearch": false,
                        "supported_regions": [207],
                        "value": 209
                    },
                    "journalist": {
                        "key": "journalist",
                        "label": "Journalist",
                        "parent": 46,
                        "precedence": 1200,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 279
                    },
                    "jpn_council_member": {
                        "key": "jpn_council_member",
                        "label": "Member of the House of Councilors",
                        "parent": 11,
                        "precedence": 200,
                        "regions": [210],
                        "show_in_quicksearch": false,
                        "supported_regions": [210],
                        "value": 546
                    },
                    "jpn_rep": {
                        "key": "jpn_rep",
                        "label": "Member of the House of Representatives",
                        "parent": 12,
                        "precedence": 250,
                        "regions": [210],
                        "show_in_quicksearch": false,
                        "supported_regions": [210],
                        "value": 547
                    },
                    "kor_senator": {
                        "key": "kor_senator",
                        "label": "Senator",
                        "parent": 11,
                        "precedence": 200,
                        "regions": [221],
                        "show_in_quicksearch": false,
                        "supported_regions": [221],
                        "value": 552
                    },
                    "manager": {
                        "key": "manager",
                        "label": "Manager",
                        "parent": 47,
                        "precedence": 1300,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 280
                    },
                    "managing_director": {
                        "key": "managing_director",
                        "label": "Managing Director",
                        "parent": 41,
                        "precedence": 700,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 281
                    },
                    "managing_editor": {
                        "key": "managing_editor",
                        "label": "Managing Editor",
                        "parent": 40,
                        "precedence": 600,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 282
                    },
                    "media_intern": {
                        "key": "media_intern",
                        "label": "Intern",
                        "parent": 47,
                        "precedence": 1300,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 278
                    },
                    "meteorologist": {
                        "key": "meteorologist",
                        "label": "Meteorologist",
                        "parent": 47,
                        "precedence": 1300,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 283
                    },
                    "mex_deputy": {
                        "key": "mex_deputy",
                        "label": "Deputy",
                        "parent": 12,
                        "precedence": 250,
                        "rank": 1000,
                        "regions": [202],
                        "show_in_quicksearch": false,
                        "supported_regions": [202],
                        "value": 55
                    },
                    "mex_deputy_secretary": {
                        "key": "mex_deputy_secretary",
                        "label": "Deputy Secretary",
                        "parent": 5,
                        "precedence": 160,
                        "rank": 1000,
                        "regions": [202],
                        "show_in_quicksearch": false,
                        "supported_regions": [202],
                        "value": 53
                    },
                    "mex_governor": {
                        "key": "mex_governor",
                        "label": "Governor",
                        "parent": 16,
                        "precedence": 310,
                        "rank": 1000,
                        "regions": [202],
                        "show_in_quicksearch": false,
                        "supported_regions": [202],
                        "value": 56
                    },
                    "mex_minister": {
                        "key": "mex_minister",
                        "label": "Minister",
                        "parent": 21,
                        "precedence": 350,
                        "rank": 1000,
                        "regions": [202],
                        "show_in_quicksearch": false,
                        "supported_regions": [202],
                        "value": 57
                    },
                    "mex_president": {
                        "key": "mex_president",
                        "label": "President",
                        "parent": 2,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [202],
                        "show_in_quicksearch": false,
                        "supported_regions": [202],
                        "value": 50
                    },
                    "mex_secretary": {
                        "key": "mex_secretary",
                        "label": "Secretary",
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [202],
                        "show_in_quicksearch": false,
                        "supported_regions": [202],
                        "value": 52
                    },
                    "mex_senator": {
                        "key": "mex_senator",
                        "label": "Senator",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [202],
                        "show_in_quicksearch": false,
                        "supported_regions": [202],
                        "value": 54
                    },
                    "mex_vice_president": {
                        "key": "mex_vice_president",
                        "label": "Vice President",
                        "parent": 3,
                        "precedence": 110,
                        "rank": 1000,
                        "regions": [202],
                        "show_in_quicksearch": false,
                        "supported_regions": [202],
                        "value": 51
                    },
                    "mys_executive_branch_member": {
                        "key": "mys_executive_branch_member",
                        "label": "Executive Branch Member",
                        "parent": 10,
                        "precedence": 275,
                        "rank": 1000,
                        "regions": [228],
                        "show_in_quicksearch": false,
                        "supported_regions": [228],
                        "value": 402
                    },
                    "mys_head_of_government": {
                        "key": "mys_head_of_government",
                        "label": "Prime Minister",
                        "parent": 2,
                        "precedence": 110,
                        "rank": 1000,
                        "regions": [228],
                        "show_in_quicksearch": false,
                        "supported_regions": [228],
                        "value": 398
                    },
                    "mys_head_of_state": {
                        "key": "mys_head_of_state",
                        "label": "The King",
                        "parent": 1,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [228],
                        "show_in_quicksearch": false,
                        "supported_regions": [228],
                        "value": 397
                    },
                    "mys_lower_body_legislator": {
                        "key": "mys_lower_body_legislator",
                        "label": "Member of the House of Representatives",
                        "parent": 12,
                        "precedence": 250,
                        "rank": 1000,
                        "regions": [228],
                        "show_in_quicksearch": false,
                        "supported_regions": [228],
                        "value": 404
                    },
                    "mys_second_level_judge": {
                        "key": "mys_second_level_judge",
                        "label": "Second-Level Judge",
                        "parent": 22,
                        "precedence": 360,
                        "rank": 1000,
                        "regions": [228],
                        "show_in_quicksearch": false,
                        "supported_regions": [228],
                        "value": 406
                    },
                    "mys_second_level_minister": {
                        "key": "mys_second_level_minister",
                        "label": "Deputy Minister",
                        "parent": 5,
                        "precedence": 160,
                        "rank": 1000,
                        "regions": [228],
                        "show_in_quicksearch": false,
                        "supported_regions": [228],
                        "value": 400
                    },
                    "mys_third_level_minister": {
                        "key": "mys_third_level_minister",
                        "label": "Secretary General",
                        "parent": 6,
                        "precedence": 170,
                        "rank": 1000,
                        "regions": [228],
                        "show_in_quicksearch": false,
                        "supported_regions": [228],
                        "value": 401
                    },
                    "mys_top_level_judge": {
                        "key": "mys_top_level_judge",
                        "label": "Federal Court Judge",
                        "parent": 21,
                        "precedence": 350,
                        "rank": 1000,
                        "regions": [228],
                        "show_in_quicksearch": false,
                        "supported_regions": [228],
                        "value": 405
                    },
                    "mys_top_level_minister": {
                        "key": "mys_top_level_minister",
                        "label": "Minister",
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [228],
                        "show_in_quicksearch": false,
                        "supported_regions": [228],
                        "value": 399
                    },
                    "mys_upper_body_legislator": {
                        "key": "mys_upper_body_legislator",
                        "label": "Senator",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [228],
                        "show_in_quicksearch": false,
                        "supported_regions": [228],
                        "value": 403
                    },
                    "news_assistant": {
                        "key": "news_assistant",
                        "label": "News Assistant",
                        "parent": 45,
                        "precedence": 1100,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 284
                    },
                    "nld_member": {
                        "key": "nld_member",
                        "label": "Member",
                        "parent": 12,
                        "precedence": 250,
                        "regions": [219],
                        "show_in_quicksearch": false,
                        "supported_regions": [219],
                        "value": 549
                    },
                    "nld_senator": {
                        "key": "nld_senator",
                        "label": "Senator",
                        "parent": 11,
                        "precedence": 200,
                        "regions": [219],
                        "show_in_quicksearch": false,
                        "supported_regions": [219],
                        "value": 548
                    },
                    "nzl_deputy_prime_minister": {
                        "key": "nzl_deputy_prime_minister",
                        "label": "Deputy Prime Minister",
                        "parent": 3,
                        "precedence": 115,
                        "rank": 1000,
                        "regions": [231],
                        "show_in_quicksearch": false,
                        "supported_regions": [231],
                        "value": 428
                    },
                    "nzl_executive_branch_member": {
                        "key": "nzl_executive_branch_member",
                        "label": "Executive Branch Member",
                        "parent": 10,
                        "precedence": 275,
                        "rank": 1000,
                        "regions": [231],
                        "show_in_quicksearch": false,
                        "supported_regions": [231],
                        "value": 432
                    },
                    "nzl_executive_council_member": {
                        "key": "nzl_executive_council_member",
                        "label": "Executive Council Member",
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [231],
                        "show_in_quicksearch": false,
                        "supported_regions": [231],
                        "value": 429
                    },
                    "nzl_governor_general": {
                        "key": "nzl_governor_general",
                        "label": "Governor General ",
                        "parent": 1,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [231],
                        "show_in_quicksearch": false,
                        "supported_regions": [231],
                        "value": 426
                    },
                    "nzl_judge_of_court_of_appeal": {
                        "key": "nzl_judge_of_court_of_appeal",
                        "label": "Judge of the Court of Appeal ",
                        "parent": 22,
                        "precedence": 360,
                        "rank": 1000,
                        "regions": [231],
                        "show_in_quicksearch": false,
                        "supported_regions": [231],
                        "value": 436
                    },
                    "nzl_judge_of_supreme_court": {
                        "key": "nzl_judge_of_supreme_court",
                        "label": "Judge of the Supreme Court",
                        "parent": 21,
                        "precedence": 350,
                        "rank": 1000,
                        "regions": [231],
                        "show_in_quicksearch": false,
                        "supported_regions": [231],
                        "value": 435
                    },
                    "nzl_member_of_parliament": {
                        "key": "nzl_member_of_parliament",
                        "label": "Member of Parliament",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [231],
                        "show_in_quicksearch": false,
                        "supported_regions": [231],
                        "value": 434
                    },
                    "nzl_organization_staffer": {
                        "key": "nzl_organization_staffer",
                        "label": "Organization Staffer",
                        "parent": 24,
                        "precedence": 400,
                        "rank": 1000,
                        "regions": [231],
                        "show_in_quicksearch": false,
                        "supported_regions": [231],
                        "value": 433
                    },
                    "nzl_parliamentary_under_secretary": {
                        "key": "nzl_parliamentary_under_secretary",
                        "label": "Parliamentary Under-Secretary",
                        "parent": 5,
                        "precedence": 160,
                        "rank": 1000,
                        "regions": [231],
                        "show_in_quicksearch": false,
                        "supported_regions": [231],
                        "value": 430
                    },
                    "nzl_prime_minister": {
                        "key": "nzl_prime_minister",
                        "label": "Prime Minister",
                        "parent": 2,
                        "precedence": 110,
                        "rank": 1000,
                        "regions": [231],
                        "show_in_quicksearch": false,
                        "supported_regions": [231],
                        "value": 427
                    },
                    "nzl_public_service_leader": {
                        "key": "nzl_public_service_leader",
                        "label": "Public Service Leader",
                        "parent": 6,
                        "precedence": 170,
                        "rank": 1000,
                        "regions": [231],
                        "show_in_quicksearch": false,
                        "supported_regions": [231],
                        "value": 431
                    },
                    "on_air_personality": {
                        "key": "on_air_personality",
                        "label": "On Air Personality",
                        "parent": 47,
                        "precedence": 1300,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 285
                    },
                    "opinion_editor": {
                        "key": "opinion_editor",
                        "label": "Opinion Editor",
                        "parent": 40,
                        "precedence": 600,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 286
                    },
                    "organization_staff_minor": {
                        "key": "organization_staff_minor",
                        "label": "Organization Staffer",
                        "parent": 24,
                        "precedence": 400,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 157
                    },
                    "pan_deputy_minister": {
                        "key": "pan_deputy_minister",
                        "label": "Deputy Minister",
                        "parent": 5,
                        "precedence": 160,
                        "rank": 1000,
                        "regions": [236],
                        "show_in_quicksearch": false,
                        "supported_regions": [236],
                        "value": 478
                    },
                    "pan_executive_branch_member": {
                        "key": "pan_executive_branch_member",
                        "label": "Executive Branch Member",
                        "parent": 10,
                        "precedence": 275,
                        "rank": 1000,
                        "regions": [236],
                        "show_in_quicksearch": false,
                        "supported_regions": [236],
                        "value": 479
                    },
                    "pan_member_of_national_assembly": {
                        "key": "pan_member_of_national_assembly",
                        "label": "Member of the National Assembly",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [236],
                        "show_in_quicksearch": false,
                        "supported_regions": [236],
                        "value": 476
                    },
                    "pan_minister": {
                        "key": "pan_minister",
                        "label": "Minister",
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [236],
                        "show_in_quicksearch": false,
                        "supported_regions": [236],
                        "value": 477
                    },
                    "pan_president": {
                        "key": "pan_president",
                        "label": "President",
                        "parent": 2,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [236],
                        "show_in_quicksearch": false,
                        "supported_regions": [236],
                        "value": 474
                    },
                    "pan_vice_president": {
                        "key": "pan_vice_president",
                        "label": "Vice President",
                        "parent": 3,
                        "precedence": 115,
                        "rank": 1000,
                        "regions": [236],
                        "show_in_quicksearch": false,
                        "supported_regions": [236],
                        "value": 475
                    },
                    "personal_staff_minor": {
                        "key": "personal_staff_minor",
                        "label": "Personal Staffer",
                        "parent": 23,
                        "precedence": 400,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 156
                    },
                    "phl_executive_branch_member": {
                        "key": "phl_executive_branch_member",
                        "label": "Executive Branch Member",
                        "parent": 10,
                        "precedence": 275,
                        "rank": 1000,
                        "regions": [229],
                        "show_in_quicksearch": false,
                        "supported_regions": [229],
                        "value": 412
                    },
                    "phl_house_member": {
                        "key": "phl_house_member",
                        "label": "House Member",
                        "parent": 12,
                        "precedence": 250,
                        "rank": 1000,
                        "regions": [229],
                        "show_in_quicksearch": false,
                        "supported_regions": [229],
                        "value": 414
                    },
                    "phl_judge": {
                        "key": "phl_judge",
                        "label": "Judge",
                        "parent": 22,
                        "precedence": 360,
                        "rank": 1000,
                        "regions": [229],
                        "show_in_quicksearch": false,
                        "supported_regions": [229],
                        "value": 416
                    },
                    "phl_president": {
                        "key": "phl_president",
                        "label": "President",
                        "parent": 1,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [229],
                        "show_in_quicksearch": false,
                        "supported_regions": [229],
                        "value": 408
                    },
                    "phl_secretary": {
                        "key": "phl_secretary",
                        "label": "Secretary",
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [229],
                        "show_in_quicksearch": false,
                        "supported_regions": [229],
                        "value": 410
                    },
                    "phl_senator": {
                        "key": "phl_senator",
                        "label": "Senator",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [229],
                        "show_in_quicksearch": false,
                        "supported_regions": [229],
                        "value": 413
                    },
                    "phl_supreme_court_justice": {
                        "key": "phl_supreme_court_justice",
                        "label": "Supreme Court Justice",
                        "parent": 21,
                        "precedence": 350,
                        "rank": 1000,
                        "regions": [229],
                        "show_in_quicksearch": false,
                        "supported_regions": [229],
                        "value": 415
                    },
                    "phl_undersecretary": {
                        "key": "phl_undersecretary",
                        "label": "Undersecretary",
                        "parent": 5,
                        "precedence": 160,
                        "rank": 1000,
                        "regions": [229],
                        "show_in_quicksearch": false,
                        "supported_regions": [229],
                        "value": 411
                    },
                    "phl_vice_president": {
                        "key": "phl_vice_president",
                        "label": "Vice President ",
                        "parent": 3,
                        "precedence": 115,
                        "rank": 1000,
                        "regions": [229],
                        "show_in_quicksearch": false,
                        "supported_regions": [229],
                        "value": 409
                    },
                    "photo_editor": {
                        "key": "photo_editor",
                        "label": "Photo Editor",
                        "parent": 40,
                        "precedence": 600,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 287
                    },
                    "podcaster": {
                        "key": "podcaster",
                        "label": "Podcaster",
                        "parent": 47,
                        "precedence": 1300,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 372
                    },
                    "pol_deputy": {
                        "key": "pol_deputy",
                        "label": "Deputy",
                        "parent": 12,
                        "precedence": 250,
                        "regions": [216],
                        "show_in_quicksearch": false,
                        "supported_regions": [216],
                        "value": 551
                    },
                    "pol_party_member": {
                        "description": "Member of a Political Party",
                        "key": "pol_party_member",
                        "label": "Political Party Member",
                        "parent": 29,
                        "precedence": 990,
                        "rank": 1000,
                        "regions": [200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241],
                        "show_in_quicksearch": false,
                        "supported_regions": [200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241],
                        "value": 159
                    },
                    "pol_senator": {
                        "key": "pol_senator",
                        "label": "Senator",
                        "parent": 11,
                        "precedence": 200,
                        "regions": [216],
                        "show_in_quicksearch": false,
                        "supported_regions": [216],
                        "value": 550
                    },
                    "producer": {
                        "key": "producer",
                        "label": "Producer",
                        "parent": 42,
                        "precedence": 800,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 288
                    },
                    "production_assistant": {
                        "key": "production_assistant",
                        "label": "Production Assistant",
                        "parent": 45,
                        "precedence": 1100,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 289
                    },
                    "production_coordinator": {
                        "key": "production_coordinator",
                        "label": "Production Coordinator",
                        "parent": 47,
                        "precedence": 1300,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 290
                    },
                    "production_director": {
                        "key": "production_director",
                        "label": "Production Director",
                        "parent": 41,
                        "precedence": 700,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 291
                    },
                    "program_director": {
                        "key": "program_director",
                        "label": "Program Director",
                        "parent": 41,
                        "precedence": 700,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 292
                    },
                    "program_manager": {
                        "key": "program_manager",
                        "label": "Program Manager",
                        "parent": 47,
                        "precedence": 1300,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 293
                    },
                    "public_affairs_director": {
                        "key": "public_affairs_director",
                        "label": "Public Affairs Director",
                        "parent": 41,
                        "precedence": 700,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 294
                    },
                    "public_service_announcement_director": {
                        "key": "public_service_announcement_director",
                        "label": "Public Service Announcement Director",
                        "parent": 41,
                        "precedence": 700,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 295
                    },
                    "publisher": {
                        "key": "publisher",
                        "label": "Publisher",
                        "parent": 44,
                        "precedence": 1000,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 296
                    },
                    "reporter": {
                        "key": "reporter",
                        "label": "Reporter",
                        "parent": 46,
                        "precedence": 1200,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 297
                    },
                    "research_assistant": {
                        "key": "research_assistant",
                        "label": "Research Assistant",
                        "parent": 45,
                        "precedence": 1100,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 298
                    },
                    "research_associate": {
                        "key": "research_associate",
                        "label": "Research Associate",
                        "parent": 47,
                        "precedence": 1300,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 299
                    },
                    "research_director": {
                        "key": "research_director",
                        "label": "Research Director",
                        "parent": 41,
                        "precedence": 700,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 300
                    },
                    "research_manager": {
                        "key": "research_manager",
                        "label": "Research Manager",
                        "parent": 47,
                        "precedence": 1300,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 301
                    },
                    "reviewer": {
                        "key": "reviewer",
                        "label": "Reviewer",
                        "parent": 47,
                        "precedence": 1300,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 302
                    },
                    "role_groups": {
                        "exec_staff": [163, 20],
                        "execs_all": [11, 12, 13, 14, 15, 16, 17, 21, 22, 186, 187, 189, 191, 193, 194, 196, 453, 198, 489, 504],
                        "execs_federal": [11, 12, 13, 14, 15, 16, 17, 489],
                        "execs_local": [198, 191, 186, 193, 187, 194, 189, 196, 504],
                        "execs_states": [21, 22, 453, 17],
                        "leg_staff": [30, 160],
                        "legislators_all": [5, 6, 7, 8, 9, 199],
                        "legislators_federal": [5, 6],
                        "legislators_local": [9, 199],
                        "legislators_states": [7, 8]
                    },
                    "rou_deputy": {
                        "key": "rou_deputy",
                        "label": "Deputy",
                        "parent": 12,
                        "precedence": 250,
                        "rank": 1000,
                        "regions": [237],
                        "show_in_quicksearch": false,
                        "supported_regions": [237],
                        "value": 488
                    },
                    "rou_deputy_prime_minister": {
                        "key": "rou_deputy_prime_minister",
                        "label": "Deputy Prime Minister",
                        "parent": 3,
                        "precedence": 115,
                        "rank": 1000,
                        "regions": [237],
                        "show_in_quicksearch": false,
                        "supported_regions": [237],
                        "value": 482
                    },
                    "rou_executive_branch_member": {
                        "key": "rou_executive_branch_member",
                        "label": "Executive Branch Member",
                        "parent": 10,
                        "precedence": 275,
                        "rank": 1000,
                        "regions": [237],
                        "show_in_quicksearch": false,
                        "supported_regions": [237],
                        "value": 486
                    },
                    "rou_minister": {
                        "key": "rou_minister",
                        "label": "Minister",
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [237],
                        "show_in_quicksearch": false,
                        "supported_regions": [237],
                        "value": 483
                    },
                    "rou_president": {
                        "key": "rou_president",
                        "label": "President",
                        "parent": 1,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [237],
                        "show_in_quicksearch": false,
                        "supported_regions": [237],
                        "value": 480
                    },
                    "rou_prime_minister": {
                        "key": "rou_prime_minister",
                        "label": "Prime Minister",
                        "parent": 2,
                        "precedence": 110,
                        "rank": 1000,
                        "regions": [237],
                        "show_in_quicksearch": false,
                        "supported_regions": [237],
                        "value": 481
                    },
                    "rou_secretary_of_state": {
                        "key": "rou_secretary_of_state",
                        "label": "Secretary of State",
                        "parent": 5,
                        "precedence": 160,
                        "rank": 1000,
                        "regions": [237],
                        "show_in_quicksearch": false,
                        "supported_regions": [237],
                        "value": 484
                    },
                    "rou_senator": {
                        "key": "rou_senator",
                        "label": "Senator",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [237],
                        "show_in_quicksearch": false,
                        "supported_regions": [237],
                        "value": 487
                    },
                    "rou_third_level_minister": {
                        "key": "rou_third_level_minister",
                        "label": "Third-Level Minister",
                        "parent": 6,
                        "precedence": 170,
                        "rank": 1000,
                        "regions": [237],
                        "show_in_quicksearch": false,
                        "supported_regions": [237],
                        "value": 485
                    },
                    "sau_council_member": {
                        "key": "sau_council_member",
                        "label": "Council Member",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [212],
                        "show_in_quicksearch": false,
                        "supported_regions": [212],
                        "value": 61
                    },
                    "sau_crown_prince": {
                        "key": "sau_crown_prince",
                        "label": "Crown Prince",
                        "parent": 3,
                        "precedence": 110,
                        "rank": 1000,
                        "regions": [212],
                        "show_in_quicksearch": false,
                        "supported_regions": [212],
                        "value": 59
                    },
                    "sau_king": {
                        "key": "sau_king",
                        "label": "King",
                        "parent": 2,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [212],
                        "show_in_quicksearch": false,
                        "supported_regions": [212],
                        "value": 58
                    },
                    "sau_minister": {
                        "key": "sau_minister",
                        "label": "Minister",
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [212],
                        "show_in_quicksearch": false,
                        "supported_regions": [212],
                        "value": 60
                    },
                    "sau_qadi": {
                        "key": "sau_qadi",
                        "label": "Qadi",
                        "parent": 21,
                        "precedence": 350,
                        "rank": 1000,
                        "regions": [212],
                        "show_in_quicksearch": false,
                        "supported_regions": [212],
                        "value": 62
                    },
                    "school_board_assistant_superintendent": {
                        "key": "school_board_assistant_superintendent",
                        "label": "School Board Assistant Superintendent",
                        "parent": 58,
                        "precedence": 910,
                        "rank": 1000,
                        "regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "value": 572
                    },
                    "school_board_member": {
                        "key": "school_board_member",
                        "label": "School Board Member / Officer",
                        "parent": 58,
                        "precedence": 920,
                        "rank": 1000,
                        "regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "value": 504
                    },
                    "school_board_superintendent": {
                        "key": "school_board_superintendent",
                        "label": "School Board Superintendent",
                        "parent": 58,
                        "precedence": 900,
                        "rank": 1000,
                        "regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "value": 571
                    },
                    "scientist": {
                        "key": "scientist",
                        "label": "Scientist",
                        "parent": 47,
                        "precedence": 1300,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 303
                    },
                    "segment_producer": {
                        "key": "segment_producer",
                        "label": "Segment Producer",
                        "parent": 42,
                        "precedence": 800,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 304
                    },
                    "sgp_deputy_prime_minister": {
                        "key": "sgp_deputy_prime_minister",
                        "label": "Deputy Prime Minister",
                        "parent": 3,
                        "precedence": 115,
                        "rank": 1000,
                        "regions": [223],
                        "show_in_quicksearch": false,
                        "supported_regions": [223],
                        "value": 317
                    },
                    "sgp_members_of_parliament": {
                        "key": "sgp_members_of_parliament",
                        "label": "Members of Parliament",
                        "parent": 12,
                        "precedence": 250,
                        "rank": 1000,
                        "regions": [223],
                        "show_in_quicksearch": false,
                        "supported_regions": [223],
                        "value": 321
                    },
                    "sgp_minister_and_council_member": {
                        "key": "sgp_minister_and_council_member",
                        "label": "Ministers and Council Members",
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [223],
                        "show_in_quicksearch": false,
                        "supported_regions": [223],
                        "value": 318
                    },
                    "sgp_permanent_secretary": {
                        "key": "sgp_permanent_secretary",
                        "label": "Permanent Secretary",
                        "parent": 5,
                        "precedence": 160,
                        "rank": 1000,
                        "regions": [223],
                        "show_in_quicksearch": false,
                        "supported_regions": [223],
                        "value": 319
                    },
                    "sgp_president": {
                        "key": "sgp_president",
                        "label": "President",
                        "parent": 1,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [223],
                        "show_in_quicksearch": false,
                        "supported_regions": [223],
                        "value": 315
                    },
                    "sgp_prime_minister": {
                        "key": "sgp_prime_minister",
                        "label": "Prime Minister",
                        "parent": 2,
                        "precedence": 110,
                        "rank": 1000,
                        "regions": [223],
                        "show_in_quicksearch": false,
                        "supported_regions": [223],
                        "value": 316
                    },
                    "sgp_senator": {
                        "key": "sgp_senator",
                        "label": "Senator",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [223],
                        "show_in_quicksearch": false,
                        "supported_regions": [223],
                        "value": 322
                    },
                    "sgp_senior_advisor": {
                        "key": "sgp_senior_advisor",
                        "label": "Senior Advisor",
                        "parent": 6,
                        "precedence": 170,
                        "rank": 1000,
                        "regions": [223],
                        "show_in_quicksearch": false,
                        "supported_regions": [223],
                        "value": 320
                    },
                    "sr_eu_correspondent": {
                        "key": "sr_eu_correspondent",
                        "label": "Senior European Correspondent",
                        "parent": 46,
                        "precedence": 1200,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 407
                    },
                    "staff_writer": {
                        "key": "staff_writer",
                        "label": "Staff Writer",
                        "parent": 43,
                        "precedence": 900,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 305
                    },
                    "station_manager": {
                        "key": "station_manager",
                        "label": "Station Manager",
                        "parent": 47,
                        "precedence": 1300,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 306
                    },
                    "sub_editor": {
                        "key": "sub_editor",
                        "label": "Sub Editor",
                        "parent": 40,
                        "precedence": 600,
                        "rank": 1000,
                        "regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 307
                    },
                    "swe_parl_member": {
                        "key": "swe_parl_member",
                        "label": "Member of Parliament",
                        "parent": 11,
                        "precedence": 200,
                        "regions": [218],
                        "show_in_quicksearch": false,
                        "supported_regions": [218],
                        "value": 553
                    },
                    "tha_rep": {
                        "key": "tha_rep",
                        "label": "Representative",
                        "parent": 12,
                        "precedence": 250,
                        "regions": [220],
                        "show_in_quicksearch": false,
                        "supported_regions": [220],
                        "value": 555
                    },
                    "tha_senator": {
                        "key": "tha_senator",
                        "label": "Senator",
                        "parent": 11,
                        "precedence": 200,
                        "regions": [220],
                        "show_in_quicksearch": false,
                        "supported_regions": [220],
                        "value": 554
                    },
                    "tur_branch_member": {
                        "key": "tur_branch_member",
                        "label": "Branch Manager",
                        "parent": 10,
                        "precedence": 275,
                        "regions": [239],
                        "show_in_quicksearch": false,
                        "supported_regions": [239],
                        "value": 518
                    },
                    "tur_chief_exec": {
                        "key": "tur_chief_exec",
                        "label": "Chief Executive ",
                        "parent": 5,
                        "precedence": 160,
                        "regions": [239],
                        "show_in_quicksearch": false,
                        "supported_regions": [239],
                        "value": 512
                    },
                    "tur_dep_chief_exec": {
                        "key": "tur_dep_chief_exec",
                        "label": "Deputy Chief Executive",
                        "parent": 5,
                        "precedence": 160,
                        "regions": [239],
                        "show_in_quicksearch": false,
                        "supported_regions": [239],
                        "value": 513
                    },
                    "tur_dep_dir_gen": {
                        "key": "tur_dep_dir_gen",
                        "label": "Deputy Director General",
                        "parent": 5,
                        "precedence": 160,
                        "regions": [239],
                        "show_in_quicksearch": false,
                        "supported_regions": [239],
                        "value": 511
                    },
                    "tur_dep_head_of_unit": {
                        "key": "tur_dep_head_of_unit",
                        "label": "Deputy Head of Unit",
                        "parent": 6,
                        "precedence": 170,
                        "regions": [239],
                        "show_in_quicksearch": false,
                        "supported_regions": [239],
                        "value": 516
                    },
                    "tur_dep_minister": {
                        "key": "tur_dep_minister",
                        "label": "Deputy Minister",
                        "parent": 4,
                        "precedence": 150,
                        "regions": [239],
                        "show_in_quicksearch": false,
                        "supported_regions": [239],
                        "value": 509
                    },
                    "tur_dir_gen": {
                        "key": "tur_dir_gen",
                        "label": "Director General",
                        "parent": 5,
                        "precedence": 160,
                        "regions": [239],
                        "show_in_quicksearch": false,
                        "supported_regions": [239],
                        "value": 510
                    },
                    "tur_director": {
                        "key": "tur_director",
                        "label": "Director",
                        "parent": 6,
                        "precedence": 170,
                        "regions": [239],
                        "show_in_quicksearch": false,
                        "supported_regions": [239],
                        "value": 517
                    },
                    "tur_head_of_department": {
                        "key": "tur_head_of_department",
                        "label": "Head of Department",
                        "parent": 6,
                        "precedence": 170,
                        "regions": [239],
                        "show_in_quicksearch": false,
                        "supported_regions": [239],
                        "value": 514
                    },
                    "tur_head_of_government": {
                        "key": "tur_head_of_government",
                        "label": "President",
                        "parent": 2,
                        "precedence": 110,
                        "rank": 1000,
                        "regions": [239],
                        "show_in_quicksearch": false,
                        "supported_regions": [239],
                        "value": 506
                    },
                    "tur_head_of_state": {
                        "key": "tur_head_of_state",
                        "label": "President",
                        "parent": 1,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [239],
                        "show_in_quicksearch": false,
                        "supported_regions": [239],
                        "value": 505
                    },
                    "tur_head_of_unit": {
                        "key": "tur_head_of_unit",
                        "label": "Head of Unit",
                        "parent": 6,
                        "precedence": 170,
                        "regions": [239],
                        "show_in_quicksearch": false,
                        "supported_regions": [239],
                        "value": 515
                    },
                    "tur_leg_chamber_leader": {
                        "key": "tur_leg_chamber_leader",
                        "label": "Speaker / Vice-Speaker",
                        "parent": 54,
                        "precedence": 385,
                        "regions": [239],
                        "show_in_quicksearch": false,
                        "supported_regions": [239],
                        "value": 521
                    },
                    "tur_member_of_parl": {
                        "key": "tur_member_of_parl",
                        "label": "Member of Parliament",
                        "parent": 11,
                        "precedence": 200,
                        "regions": [239],
                        "show_in_quicksearch": false,
                        "supported_regions": [239],
                        "value": 520
                    },
                    "tur_minister": {
                        "key": "tur_minister",
                        "label": "Minister",
                        "parent": 4,
                        "precedence": 150,
                        "regions": [239],
                        "show_in_quicksearch": false,
                        "supported_regions": [239],
                        "value": 508
                    },
                    "tur_supervisor": {
                        "key": "tur_supervisor",
                        "label": "Supervisor",
                        "parent": 10,
                        "precedence": 275,
                        "regions": [239],
                        "show_in_quicksearch": false,
                        "supported_regions": [239],
                        "value": 519
                    },
                    "tur_vice_president": {
                        "key": "tur_vice_president",
                        "label": "Vice President",
                        "parent": 3,
                        "precedence": 115,
                        "rank": 1000,
                        "regions": [239],
                        "show_in_quicksearch": false,
                        "supported_regions": [239],
                        "value": 507
                    },
                    "twn_chief_judge": {
                        "key": "twn_chief_judge",
                        "label": "Chief Judge",
                        "parent": 22,
                        "precedence": 360,
                        "rank": 1000,
                        "regions": [225],
                        "show_in_quicksearch": false,
                        "supported_regions": [225],
                        "value": 358
                    },
                    "twn_executive_branch_member": {
                        "key": "twn_executive_branch_member",
                        "label": "Executive Branch Member",
                        "parent": 10,
                        "precedence": 275,
                        "rank": 1000,
                        "regions": [225],
                        "show_in_quicksearch": false,
                        "supported_regions": [225],
                        "value": 355
                    },
                    "twn_executive_yuan_deputy_minister": {
                        "key": "twn_executive_yuan_deputy_minister",
                        "label": "Executive Yuan Deputy Minister",
                        "parent": 5,
                        "precedence": 160,
                        "rank": 1000,
                        "regions": [225],
                        "show_in_quicksearch": false,
                        "supported_regions": [225],
                        "value": 354
                    },
                    "twn_judicial_yuan_principal_officer_and_justice": {
                        "key": "twn_judicial_yuan_principal_officer_and_justice",
                        "label": "Judicial Yuan Principal Officer and Justice",
                        "parent": 21,
                        "precedence": 350,
                        "rank": 1000,
                        "regions": [225],
                        "show_in_quicksearch": false,
                        "supported_regions": [225],
                        "value": 357
                    },
                    "twn_legislator": {
                        "key": "twn_legislator",
                        "label": "Legislator",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [225],
                        "show_in_quicksearch": false,
                        "supported_regions": [225],
                        "value": 352
                    },
                    "twn_premier": {
                        "key": "twn_premier",
                        "label": "Premier",
                        "parent": 2,
                        "precedence": 110,
                        "rank": 1000,
                        "regions": [225],
                        "show_in_quicksearch": false,
                        "supported_regions": [225],
                        "value": 350
                    },
                    "twn_president": {
                        "key": "twn_president",
                        "label": "President",
                        "parent": 1,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [225],
                        "show_in_quicksearch": false,
                        "supported_regions": [225],
                        "value": 349
                    },
                    "twn_state_executive": {
                        "key": "twn_state_executive",
                        "label": "State Executive",
                        "parent": 14,
                        "precedence": 300,
                        "rank": 1000,
                        "regions": [225],
                        "show_in_quicksearch": false,
                        "supported_regions": [225],
                        "value": 356
                    },
                    "twn_vice_president": {
                        "key": "twn_vice_president",
                        "label": "Vice-President",
                        "parent": 3,
                        "precedence": 115,
                        "rank": 1000,
                        "regions": [225],
                        "show_in_quicksearch": false,
                        "supported_regions": [225],
                        "value": 351
                    },
                    "twn_yuan_principal_officer": {
                        "key": "twn_yuan_principal_officer",
                        "label": "Yuan Principal Officer",
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [225],
                        "show_in_quicksearch": false,
                        "supported_regions": [225],
                        "value": 353
                    },
                    "uae_chairman": {
                        "key": "uae_chairman",
                        "label": "Chairman / CEO",
                        "parent": 17,
                        "precedence": 300,
                        "rank": 1000,
                        "regions": [241],
                        "show_in_quicksearch": false,
                        "supported_regions": [241],
                        "value": 570
                    },
                    "uae_council_member": {
                        "key": "uae_council_member",
                        "label": "Council Member",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [241],
                        "show_in_quicksearch": false,
                        "supported_regions": [241],
                        "value": 567
                    },
                    "uae_deputy_prime_minister": {
                        "key": "uae_deputy_prime_minister",
                        "label": "Deputy Prime Minister",
                        "parent": 3,
                        "precedence": 115,
                        "rank": 1000,
                        "regions": [241],
                        "show_in_quicksearch": false,
                        "supported_regions": [241],
                        "value": 564
                    },
                    "uae_deputy_ruler": {
                        "key": "uae_deputy_ruler",
                        "label": "Deputy Ruler",
                        "parent": 15,
                        "precedence": 255,
                        "rank": 1000,
                        "regions": [241],
                        "show_in_quicksearch": false,
                        "supported_regions": [241],
                        "value": 569
                    },
                    "uae_minister": {
                        "key": "uae_minister",
                        "label": "Minister",
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [241],
                        "show_in_quicksearch": false,
                        "supported_regions": [241],
                        "value": 565
                    },
                    "uae_minister_of_state": {
                        "key": "uae_minister_of_state",
                        "label": "Minister of State",
                        "parent": 5,
                        "precedence": 155,
                        "rank": 1000,
                        "regions": [241],
                        "show_in_quicksearch": false,
                        "supported_regions": [241],
                        "value": 566
                    },
                    "uae_president": {
                        "key": "uae_president",
                        "label": "President",
                        "parent": 1,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [241],
                        "show_in_quicksearch": false,
                        "supported_regions": [241],
                        "value": 561
                    },
                    "uae_prime_minister": {
                        "key": "uae_prime_minister",
                        "label": "Prime Minister",
                        "parent": 2,
                        "precedence": 110,
                        "rank": 1000,
                        "regions": [241],
                        "show_in_quicksearch": false,
                        "supported_regions": [241],
                        "value": 563
                    },
                    "uae_ruler": {
                        "key": "uae_ruler",
                        "label": "Ruler",
                        "parent": 14,
                        "precedence": 250,
                        "rank": 1000,
                        "regions": [241],
                        "show_in_quicksearch": false,
                        "supported_regions": [241],
                        "value": 568
                    },
                    "uae_vice_president": {
                        "key": "uae_vice_president",
                        "label": "Vice President",
                        "parent": 59,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [241],
                        "show_in_quicksearch": false,
                        "supported_regions": [241],
                        "value": 562
                    },
                    "us_ambassador": {
                        "key": "us_ambassador",
                        "label": "Ambassador",
                        "parent": 57,
                        "precedence": 400,
                        "rank": 1000,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 10000],
                        "value": 489
                    },
                    "us_assistant_secretary": {
                        "key": "us_assistant_secretary",
                        "kw_code": "ASSTSEC",
                        "label": "Assistant Secretary",
                        "order": 12,
                        "parent": 6,
                        "precedence": 305,
                        "rank": 1000,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 10000],
                        "unique_identifier": "ASSTSEC",
                        "value": 13
                    },
                    "us_county_legislator": {
                        "key": "us_county_legislator",
                        "kw_code": "MEM",
                        "label": "County Legislator",
                        "order": 5,
                        "parent": 26,
                        "precedence": 930,
                        "rank": 1000,
                        "regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "show_in_quicksearch": false,
                        "source_enum": "RoleType",
                        "source_enum_value": 23,
                        "supported_regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "unique_identifier": "CL",
                        "value": 9
                    },
                    "us_dept_secretary": {
                        "key": "us_dept_secretary",
                        "kw_code": "SEC",
                        "label": "Department Secretary",
                        "order": 10,
                        "parent": 4,
                        "precedence": 150,
                        "rank": 18,
                        "regions": [1, 10000],
                        "show_in_quicksearch": true,
                        "supported_regions": [1, 10000],
                        "unique_identifier": "SEC",
                        "value": 11
                    },
                    "us_deputy_assistant_secretary": {
                        "key": "us_deputy_assistant_secretary",
                        "kw_code": "DEPASSTSEC",
                        "label": "Deputy Assistant Secretary",
                        "order": 13,
                        "parent": 7,
                        "precedence": 310,
                        "rank": 1000,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 10000],
                        "unique_identifier": "DEPASSTSEC",
                        "value": 14
                    },
                    "us_deputy_secretary": {
                        "key": "us_deputy_secretary",
                        "kw_code": "DEPSEC",
                        "label": "Deputy Secretary",
                        "order": 11,
                        "parent": 5,
                        "precedence": 300,
                        "rank": 30,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 10000],
                        "unique_identifier": "DEPSEC",
                        "value": 12
                    },
                    "us_deputy_under_secretary": {
                        "key": "us_deputy_under_secretary",
                        "kw_code": "DEPUNDSEC",
                        "label": "Deputy Under Secretary",
                        "order": 15,
                        "parent": 9,
                        "precedence": 320,
                        "rank": 1000,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 10000],
                        "unique_identifier": "DEPUNDSEC",
                        "value": 16
                    },
                    "us_ebm": {
                        "description": "\n            Individuals that work within the federal executive branch, could not be classified\n            into any other category and should have a high precedence.\n        ",
                        "key": "us_ebm",
                        "label": "Executive Member",
                        "parent": 10,
                        "precedence": 340,
                        "rank": 1000,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 10000],
                        "value": 18
                    },
                    "us_fed_caucus_staff": {
                        "key": "us_fed_caucus_staff",
                        "label": "Federal Caucus Staff",
                        "parent": 24,
                        "precedence": 540,
                        "rank": 1000,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 10000],
                        "value": 178
                    },
                    "us_fed_committee_staff": {
                        "key": "us_fed_committee_staff",
                        "label": "Federal Committee Staff",
                        "parent": 24,
                        "precedence": 520,
                        "rank": 1000,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "source_enum": "StafferType",
                        "source_enum_value": 3,
                        "supported_regions": [1, 10000],
                        "value": 32
                    },
                    "us_fed_exec_can_staff": {
                        "key": "us_fed_exec_can_staff",
                        "label": "Federal Executive Candidate Staff",
                        "parent": 24,
                        "precedence": 800,
                        "rank": 1000,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 10000],
                        "value": 166
                    },
                    "us_fed_exec_other": {
                        "description": "\n            Individuals that work within the federal executive branch, could not be classified\n            into any other category and should have a low precedence\n        ",
                        "key": "us_fed_exec_other",
                        "label": "Federal Executive Other",
                        "parent": 50,
                        "precedence": 990,
                        "rank": 1000,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 10000],
                        "value": 313
                    },
                    "us_fed_exec_staff": {
                        "key": "us_fed_exec_staff",
                        "label": "Federal Executive Staff",
                        "parent": 24,
                        "precedence": 500,
                        "rank": 1000,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "source_enum": "StafferType",
                        "source_enum_value": 4,
                        "supported_regions": [1, 10000],
                        "value": 33
                    },
                    "us_fed_house_can": {
                        "key": "us_fed_house_can",
                        "label": "Candidate for US House of Representatives",
                        "parent": 28,
                        "precedence": 715,
                        "rank": 1000,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 10000],
                        "value": 201
                    },
                    "us_fed_house_officer": {
                        "key": "us_fed_house_officer",
                        "label": "House Officer",
                        "parent": 12,
                        "precedence": 275,
                        "rank": 1000,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 10000],
                        "value": 176
                    },
                    "us_fed_jud_office_staff": {
                        "key": "us_fed_jud_office_staff",
                        "label": "Federal Judicial Office Staff",
                        "parent": 24,
                        "precedence": 520,
                        "rank": 1000,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 10000],
                        "value": 170
                    },
                    "us_fed_leg_agency_member": {
                        "key": "us_fed_leg_agency_member",
                        "label": "Federal Legislative Agency Member",
                        "parent": 48,
                        "precedence": 345,
                        "rank": 1000,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 10000],
                        "value": 310
                    },
                    "us_fed_leg_can_staff": {
                        "key": "us_fed_leg_can_staff",
                        "label": "Federal Legislative Candidate Staff",
                        "parent": 24,
                        "precedence": 810,
                        "rank": 1000,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 10000],
                        "value": 168
                    },
                    "us_fed_leg_office_staff": {
                        "key": "us_fed_leg_office_staff",
                        "label": "Federal Legislative Office Staff",
                        "parent": 24,
                        "precedence": 520,
                        "rank": 1000,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 10000],
                        "value": 172
                    },
                    "us_fed_member_staff": {
                        "key": "us_fed_member_staff",
                        "label": "Federal Member Staff",
                        "parent": 23,
                        "precedence": 505,
                        "rank": 1000,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "source_enum": "StafferType",
                        "source_enum_value": 1,
                        "supported_regions": [1, 10000],
                        "value": 30
                    },
                    "us_fed_officer_staff": {
                        "key": "us_fed_officer_staff",
                        "label": "Federal Legislative Officer Staff",
                        "parent": 23,
                        "precedence": 510,
                        "rank": 1000,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "source_enum": "StafferType",
                        "source_enum_value": 2,
                        "supported_regions": [1, 10000],
                        "value": 31
                    },
                    "us_fed_panel_staff": {
                        "key": "us_fed_panel_staff",
                        "label": "Federal Panel Staff",
                        "parent": 24,
                        "precedence": 530,
                        "rank": 1000,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 10000],
                        "value": 180
                    },
                    "us_fed_senate_can": {
                        "key": "us_fed_senate_can",
                        "label": "Candidate for US Senate",
                        "parent": 37,
                        "precedence": 710,
                        "rank": 1000,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 10000],
                        "value": 164
                    },
                    "us_fed_senate_officer": {
                        "key": "us_fed_senate_officer",
                        "label": "Senate Officer",
                        "parent": 11,
                        "precedence": 270,
                        "rank": 1000,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 10000],
                        "value": 174
                    },
                    "us_first_lady": {
                        "key": "us_first_lady",
                        "kw_code": "PRES02",
                        "label": "First Lady",
                        "parent": 10,
                        "precedence": 105,
                        "rank": 3,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 10000],
                        "value": 2
                    },
                    "us_governor": {
                        "key": "us_governor",
                        "kw_code": "GOVNR",
                        "label": "Governor",
                        "order": 7,
                        "parent": 16,
                        "precedence": 260,
                        "rank": 10,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": true,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "unique_identifier": "GOVNR",
                        "value": 21
                    },
                    "us_house_committee_leader": {
                        "key": "us_house_committee_leader",
                        "label": "House Committee Leader",
                        "parent": 55,
                        "precedence": 390,
                        "rank": 1000,
                        "regions": [1, 10000, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 10000, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 457
                    },
                    "us_house_leg_chamber_leader": {
                        "key": "us_house_leg_chamber_leader",
                        "label": "House Leader",
                        "parent": 54,
                        "precedence": 385,
                        "rank": 1000,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 10000],
                        "value": 455
                    },
                    "us_house_party_leader": {
                        "key": "us_house_party_leader",
                        "label": "House Party Leader",
                        "parent": 56,
                        "precedence": 395,
                        "rank": 1000,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 10000],
                        "value": 459
                    },
                    "us_local_county_admin": {
                        "key": "us_local_county_admin",
                        "kw_code": "ADMIN",
                        "label": "County Administrator",
                        "order": 19,
                        "parent": 25,
                        "precedence": 908,
                        "rank": 1000,
                        "regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "value": 186
                    },
                    "us_local_county_chief_of_staff": {
                        "key": "us_local_county_chief_of_staff",
                        "kw_code": "COS",
                        "label": "County Chief of Staff",
                        "parent": 26,
                        "precedence": 940,
                        "rank": 1000,
                        "regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "value": 190
                    },
                    "us_local_county_clerk": {
                        "key": "us_local_county_clerk",
                        "kw_code": "CLK",
                        "label": "County Clerk",
                        "order": 21,
                        "parent": 25,
                        "precedence": 915,
                        "rank": 1000,
                        "regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "value": 187
                    },
                    "us_local_county_da": {
                        "key": "us_local_county_da",
                        "kw_code": "DA",
                        "label": "County District Attorney",
                        "parent": 35,
                        "precedence": 910,
                        "rank": 1000,
                        "regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "value": 188
                    },
                    "us_local_county_executive": {
                        "key": "us_local_county_executive",
                        "kw_code": "CE",
                        "label": "County Executive",
                        "order": 17,
                        "parent": 25,
                        "precedence": 905,
                        "rank": 1000,
                        "regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "value": 191
                    },
                    "us_local_county_leg_officer": {
                        "key": "us_local_county_leg_officer",
                        "label": "County Legislative Officer",
                        "parent": 26,
                        "precedence": 925,
                        "rank": 1000,
                        "regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "value": 192
                    },
                    "us_local_county_sheriff": {
                        "key": "us_local_county_sheriff",
                        "kw_code": "SHER",
                        "label": "County Sheriff",
                        "order": 23,
                        "parent": 35,
                        "precedence": 920,
                        "rank": 1000,
                        "regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "value": 189
                    },
                    "us_local_muni_admin": {
                        "key": "us_local_muni_admin",
                        "kw_code": "ADMIN",
                        "label": "City Administrator",
                        "order": 20,
                        "parent": 25,
                        "precedence": 958,
                        "rank": 1000,
                        "regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "value": 193
                    },
                    "us_local_muni_chief_of_staff": {
                        "key": "us_local_muni_chief_of_staff",
                        "kw_code": "COS",
                        "label": "Municipal Chief of Staff",
                        "parent": 26,
                        "precedence": 990,
                        "rank": 1000,
                        "regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "value": 197
                    },
                    "us_local_muni_clerk": {
                        "key": "us_local_muni_clerk",
                        "kw_code": "CLK",
                        "label": "City Clerk",
                        "order": 22,
                        "parent": 25,
                        "precedence": 965,
                        "rank": 1000,
                        "regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "value": 194
                    },
                    "us_local_muni_da": {
                        "key": "us_local_muni_da",
                        "kw_code": "DA",
                        "label": "Municipal District Attorney",
                        "parent": 35,
                        "precedence": 960,
                        "rank": 1000,
                        "regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "value": 195
                    },
                    "us_local_muni_leg_officer": {
                        "key": "us_local_muni_leg_officer",
                        "label": "Municipal Legislative Officer",
                        "parent": 26,
                        "precedence": 975,
                        "rank": 1000,
                        "regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "value": 200
                    },
                    "us_local_muni_legislator": {
                        "key": "us_local_muni_legislator",
                        "kw_code": "MEM",
                        "label": "City Legislator",
                        "order": 6,
                        "parent": 26,
                        "precedence": 980,
                        "rank": 1000,
                        "regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "value": 199
                    },
                    "us_local_muni_mayor": {
                        "key": "us_local_muni_mayor",
                        "kw_code": "CE",
                        "label": "City Mayor",
                        "order": 18,
                        "parent": 25,
                        "precedence": 955,
                        "rank": 1000,
                        "regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "value": 198
                    },
                    "us_local_muni_sheriff": {
                        "key": "us_local_muni_sheriff",
                        "kw_code": "SHER",
                        "label": "City Police Chief",
                        "order": 24,
                        "parent": 35,
                        "precedence": 970,
                        "rank": 1000,
                        "regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "value": 196
                    },
                    "us_local_municipal_executive": {
                        "key": "us_local_municipal_executive",
                        "label": "Municipal Executive",
                        "parent": 25,
                        "precedence": 910,
                        "rank": 1000,
                        "regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "value": 308
                    },
                    "us_local_other": {
                        "description": "A catch all for local people",
                        "key": "us_local_other",
                        "label": "Local Other",
                        "parent": 25,
                        "precedence": 1200,
                        "rank": 1000,
                        "regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 10002, 10000],
                        "value": 309
                    },
                    "us_lt_governor": {
                        "key": "us_lt_governor",
                        "kw_code": "LTGOV",
                        "label": "Lt. Governor",
                        "order": 8,
                        "parent": 17,
                        "precedence": 265,
                        "rank": 11,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "unique_identifier": "LTGOV",
                        "value": 22
                    },
                    "us_potus": {
                        "key": "us_potus",
                        "kw_code": "PRES01",
                        "label": "President of the United States",
                        "parent": 2,
                        "precedence": 100,
                        "rank": 1,
                        "regions": [1, 10000],
                        "show_in_quicksearch": true,
                        "supported_regions": [1, 10000],
                        "value": 1
                    },
                    "us_pres_can": {
                        "key": "us_pres_can",
                        "label": "Candidate for President",
                        "parent": 27,
                        "precedence": 700,
                        "rank": 1000,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 10000],
                        "value": 19
                    },
                    "us_representative": {
                        "key": "us_representative",
                        "kw_code": "FH",
                        "label": "Representative",
                        "order": 2,
                        "parent": 12,
                        "precedence": 250,
                        "rank": 1000,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "source_enum": "RoleType",
                        "source_enum_value": 2,
                        "supported_regions": [1, 10000],
                        "unique_identifier": "FH",
                        "value": 6
                    },
                    "us_senate_committee_leader": {
                        "key": "us_senate_committee_leader",
                        "label": "Senate Committee Leader",
                        "parent": 55,
                        "precedence": 390,
                        "rank": 1000,
                        "regions": [1, 10000, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 10000, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 456
                    },
                    "us_senate_leg_chamber_leader": {
                        "key": "us_senate_leg_chamber_leader",
                        "label": "Senate Leader",
                        "parent": 54,
                        "precedence": 385,
                        "rank": 1000,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 10000],
                        "value": 454
                    },
                    "us_senate_party_leader": {
                        "key": "us_senate_party_leader",
                        "label": "Senate Party Leader",
                        "parent": 56,
                        "precedence": 395,
                        "rank": 1000,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 10000],
                        "value": 458
                    },
                    "us_senator": {
                        "key": "us_senator",
                        "kw_code": "FS",
                        "label": "Senator",
                        "order": 1,
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "source_enum": "RoleType",
                        "source_enum_value": 1,
                        "supported_regions": [1, 10000],
                        "unique_identifier": "FS",
                        "value": 5
                    },
                    "us_sta_att_gen_can": {
                        "key": "us_sta_att_gen_can",
                        "label": "Candidate for State Attorney General",
                        "parent": 36,
                        "precedence": 770,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 219
                    },
                    "us_sta_attorney_general": {
                        "key": "us_sta_attorney_general",
                        "kw_code": "ATTYGEN",
                        "label": "Attorney General",
                        "order": 9,
                        "parent": 17,
                        "precedence": 380,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 453
                    },
                    "us_sta_caucus_staff": {
                        "key": "us_sta_caucus_staff",
                        "label": "State Caucus Staff",
                        "parent": 24,
                        "precedence": 590,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 179
                    },
                    "us_sta_committee_staff": {
                        "key": "us_sta_committee_staff",
                        "label": "State Committee Staff",
                        "parent": 24,
                        "precedence": 570,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 162
                    },
                    "us_sta_exec_can_staff": {
                        "key": "us_sta_exec_can_staff",
                        "label": "State Executive Candidate Staff",
                        "parent": 24,
                        "precedence": 850,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 167
                    },
                    "us_sta_exec_staff": {
                        "key": "us_sta_exec_staff",
                        "label": "State Executive Staff",
                        "parent": 24,
                        "precedence": 595,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 163
                    },
                    "us_sta_house_can": {
                        "key": "us_sta_house_can",
                        "label": "Candidate for State House",
                        "parent": 39,
                        "precedence": 765,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 202
                    },
                    "us_sta_jud_office_staff": {
                        "key": "us_sta_jud_office_staff",
                        "label": "State Judicial Office Staff",
                        "parent": 24,
                        "precedence": 570,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 171
                    },
                    "us_sta_leg_agency_member": {
                        "key": "us_sta_leg_agency_member",
                        "label": "State Legislative Agency Member",
                        "parent": 49,
                        "precedence": 445,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 311
                    },
                    "us_sta_leg_can_staff": {
                        "key": "us_sta_leg_can_staff",
                        "label": "State Legislative Candidate Staff",
                        "parent": 24,
                        "precedence": 860,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 169
                    },
                    "us_sta_leg_office_staff": {
                        "key": "us_sta_leg_office_staff",
                        "label": "State Legislative Office Staff",
                        "parent": 24,
                        "precedence": 570,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 173
                    },
                    "us_sta_lower_officer": {
                        "key": "us_sta_lower_officer",
                        "label": "State Lower Body Officer",
                        "parent": 19,
                        "precedence": 390,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 177
                    },
                    "us_sta_lt_gov_can": {
                        "key": "us_sta_lt_gov_can",
                        "label": "Candidate for Lt. Governor",
                        "parent": 36,
                        "precedence": 755,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 203
                    },
                    "us_sta_member_staff": {
                        "key": "us_sta_member_staff",
                        "label": "State Member Staff",
                        "parent": 23,
                        "precedence": 550,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 160
                    },
                    "us_sta_officer_staff": {
                        "key": "us_sta_officer_staff",
                        "label": "State Legislative Officer Staff",
                        "parent": 23,
                        "precedence": 560,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 161
                    },
                    "us_sta_other_exec_can": {
                        "key": "us_sta_other_exec_can",
                        "label": "Other State Executive Candidate",
                        "parent": 36,
                        "precedence": 780,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 204
                    },
                    "us_sta_panel_staff": {
                        "key": "us_sta_panel_staff",
                        "label": "State Panel Staff",
                        "parent": 24,
                        "precedence": 580,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 181
                    },
                    "us_sta_sec_state_can": {
                        "key": "us_sta_sec_state_can",
                        "label": "Candidate for State Secretary of State",
                        "parent": 36,
                        "precedence": 775,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 220
                    },
                    "us_sta_senate_can": {
                        "key": "us_sta_senate_can",
                        "label": "Candidate for State Senate",
                        "parent": 38,
                        "precedence": 760,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 165
                    },
                    "us_sta_upper_officer": {
                        "key": "us_sta_upper_officer",
                        "label": "State Upper Body Officer",
                        "parent": 18,
                        "precedence": 385,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 175
                    },
                    "us_state_assistant_secretary": {
                        "key": "us_state_assistant_secretary",
                        "kw_code": "ASSTSEC",
                        "label": "State Executive Assistant Secretary",
                        "parent": 31,
                        "precedence": 405,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 183
                    },
                    "us_state_department_secretary": {
                        "key": "us_state_department_secretary",
                        "label": "State Executive Department Secretary",
                        "parent": 17,
                        "precedence": 345,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 24
                    },
                    "us_state_deputy_assistant_secretary": {
                        "key": "us_state_deputy_assistant_secretary",
                        "kw_code": "DEPASSTSEC",
                        "label": "State Executive Deputy Assistant Secretary",
                        "parent": 32,
                        "precedence": 410,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 184
                    },
                    "us_state_deputy_secretary": {
                        "key": "us_state_deputy_secretary",
                        "kw_code": "DEPSEC",
                        "label": "State Executive Deputy Secretary",
                        "parent": 30,
                        "precedence": 400,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 182
                    },
                    "us_state_ebm": {
                        "description": "\n            Individuals that work within the state executive branch, could not be classified\n            into any other category and should have a high precedence.\n        ",
                        "key": "us_state_ebm",
                        "label": "State Executive Member",
                        "parent": 10,
                        "precedence": 440,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 20
                    },
                    "us_state_exec_other": {
                        "description": "\n            Individuals that work within the state executive branch, could not be classified\n            into any other category and should have a low precedence\n        ",
                        "key": "us_state_exec_other",
                        "label": "State Executive Other",
                        "parent": 51,
                        "precedence": 990,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 314
                    },
                    "us_state_gov_can": {
                        "key": "us_state_gov_can",
                        "label": "Candidate for Governor",
                        "parent": 36,
                        "precedence": 750,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 23
                    },
                    "us_state_parl": {
                        "key": "us_state_parl",
                        "label": "State Parliamentarian",
                        "parent": 53,
                        "precedence": 800,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 374
                    },
                    "us_state_prot_officer": {
                        "key": "us_state_prot_officer",
                        "label": "State Protocol Officer",
                        "parent": 53,
                        "precedence": 800,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 375
                    },
                    "us_state_rec": {
                        "key": "us_state_rec",
                        "label": "State Recorder",
                        "parent": 53,
                        "precedence": 800,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 376
                    },
                    "us_state_representative": {
                        "key": "us_state_representative",
                        "kw_code": "SH",
                        "label": "State Representative",
                        "order": 4,
                        "parent": 19,
                        "precedence": 375,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "source_enum": "RoleType",
                        "source_enum_value": 6,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "unique_identifier": "SH",
                        "value": 8
                    },
                    "us_state_senator": {
                        "key": "us_state_senator",
                        "kw_code": "SS",
                        "label": "State Senator",
                        "order": 3,
                        "parent": 18,
                        "precedence": 350,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "source_enum": "RoleType",
                        "source_enum_value": 5,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "unique_identifier": "SS",
                        "value": 7
                    },
                    "us_state_under_secretary": {
                        "key": "us_state_under_secretary",
                        "kw_code": "UNDERSEC",
                        "label": "State Executive Under Secretary",
                        "parent": 33,
                        "precedence": 415,
                        "rank": 1000,
                        "regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "value": 185
                    },
                    "us_treasurer": {
                        "key": "us_treasurer",
                        "kw_code": "TREAS",
                        "label": "Treasurer",
                        "order": 16,
                        "parent": 10,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [1, 10000, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 10000, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000],
                        "unique_identifier": "TREAS",
                        "value": 17
                    },
                    "us_under_secretary": {
                        "key": "us_under_secretary",
                        "kw_code": "UNDERSEC",
                        "label": "Under Secretary",
                        "order": 14,
                        "parent": 8,
                        "precedence": 315,
                        "rank": 1000,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 10000],
                        "unique_identifier": "UNDERSEC",
                        "value": 15
                    },
                    "us_vice_pres_can": {
                        "key": "us_vice_pres_can",
                        "label": "Candidate for Vice President",
                        "parent": 27,
                        "precedence": 705,
                        "rank": 1000,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 10000],
                        "value": 205
                    },
                    "us_vpotus": {
                        "key": "us_vpotus",
                        "kw_code": "PRES03",
                        "label": "Vice President of the United States",
                        "parent": 3,
                        "precedence": 110,
                        "rank": 2,
                        "regions": [1, 10000],
                        "show_in_quicksearch": true,
                        "supported_regions": [1, 10000],
                        "value": 3
                    },
                    "us_vpotus_spouse": {
                        "key": "us_vpotus_spouse",
                        "kw_code": "PRES04",
                        "label": "Spouse of the Vice President",
                        "parent": 10,
                        "precedence": 330,
                        "rank": 4,
                        "regions": [1, 10000],
                        "show_in_quicksearch": false,
                        "supported_regions": [1, 10000],
                        "value": 4
                    },
                    "vnm_cabinet_member": {
                        "key": "vnm_cabinet_member",
                        "label": "Cabinet Member",
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [230],
                        "show_in_quicksearch": false,
                        "supported_regions": [230],
                        "value": 420
                    },
                    "vnm_delegate": {
                        "key": "vnm_delegate",
                        "label": "Delegate",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [230],
                        "show_in_quicksearch": false,
                        "supported_regions": [230],
                        "value": 423
                    },
                    "vnm_deputy_minister": {
                        "key": "vnm_deputy_minister",
                        "label": "Deputy Minister",
                        "parent": 5,
                        "precedence": 160,
                        "rank": 1000,
                        "regions": [230],
                        "show_in_quicksearch": false,
                        "supported_regions": [230],
                        "value": 421
                    },
                    "vnm_deputy_prime_minister": {
                        "key": "vnm_deputy_prime_minister",
                        "label": "Deputy Prime Minister",
                        "parent": 3,
                        "precedence": 115,
                        "rank": 1000,
                        "regions": [230],
                        "show_in_quicksearch": false,
                        "supported_regions": [230],
                        "value": 419
                    },
                    "vnm_executive_branch_member": {
                        "key": "vnm_executive_branch_member",
                        "label": "Executive Branch Member",
                        "parent": 10,
                        "precedence": 275,
                        "rank": 1000,
                        "regions": [230],
                        "show_in_quicksearch": false,
                        "supported_regions": [230],
                        "value": 422
                    },
                    "vnm_judge_of_high_court": {
                        "key": "vnm_judge_of_high_court",
                        "label": "Judge of the High Court",
                        "parent": 22,
                        "precedence": 360,
                        "rank": 1000,
                        "regions": [230],
                        "show_in_quicksearch": false,
                        "supported_regions": [230],
                        "value": 425
                    },
                    "vnm_judge_of_supreme_peoples_court": {
                        "key": "vnm_judge_of_supreme_peoples_court",
                        "label": "Judge of the Supreme People's Court",
                        "parent": 21,
                        "precedence": 350,
                        "rank": 1000,
                        "regions": [230],
                        "show_in_quicksearch": false,
                        "supported_regions": [230],
                        "value": 424
                    },
                    "vnm_president": {
                        "key": "vnm_president",
                        "label": "President",
                        "parent": 1,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [230],
                        "show_in_quicksearch": false,
                        "supported_regions": [230],
                        "value": 417
                    },
                    "vnm_prime_minister": {
                        "key": "vnm_prime_minister",
                        "label": "Prime Minister",
                        "parent": 2,
                        "precedence": 110,
                        "rank": 1000,
                        "regions": [230],
                        "show_in_quicksearch": false,
                        "supported_regions": [230],
                        "value": 418
                    },
                    "zaf_delegate": {
                        "key": "zaf_delegate",
                        "label": "Delegate",
                        "parent": 11,
                        "precedence": 200,
                        "rank": 1000,
                        "regions": [214],
                        "show_in_quicksearch": false,
                        "supported_regions": [214],
                        "value": 145
                    },
                    "zaf_deputy_minister": {
                        "key": "zaf_deputy_minister",
                        "label": "Deputy Minister",
                        "parent": 5,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [214],
                        "show_in_quicksearch": false,
                        "supported_regions": [214],
                        "value": 147
                    },
                    "zaf_deputy_president": {
                        "key": "zaf_deputy_president",
                        "label": "Deputy President",
                        "parent": 3,
                        "precedence": 110,
                        "rank": 1000,
                        "regions": [214],
                        "show_in_quicksearch": false,
                        "supported_regions": [214],
                        "value": 143
                    },
                    "zaf_judge": {
                        "key": "zaf_judge",
                        "label": "Judge",
                        "parent": 21,
                        "precedence": 350,
                        "rank": 1000,
                        "regions": [214],
                        "show_in_quicksearch": false,
                        "supported_regions": [214],
                        "value": 148
                    },
                    "zaf_member_national_assembly": {
                        "key": "zaf_member_national_assembly",
                        "label": "Member of the National Assembly",
                        "parent": 12,
                        "precedence": 250,
                        "rank": 1000,
                        "regions": [214],
                        "show_in_quicksearch": false,
                        "supported_regions": [214],
                        "value": 146
                    },
                    "zaf_minister": {
                        "key": "zaf_minister",
                        "label": "Minister",
                        "parent": 4,
                        "precedence": 150,
                        "rank": 1000,
                        "regions": [214],
                        "show_in_quicksearch": false,
                        "supported_regions": [214],
                        "value": 144
                    },
                    "zaf_president": {
                        "key": "zaf_president",
                        "label": "President",
                        "parent": 2,
                        "precedence": 100,
                        "rank": 1000,
                        "regions": [214],
                        "show_in_quicksearch": false,
                        "supported_regions": [214],
                        "value": 142
                    }
                }
            },
            "searchfiltertypes": {},
            "types": {
                "Party": {
                    "Bipartisan": {
                        "abbrev": "BP",
                        "class_name": "bipartisan",
                        "color": "#b4bcc2",
                        "key": "Bipartisan",
                        "label": "Bipartisan",
                        "order": 6,
                        "show_in_enumeration": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                        "value": 6,
                        "wiw_abbrev": "",
                        "wiw_name": ""
                    },
                    "Democrat": {
                        "abbrev": "D",
                        "class_name": "democrat",
                        "color": "#1f77b4",
                        "key": "Democrat",
                        "label": "Democrat",
                        "order": 1,
                        "show_in_enumeration": true,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                        "value": 1,
                        "wiw_abbrev": "",
                        "wiw_name": ""
                    },
                    "Independent": {
                        "abbrev": "I",
                        "class_name": "independent",
                        "color": "#8C564B",
                        "key": "Independent",
                        "label": "Independent",
                        "order": 2,
                        "show_in_enumeration": true,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                        "value": 3,
                        "wiw_abbrev": "",
                        "wiw_name": ""
                    },
                    "Libertarian": {
                        "abbrev": "LIB",
                        "class_name": "libertarian",
                        "color": "#b4bcc2",
                        "key": "Libertarian",
                        "label": "Libertarian",
                        "order": 21,
                        "show_in_enumeration": true,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                        "value": 21,
                        "wiw_abbrev": "",
                        "wiw_name": ""
                    },
                    "NoParty": {
                        "abbrev": "Non-Designated",
                        "class_name": "defaultPartyClass",
                        "color": "#b4bcc1",
                        "key": "NoParty",
                        "label": "Non-Designated",
                        "order": 7,
                        "show_in_enumeration": true,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 0,
                        "wiw_abbrev": "",
                        "wiw_name": ""
                    },
                    "Other": {
                        "abbrev": "O",
                        "class_name": "other",
                        "color": "#b4bcc2",
                        "key": "Other",
                        "label": "Other",
                        "order": 4,
                        "show_in_enumeration": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                        "value": 4,
                        "wiw_abbrev": "",
                        "wiw_name": ""
                    },
                    "Republican": {
                        "abbrev": "R",
                        "class_name": "republican",
                        "color": "#D62728",
                        "key": "Republican",
                        "label": "Republican",
                        "order": 3,
                        "show_in_enumeration": true,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                        "value": 2,
                        "wiw_abbrev": "",
                        "wiw_name": ""
                    },
                    "Shared": {
                        "abbrev": "S",
                        "class_name": "both",
                        "color": "#b4bcc2",
                        "key": "Shared",
                        "label": "Both",
                        "order": 5,
                        "show_in_enumeration": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                        "value": 5,
                        "wiw_abbrev": "",
                        "wiw_name": ""
                    },
                    "__is_enum": true,
                    "alde": {
                        "abbrev": "RENEW",
                        "class_name": "alliance-of-liberals-and-democrats",
                        "color": "#FFD700",
                        "key": "alde",
                        "label": "Renew Europe Group",
                        "order": 21,
                        "show_in_enumeration": false,
                        "supported_regions": [106],
                        "value": 11,
                        "wiw_abbrev": "RENEW",
                        "wiw_name": "Alliance of Liberals and Democrats for Europe"
                    },
                    "ecr": {
                        "abbrev": "ECR",
                        "class_name": "european-conservatives-and-reformists",
                        "color": "#0000FF",
                        "key": "ecr",
                        "label": "European Conservatives and Reformists Group",
                        "order": 20,
                        "show_in_enumeration": false,
                        "supported_regions": [106],
                        "value": 10,
                        "wiw_abbrev": "ECR",
                        "wiw_name": "European Conservatives and Reformists Group"
                    },
                    "efdd": {
                        "abbrev": "EFDD",
                        "class_name": "europe-of-free-and-direct-democracy",
                        "color": "#24B9B9",
                        "key": "efdd",
                        "label": "Europe of Freedom and Direct Democracy",
                        "order": 24,
                        "show_in_enumeration": false,
                        "supported_regions": [106],
                        "value": 14,
                        "wiw_abbrev": "EFD",
                        "wiw_name": "Europe of Freedom and Direct Democracy Group"
                    },
                    "enl": {
                        "abbrev": "ID",
                        "class_name": "europe-of-nations-and-freedom",
                        "color": "#2B3856",
                        "key": "enl",
                        "label": "Identity and Democracy Group",
                        "order": 25,
                        "show_in_enumeration": false,
                        "supported_regions": [106],
                        "value": 15,
                        "wiw_abbrev": "ID",
                        "wiw_name": "Identity and Democracy Group"
                    },
                    "epp": {
                        "abbrev": "EPP",
                        "class_name": "european-peoples-party",
                        "color": "#3399FF",
                        "key": "epp",
                        "label": "European People's Party",
                        "order": 17,
                        "show_in_enumeration": false,
                        "supported_regions": [106],
                        "value": 7,
                        "wiw_abbrev": "PPE",
                        "wiw_name": "Group of the European People\u2019s Party (Christian Democrats)"
                    },
                    "esn": {
                        "abbrev": "ESN",
                        "class_name": "europe-of-sovereign-nations",
                        "color": "#1F578A",
                        "key": "esn",
                        "label": "Europe of Sovereign Nations",
                        "order": 28,
                        "show_in_enumeration": false,
                        "supported_regions": [106],
                        "value": 23,
                        "wiw_abbrev": "",
                        "wiw_name": ""
                    },
                    "greens_efa": {
                        "abbrev": "Greens-EFA",
                        "class_name": "greens-european-free-alliance",
                        "color": "#009900",
                        "key": "greens_efa",
                        "label": "The Greens-European Free Alliance",
                        "order": 23,
                        "show_in_enumeration": false,
                        "supported_regions": [106],
                        "value": 13,
                        "wiw_abbrev": "GREEN_EFA",
                        "wiw_name": "Group of the Greens/European Free Alliance"
                    },
                    "gue_ngl": {
                        "abbrev": "GUE-NGL",
                        "class_name": "european-united-left-nordic-green_left",
                        "color": "#990000",
                        "key": "gue_ngl",
                        "label": "The Left group in the European Parliament",
                        "order": 22,
                        "show_in_enumeration": false,
                        "supported_regions": [106],
                        "value": 12,
                        "wiw_abbrev": "GUE_NGL",
                        "wiw_name": "The Left group in the European Parliament \u2014 GUE/NGL (The Left)"
                    },
                    "ni": {
                        "abbrev": "NI",
                        "class_name": "non-inscrits",
                        "color": "#ccc",
                        "key": "ni",
                        "label": "Non-Inscrits",
                        "order": 26,
                        "show_in_enumeration": false,
                        "supported_regions": [106],
                        "value": 16,
                        "wiw_abbrev": "NI",
                        "wiw_name": "Non-attached Members"
                    },
                    "pfe": {
                        "abbrev": "PfE",
                        "class_name": "patriots-for-europe",
                        "color": "#2F1C59",
                        "key": "pfe",
                        "label": "Patriots for Europe",
                        "order": 27,
                        "show_in_enumeration": false,
                        "supported_regions": [106],
                        "value": 22,
                        "wiw_abbrev": "",
                        "wiw_name": ""
                    },
                    "pip": {
                        "abbrev": "PIP",
                        "class_name": "puerto-rican-independence-party",
                        "color": "#227d16",
                        "key": "pip",
                        "label": "Puerto Rican Independence Party",
                        "order": 29,
                        "show_in_enumeration": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                        "value": 19,
                        "wiw_abbrev": "",
                        "wiw_name": ""
                    },
                    "pnp": {
                        "abbrev": "PNP",
                        "class_name": "new-progressive-party",
                        "color": "#203d8a",
                        "key": "pnp",
                        "label": "New Progressive Party",
                        "order": 27,
                        "show_in_enumeration": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                        "value": 17,
                        "wiw_abbrev": "",
                        "wiw_name": ""
                    },
                    "ppd": {
                        "abbrev": "PPD",
                        "class_name": "popular-democratic-party",
                        "color": "#e81728",
                        "key": "ppd",
                        "label": "Popular Democratic Party",
                        "order": 28,
                        "show_in_enumeration": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                        "value": 18,
                        "wiw_abbrev": "",
                        "wiw_name": ""
                    },
                    "ppt": {
                        "abbrev": "PPT",
                        "class_name": "working-peoples-party",
                        "color": "#62378d",
                        "key": "ppt",
                        "label": "Working People's Party",
                        "order": 30,
                        "show_in_enumeration": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                        "value": 20,
                        "wiw_abbrev": "",
                        "wiw_name": ""
                    },
                    "sd": {
                        "abbrev": "S&D",
                        "class_name": "socialists-and-democrats",
                        "color": "#FF0000",
                        "key": "sd",
                        "label": "Progressive Alliance of Socialists and Democrats",
                        "order": 18,
                        "show_in_enumeration": false,
                        "supported_regions": [106],
                        "value": 8,
                        "wiw_abbrev": "SD",
                        "wiw_name": "Group of the Progressive Alliance of Socialists and Democrats in the European Parliament"
                    }
                },
                "RoleType": {
                    "__is_enum": true,
                    "_ph_only": { "key": "_ph_only", "kw_code": "", "label": "PH Only Enum", "long_title": "", "precedence": 2, "profile_download": false, "short_title": "PH only enum", "state_id": "", "value": 999 },
                    "county_executive": { "geography_key": "most_recent_county", "geography_model": "County", "geography_model_full_path": "app.geography.models.County", "key": "county_executive", "kw_code": "", "label": "County Executive", "long_title": "", "person_type": 3, "precedence": 3, "primary_geo_shape_type": 4, "profile_download": true, "short_title": "", "staffer_type": 4, "state_id": "County Executive", "value": 21 },
                    "county_legislator": { "chamber": 3, "geography_key": "most_recent_county", "geography_model": "County", "geography_model_full_path": "app.geography.models.County", "key": "county_legislator", "kw_code": "", "label": "County Legislator", "long_title": "Councilor", "person_type": 6, "precedence": 3, "primary_geo_shape_type": 4, "profile_download": true, "short_title": "CM", "state_id": "", "value": 23 },
                    "eu_commissioner": { "key": "eu_commissioner", "kw_code": "", "label": "Commissioner", "long_title": "", "person_type": 3, "precedence": 1, "profile_download": false, "short_title": "", "staffer_type": 106, "state_id": "", "value": 25 },
                    "eu_council_minister": { "key": "eu_council_minister", "kw_code": "", "label": "Minister of the Council of the EU", "long_title": "", "person_type": 3, "precedence": 2, "profile_download": false, "short_title": "Minister", "state_id": "", "value": 27 },
                    "eu_councilmember": { "key": "eu_councilmember", "kw_code": "", "label": "European Council Representative", "long_title": "", "person_type": 3, "precedence": 2, "profile_download": false, "short_title": "", "state_id": "", "value": 26 },
                    "eu_mep": { "key": "eu_mep", "kw_code": "", "label": "MEP", "long_title": "", "person_type": 1, "precedence": 1, "primary_geo_shape_type": 1, "profile_download": false, "short_title": "MEP", "state_id": "", "value": 28 },
                    "eu_mep_candidate": { "key": "eu_mep_candidate", "kw_code": "", "label": "MEP Candidate", "long_title": "", "person_type": 1, "precedence": 2, "profile_download": false, "short_title": "MEP Candidate", "state_id": "", "value": 38 },
                    "eu_permanent_representative": { "key": "eu_permanent_representative", "kw_code": "", "label": "Permanent Representation", "long_title": "", "person_type": 3, "precedence": 2, "profile_download": false, "short_title": "", "state_id": "", "value": 30 },
                    "eu_person": { "key": "eu_person", "kw_code": "", "label": "EU Person", "long_title": "", "person_type": 3, "precedence": 8, "profile_download": false, "short_title": "", "state_id": "", "value": 29 },
                    "federal_executive": { "key": "federal_executive", "kw_code": "", "label": "Federal Executive", "long_title": "", "person_type": 3, "precedence": 3, "primary_geo_shape_type": 1, "profile_download": true, "short_title": "", "staffer_type": 4, "state_id": "Federal Executive", "value": 11 },
                    "filter_groups": [
                        {
                            "display": "list",
                            "title": "Federal Legislators",
                            "values": [1, 2]
                        },
                        {
                            "display": "list",
                            "title": "State Legislators",
                            "values": [5, 6, 7, 8, 9]
                        },
                        {
                            "display": "list",
                            "title": "Executives",
                            "values": [11, 12, 21, 22, 25, 29, 30, 26, 27]
                        },
                        {
                            "display": "list",
                            "title": "Staffers",
                            "values": [10]
                        },
                        {
                            "display": "list",
                            "title": "Person With Stats",
                            "values": [1, 2, 5, 6, 7, 8, 9, 11, 12]
                        },
                        {
                            "display": "list",
                            "title": "Local People",
                            "values": [21, 22, 23, 24]
                        }
                    ],
                    "municipal_executive": { "geography_key": "most_recent_municipality", "geography_model": "Municipality", "geography_model_full_path": "app.geography.models.Municipality", "key": "municipal_executive", "kw_code": "", "label": "Municipal Executive", "long_title": "", "person_type": 3, "precedence": 5, "primary_geo_shape_type": 7, "profile_download": true, "short_title": "", "staffer_type": 4, "state_id": "Municipal Executive", "value": 22 },
                    "municipal_legislator": { "chamber": 3, "geography_key": "most_recent_municipality", "geography_model": "Municipality", "geography_model_full_path": "app.geography.models.Municipality", "key": "municipal_legislator", "kw_code": "", "label": "City Council Member", "long_title": "Councilor", "person_type": 6, "precedence": 4, "primary_geo_shape_type": 7, "profile_download": true, "short_title": "CM", "state_id": "", "value": 24 },
                    "none": { "key": "none", "kw_code": "", "label": "No type found", "long_title": "", "precedence": 10, "profile_download": false, "short_title": "", "state_id": "" },
                    "pol_party_mem": { "key": "pol_party_mem", "kw_code": "", "label": "Political Party Member", "long_title": "", "person_type": 1, "precedence": 3, "profile_download": false, "short_title": "", "state_id": "", "value": 39 },
                    "representative": { "chamber": 2, "geography_key": "most_recent_district", "geography_model": "District", "geography_model_full_path": "app.geography.models.District", "geography_stats_model": "app.geography.models.DistrictStats", "key": "representative", "kw_code": "FH", "label": "Representative", "long_title": "Representative", "person_type": 1, "precedence": 1, "primary_geo_shape_type": 3, "profile_download": true, "short_title": "Rep.", "staffer_type": 1, "state_id": "", "value": 2 },
                    "senator": { "chamber": 1, "geography_key": "most_recent_state", "geography_model": "State", "geography_model_full_path": "app.geography.models.State", "geography_stats_model": "app.geography.models.StateStats", "key": "senator", "kw_code": "FS", "label": "Senator", "long_title": "Senator", "person_type": 1, "precedence": 1, "primary_geo_shape_type": 2, "profile_download": true, "short_title": "Sen.", "staffer_type": 1, "state_id": "", "value": 1 },
                    "staffer": { "key": "staffer", "kw_code": "", "label": "Staffer", "long_title": "", "person_type": 2, "precedence": 7, "profile_download": false, "short_title": "", "state_id": "Staffer", "value": 10 },
                    "state_assemblymember": { "chamber": 2, "geography_key": "most_recent_lower_district", "geography_model": "LowerLD", "geography_model_full_path": "app.geography.models.LowerLD", "geography_stats_model": "app.geography.models.LowerLDStats", "key": "state_assemblymember", "kw_code": "", "label": "Assembly Member", "long_title": "Assembly Member", "person_type": 1, "precedence": 3, "primary_geo_shape_type": 6, "profile_download": true, "short_title": "Assemb.", "staffer_type": 1, "state_id": "Assembly Member", "value": 9 },
                    "state_councilmember": { "chamber": 1, "geography_key": "most_recent_upper_district", "geography_model": "UpperLD", "geography_model_full_path": "app.geography.models.UpperLD", "geography_stats_model": "app.geography.models.UpperLDStats", "key": "state_councilmember", "kw_code": "", "label": "Council Member", "long_title": "Councilmember", "person_type": 1, "precedence": 2, "primary_geo_shape_type": 5, "profile_download": true, "short_title": "CM", "staffer_type": 1, "state_id": "Council Member", "value": 7 },
                    "state_delegate": { "chamber": 2, "geography_key": "most_recent_lower_district", "geography_model": "LowerLD", "geography_model_full_path": "app.geography.models.LowerLD", "geography_stats_model": "app.geography.models.LowerLDStats", "key": "state_delegate", "kw_code": "", "label": "Delegate", "long_title": "Delegate", "person_type": 1, "precedence": 2, "primary_geo_shape_type": 6, "profile_download": true, "short_title": "Del.", "staffer_type": 1, "state_id": "Delegate", "value": 8 },
                    "state_executive": { "geography_key": "most_recent_state", "geography_model": "State", "geography_model_full_path": "app.geography.models.State", "geography_stats_model": "app.geography.models.StateStats", "key": "state_executive", "kw_code": "", "label": "State Executive", "long_title": "", "person_type": 3, "precedence": 3, "primary_geo_shape_type": 2, "profile_download": true, "short_title": "", "staffer_type": 4, "state_id": "State Executive", "value": 12 },
                    "state_representative": { "chamber": 2, "geography_key": "most_recent_lower_district", "geography_model": "LowerLD", "geography_model_full_path": "app.geography.models.LowerLD", "geography_stats_model": "app.geography.models.LowerLDStats", "key": "state_representative", "kw_code": "SH", "label": "Representative ", "long_title": "Representative", "person_type": 1, "precedence": 2, "primary_geo_shape_type": 6, "profile_download": true, "short_title": "Rep.", "staffer_type": 1, "state_id": "Representative", "value": 6 },
                    "state_senator": { "chamber": 1, "geography_key": "most_recent_upper_district", "geography_model": "UpperLD", "geography_model_full_path": "app.geography.models.UpperLD", "geography_stats_model": "app.geography.models.UpperLDStats", "key": "state_senator", "kw_code": "SS", "label": "Senator ", "long_title": "Senator", "person_type": 1, "precedence": 2, "primary_geo_shape_type": 5, "profile_download": true, "short_title": "Sen.", "staffer_type": 1, "state_id": "Senator", "value": 5 }
                },
                "TagType": {
                    "__is_enum": true,
                    "boolean": { "client_facing_label": "Yes/No (for example, Part of Ambassador Program or Registered Through Event)", "column_data_type": "boolean", "display_order": 1, "key": "boolean", "label": "Boolean", "short_client_facing_label": "Yes/No", "slug": "boolean", "value": 6 },
                    "date": { "client_facing_label": "Date (for example, Time of Last Meeting)", "column_data_type": "integer", "display_order": 6, "key": "date", "label": "Date", "short_client_facing_label": "Date", "slug": "date", "value": 8 },
                    "multi_options_list": { "client_facing_label": "Options (for example, Contact Type or Division), choose multiple options", "column_data_type": "string", "display_order": 5, "key": "multi_options_list", "label": "Multiple Options", "short_client_facing_label": "Choose Many", "slug": "multi_option", "value": 7 },
                    "number": { "client_facing_label": "Number (for example, Supporter Score or Total Donations)", "column_data_type": "integer", "display_order": 2, "key": "number", "label": "Number", "short_client_facing_label": "Number", "slug": "number", "value": 1 },
                    "power_search": { "client_facing_label": "Power Search", "column_data_type": "string", "display_order": 7, "key": "power_search", "label": "Power Search", "short_client_facing_label": "Power Search", "slug": "power_search", "value": 9 },
                    "single_option_list": { "client_facing_label": "Options (for example, Contact Type or Division), choose one option", "column_data_type": "string", "display_order": 4, "key": "single_option_list", "label": "Single Option", "short_client_facing_label": "Choose One", "slug": "options", "value": 5 },
                    "string": { "client_facing_label": "Text (for example, Supervisor Name or Preferred Name)", "column_data_type": "string", "display_order": 3, "key": "string", "label": "String", "short_client_facing_label": "Text", "slug": "text", "value": 2 }
                }
            }
        },
        "reg": {
            "types": {}
        },
        "search": {
            "bulkactions": {
                "types": {}
            },
            "types": {}
        },
        "social": {
            "models": {
                "SocialUserType": {
                    "__is_enum": true,
                    "facebook_user": { "canonical_url": "Facebook.com", "color": "#1877F2", "document_type": 9, "faIcon": "fa-facebook", "icon": "facebook-square", "icon_family": "fab", "key": "facebook_user", "label": "Facebook User", "parser_name": "facebook", "platform_key": "facebook", "platform_name": "Facebook", "profile_url": "https://facebook.com/", "proxy": "NewFacebookUser", "value": 2 },
                    "instagram_user": { "canonical_url": "Instagram.com", "color": "#515BD4", "document_type": 39, "faIcon": "fa-instagram", "icon": "instagram-square", "icon_family": "fab", "key": "instagram_user", "label": "Instagram User", "parser_name": "instagram", "platform_key": "instagram", "platform_name": "Instagram", "profile_url": "https://instagram.com/", "proxy": "NewInstagramUser", "value": 4 },
                    "medium_user": { "canonical_url": "Medium.com", "color": "#495057", "document_type": 38, "faIcon": "fa-medium", "icon": "medium", "icon_family": "fab", "key": "medium_user", "label": "Medium User", "parser_name": "medium", "platform_key": "medium", "platform_name": "Medium", "profile_url": "https://medium.com/@", "proxy": "NewMediumUser", "value": 3 },
                    "twitter_user": { "canonical_url": "Twitter.com", "color": "#1DA1F2", "document_type": 6, "faIcon": "fa-twitter", "icon": "twitter-square", "icon_family": "fab", "key": "twitter_user", "label": "Twitter User", "parser_name": "social", "platform_key": "twitter", "platform_name": "Twitter", "profile_url": "https://twitter.com/", "proxy": "NewTwitterUser", "value": 1 },
                    "youtube_user": { "canonical_url": "Youtube.com", "color": "#FF0000", "document_type": 41, "faIcon": "fa-youtube", "icon": "youtube-square", "icon_family": "fab", "key": "youtube_user", "label": "YouTube User", "parser_name": "youtube", "platform_key": "youtube", "platform_name": "Youtube", "profile_url": "https://youtube.com/user/", "proxy": "NewYouTubeUser", "value": 5 }
                }
            }
        },
        "statements": {
            "models": {}
        },
        "stats": {
            "models": {},
            "types": {}
        },
        "texting": {
            "types": {}
        },
        "tracking_dashboards": {
            "types": {}
        },
        "types": {
            "NewGrassrootsEnum": { "__is_enum": true }
        },
        "useractions": {
            "types": {}
        },
        "userdata": {
            "alerts": {
                "types": {}
            },
            "models": {
                "GrassrootsIssue": {
                    "__is_model": true,
                    "__name__": "GrassrootsIssue",
                    "data_model": "app.userdata.models.GrassrootsIssue",
                    "default_exclude_filters": {},
                    "default_filters": { "archived": false },
                    "endpoint": "/api/grassrootsissue/",
                    "fields": [],
                    "proxy_endpoint": "/api/project/"
                },
                "IssueManagement": {
                    "__is_model": true,
                    "__name__": "IssueManagement",
                    "data_model": "app.userdata.models.IssueManagement",
                    "default_exclude_filters": {},
                    "default_filters": { "archived": false },
                    "endpoint": "/api/issuemanagement/",
                    "fields": [],
                    "proxy_endpoint": "/api/project/"
                },
                "Organization": {
                    "__is_model": true,
                    "__name__": "Organization",
                    "data_model": "app.userdata.models.Organization",
                    "default_exclude_filters": {},
                    "default_filters": {},
                    "endpoint": "/api/organization/",
                    "fields": []
                },
                "Project": {
                    "__is_model": true,
                    "__name__": "Project",
                    "data_model": "app.userdata.models.Project",
                    "default_exclude_filters": {},
                    "default_filters": { "archived": false },
                    "endpoint": "/api/project/",
                    "fields": []
                }
            },
            "notes": {
                "models": {
                    "CustomInteractionType": {
                        "__is_model": true,
                        "__name__": "CustomInteractionType",
                        "data_model": "app.userdata.notes.models.CustomInteractionType",
                        "default_exclude_filters": {},
                        "default_filters": {},
                        "endpoint": "/api/custominteractiontype/",
                        "fields": []
                    },
                    "Interaction": {
                        "__is_model": true,
                        "__name__": "Interaction",
                        "data_model": "app.userdata.notes.models.Interaction",
                        "default_exclude_filters": {
                            "interaction_type__in": [12, 13, 34, 22, 23, 32, 24, 81]
                        },
                        "default_filters": { "archived": false },
                        "endpoint": "/api/interaction/",
                        "fields": [],
                        "proxy_endpoint": "/api/note/"
                    },
                    "Relationship": {
                        "__is_model": true,
                        "__name__": "Relationship",
                        "data_model": "app.userdata.notes.models.Relationship",
                        "default_exclude_filters": {
                            "interaction_type__in": [12, 13, 34, 22, 23, 32, 24, 81]
                        },
                        "default_filters": { "archived": false },
                        "endpoint": "/api/relationship/",
                        "fields": [],
                        "proxy_endpoint": "/api/note/"
                    },
                    "SupporterAction": {
                        "__is_model": true,
                        "__name__": "SupporterAction",
                        "data_model": "app.userdata.notes.models.SupporterAction",
                        "default_exclude_filters": {
                            "interaction_type__in": [12, 13, 34, 22, 23, 32, 24, 81]
                        },
                        "default_filters": { "archived": false },
                        "endpoint": "/api/oldsupporteraction/",
                        "proxy_endpoint": "/api/interaction/"
                    }
                }
            },
            "outbox": {
                "types": {}
            },
            "push_notifs": {
                "models": {}
            },
            "spreadsheet": {
                "data_types": {},
                "types": {}
            },
            "types": {
                "GrassrootsInteractionType": {
                    "__is_enum": true,
                    "authorized_contribution": {
                        "description": "submitted an authorized contribution",
                        "editable": false,
                        "future_description": "will submit an authorized contribution",
                        "grassroots": true,
                        "grassroots_description": "submitted an authorized contribution",
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-pencil-square-o",
                        "key": "authorized_contribution",
                        "label": "Authorized Contribution",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 11,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 108
                    },
                    "briefing": {
                        "description": "attended a briefing with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will attend a briefing with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-briefcase",
                        "key": "briefing",
                        "label": "Briefing",
                        "loggable": true,
                        "magic_lists": true,
                        "order": 19,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 36
                    },
                    "clicked_link": {
                        "editable": false,
                        "grassroots": false,
                        "grassroots_description": "clicked your link",
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-mouse-pointer",
                        "key": "clicked_link",
                        "label": "Clicked Link",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 10,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 34
                    },
                    "commented_on_regulation": {
                        "description": "commented on a regulation",
                        "editable": false,
                        "future_description": "will comment on a regulation",
                        "grassroots": false,
                        "grassroots_description": "commented on a regulation for the campaign",
                        "grassroots_note_takes_campaign": true,
                        "icon": "fa-envelope-o",
                        "key": "commented_on_regulation",
                        "label": "Commented on Regulation",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 11,
                        "qcal_class": "note-cal-grassroots",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": true,
                        "value": 44
                    },
                    "contact_us": {
                        "description": "contacted your organization",
                        "editable": false,
                        "future_description": "will contact your ogranization",
                        "grassroots": true,
                        "grassroots_description": "contacted your organization",
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-envelope-o",
                        "key": "contact_us",
                        "label": "Contact Us",
                        "loggable": false,
                        "magic_lists": false,
                        "mobile_order": 19,
                        "order": 27,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 109
                    },
                    "discussion": {
                        "description": "had an in-person discussion with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will have an in-person discussion with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-gavel",
                        "key": "discussion",
                        "label": "Discussion",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 14,
                        "order": 9,
                        "qcal_class": "note-cal-correspondence",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": false,
                        "value": 21
                    },
                    "donation_given": {
                        "description": "gave a donation to",
                        "divider": "to",
                        "editable": true,
                        "future_description": "will give a donation to",
                        "grassroots": true,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-gift",
                        "key": "donation_given",
                        "label": "Donation Given",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 3,
                        "order": 6,
                        "qcal_class": "note-cal-events",
                        "should_display_amount": true,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                        "supporter": false,
                        "value": 7
                    },
                    "email": {
                        "description": "emailed",
                        "divider": "to",
                        "editable": true,
                        "future_description": "will email",
                        "grassroots": true,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-envelope-o",
                        "key": "email",
                        "label": "Email Sent",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 5,
                        "order": 3,
                        "qcal_class": "note-cal-correspondence",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": false,
                        "value": 11
                    },
                    "email_exchange": {
                        "description": "exchanged an email with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will exchange an email with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-envelope",
                        "key": "email_exchange",
                        "label": "Email Exchange",
                        "loggable": true,
                        "magic_lists": true,
                        "order": 5,
                        "qcal_class": "note-cal-correspondence",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": true,
                        "value": 35
                    },
                    "event_attended": {
                        "associated_QDT": 28,
                        "description": "attended an event with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will attend an event with",
                        "grassroots": true,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-calendar",
                        "key": "event_attended",
                        "label": "Event Attended",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 4,
                        "order": 4,
                        "qcal_class": "note-cal-events",
                        "replace_with_name": "an event",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": false,
                        "value": 1
                    },
                    "facility_visit": {
                        "description": "visited a facility with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will visit a facility with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-industry",
                        "key": "facility_visit",
                        "label": "Facility Visit",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 14,
                        "order": 20,
                        "qcal_class": "note-cal-events",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 37
                    },
                    "filter_groups": [
                        {
                            "display": "list",
                            "title": "Grassroots",
                            "values": [25, 50, 26, 27, 28, 29, 30, 31, 45, 44, 46, 48, 85, 108, 104, 105, 106, 107, 109]
                        }
                    ],
                    "fundraiser_attended": {
                        "description": "attended a fundraiser with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will attend a fundraiser with",
                        "grassroots": true,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-usd",
                        "key": "fundraiser_attended",
                        "label": "Fundraiser Attended",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 10,
                        "order": 18,
                        "qcal_class": "note-cal-events",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                        "supporter": false,
                        "value": 6
                    },
                    "grassroots_phone_call": {
                        "description": "spoke on the phone with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will speak on the phone with",
                        "grassroots": true,
                        "grassroots_description": "called for the campaign",
                        "grassroots_note_takes_campaign": true,
                        "icon": "fa-phone",
                        "key": "grassroots_phone_call",
                        "label": "Call a Number",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 3,
                        "qcal_class": "note-cal-correspondence",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": true,
                        "value": 50
                    },
                    "in_district_interaction": {
                        "description": "had an in-district interaction with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will have an in-district interaction with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-map",
                        "key": "in_district_interaction",
                        "label": "In-District Interaction",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 14,
                        "order": 23,
                        "qcal_class": "note-cal-events",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                        "value": 43
                    },
                    "meeting": {
                        "description": "met with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will meet with",
                        "grassroots": true,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-check",
                        "key": "meeting",
                        "label": "Meeting",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 1,
                        "order": 0,
                        "qcal_class": "note-cal-correspondence",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": false,
                        "value": 4
                    },
                    "multi_campaigned": {
                        "description": "participated in a combined campaign",
                        "editable": false,
                        "future_description": "intends to participate in a combined campaign",
                        "grassroots": true,
                        "grassroots_description": "participated in the combined campaign",
                        "grassroots_note_takes_campaign": true,
                        "icon": "fa-share-alt",
                        "key": "multi_campaigned",
                        "label": "Combined Campaign",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 24,
                        "qcal_class": "note-cal-grassroots",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": true,
                        "value": 48
                    },
                    "office_visit": {
                        "description": "visited the office of",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will visit the office of",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-bank",
                        "key": "office_visit",
                        "label": "Office Visit",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 15,
                        "order": 23,
                        "qcal_class": "note-cal-events",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 51
                    },
                    "outbox_email": {
                        "associated_QDT": 44,
                        "description": "sent an Outbox email to",
                        "divider": "with",
                        "editable": false,
                        "future_description": "will send an Outbox email to",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-envelope",
                        "key": "outbox_email",
                        "label": "Outbox Email",
                        "loggable": false,
                        "magic_lists": true,
                        "mobile_order": 6.5,
                        "order": 5.5,
                        "qcal_class": "note-cal-correspondence",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": true,
                        "value": 49
                    },
                    "personal_interaction": {
                        "description": "had a personal interaction with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will have a personal interaction with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-hand-paper-o",
                        "key": "personal_interaction",
                        "label": "Personal Interaction",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 16,
                        "order": 24,
                        "qcal_class": "note-cal-events",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 52
                    },
                    "personalized_message": {
                        "description": "wrote a personalized message",
                        "editable": false,
                        "future_description": "will write a personalized message",
                        "grassroots": false,
                        "grassroots_description": "wrote a personalized message for the campaign",
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-magic",
                        "key": "personalized_message",
                        "label": "Personalized a message",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 1,
                        "qcal_class": "note-cal-grassroots",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": true,
                        "value": 53
                    },
                    "phone_call": {
                        "description": "spoke on the phone with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will speak on the phone with",
                        "grassroots": false,
                        "grassroots_description": "called for the campaign",
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-phone",
                        "key": "phone_call",
                        "label": "Phone Call",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 2,
                        "order": 1,
                        "qcal_class": "note-cal-correspondence",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": false,
                        "value": 3
                    },
                    "received_points": {
                        "description": "received points on",
                        "divider": "on",
                        "editable": false,
                        "future_description": "will receive points on",
                        "grassroots": true,
                        "grassroots_description": "received custom points",
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-star",
                        "key": "received_points",
                        "label": "Received points",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 11,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 104
                    },
                    "registered": {
                        "description": "registered for your fly in",
                        "editable": false,
                        "grassroots": false,
                        "grassroots_description": "registered for your fly in",
                        "grassroots_note_takes_campaign": true,
                        "icon": "fa-plane",
                        "key": "registered",
                        "label": "Registered for Fly In",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 3,
                        "qcal_class": "note-cal-grassroots",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": true,
                        "value": 27
                    },
                    "registration_form_registered": {
                        "description": "registered on",
                        "divider": "on",
                        "editable": false,
                        "future_description": "will register on",
                        "grassroots": false,
                        "grassroots_description": "registered with your registration form",
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-pencil-square-o",
                        "key": "registration_form_registered",
                        "label": "Registered on Registration Form",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 10,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 85
                    },
                    "scheduled": {
                        "description": "scheduled a meeting with",
                        "editable": false,
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-calendar",
                        "key": "scheduled",
                        "label": "Schedule",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 7,
                        "qcal_class": "note-cal-grassroots",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": true,
                        "value": 31
                    },
                    "share": {
                        "description": "shared the link in your campaign",
                        "editable": false,
                        "grassroots": false,
                        "grassroots_description": "shared the link in your campaign",
                        "grassroots_note_takes_campaign": true,
                        "icon": "fa-bullhorn",
                        "key": "share",
                        "label": "Shared a Link",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 6,
                        "qcal_class": "note-cal-grassroots",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": true,
                        "value": 30
                    },
                    "signature": {
                        "description": "participated",
                        "editable": false,
                        "future_description": "will participate",
                        "grassroots": false,
                        "grassroots_description": "participated in campaign",
                        "grassroots_note_takes_campaign": true,
                        "icon": "fa-pencil",
                        "key": "signature",
                        "label": "Custom Call to Action",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 4,
                        "qcal_class": "note-cal-grassroots",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": true,
                        "value": 28
                    },
                    "submit_election_info": {
                        "description": "submitted Election Info widget",
                        "editable": false,
                        "future_description": "will submit Election Info widget",
                        "grassroots": true,
                        "grassroots_description": "submitted Election Info widget",
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-sticky-note-o",
                        "key": "submit_election_info",
                        "label": "Submit Election Info Widget",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 14,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 107
                    },
                    "submit_gotv": {
                        "description": "submitted GOTV widget",
                        "editable": false,
                        "future_description": "will submit GOTV widget",
                        "grassroots": true,
                        "grassroots_description": "submitted GOTV widget",
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-sticky-note-o",
                        "key": "submit_gotv",
                        "label": "Submit GOTV Widget",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 12,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 105
                    },
                    "submit_voter_registration": {
                        "description": "submitted Voter Registration widget",
                        "editable": false,
                        "future_description": "will submit Voter Registration widget",
                        "grassroots": true,
                        "grassroots_description": "submitted Voter Registration widget",
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-sticky-note-o",
                        "key": "submit_voter_registration",
                        "label": "Submit Voter Registration Widget",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 13,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 106
                    },
                    "told_grassroots_story": {
                        "description": "told a Grassroots story",
                        "editable": false,
                        "future_description": "will tell a grassroots story",
                        "grassroots": false,
                        "grassroots_description": "shared a story",
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-user",
                        "key": "told_grassroots_story",
                        "label": "Tell Us Your Story",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 12,
                        "qcal_class": "note-cal-grassroots",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": true,
                        "value": 45
                    },
                    "tweet": {
                        "description": "tweeted at",
                        "editable": false,
                        "future_description": "will tweet at",
                        "grassroots": false,
                        "grassroots_description": "tweeted for the campaign",
                        "grassroots_note_takes_campaign": true,
                        "icon": "fa-twitter",
                        "key": "tweet",
                        "label": "Post at Someone",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 5,
                        "qcal_class": "note-cal-grassroots",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": true,
                        "value": 29
                    },
                    "visit": {
                        "description": "visited",
                        "editable": false,
                        "future_description": "will visit",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-suitcase",
                        "key": "visit",
                        "label": "Request a Visit",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 2,
                        "qcal_class": "note-cal-grassroots",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": true,
                        "value": 26
                    },
                    "wrote_letter": {
                        "description": "wrote a letter to",
                        "editable": false,
                        "future_description": "will write a letter to",
                        "grassroots": false,
                        "grassroots_description": "wrote a letter for the campaign",
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-envelope-o",
                        "key": "wrote_letter",
                        "label": "Write a Letter",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 1,
                        "qcal_class": "note-cal-grassroots",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": true,
                        "value": 25
                    },
                    "wrote_white_house": {
                        "description": "wrote to the White House",
                        "editable": false,
                        "future_description": "will write to the White House",
                        "grassroots": false,
                        "grassroots_description": "wrote the White House",
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-envelope-o",
                        "key": "wrote_white_house",
                        "label": "Write to the White House",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 13,
                        "qcal_class": "note-cal-grassroots",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": true,
                        "value": 46
                    }
                },
                "InteractionType": {
                    "__is_enum": true,
                    "article": {
                        "deprecated": true,
                        "description": "published an article with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will publish an article with",
                        "grassroots": false,
                        "icon": "fa-newspaper-o",
                        "key": "article",
                        "label": "Article",
                        "loggable": true,
                        "magic_lists": false,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 95
                    },
                    "assignment_created": {
                        "deprecated": false,
                        "description": "was assigned to",
                        "divider": "with",
                        "editable": false,
                        "future_description": "will be assigned to",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-star",
                        "key": "assignment_created",
                        "label": "Assignment Created",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 0,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 12
                    },
                    "assignment_deleted": {
                        "deprecated": false,
                        "description": "was unassigned from",
                        "divider": "with",
                        "editable": false,
                        "future_description": "will be unassigned from",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-star-o",
                        "key": "assignment_deleted",
                        "label": "Assignment Removed",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 0,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 13
                    },
                    "attend_formal_public_meeting": {
                        "associated_QDT": 28,
                        "deprecated": true,
                        "description": "attended a formal public meeting with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will attend a formal public meeting with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-check",
                        "key": "attend_formal_public_meeting",
                        "label": "Attend Formal Public Meeting",
                        "loggable": true,
                        "magic_lists": true,
                        "qcal_class": "note-cal-correspondence",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": false,
                        "value": 103
                    },
                    "authorized_contribution": {
                        "deprecated": false,
                        "description": "submitted an authorized contribution",
                        "editable": false,
                        "feature_flags": ["ff_pac"],
                        "future_description": "will submit an authorized contribution",
                        "grassroots": true,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-pencil-square-o",
                        "key": "authorized_contribution",
                        "label": "Authorized Contribution",
                        "loggable": true,
                        "magic_lists": false,
                        "order": 1,
                        "permission_enum": [8],
                        "qcal_class": false,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 108
                    },
                    "blog_post": {
                        "deprecated": true,
                        "description": "published a Blog Post with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will publish a Blog Post with",
                        "grassroots": false,
                        "icon": "fa-book",
                        "key": "blog_post",
                        "label": "Blog Post",
                        "loggable": true,
                        "magic_lists": false,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 94
                    },
                    "briefing": {
                        "deprecated": true,
                        "description": "attended a briefing with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will attend a briefing with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-briefcase",
                        "key": "briefing",
                        "label": "Briefing",
                        "loggable": true,
                        "magic_lists": true,
                        "order": 19,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 36
                    },
                    "casework": {
                        "deprecated": true,
                        "description": "did casework with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will do casework with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-briefcase",
                        "key": "casework",
                        "label": "Casework",
                        "loggable": true,
                        "magic_lists": false,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 71
                    },
                    "clicked_link": {
                        "deprecated": false,
                        "editable": false,
                        "grassroots": false,
                        "grassroots_description": "clicked your link",
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-mouse-pointer",
                        "key": "clicked_link",
                        "label": "Clicked Link",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 10,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 34
                    },
                    "collateral": {
                        "deprecated": true,
                        "description": "released material with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will release material with",
                        "grassroots": false,
                        "icon": "fa-bar-chart",
                        "key": "collateral",
                        "label": "Collateral",
                        "loggable": true,
                        "magic_lists": false,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 98
                    },
                    "commented_on_regulation": {
                        "deprecated": false,
                        "description": "commented on a regulation",
                        "editable": false,
                        "future_description": "will comment on a regulation",
                        "grassroots": false,
                        "grassroots_description": "commented on a regulation for the campaign",
                        "grassroots_note_takes_campaign": true,
                        "icon": "fa-envelope-o",
                        "key": "commented_on_regulation",
                        "label": "Comment on Regulation",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 11,
                        "qcal_class": "note-cal-grassroots",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": true,
                        "value": 44
                    },
                    "committee_statement": {
                        "deprecated": true,
                        "description": "has a committee statement with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will have a committee statement with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-bullhorn",
                        "key": "committee_statement",
                        "label": "Committee Statement",
                        "loggable": true,
                        "magic_lists": false,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 79
                    },
                    "congress_delegation": {
                        "deprecated": true,
                        "description": "attended a congressional delegation",
                        "editable": true,
                        "future_description": "will attend a congressional delegation",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-check",
                        "key": "congress_delegation",
                        "label": "Congressional Delegation",
                        "loggable": true,
                        "magic_lists": true,
                        "qcal_class": "note-cal-correspondence",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                        "supporter": false,
                        "value": 69
                    },
                    "contact_us": {
                        "deprecated": false,
                        "description": "contacted your organization",
                        "editable": false,
                        "future_description": "will contact your ogranization",
                        "grassroots": true,
                        "grassroots_description": "contacted your organization",
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-envelope-o",
                        "key": "contact_us",
                        "label": "Contact Us",
                        "loggable": false,
                        "magic_lists": false,
                        "mobile_order": 19,
                        "order": 27,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 109
                    },
                    "contacted_constituent": {
                        "deprecated": true,
                        "description": "contacted a constituent of",
                        "divider": "of",
                        "editable": true,
                        "future_description": "will contact a constituent of",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-street-view",
                        "key": "contacted_constituent",
                        "label": "Contacted Constituent",
                        "loggable": true,
                        "magic_lists": true,
                        "order": 15,
                        "qcal_class": "note-cal-correspondence",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                        "supporter": false,
                        "value": 17
                    },
                    "dc_event": {
                        "associated_QDT": 28,
                        "deprecated": true,
                        "description": "attended a DC event with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will attend a DC event with",
                        "icon": "fa-calendar",
                        "key": "dc_event",
                        "label": "DC Event",
                        "loggable": true,
                        "magic_lists": false,
                        "qcal_class": "note-cal-events",
                        "replace_with_name": "an event",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 91
                    },
                    "dc_meeting": {
                        "deprecated": true,
                        "description": "attended a DC meeting with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will attend a DC meeting with",
                        "icon": "fa-check",
                        "key": "dc_meeting",
                        "label": "DC Meeting",
                        "loggable": true,
                        "magic_lists": false,
                        "qcal_class": "note-cal-correspondence",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 90
                    },
                    "default": {
                        "deprecated": false,
                        "description": "interacted with",
                        "editable": true,
                        "future_description": "will interact with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-handshake-o",
                        "key": "default",
                        "label": "Default",
                        "loggable": true,
                        "magic_lists": true,
                        "qcal_class": false,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 110
                    },
                    "discussion": {
                        "deprecated": true,
                        "description": "had an in-person discussion with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will have an in-person discussion with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-gavel",
                        "key": "discussion",
                        "label": "Discussion",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 14,
                        "order": 9,
                        "qcal_class": "note-cal-correspondence",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": false,
                        "value": 21
                    },
                    "donation_given": {
                        "deprecated": false,
                        "description": "gave a donation to",
                        "divider": "to",
                        "editable": true,
                        "future_description": "will give a donation to",
                        "grassroots": true,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-gift",
                        "key": "donation_given",
                        "label": "Donation Given",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 3,
                        "order": 6,
                        "qcal_class": "note-cal-events",
                        "should_display_amount": true,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                        "supporter": false,
                        "value": 7
                    },
                    "donation_received": {
                        "deprecated": false,
                        "description": "received a donation from",
                        "divider": "from",
                        "editable": true,
                        "future_description": "will receive a donation from",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-gift",
                        "key": "donation_received",
                        "label": "Donation Received",
                        "loggable": true,
                        "magic_lists": true,
                        "order": 7,
                        "qcal_class": "note-cal-events",
                        "should_display_amount": true,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                        "value": 8
                    },
                    "donation_rejected": {
                        "deprecated": false,
                        "description": "rejected a donation from",
                        "divider": "from",
                        "editable": true,
                        "future_description": "will reject a letter from",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-gift",
                        "key": "donation_rejected",
                        "label": "Donation Rejected",
                        "loggable": true,
                        "magic_lists": true,
                        "qcal_class": "note-cal-events",
                        "should_display_amount": true,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                        "supporter": false,
                        "value": 60
                    },
                    "drop_off_materials": {
                        "deprecated": true,
                        "description": "dropped materials off with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will drop off materials with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-briefcase",
                        "key": "drop_off_materials",
                        "label": "Drop Off Materials",
                        "loggable": true,
                        "magic_lists": false,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 82
                    },
                    "email": {
                        "deprecated": true,
                        "description": "emailed",
                        "divider": "to",
                        "editable": true,
                        "future_description": "will email",
                        "grassroots": true,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-envelope-o",
                        "key": "email",
                        "label": "Email Sent",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 5,
                        "order": 3,
                        "qcal_class": "note-cal-correspondence",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": false,
                        "value": 11
                    },
                    "email_exchange": {
                        "deprecated": false,
                        "description": "exchanged an email with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will exchange an email with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-envelope",
                        "key": "email_exchange",
                        "label": "Email Exchange",
                        "loggable": true,
                        "magic_lists": true,
                        "order": 5,
                        "qcal_class": "note-cal-correspondence",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": true,
                        "value": 35
                    },
                    "email_received": {
                        "deprecated": true,
                        "description": "received an email from",
                        "divider": "from",
                        "editable": true,
                        "future_description": "will receive an email from",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-envelope",
                        "key": "email_received",
                        "label": "Email Received",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 6,
                        "order": 4,
                        "qcal_class": "note-cal-correspondence",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": false,
                        "value": 16
                    },
                    "endorsement_given": {
                        "deprecated": true,
                        "description": "endorsed",
                        "divider": "to",
                        "editable": true,
                        "future_description": "will endorse",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-heart",
                        "key": "endorsement_given",
                        "label": "Endorsement Given",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 9,
                        "order": 10,
                        "qcal_class": "note-cal-events",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": false,
                        "value": 18
                    },
                    "endorsement_received": {
                        "deprecated": true,
                        "description": "received an endorsement from",
                        "divider": "from",
                        "editable": true,
                        "future_description": "will receive an endorsement from",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-heart-o",
                        "key": "endorsement_received",
                        "label": "Endorsement Received",
                        "loggable": true,
                        "magic_lists": true,
                        "order": 11,
                        "qcal_class": "note-cal-events",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": false,
                        "value": 19
                    },
                    "event_attended": {
                        "associated_QDT": 28,
                        "deprecated": true,
                        "description": "attended an event with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will attend an event with",
                        "grassroots": true,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-calendar",
                        "key": "event_attended",
                        "label": "Event Attended",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 4,
                        "order": 4,
                        "qcal_class": "note-cal-events",
                        "replace_with_name": "an event",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": false,
                        "value": 1
                    },
                    "event_hosted": {
                        "associated_QDT": 28,
                        "deprecated": true,
                        "description": "hosted an event with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will host an event with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-calendar",
                        "key": "event_hosted",
                        "label": "Event Hosted",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 11,
                        "order": 5,
                        "qcal_class": "note-cal-events",
                        "replace_with_name": "an event",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": false,
                        "value": 2
                    },
                    "event_registered": {
                        "deprecated": true,
                        "description": "registered for an event with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will register for an event with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-envelope-square",
                        "key": "event_registered",
                        "label": "Registered for an Event",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 17,
                        "order": 25,
                        "qcal_class": "note-cal-events",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 53
                    },
                    "exchanged_letter": {
                        "deprecated": true,
                        "description": "exchanged a letter with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will exchange a letter with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-envelope-o",
                        "key": "exchanged_letter",
                        "label": "Letter Exchanged",
                        "loggable": true,
                        "magic_lists": true,
                        "qcal_class": "note-cal-grassroots",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 57
                    },
                    "facility_visit": {
                        "deprecated": true,
                        "description": "visited a facility with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will visit a facility with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-industry",
                        "key": "facility_visit",
                        "label": "Facility Visit",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 14,
                        "order": 20,
                        "qcal_class": "note-cal-events",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 37
                    },
                    "filter_groups": [
                        {
                            "display": "list",
                            "title": "Correspondence",
                            "values": [95, 94, 36, 71, 98, 17, 90, 21, 82, 35, 16, 11, 57, 88, 89, 58, 67, 4, 61, 51, 33, 64, 49, 52, 3, 93, 92, 39, 86, 59, 9, 63, 73, 62, 54, 84, 72, 74, 66, 100, 101, 10, 65, 97, 38]
                        },
                        {
                            "display": "list",
                            "title": "Events",
                            "values": [69, 91, 7, 8, 60, 18, 19, 1, 2, 53, 37, 6, 5, 56, 80, 20, 87, 43, 47, 15, 14, 75, 55, 68, 99, 96, 83, 70, 102, 103]
                        },
                        {
                            "display": "list",
                            "title": "Grassroots",
                            "values": [25, 50, 26, 27, 28, 29, 30, 31, 34, 45, 44, 46, 48, 85, 109]
                        }
                    ],
                    "fundraiser_attended": {
                        "deprecated": true,
                        "description": "attended a fundraiser with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will attend a fundraiser with",
                        "grassroots": true,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-usd",
                        "key": "fundraiser_attended",
                        "label": "Fundraiser Attended",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 10,
                        "order": 18,
                        "qcal_class": "note-cal-events",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                        "supporter": false,
                        "value": 6
                    },
                    "fundraiser_thrown": {
                        "deprecated": true,
                        "description": "threw a fundraiser with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will throw a fundraiser with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-usd",
                        "key": "fundraiser_thrown",
                        "label": "Fundraiser Thrown",
                        "loggable": true,
                        "magic_lists": true,
                        "order": 17,
                        "qcal_class": "note-cal-events",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                        "supporter": false,
                        "value": 5
                    },
                    "grant": {
                        "deprecated": false,
                        "description": "gave a grant to",
                        "divider": "to",
                        "editable": true,
                        "future_description": "will give a grant to",
                        "grassroots": true,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-gift",
                        "key": "grant",
                        "label": "Grant",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 4,
                        "order": 8,
                        "qcal_class": "note-cal-events",
                        "should_display_amount": true,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                        "supporter": false,
                        "value": 56
                    },
                    "grassroots_phone_call": {
                        "deprecated": false,
                        "description": "spoke on the phone with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will speak on the phone with",
                        "grassroots": true,
                        "grassroots_description": "called for the campaign",
                        "grassroots_note_takes_campaign": true,
                        "icon": "fa-phone",
                        "key": "grassroots_phone_call",
                        "label": "Called By Phone",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 3,
                        "qcal_class": "note-cal-correspondence",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": true,
                        "value": 50
                    },
                    "health_center_visit": {
                        "deprecated": true,
                        "description": "visited a health center with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will visit a health center with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-hospital-o",
                        "key": "health_center_visit",
                        "label": "Health Center Visit",
                        "loggable": true,
                        "magic_lists": false,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                        "value": 80
                    },
                    "hearing_attended": {
                        "deprecated": true,
                        "description": "attended a hearing with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will attend a hearing with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-gavel",
                        "key": "hearing_attended",
                        "label": "Hearing Attended",
                        "loggable": true,
                        "magic_lists": true,
                        "order": 12,
                        "qcal_class": "note-cal-events",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 20
                    },
                    "high_priority": {
                        "deprecated": false,
                        "editable": false,
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-arrow-cirle-up",
                        "key": "high_priority",
                        "label": "High priority",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 0,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 40
                    },
                    "host_formal_public_meeting": {
                        "associated_QDT": 28,
                        "deprecated": true,
                        "description": "hosted a formal public meeting with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will host a formal public meeting with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-check",
                        "key": "host_formal_public_meeting",
                        "label": "Host Formal Public Meeting",
                        "loggable": true,
                        "magic_lists": true,
                        "qcal_class": "note-cal-correspondence",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": false,
                        "value": 102
                    },
                    "in_district_event": {
                        "associated_QDT": 28,
                        "deprecated": true,
                        "description": "attended an in-district event with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will attend an in-district event with",
                        "icon": "fa-calendar",
                        "key": "in_district_event",
                        "label": "In-District Event",
                        "loggable": true,
                        "magic_lists": false,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 87
                    },
                    "in_district_interaction": {
                        "deprecated": true,
                        "description": "had an in-district interaction with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will have an in-district interaction with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-map",
                        "key": "in_district_interaction",
                        "label": "In-District Interaction",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 14,
                        "order": 23,
                        "qcal_class": "note-cal-events",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                        "value": 43
                    },
                    "in_district_meeting": {
                        "deprecated": true,
                        "description": "attended an in-district meeting with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will attend an in-district meeting with",
                        "icon": "fa-check",
                        "key": "in_district_meeting",
                        "label": "In-District Meeting",
                        "loggable": true,
                        "magic_lists": false,
                        "qcal_class": "note-cal-correspondence",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 88
                    },
                    "intended_donation": {
                        "deprecated": false,
                        "description": "pledged to donate to",
                        "divider": "to",
                        "editable": true,
                        "future_description": "will pledge to donate to",
                        "grassroots": true,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-credit-card",
                        "key": "intended_donation",
                        "label": "Pledged Donation",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 3,
                        "order": 6,
                        "qcal_class": "note-cal-events",
                        "should_display_amount": true,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                        "supporter": false,
                        "value": 47
                    },
                    "intercept": {
                        "deprecated": true,
                        "description": "intercepted",
                        "divider": "intercepted",
                        "editable": true,
                        "future_description": "will intercept",
                        "icon": "fa-flag-o",
                        "key": "intercept",
                        "label": "Intercepted",
                        "loggable": true,
                        "magic_lists": false,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 89
                    },
                    "invitation_sent": {
                        "deprecated": true,
                        "description": "sent an invitation to",
                        "divider": "to",
                        "editable": true,
                        "future_description": "will send an invitation to",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-envelope-o",
                        "key": "invitation_sent",
                        "label": "Invitation Sent",
                        "loggable": true,
                        "magic_lists": true,
                        "qcal_class": "note-cal-grassroots",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 58
                    },
                    "legislative_ask": {
                        "deprecated": true,
                        "description": "made a legislative ask from",
                        "divider": "from",
                        "editable": true,
                        "future_description": "will make a legislative ask from",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-gavel",
                        "key": "legislative_ask",
                        "label": "Legislative Ask",
                        "loggable": true,
                        "magic_lists": true,
                        "qcal_class": "note-cal-correspondence",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": false,
                        "value": 67
                    },
                    "low_priority": {
                        "deprecated": false,
                        "editable": false,
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-arrow-cirle-down",
                        "key": "low_priority",
                        "label": "Low priority",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 0,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 42
                    },
                    "medium_priority": {
                        "deprecated": false,
                        "editable": false,
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-minus-square",
                        "key": "medium_priority",
                        "label": "Medium priority",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 0,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 41
                    },
                    "meeting": {
                        "deprecated": true,
                        "description": "met with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will meet with",
                        "grassroots": true,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-check",
                        "key": "meeting",
                        "label": "Meeting",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 1,
                        "order": 0,
                        "qcal_class": "note-cal-correspondence",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": false,
                        "value": 4
                    },
                    "multi_campaigned": {
                        "deprecated": false,
                        "description": "participated in a combined campaign",
                        "editable": false,
                        "future_description": "intends to participate in a combined campaign",
                        "grassroots": true,
                        "grassroots_description": "participated in the combined campaign",
                        "grassroots_note_takes_campaign": true,
                        "icon": "fa-share-alt",
                        "key": "multi_campaigned",
                        "label": "Participated in Combined Campaign",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 24,
                        "qcal_class": "note-cal-grassroots",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": true,
                        "value": 48
                    },
                    "news_received": {
                        "deprecated": true,
                        "description": "received news clips concerning",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will receive news clips concerning",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-envelope-o",
                        "key": "news_received",
                        "label": "Critical Clips",
                        "loggable": true,
                        "magic_lists": true,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                        "value": 61
                    },
                    "offered_amendment": {
                        "deprecated": true,
                        "description": "offered an amendment with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will offer an amendment with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-gavel",
                        "key": "offered_amendment",
                        "label": "Offered Amendment",
                        "loggable": true,
                        "magic_lists": false,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 81
                    },
                    "office_visit": {
                        "deprecated": true,
                        "description": "visited the office of",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will visit the office of",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-bank",
                        "key": "office_visit",
                        "label": "Office Visit",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 15,
                        "order": 23,
                        "qcal_class": "note-cal-events",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 51
                    },
                    "op_ed_published": {
                        "deprecated": true,
                        "description": "published an op-ed with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will publish an op-ed with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-newspaper-o",
                        "key": "op_ed_published",
                        "label": "Op-Ed Published",
                        "loggable": true,
                        "magic_lists": false,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 78
                    },
                    "opened_email": {
                        "deprecated": false,
                        "editable": false,
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-folder-open",
                        "key": "opened_email",
                        "label": "Opened Email",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 9,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 33
                    },
                    "oppose": {
                        "deprecated": false,
                        "editable": false,
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-thumbs-down",
                        "key": "oppose",
                        "label": "Oppose",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 0,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 23
                    },
                    "other": {
                        "deprecated": true,
                        "description": "interacted with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will interact with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-handshake-o",
                        "key": "other",
                        "label": "Other",
                        "loggable": true,
                        "magic_lists": true,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 64
                    },
                    "outbox_email": {
                        "associated_QDT": 44,
                        "deprecated": false,
                        "description": "sent an Outbox email to",
                        "divider": "with",
                        "editable": false,
                        "future_description": "will send an Outbox email to",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-envelope",
                        "key": "outbox_email",
                        "label": "Outbox Email",
                        "loggable": false,
                        "magic_lists": true,
                        "mobile_order": 6.5,
                        "order": 5.5,
                        "qcal_class": "note-cal-correspondence",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": true,
                        "value": 49
                    },
                    "personal_interaction": {
                        "deprecated": true,
                        "description": "had a personal interaction with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will have a personal interaction with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-hand-paper-o",
                        "key": "personal_interaction",
                        "label": "Personal Interaction",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 16,
                        "order": 24,
                        "qcal_class": "note-cal-events",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 52
                    },
                    "phone_call": {
                        "deprecated": true,
                        "description": "spoke on the phone with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will speak on the phone with",
                        "grassroots": false,
                        "grassroots_description": "called for the campaign",
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-phone",
                        "key": "phone_call",
                        "label": "Phone Call",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 2,
                        "order": 1,
                        "qcal_class": "note-cal-correspondence",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": false,
                        "value": 3
                    },
                    "placed_ad": {
                        "deprecated": true,
                        "description": "placed an Ad with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will place an Ad with",
                        "grassroots": false,
                        "icon": "fa-address-card-o",
                        "key": "placed_ad",
                        "label": "Placed an Ad",
                        "loggable": true,
                        "magic_lists": false,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 93
                    },
                    "press_conference_attended": {
                        "deprecated": true,
                        "description": "attended a press conference with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will attend a press conference with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-microphone",
                        "key": "press_conference_attended",
                        "label": "Press Conference Attended",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 12,
                        "order": 14,
                        "qcal_class": "note-cal-events",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": false,
                        "value": 15
                    },
                    "press_conference_held": {
                        "deprecated": true,
                        "description": "held a press conference with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will hold a press conference with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-microphone",
                        "key": "press_conference_held",
                        "label": "Press Conference Held",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 13,
                        "order": 13,
                        "qcal_class": "note-cal-events",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": false,
                        "value": 14
                    },
                    "press_conference_speaking_role": {
                        "deprecated": true,
                        "description": "has a press conference speaking role with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will have a press conference speaking role with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-bullhorn",
                        "key": "press_conference_speaking_role",
                        "label": "Press Conference Speaking Role",
                        "loggable": true,
                        "magic_lists": false,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 75
                    },
                    "press_quote": {
                        "deprecated": true,
                        "description": "has a press quote with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will have a press quote with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-newspaper-o",
                        "key": "press_quote",
                        "label": "Press Quote",
                        "loggable": true,
                        "magic_lists": false,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 77
                    },
                    "press_release_issued": {
                        "deprecated": true,
                        "description": "issued a press release with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will issue a press release with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-newspaper-o",
                        "key": "press_release_issued",
                        "label": "Press Release Issued",
                        "loggable": true,
                        "magic_lists": false,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 76
                    },
                    "public_consultation": {
                        "deprecated": true,
                        "description": "consulted with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will consult with",
                        "grassroots": false,
                        "icon": "fa-comments-o",
                        "key": "public_consultation",
                        "label": "Public Consultation",
                        "loggable": true,
                        "magic_lists": false,
                        "should_display_amount": false,
                        "supported_regions": [106],
                        "value": 92
                    },
                    "received_letter": {
                        "deprecated": true,
                        "description": "received a letter from",
                        "divider": "from",
                        "editable": true,
                        "future_description": "will receive a letter from",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-envelope",
                        "key": "received_letter",
                        "label": "Received Letter",
                        "loggable": true,
                        "magic_lists": true,
                        "order": 22,
                        "qcal_class": "note-cal-events",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 39
                    },
                    "registered": {
                        "deprecated": false,
                        "description": "registered for your fly in",
                        "editable": false,
                        "grassroots": false,
                        "grassroots_description": "registered for your fly in",
                        "grassroots_note_takes_campaign": true,
                        "icon": "fa-plane",
                        "key": "registered",
                        "label": "Registered for Fly In",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 3,
                        "qcal_class": "note-cal-grassroots",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": true,
                        "value": 27
                    },
                    "registration_form_registered": {
                        "deprecated": false,
                        "description": "registered on",
                        "divider": "on",
                        "editable": false,
                        "future_description": "will register on",
                        "grassroots": false,
                        "grassroots_description": "registered with your registration form",
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-pencil-square-o",
                        "key": "registration_form_registered",
                        "label": "Registered on Registration Form",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 10,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 85
                    },
                    "requested_in_district_meeting": {
                        "deprecated": true,
                        "description": "requested an in-district meeting with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will request an in-district meeting with",
                        "icon": "fa-check",
                        "key": "requested_in_district_meeting",
                        "label": "Requested In-District Meeting",
                        "loggable": true,
                        "magic_lists": false,
                        "qcal_class": "note-cal-correspondence",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 86
                    },
                    "scheduled": {
                        "deprecated": false,
                        "description": "scheduled a meeting with",
                        "editable": false,
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-calendar",
                        "key": "scheduled",
                        "label": "Schedule",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 7,
                        "qcal_class": "note-cal-grassroots",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": true,
                        "value": 31
                    },
                    "sent_letter": {
                        "deprecated": true,
                        "description": "sent a letter to",
                        "divider": "to",
                        "editable": true,
                        "future_description": "will send a letter to",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-envelope-o",
                        "key": "sent_letter",
                        "label": "Sent Letter",
                        "loggable": true,
                        "magic_lists": true,
                        "qcal_class": "note-cal-grassroots",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 59
                    },
                    "share": {
                        "deprecated": false,
                        "description": "shared on social media",
                        "editable": false,
                        "grassroots": false,
                        "grassroots_description": "shared the link in your campaign",
                        "grassroots_note_takes_campaign": true,
                        "icon": "fa-bullhorn",
                        "key": "share",
                        "label": "Shared on Social Media",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 6,
                        "qcal_class": "note-cal-grassroots",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": true,
                        "value": 30
                    },
                    "sighting": {
                        "deprecated": true,
                        "description": "saw",
                        "divider": "of",
                        "editable": true,
                        "future_description": "will see",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-eye",
                        "key": "sighting",
                        "label": "Sighting",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 7,
                        "order": 16,
                        "qcal_class": "note-cal-correspondence",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": false,
                        "value": 9
                    },
                    "signature": {
                        "deprecated": false,
                        "description": "participated",
                        "editable": false,
                        "future_description": "will participate",
                        "grassroots": false,
                        "grassroots_description": "participated in campaign",
                        "grassroots_note_takes_campaign": true,
                        "icon": "fa-pencil",
                        "key": "signature",
                        "label": "Custom Call to Action",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 4,
                        "qcal_class": "note-cal-grassroots",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": true,
                        "value": 28
                    },
                    "signed_amicus_brief": {
                        "deprecated": true,
                        "description": "signed an amicus brief with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will sign an amicus brief with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-gavel",
                        "key": "signed_amicus_brief",
                        "label": "Signed Amicus Brief",
                        "loggable": true,
                        "magic_lists": true,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                        "value": 63
                    },
                    "signed_coalition_letter": {
                        "deprecated": true,
                        "description": "signed a coalition letter with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will sign a coalition letter with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-envelope-o",
                        "key": "signed_coalition_letter",
                        "label": "Signed Coalition Letter",
                        "loggable": true,
                        "magic_lists": false,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 73
                    },
                    "signed_group_letter": {
                        "deprecated": true,
                        "description": "signed a group letter with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will sign a group letter with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-envelope-o",
                        "key": "signed_group_letter",
                        "label": "Signed Group Letter",
                        "loggable": true,
                        "magic_lists": true,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 62
                    },
                    "signed_letter": {
                        "deprecated": true,
                        "description": "recorded that a Letter was signed by",
                        "divider": "by",
                        "editable": true,
                        "future_description": "recorded that a Letter will be signed by",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-pencil-square-o",
                        "key": "signed_letter",
                        "label": "Signed a Letter",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 18,
                        "order": 26,
                        "qcal_class": "note-cal-events",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 54
                    },
                    "social_media_post": {
                        "deprecated": true,
                        "description": "interacted on social media with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will interact on social media with",
                        "grassroots": true,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-share-square-o",
                        "key": "social_media_post",
                        "label": "Social Media",
                        "loggable": true,
                        "magic_lists": false,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                        "value": 84
                    },
                    "spoke_at_briefing": {
                        "deprecated": true,
                        "description": "spoke at a briefing with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will speak at a briefing with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-briefcase",
                        "key": "spoke_at_briefing",
                        "label": "Spoke at Briefing",
                        "loggable": true,
                        "magic_lists": false,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 72
                    },
                    "spoke_at_event": {
                        "deprecated": true,
                        "description": "spoke at an event with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will speak at an event with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-bullhorn",
                        "key": "spoke_at_event",
                        "label": "Spoke at Event",
                        "loggable": true,
                        "magic_lists": false,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 74
                    },
                    "sponsorship": {
                        "deprecated": false,
                        "description": "sponsored",
                        "divider": "to",
                        "editable": true,
                        "future_description": "will sponsor",
                        "grassroots": true,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-gift",
                        "key": "sponsorship",
                        "label": "Sponsorship",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 4,
                        "order": 8,
                        "qcal_class": "note-cal-events",
                        "should_display_amount": true,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                        "supporter": false,
                        "value": 55
                    },
                    "staff_delegation": {
                        "deprecated": true,
                        "description": "attended a staff delegation",
                        "editable": true,
                        "future_description": "will attend a staff delegation",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-check",
                        "key": "staff_delegation",
                        "label": "Staff Delegation",
                        "loggable": true,
                        "magic_lists": true,
                        "qcal_class": "note-cal-correspondence",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": false,
                        "value": 68
                    },
                    "support": {
                        "deprecated": false,
                        "editable": false,
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-thumbs-up",
                        "key": "support",
                        "label": "Support",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 0,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 22
                    },
                    "tele_town_hall": {
                        "deprecated": true,
                        "description": "hosted a tele-town hall with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will host a tele-town hall with",
                        "grassroots": false,
                        "icon": "fa-comments-o",
                        "key": "tele_town_hall",
                        "label": "Tele-Town Hall",
                        "loggable": true,
                        "magic_lists": false,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 99
                    },
                    "teleconference": {
                        "deprecated": true,
                        "description": "had a teleconference with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will have a teleconference with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-check",
                        "key": "teleconference",
                        "label": "Teleconference",
                        "loggable": true,
                        "magic_lists": true,
                        "qcal_class": "note-cal-correspondence",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": false,
                        "value": 66
                    },
                    "testified": {
                        "deprecated": true,
                        "description": "testified at",
                        "divider": "at",
                        "editable": true,
                        "future_description": "will testify at",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-gavel",
                        "key": "testified",
                        "label": "Testified",
                        "loggable": true,
                        "magic_lists": false,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002],
                        "value": 83
                    },
                    "text_received": {
                        "deprecated": true,
                        "description": "received a text from",
                        "divider": "from",
                        "editable": true,
                        "future_description": "will receive a text from",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-envelope-o",
                        "key": "text_received",
                        "label": "Text Received",
                        "loggable": true,
                        "magic_lists": true,
                        "qcal_class": "note-cal-correspondence",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": false,
                        "value": 100
                    },
                    "text_sent": {
                        "deprecated": true,
                        "description": "sent a text to",
                        "divider": "to",
                        "editable": true,
                        "future_description": "will send a text to",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-envelope-o",
                        "key": "text_sent",
                        "label": "Text Sent",
                        "loggable": true,
                        "magic_lists": true,
                        "qcal_class": "note-cal-correspondence",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": false,
                        "value": 101
                    },
                    "told_grassroots_story": {
                        "deprecated": false,
                        "description": "told a Grassroots story",
                        "editable": false,
                        "future_description": "will tell a grassroots story",
                        "grassroots": false,
                        "grassroots_description": "shared a story",
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-user",
                        "key": "told_grassroots_story",
                        "label": "Submitted Grassroots Story",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 12,
                        "qcal_class": "note-cal-grassroots",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": true,
                        "value": 45
                    },
                    "training": {
                        "deprecated": true,
                        "description": "attended a training with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will attend a training with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-briefcase",
                        "key": "training",
                        "label": "Training",
                        "loggable": true,
                        "magic_lists": false,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 70
                    },
                    "tv_radio": {
                        "deprecated": true,
                        "description": "spoke on the TV/Radio with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will speak on the TV/Radio with",
                        "grassroots": false,
                        "icon": "fa-television",
                        "key": "tv_radio",
                        "label": "TV/Radio",
                        "loggable": true,
                        "magic_lists": false,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 96
                    },
                    "tweet": {
                        "deprecated": false,
                        "description": "tweeted at",
                        "editable": false,
                        "future_description": "will tweet at",
                        "grassroots": false,
                        "grassroots_description": "tweeted for the campaign",
                        "grassroots_note_takes_campaign": true,
                        "icon": "fa-twitter",
                        "key": "tweet",
                        "label": "Sent a Tweet",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 5,
                        "qcal_class": "note-cal-grassroots",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": true,
                        "value": 29
                    },
                    "unexpected_interaction": {
                        "deprecated": true,
                        "description": "unexpectedly saw",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will unexpectedly see",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-flag-o",
                        "key": "unexpected_interaction",
                        "label": "Unexpected Interaction",
                        "loggable": true,
                        "magic_lists": true,
                        "mobile_order": 8,
                        "order": 8,
                        "qcal_class": "note-cal-correspondence",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": false,
                        "value": 10
                    },
                    "unimportant": {
                        "deprecated": false,
                        "editable": false,
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-circle-thin",
                        "key": "unimportant",
                        "label": "Irrelevant",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 0,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 24
                    },
                    "virtual_meeting": {
                        "deprecated": true,
                        "description": "virtually met with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will virtually meet with",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-check",
                        "key": "virtual_meeting",
                        "label": "Virtual Meeting",
                        "loggable": true,
                        "magic_lists": true,
                        "qcal_class": "note-cal-correspondence",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": false,
                        "value": 65
                    },
                    "visit": {
                        "deprecated": false,
                        "description": "visited",
                        "editable": false,
                        "future_description": "will visit",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-suitcase",
                        "key": "visit",
                        "label": "Visited Office",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 2,
                        "qcal_class": "note-cal-grassroots",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": true,
                        "value": 26
                    },
                    "watching": {
                        "deprecated": false,
                        "editable": false,
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-eye",
                        "key": "watching",
                        "label": "Watching",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 8,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 32
                    },
                    "white_paper_research": {
                        "deprecated": true,
                        "description": "released research with",
                        "divider": "with",
                        "editable": true,
                        "future_description": "will release research with",
                        "grassroots": false,
                        "icon": "fa-flask",
                        "key": "white_paper_research",
                        "label": "White Paper/Research",
                        "loggable": true,
                        "magic_lists": false,
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 97
                    },
                    "wrote_letter": {
                        "deprecated": false,
                        "description": "wrote a letter to",
                        "editable": false,
                        "future_description": "will write a letter to",
                        "grassroots": false,
                        "grassroots_description": "wrote a letter for the campaign",
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-envelope-o",
                        "key": "wrote_letter",
                        "label": "Wrote Letter",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 13,
                        "qcal_class": "note-cal-grassroots",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": true,
                        "value": 25
                    },
                    "wrote_site_letter": {
                        "deprecated": true,
                        "description": "wrote a site letter to",
                        "divider": "to",
                        "editable": true,
                        "future_description": "will write a site letter to",
                        "grassroots": false,
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-envelope",
                        "key": "wrote_site_letter",
                        "label": "Wrote a Site Letter",
                        "loggable": true,
                        "magic_lists": true,
                        "order": 21,
                        "qcal_class": "note-cal-events",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "value": 38
                    },
                    "wrote_white_house": {
                        "deprecated": false,
                        "description": "wrote to the White House",
                        "editable": false,
                        "future_description": "will write to the White House",
                        "grassroots": false,
                        "grassroots_description": "wrote the White House",
                        "grassroots_note_takes_campaign": false,
                        "icon": "fa-envelope-o",
                        "key": "wrote_white_house",
                        "label": "Wrote to the White House",
                        "loggable": false,
                        "magic_lists": false,
                        "order": 13,
                        "qcal_class": "note-cal-grassroots",
                        "should_display_amount": false,
                        "supported_regions": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                        "supporter": true,
                        "value": 46
                    }
                },
                "PossibleDurations": {
                    "__is_enum": true,
                    "fifteen_minutes": { "default": false, "in_minutes": 15, "key": "fifteen_minutes", "label": "15 mins", "order": 2, "value": 15 },
                    "five_days": { "default": false, "in_minutes": 7200, "key": "five_days", "label": "5 days", "order": 22, "value": 46 },
                    "five_minutes": { "default": false, "in_minutes": 5, "key": "five_minutes", "label": "5 mins", "order": 1, "value": 5 },
                    "four_days": { "default": false, "in_minutes": 5760, "key": "four_days", "label": "4 days", "order": 21, "value": 36 },
                    "four_hours": { "default": false, "in_minutes": 240, "key": "four_hours", "label": "4 hrs", "order": 17, "value": 4 },
                    "fourty_five_minutes": { "default": false, "in_minutes": 45, "key": "fourty_five_minutes", "label": "45 mins", "order": 4, "value": 45 },
                    "ninety_minutes": { "default": false, "in_minutes": 90, "key": "ninety_minutes", "label": "1.5 hrs", "order": 7, "value": 90 },
                    "none": { "default": false, "in_minutes": 0, "key": "none", "label": "N/A", "order": 25 },
                    "one_day": { "default": false, "in_minutes": 1440, "key": "one_day", "label": "1 day", "order": 18, "value": 9 },
                    "one_hour": { "default": false, "in_minutes": 60, "key": "one_hour", "label": "1 hr", "order": 5, "value": 1 },
                    "one_hour_fifteen_minutes": { "default": false, "in_minutes": 75, "key": "one_hour_fifteen_minutes", "label": "1.25 hrs", "order": 6, "value": 75 },
                    "one_hour_forty_five_minutes": { "default": false, "in_minutes": 105, "key": "one_hour_forty_five_minutes", "label": "1.75 hrs", "order": 8, "value": 105 },
                    "seven_days": { "default": false, "in_minutes": 10080, "key": "seven_days", "label": "7 days", "order": 24, "value": 63 },
                    "six_days": { "default": false, "in_minutes": 8640, "key": "six_days", "label": "6 days", "order": 23, "value": 54 },
                    "thirty_minutes": { "default": true, "in_minutes": 30, "key": "thirty_minutes", "label": "30 mins", "order": 3, "value": 30 },
                    "three_days": { "default": false, "in_minutes": 4320, "key": "three_days", "label": "3 days", "order": 20, "value": 27 },
                    "three_hours": { "default": false, "in_minutes": 180, "key": "three_hours", "label": "3 hrs", "order": 13, "value": 3 },
                    "three_hours_fifteen_minutes": { "default": false, "in_minutes": 195, "key": "three_hours_fifteen_minutes", "label": "3.25 hrs", "order": 14, "value": 195 },
                    "three_hours_fourty_five_minutes": { "default": false, "in_minutes": 225, "key": "three_hours_fourty_five_minutes", "label": "3.75 hrs", "order": 16, "value": 225 },
                    "three_hours_thirty_minutes": { "default": false, "in_minutes": 210, "key": "three_hours_thirty_minutes", "label": "3.5 hrs", "order": 15, "value": 210 },
                    "two_days": { "default": false, "in_minutes": 2880, "key": "two_days", "label": "2 days", "order": 19, "value": 18 },
                    "two_hours": { "default": false, "in_minutes": 120, "key": "two_hours", "label": "2 hrs", "order": 9, "value": 2 },
                    "two_hours_fifteen_minutes": { "default": false, "in_minutes": 135, "key": "two_hours_fifteen_minutes", "label": "2.25 hrs", "order": 10, "value": 135 },
                    "two_hours_fourty_five_minutes": { "default": false, "in_minutes": 165, "key": "two_hours_fourty_five_minutes", "label": "2.75 hrs", "order": 12, "value": 165 },
                    "two_hours_thirty_minutes": { "default": false, "in_minutes": 150, "key": "two_hours_thirty_minutes", "label": "2.5 hrs", "order": 11, "value": 150 },
                    "zero": { "default": false, "in_minutes": 0, "key": "zero", "label": "Not Applicable (N/A)", "order": 0, "value": 0 }
                },
                "RelationshipType": {
                    "__is_enum": true,
                    "ambassador_donor": { "divider": "with", "grouped": "have a relationship through a donation", "icon": "fa-gift", "key": "ambassador_donor", "label": "Donor Relationship", "past_grouped": "had a relationship through a donation", "past_third_person": "had a relationship through a donation", "plural_label": "Donor Relationship", "second_person": "have a relationship through a donation", "survey_question": "Are you a donor", "third_person": "has a relationship through a donation", "value": 12 },
                    "board": { "divider": "of", "grouped": "sit on a board", "icon": "fa-user-circle", "key": "board", "label": "Board", "mobile_order": 8, "order": 8, "past_grouped": "sat on a board", "past_third_person": "sat on a board", "plural_label": "Sit on a board", "second_person": "sit on a board", "survey_question": "Do you sit on a board", "third_person": "sits on a board", "value": 11 },
                    "classmate": { "divider": "of", "grouped": "are classmates", "icon": "fa-graduation-cap", "key": "classmate", "label": "Classmate", "mobile_order": 4, "order": 4, "past_grouped": "are classmates", "past_third_person": "are classmates", "plural_label": "Classmates", "second_person": "are a classmate", "survey_question": "Are you a classmate", "third_person": "is a classmate", "value": 6 },
                    "colleague": { "divider": "of", "grouped": "are colleagues", "icon": "fa-briefcase", "key": "colleague", "label": "Colleague", "mobile_order": 3, "order": 3, "past_grouped": "were colleagues", "past_third_person": "were colleagues", "plural_label": "Colleagues", "second_person": "are a colleague", "survey_question": "Are you a colleague", "third_person": "is a colleague", "value": 5 },
                    "custom": { "divider": "with", "grouped": "have a relationship", "icon": "fa-exchange", "key": "custom", "label": "Other", "mobile_order": 9, "order": 9, "past_grouped": "had a relationship", "past_third_person": "had a relationship", "plural_label": "Other", "second_person": "have a relationship", "survey_question": "Do you have any other relationships or connections", "third_person": "has a relationship", "value": 1 },
                    "family": { "divider": "with", "grouped": "are connected through family", "icon": "fa-sign-language", "key": "family", "label": "Family Connection", "mobile_order": 6, "order": 6, "past_grouped": "were connected through family", "past_third_person": "were connected through family", "plural_label": "Family Connection", "second_person": "have a family connection", "survey_question": "Do you share a family connection", "third_person": "has a family connection", "value": 8 },
                    "friend": { "divider": "of", "grouped": "are friends", "icon": "fa-hand-peace-o", "key": "friend", "label": "Friend", "mobile_order": 5, "order": 5, "past_grouped": "are friends", "past_third_person": "are friends", "plural_label": "Friends", "second_person": "are a friend", "survey_question": "Are you a friend", "third_person": "is a friend", "value": 7 },
                    "neighbor": { "divider": "with", "grouped": "are neighbors", "icon": "fa-home", "key": "neighbor", "label": "Neighbor", "mobile_order": 7, "order": 7, "past_grouped": "were neighbors", "past_third_person": "were neighbors", "plural_label": "Neighbors", "second_person": "are neighbors", "survey_question": "Are you neighbors", "third_person": "is neighbors", "value": 9 },
                    "none": { "divider": "with", "grouped": "have no relationship", "icon": "fa-user-times", "key": "none", "label": "No Relationship", "mobile_order": 10, "order": 10, "past_grouped": "had no relationship", "past_third_person": "had no relationship", "plural_label": "No Relationship", "second_person": "have no relationship", "survey_question": "Do you have no relationship", "third_person": "has no relationship", "value": 10 },
                    "political": { "divider": "with", "grouped": "are political acquaintances", "icon": "fa-handshake-o", "key": "political", "label": "Political Acquaintance", "mobile_order": 1, "order": 1, "past_grouped": "were political acquaintances", "past_third_person": "were political acquaintances", "plural_label": "Political Acquaintances", "second_person": "are politically acquainted", "survey_question": "Are you politically acquainted", "third_person": "is politically acquainted", "value": 3 },
                    "professional": { "divider": "with", "grouped": "are professional acquaintances", "icon": "fa-address-card-o", "key": "professional", "label": "Professional Acquaintance", "mobile_order": 0, "order": 0, "past_grouped": "were professional acquaintances", "past_third_person": "were professional acquaintances", "plural_label": "Professional Acquaintances", "second_person": "are professionally acquainted", "survey_question": "Are you professionally acquainted", "third_person": "is professionally acquainted", "value": 2 },
                    "social": { "divider": "with", "grouped": "are social acquaintances", "icon": "fa-coffee", "key": "social", "label": "Social Acquaintance", "mobile_order": 2, "order": 2, "past_grouped": "were social acquaintances", "past_third_person": "were social acquaintances", "plural_label": "Social Acquaintances", "second_person": "are socially acquainted", "survey_question": "Are you socially acquainted", "third_person": "is socially acquainted", "value": 4 },
                    "willing_to_call": { "divider": "", "grouped": "are willing to call", "icon": "fa-phone", "key": "willing_to_call", "label": "Willing to Call", "past_grouped": "were willing to call", "past_third_person": "was willing to call", "plural_label": "Willing to Call", "second_person": "are willing to call", "survey_question": "Are you willing to call", "third_person": "is willing to call", "value": 13 },
                    "willing_to_email": { "divider": "to", "grouped": "are willing to send a personal email", "icon": "fa-envelope-o", "key": "willing_to_email", "label": "Willing to Send Personal Email", "past_grouped": "were willing to send a personal email", "past_third_person": "was willing to send a personal email", "plural_label": "Willing to Send Personal Email", "second_person": "are willing to send a personal email", "survey_question": "Are you willing to send a personal email", "third_person": "is willing to send a personal email", "value": 15 },
                    "willing_to_visit": { "divider": "", "grouped": "are willing to visit", "icon": "fa-handshake-o", "key": "willing_to_visit", "label": "Willing to Visit", "past_grouped": "were willing to visit", "past_third_person": "was willing to visit", "plural_label": "Willing to Visit", "second_person": "are willing to visit", "survey_question": "Are you willing to visit", "third_person": "is willing to visit", "value": 14 }
                },
                "Timezones": {
                    "__is_enum": true,
                    "alaska": { "daylight_savings_offset": -8, "jstz": 540, "key": "alaska", "label": "Alaska Time", "order": 5, "pytz": "US/Alaska", "standard_offset": -9, "value": 5 },
                    "atlantic": { "daylight_savings_offset": -3, "jstz": 240, "key": "atlantic", "label": "Atlantic Time", "order": 13, "pytz": "America/Puerto_Rico", "standard_offset": -4, "value": 13 },
                    "british": { "daylight_savings_offset": 0, "jstz": 0, "key": "british", "label": "Greenwich Mean Time", "order": 7, "pytz": "Europe/London", "standard_offset": 0, "value": 7 },
                    "central": { "daylight_savings_offset": -5, "jstz": 360, "key": "central", "label": "Central Time", "order": 2, "pytz": "US/Central", "standard_offset": -6, "value": 2 },
                    "central_european": { "daylight_savings_offset": 2, "jstz": -60, "key": "central_european", "label": "Central European Time", "order": 8, "pytz": "Europe/Brussels", "standard_offset": 1, "value": 8 },
                    "chamorro": { "daylight_savings_offset": 10, "jstz": 600, "key": "chamorro", "label": "Chamorro Standard Time", "order": 12, "pytz": "Pacific/Guam", "standard_offset": 10, "value": 12 },
                    "eastern": { "daylight_savings_offset": -4, "jstz": 300, "key": "eastern", "label": "Eastern Time", "order": 1, "pytz": "US/Eastern", "standard_offset": -5, "value": 1 },
                    "eastern_european": { "daylight_savings_offset": 3, "jstz": -120, "key": "eastern_european", "label": "Eastern European Time", "order": 9, "pytz": "Europe/Helsinki", "standard_offset": 2, "value": 9 },
                    "hawaii": { "daylight_savings_offset": -9, "jstz": 600, "key": "hawaii", "label": "Hawaii Aleutian Time", "order": 6, "pytz": "US/Hawaii", "standard_offset": -10, "value": 6 },
                    "mountain": { "daylight_savings_offset": -6, "jstz": 420, "key": "mountain", "label": "Mountain Time", "order": 3, "pytz": "US/Mountain", "standard_offset": -7, "value": 3 },
                    "newfoundland": { "daylight_savings_offset": -2.5, "jstz": 210, "key": "newfoundland", "label": "Newfoundland Time", "order": 14, "pytz": "America/St_Johns", "standard_offset": -3.5, "value": 14 },
                    "pacific": { "daylight_savings_offset": -7, "jstz": 480, "key": "pacific", "label": "Pacific Time", "order": 4, "pytz": "US/Pacific", "standard_offset": -8, "value": 4 },
                    "samoa": { "daylight_savings_offset": 0, "jstz": 660, "key": "samoa", "label": "Samoa Standard Time", "order": 11, "pytz": "Pacific/Apia", "standard_offset": -11, "value": 11 },
                    "western_european": { "daylight_savings_offset": 1, "jstz": 60, "key": "western_european", "label": "Western European Time", "order": 10, "pytz": "Europe/Lisbon", "standard_offset": 0, "value": 10 }
                },
                "UserRole": {
                    "__is_enum": true,
                    "csm": { "key": "csm", "label": "Quorum CSM", "value": 3 },
                    "employee": { "key": "employee", "label": "Quorum Employee", "value": 2 },
                    "engineer": { "key": "engineer", "label": "Quorum Engineer", "value": 5 },
                    "filter_groups": [
                        {
                            "display": "list",
                            "title": "Superuser",
                            "values": [3, 4, 5]
                        },
                        {
                            "display": "list",
                            "title": "Staff",
                            "values": [2, 3, 4, 5]
                        }
                    ],
                    "support": { "key": "support", "label": "Quorum Support", "value": 4 },
                    "user": { "key": "user", "label": "User", "value": 1 }
                }
            }
        },
        "vote": {
            "models": {}
        },
        "widgets": {
            "templates": {},
            "types": {
                "AggregationFields": {
                    "__is_enum": true,
                    "amount": {
                        "aggregation_type": 1,
                        "filter_keys": { "107": "amount" },
                        "key": "amount",
                        "label": "Total Sum Amount (USD)",
                        "permission_enum": [8],
                        "qdts": [107],
                        "querymethod": { "107": "sum_aggregation" },
                        "value": 1
                    },
                    "amount_pac_v2": {
                        "aggregation_type": 1,
                        "filter_keys": { "135": "amount" },
                        "key": "amount_pac_v2",
                        "label": "Total Sum Amount (USD)",
                        "permission_enum": [215],
                        "qdts": [135],
                        "querymethod": { "135": "sum_aggregation" },
                        "value": 2
                    },
                    "filter_groups": [
                        {
                            "display": "list",
                            "title": "What",
                            "values": [1, 2]
                        }
                    ],
                    "valid_qdts": [107, 135]
                },
                "AggregationTypes": {
                    "__is_enum": true,
                    "count": { "key": "count", "label": "Count", "value": 2 },
                    "sum": { "key": "sum", "label": "Sum", "value": 1 }
                },
                "BarVisualizationDirection": {
                    "__is_enum": true,
                    "horizontal": { "key": "horizontal", "label": "Horizontal", "value": 1 },
                    "vertical": { "key": "vertical", "label": "Vertical", "value": 2 }
                },
                "BarVisualizationDisplayStyle": {
                    "__is_enum": true,
                    "one_hundred_percent_stacked": { "key": "one_hundred_percent_stacked", "label": "100% Stacked", "value": 3 },
                    "regular": { "key": "regular", "label": "Regular", "value": 1 },
                    "stacked": { "key": "stacked", "label": "Stacked", "value": 2 }
                },
                "DashboardWidgetType": {
                    "__is_enum": true,
                    "calendar_widget": { "feature_flags": null, "icon": "list", "initialH": 11, "initialW": 2, "key": "calendar_widget", "label": "Calendar Events", "maxH": null, "maxW": null, "minH": 6, "minW": 2, "permission_enum": null, "shape": "(((dehydrate_extra?:dehydrate_extra&format?:format)&data_sources:[list_widget_data_source])&meta:meta)", "sort": 0, "tip": "List of upcoming calendar events.", "uses_widget_engine": true, "value": 9 },
                    "favorites_widget": { "feature_flags": null, "icon": "star", "initialH": 7, "initialW": 1, "key": "favorites_widget", "label": "Favorite Searches Widget", "maxH": null, "maxW": null, "minH": 7, "minW": 1, "permission_enum": null, "shape": "meta:meta", "sort": 6, "tip": "Keeps track of your favorited searches.", "uses_widget_engine": false, "value": 6 },
                    "list_widget": { "feature_flags": null, "icon": "list", "initialH": 11, "initialW": 2, "key": "list_widget", "label": "List Widget", "maxH": null, "maxW": null, "minH": 6, "minW": 2, "permission_enum": null, "shape": "(((dehydrate_extra?:dehydrate_extra&format?:format)&data_sources:[list_widget_data_source])&meta:meta)", "sort": 0, "tip": "Keep the most important things like stakeholders, events, or bills right at at your fingertips in an auto-updating list.", "uses_widget_engine": true, "value": 1 },
                    "number_widget": { "feature_flags": null, "icon": "hashtag", "initialH": 11, "initialW": 2, "key": "number_widget", "label": "Number Widget", "maxH": null, "maxW": null, "minH": 5, "minW": null, "permission_enum": null, "shape": "((colors?:colors&(data_sources:[number_widget_data_source]&operation?:operation))&meta:meta)", "sort": 1, "tip": "Easily keep track of counts on campaigns, votes, participants, etc. These can be displayed as a count, percentage, or fraction.", "uses_widget_engine": true, "value": 2 },
                    "quicklink_widget": { "feature_flags": null, "icon": "link", "initialH": 2, "initialW": 1, "key": "quicklink_widget", "label": "Quicklink", "maxH": 2, "maxW": null, "minH": 2, "minW": null, "permission_enum": null, "shape": "meta:quicklink_meta", "sort": 5, "tip": "Create buttons that link quickly to your favorite searches, sheets, or external URLs.", "uses_widget_engine": false, "value": 4 },
                    "recents_widget": { "feature_flags": null, "icon": "star", "initialH": 7, "initialW": 1, "key": "recents_widget", "label": "Recent Searches Widget", "maxH": null, "maxW": null, "minH": 7, "minW": 1, "permission_enum": null, "shape": "meta:meta", "sort": 7, "tip": "Keeps track of your most recent searches.", "uses_widget_engine": false, "value": 7 },
                    "text_widget": { "feature_flags": null, "icon": "text", "initialH": 7, "initialW": 2, "key": "text_widget", "label": "Text Widget", "maxH": null, "maxW": null, "minH": 4, "minW": null, "permission_enum": null, "shape": "meta:meta", "sort": 2, "tip": "A plain text box that is good for instructions, insights, or inspirational quotes.", "uses_widget_engine": false, "value": 3 },
                    "trending_widget": {
                        "feature_flags": null,
                        "icon": "chart-line",
                        "initialH": 10,
                        "initialW": 1,
                        "key": "trending_widget",
                        "label": "Trending Topics Widget",
                        "maxH": null,
                        "maxW": null,
                        "minH": 10,
                        "minW": 1,
                        "permission_enum": [104],
                        "shape": "(data_sources:[trending_data_source]&meta:meta)",
                        "sort": 8,
                        "tip": "Create this widget in order to stay up to date on news that is currently trending.",
                        "uses_widget_engine": true,
                        "value": 8
                    },
                    "visualization_widget": { "feature_flags": null, "icon": "chart-bar", "initialH": 9, "initialW": 3, "key": "visualization_widget", "label": "Visualization Widget", "maxH": null, "maxW": null, "minH": 9, "minW": 2, "permission_enum": null, "shape": "((data_sources:[group_by_data_source]&meta:vis_meta)|((colors?:colors&(data_sources:[number_widget_data_source]&operation?:operation))&meta:vis_meta))", "sort": 4, "tip": "Choose from line, pie, maps, or bar charts to compare data, monitor trends, and discover insights.", "uses_widget_engine": true, "value": 5 }
                },
                "DateFields": {
                    "__is_enum": true,
                    "alignment_over_time": {
                        "key": "alignment_over_time",
                        "label": "Alignment Over Time",
                        "qdts": [1, 8, 12, 120],
                        "querymethod": "alignment_over_time_frequency_tuples",
                        "supported_line_graph_time_intervals": [1, 2, 3, 4, 5],
                        "value": 20
                    },
                    "comment_close_date": {
                        "field": "comment_close_date",
                        "key": "comment_close_date",
                        "label": "Comment Close Date",
                        "qdts": [80],
                        "supported_line_graph_time_intervals": [1, 2, 3, 4, 5],
                        "value": 17
                    },
                    "created": {
                        "field": "created",
                        "key": "created",
                        "label": "Created",
                        "qdts": [62, 69, 45, 119, 134, 12, 3],
                        "supported_line_graph_time_intervals": [1, 2, 3, 4, 5],
                        "value": 1
                    },
                    "current_status_date": {
                        "field": "current_status_date",
                        "key": "current_status_date",
                        "label": "Current Status Date",
                        "qdts": [2],
                        "supported_line_graph_time_intervals": [1, 2, 3, 4, 5],
                        "value": 4
                    },
                    "date": {
                        "field": "date",
                        "key": "date",
                        "label": "Date",
                        "qdts": [69, 20],
                        "supported_line_graph_time_intervals": [1, 2, 3, 4, 5],
                        "value": 19
                    },
                    "date_time": {
                        "field": "date_time",
                        "key": "date_time",
                        "label": "Date",
                        "qdts": [25],
                        "supported_line_graph_time_intervals": [1, 2, 3, 4, 5],
                        "value": 7
                    },
                    "detailed_status_date": {
                        "field": "detailed_status_date",
                        "key": "detailed_status_date",
                        "label": "Detailed Status Date",
                        "qdts": [80],
                        "supported_line_graph_time_intervals": [1, 2, 3, 4, 5],
                        "value": 12
                    },
                    "effective_date": {
                        "field": "effective_date",
                        "key": "effective_date",
                        "label": "Effective Date",
                        "qdts": [80],
                        "supported_line_graph_time_intervals": [1, 2, 3, 4, 5],
                        "value": 15
                    },
                    "election_year": {
                        "key": "election_year",
                        "label": "Election Year",
                        "qdts": [114],
                        "querymethod": "year_frequency_tuples",
                        "supported_line_graph_time_intervals": [5],
                        "value": 21
                    },
                    "end": {
                        "field": "end",
                        "key": "end",
                        "label": "End",
                        "qdts": [28],
                        "supported_line_graph_time_intervals": [1, 2, 3, 4, 5],
                        "value": 9
                    },
                    "estimated_published_date": {
                        "field": "estimated_published_date",
                        "key": "estimated_published_date",
                        "label": "Published Date",
                        "qdts": [105],
                        "supported_line_graph_time_intervals": [1, 2, 3, 4, 5],
                        "value": 18
                    },
                    "expiration_date": {
                        "field": "expiration_date",
                        "key": "expiration_date",
                        "label": "Expiration Date",
                        "qdts": [80],
                        "supported_line_graph_time_intervals": [1, 2, 3, 4, 5],
                        "value": 16
                    },
                    "general_status_date": {
                        "field": "general_status_date",
                        "key": "general_status_date",
                        "label": "General Status Date",
                        "qdts": [80],
                        "supported_line_graph_time_intervals": [1, 2, 3, 4, 5],
                        "value": 11
                    },
                    "introduced_date": {
                        "field": "introduced_date",
                        "key": "introduced_date",
                        "label": "Introduced Date",
                        "qdts": [2],
                        "supported_line_graph_time_intervals": [1, 2, 3, 4, 5],
                        "value": 5
                    },
                    "most_recent_action_date": {
                        "field": "most_recent_action_date",
                        "key": "most_recent_action_date",
                        "label": "Latest Action Date",
                        "qdts": [2],
                        "supported_line_graph_time_intervals": [1, 2, 3, 4, 5],
                        "value": 6
                    },
                    "occurred_time": {
                        "db_column": "creation_time",
                        "field": "occurred_time",
                        "key": "occurred_time",
                        "label": "Occurred Time",
                        "qdts": [107],
                        "supported_line_graph_time_intervals": [1, 2, 3, 4, 5],
                        "value": 22
                    },
                    "pre_proposal_date": {
                        "field": "pre_proposal_date",
                        "key": "pre_proposal_date",
                        "label": "Pre Proposal Date",
                        "qdts": [80],
                        "supported_line_graph_time_intervals": [1, 2, 3, 4, 5],
                        "value": 13
                    },
                    "processed": {
                        "field": "processed",
                        "key": "processed",
                        "label": "Processed",
                        "qdts": [],
                        "supported_line_graph_time_intervals": [1, 2, 3, 4, 5],
                        "value": 10
                    },
                    "proposal_date": {
                        "field": "proposal_date",
                        "key": "proposal_date",
                        "label": "Proposal Date",
                        "qdts": [80],
                        "supported_line_graph_time_intervals": [1, 2, 3, 4, 5],
                        "value": 14
                    },
                    "status_date": {
                        "field": "status_date",
                        "key": "status_date",
                        "label": "Last Acted Upon",
                        "qdts": [4],
                        "supported_line_graph_time_intervals": [1, 2, 3, 4, 5],
                        "value": 3
                    },
                    "sum_aggregation": {
                        "key": "sum_aggregation",
                        "label": "Sum ($)",
                        "qdts": [107],
                        "querymethod": "sum_aggregation_frequency_tuples",
                        "supported_line_graph_time_intervals": [1, 2, 3, 4, 5],
                        "value": 23
                    },
                    "sum_aggregation_pacv2": {
                        "key": "sum_aggregation_pacv2",
                        "label": "Sum ($)",
                        "qdts": [135],
                        "querymethod": "sum_aggregation_frequency_tuples",
                        "supported_line_graph_time_intervals": [1, 2, 3, 4, 5],
                        "value": 25
                    },
                    "transaction_date": {
                        "db_column": "TransactionDate",
                        "field": "transaction_date",
                        "key": "transaction_date",
                        "label": "Transaction Date",
                        "qdts": [135],
                        "supported_line_graph_time_intervals": [1, 2, 3, 4, 5],
                        "value": 24
                    },
                    "updated": {
                        "field": "updated",
                        "key": "updated",
                        "label": "Updated",
                        "qdts": [69, 12],
                        "supported_line_graph_time_intervals": [1, 2, 3, 4, 5],
                        "value": 2
                    },
                    "valid_qdts": [1, 2, 3, 4, 134, 135, 8, 12, 20, 25, 28, 45, 62, 69, 80, 105, 107, 114, 119, 120],
                    "when": {
                        "field": "when",
                        "key": "when",
                        "label": "When",
                        "qdts": [28],
                        "supported_line_graph_time_intervals": [1, 2, 3, 4, 5],
                        "value": 8
                    }
                },
                "FrequencyTupleFields": {
                    "__is_enum": true,
                    "actions": {
                        "filter_keys": {},
                        "key": "actions",
                        "label": "Actions",
                        "qdts": [44],
                        "querymethod": "actions_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 45,
                        "visualization_subtype": 1
                    },
                    "agencies": {
                        "filter_keys": { "80": "public_org_associated_with_agency" },
                        "key": "agencies",
                        "label": "Agencies",
                        "qdts": [80],
                        "querymethod": "agency_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 14,
                        "visualization_subtype": 1
                    },
                    "author": {
                        "filter_keys": {},
                        "key": "author",
                        "label": "Authors",
                        "qdts": [105],
                        "querymethod": "term_frequency_tuples",
                        "querymethod_kwargs": {
                            "exclude": ["Unknown", "admin"],
                            "field_name": "author__name"
                        },
                        "supported_regions_qdt": {},
                        "value": 62,
                        "visualization_subtype": 1
                    },
                    "bill_types": {
                        "enum": { "2": "app.bill.models.BillType" },
                        "filter_keys": { "2": "bill_type__in" },
                        "key": "bill_types",
                        "label": "Bill Types",
                        "qdts": [2],
                        "querymethod": "type_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 20,
                        "visualization_subtype": 1
                    },
                    "bounces": {
                        "filter_keys": {},
                        "key": "bounces",
                        "label": "Bounces",
                        "qdts": [44],
                        "querymethod": "bounces_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 43,
                        "visualization_subtype": 1
                    },
                    "campaign_confirmation_emails": {
                        "filter_keys": {},
                        "key": "campaign_confirmation_emails",
                        "label": "Confirmation Emails",
                        "qdts": [47],
                        "querymethod": "confirmation_email_campaign_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 47,
                        "visualization_subtype": 1
                    },
                    "category": {
                        "filter_keys": {},
                        "key": "category",
                        "label": "Categories",
                        "qdts": [105],
                        "querymethod": "term_frequency_tuples",
                        "querymethod_kwargs": { "field_name": "source__category", "postprocess_func": "postprocess_category_frequency_tuples" },
                        "supported_regions_qdt": {},
                        "value": 66,
                        "visualization_subtype": 1
                    },
                    "caucuses": {
                        "filter_keys": { "25": "source_caucus_filter" },
                        "key": "caucuses",
                        "label": "Caucuses",
                        "qdts": [25],
                        "querymethod": "caucus_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 6,
                        "visualization_subtype": 1
                    },
                    "click_rate": {
                        "filter_keys": {},
                        "key": "click_rate",
                        "label": "Click Rate",
                        "qdts": [44, 113],
                        "querymethod": "click_rate_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 41,
                        "visualization_subtype": 1
                    },
                    "clicks": {
                        "filter_keys": {},
                        "key": "clicks",
                        "label": "Clicked",
                        "qdts": [44, 113],
                        "querymethod": "clicks_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 40,
                        "visualization_subtype": 1
                    },
                    "committees": {
                        "filter_keys": { "2": "from_committee", "25": "source_committee_filter" },
                        "key": "committees",
                        "label": "Committees",
                        "qdts": [2, 25],
                        "querymethod": "committee_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 4,
                        "visualization_subtype": 1
                    },
                    "contacts": {
                        "filter_keys": { "20": "involving_supporter", "25": "associated_with_supporter", "69": "involving_supporter" },
                        "key": "contacts",
                        "label": "Contacts",
                        "qdts": [25, 69, 20, 107, 135],
                        "querymethod": "contact_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 7,
                        "visualization_subtype": 1
                    },
                    "conversions": {
                        "filter_keys": {},
                        "key": "conversions",
                        "label": "Conversions",
                        "qdts": [113],
                        "querymethod": "conversions_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 80,
                        "visualization_subtype": 1
                    },
                    "cosponsors": {
                        "filter_keys": { "2": "cosponsored_by_or" },
                        "key": "cosponsors",
                        "label": "Co-Sponsors",
                        "qdts": [2],
                        "querymethod": "cosponsor_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 2,
                        "visualization_subtype": 1
                    },
                    "counties": {
                        "filter_keys": { "25": "for_counties" },
                        "key": "counties",
                        "label": "Counties",
                        "qdts": [25],
                        "querymethod": "county_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 16,
                        "visualization_subtype": 1
                    },
                    "country": {
                        "filter_keys": {},
                        "key": "country",
                        "label": "Countries",
                        "qdts": [105, 120],
                        "querymethod": { "105": "term_frequency_tuples", "120": "country_frequency_tuples" },
                        "querymethod_kwargs": { "field_name": "source__country", "postprocess_func": "postprocess_country_frequency_tuples" },
                        "supported_regions_qdt": {},
                        "value": 64,
                        "visualization_subtype": 1
                    },
                    "created_by_user": {
                        "filter_keys": {},
                        "key": "created_by_user",
                        "label": "Team Member Created",
                        "qdts": [44, 113],
                        "querymethod": "created_by_user_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 48,
                        "visualization_subtype": 1
                    },
                    "creators": {
                        "filter_keys": { "20": "involving_creator" },
                        "key": "creators",
                        "label": "Creators",
                        "qdts": [20],
                        "querymethod": "creator_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 12,
                        "visualization_subtype": 1
                    },
                    "custom": {
                        "filter_keys": {},
                        "key": "custom",
                        "label": "Custom",
                        "qdts": [],
                        "supported_regions_qdt": {},
                        "value": 61,
                        "visualization_subtype": 4
                    },
                    "date_fields": [32, 33, 34, 35],
                    "document_types": {
                        "enum": { "25": "app.document.models.DocumentType" },
                        "filter_keys": { "25": "document_type__in" },
                        "key": "document_types",
                        "label": "Types",
                        "qdts": [25],
                        "querymethod": "type_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 25,
                        "visualization_subtype": 1
                    },
                    "election": {
                        "filter_keys": { "107": "transaction_election", "135": "transaction_election" },
                        "key": "election",
                        "label": "Election",
                        "qdts": [107, 135],
                        "querymethod": "election_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 77,
                        "visualization_subtype": 1
                    },
                    "email_count": {
                        "filter_keys": {},
                        "key": "email_count",
                        "label": "Sent",
                        "qdts": [44],
                        "querymethod": "email_count_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 37,
                        "visualization_subtype": 1
                    },
                    "filter_groups": [
                        {
                            "display": "list",
                            "title": "Who",
                            "values": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 62, 63, 74]
                        },
                        {
                            "display": "list",
                            "title": "Where",
                            "values": [15, 16, 17, 59, 60, 61, 64]
                        },
                        {
                            "display": "list",
                            "title": "What",
                            "values": [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 65, 66, 67, 68, 69, 70, 72, 71, 73, 75, 76, 77, 78, 79, 80]
                        },
                        {
                            "display": "list",
                            "title": "When",
                            "values": [32, 33, 34, 35]
                        }
                    ],
                    "grassroots_note_types": {
                        "enum": { "69": "app.userdata.types.InteractionType" },
                        "filter_keys": { "69": "interaction_type__in" },
                        "key": "grassroots_note_types",
                        "label": "Types",
                        "qdts": [69],
                        "querymethod": "type_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 27,
                        "visualization_subtype": 1
                    },
                    "hashtags": {
                        "filter_keys": { "25": "contains_hashtags" },
                        "key": "hashtags",
                        "label": "Hashtags",
                        "qdts": [25],
                        "querymethod": "hashtag_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 29,
                        "visualization_subtype": 1
                    },
                    "heat": {
                        "filter_keys": {
                            "1": { "2": "for_supported_regions" },
                            "10000": { "1": "representing_state", "105": "for_states", "12": "representing_state", "120": "representing_state", "2": "for_supported_regions", "20": "for_regions", "25": "for_regions", "42": "for_regions", "69": "for_regions", "8": "representing_state", "80": "for_regions" },
                            "10003": { "1": "representing_country", "105": "for_countries", "12": "representing_country", "120": "representing_country", "20": "for_countries", "25": "for_countries", "42": "for_countries", "69": "for_countries" },
                            "106": { "1": "representing_country", "105": "for_countries", "12": "representing_country", "120": "representing_country", "2": "for_countries", "20": "for_countries", "25": "for_countries", "42": "for_countries", "69": "for_countries" }
                        },
                        "key": "heat",
                        "label": "Heat",
                        "qdts": [2, 25, 12, 69, 1, 42, 80, 20, 105, 8, 120],
                        "querymethod": "region_frequency_tuples",
                        "supported_regions_qdt": {
                            "1": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                            "105": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                            "12": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                            "120": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                            "2": [1, 10000, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 10001, 10000, 106, 201, 203, 204, 208, 205, 211, 1000],
                            "20": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                            "25": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                            "42": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                            "69": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                            "80": [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 107, 108, 109, 110, 1, 204, 201, 203, 208, 205, 211]
                        },
                        "value": 59,
                        "visualization_subtype": 4
                    },
                    "issue_priority": {
                        "filter_keys": {},
                        "key": "issue_priority",
                        "label": "Priorities",
                        "qdts": [105, 120],
                        "querymethod": "in_issue_frequency_tuples",
                        "querymethod_kwargs": { "issue_display_field": "priority" },
                        "supported_regions_qdt": {},
                        "value": 69,
                        "visualization_subtype": 1
                    },
                    "issues": {
                        "filter_keys": { "1": "in_issue_or", "105": "in_issue_or", "114": "in_issue_or", "12": "in_issue_or", "120": "in_issue_or", "15": "in_issue_or", "16": "in_issue_or", "2": "in_issue_or", "20": "for_project_and_subprojects", "25": "in_issue_or", "28": "in_issue_or", "3": "in_issue_or", "4": "in_issue_or", "42": "in_issue_or", "8": "in_issue_or", "80": "in_issue_or" },
                        "key": "issues",
                        "label": "Issues",
                        "qdts": [4, 2, 44, 16, 15, 25, 28, 105, 20, 1, 114, 120, 42, 80, 8, 12, 3],
                        "querymethod": "in_issue_frequency_tuples",
                        "querymethod_kwargs": { "issue_display_field": "name" },
                        "supported_regions_qdt": {},
                        "value": 24,
                        "visualization_subtype": 1
                    },
                    "language": {
                        "enum": { "105": "app.document.types.Language" },
                        "filter_keys": { "105": "language" },
                        "key": "language",
                        "label": "Language",
                        "qdts": [],
                        "querymethod": "term_frequency_tuples",
                        "querymethod_kwargs": { "field_name": "language" },
                        "supported_regions_qdt": {},
                        "value": 36,
                        "visualization_subtype": 1
                    },
                    "leg_stance": {
                        "filter_keys": {},
                        "key": "leg_stance",
                        "label": "Stance",
                        "qdts": [105, 120],
                        "querymethod": "stance_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 71,
                        "visualization_subtype": 1
                    },
                    "lists": {
                        "filter_keys": {},
                        "key": "lists",
                        "label": "Lists",
                        "qdts": [105],
                        "querymethod": "in_issue_frequency_tuples",
                        "querymethod_kwargs": { "issue_display_field": "new_lists__name" },
                        "supported_regions_qdt": {},
                        "value": 70,
                        "visualization_subtype": 1
                    },
                    "media_type": {
                        "filter_keys": {},
                        "key": "media_type",
                        "label": "Media Types",
                        "qdts": [105],
                        "querymethod": "term_frequency_tuples",
                        "querymethod_kwargs": { "field_name": "source__media_type", "postprocess_func": "postprocess_media_type_frequency_tuples" },
                        "supported_regions_qdt": {},
                        "value": 67,
                        "visualization_subtype": 1
                    },
                    "mentions": {
                        "filter_keys": { "25": "contains_mentions" },
                        "key": "mentions",
                        "label": "Mentions",
                        "qdts": [25],
                        "querymethod": "mention_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 10,
                        "visualization_subtype": 1
                    },
                    "month": {
                        "filter_keys": { "105": null, "107": null, "12": null, "135": null, "2": null, "20": null, "25": null, "28": null, "3": null, "4": null, "62": null, "69": null, "80": null },
                        "key": "month",
                        "label": "Months",
                        "qdts": [4, 62, 2, 25, 28, 69, 105, 20, 80, 12, 107, 135, 3],
                        "supported_regions_qdt": {},
                        "value": 32,
                        "visualization_subtype": 1
                    },
                    "municipalities": {
                        "filter_keys": { "25": "for_municipalities" },
                        "key": "municipalities",
                        "label": "Municipalities",
                        "qdts": [25],
                        "querymethod": "municipality_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 17,
                        "visualization_subtype": 1
                    },
                    "note_custom_interaction_types": {
                        "filter_keys": { "20": "custom_interaction_type__in" },
                        "key": "note_custom_interaction_types",
                        "label": "Types",
                        "qdts": [20],
                        "querymethod": "type_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 28,
                        "visualization_subtype": 1
                    },
                    "note_types": {
                        "enum": { "20": "app.userdata.types.InteractionType" },
                        "filter_keys": { "20": "interaction_type__in" },
                        "key": "note_types",
                        "label": "Donation Types",
                        "qdts": [20],
                        "querymethod": "donation_type_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 26,
                        "visualization_subtype": 1
                    },
                    "offices": {
                        "filter_keys": { "20": "involving_office" },
                        "key": "offices",
                        "label": "Offices",
                        "qdts": [20],
                        "querymethod": "office_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 13,
                        "visualization_subtype": 1
                    },
                    "official_clicks": {
                        "filter_keys": {},
                        "key": "official_clicks",
                        "label": "Official Clicks",
                        "qdts": [44],
                        "querymethod": "clicked_by_official_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 56,
                        "visualization_subtype": 1
                    },
                    "official_opens": {
                        "filter_keys": {},
                        "key": "official_opens",
                        "label": "Official Opens",
                        "qdts": [44],
                        "querymethod": "opened_by_official_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 53,
                        "visualization_subtype": 1
                    },
                    "official_received": {
                        "filter_keys": {},
                        "key": "official_received",
                        "label": "Official Received",
                        "qdts": [44],
                        "querymethod": "received_by_official_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 50,
                        "visualization_subtype": 1
                    },
                    "officials": {
                        "filter_keys": { "20": "involving_member", "25": "source_members", "69": "involving_member" },
                        "key": "officials",
                        "label": "Officials",
                        "qdts": [25, 69, 20],
                        "querymethod": "person_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 5,
                        "visualization_subtype": 1
                    },
                    "open_rate": {
                        "filter_keys": {},
                        "key": "open_rate",
                        "label": "Open Rate",
                        "qdts": [44],
                        "querymethod": "open_rate_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 39,
                        "visualization_subtype": 1
                    },
                    "opens": {
                        "filter_keys": {},
                        "key": "opens",
                        "label": "Opened",
                        "qdts": [44],
                        "querymethod": "opens_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 38,
                        "visualization_subtype": 1
                    },
                    "organizations": {
                        "filter_keys": { "20": "involving_public_organization_and_associated_individuals", "25": "searchify_source_public_organization" },
                        "key": "organizations",
                        "label": "Organizations",
                        "qdts": [25, 20, 107, 135],
                        "querymethod": { "107": "public_organization_frequency_tuples", "135": "public_organization_frequency_tuples", "20": "public_organization_frequency_tuples", "25": "organization_frequency_tuples" },
                        "supported_regions_qdt": {},
                        "value": 8,
                        "visualization_subtype": 1
                    },
                    "outlet": {
                        "filter_keys": { "105": "source__name" },
                        "key": "outlet",
                        "label": "Outlets",
                        "qdts": [105, 120],
                        "querymethod": { "105": "term_frequency_tuples", "120": "outlet_frequency_tuples" },
                        "querymethod_kwargs": { "field_name": "source__name" },
                        "supported_regions_qdt": {},
                        "value": 63,
                        "visualization_subtype": 1
                    },
                    "participants": {
                        "filter_keys": { "20": "involving_user" },
                        "key": "participants",
                        "label": "Participants",
                        "qdts": [20],
                        "querymethod": "team_member_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 11,
                        "visualization_subtype": 1
                    },
                    "party": {
                        "enum": { "107": "app.pac.types.FecParty", "114": "app.pac.types.FecParty", "135": "app.pac_classic.types.PacClassicPartyType", "2": "app.person.types.Party", "20": "app.person.types.Party", "25": "app.person.types.Party", "69": "app.person.types.Party" },
                        "filter_keys": { "114": "party__in", "2": "for_parties", "20": null, "25": "party", "69": null },
                        "key": "party",
                        "label": "Party",
                        "qdts": [2, 25, 69, 20, 114, 107, 135],
                        "querymethod": "party_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 3,
                        "visualization_subtype": 1
                    },
                    "point": {
                        "filter_keys": { "12": "id__in", "69": "id__in" },
                        "key": "point",
                        "label": "Point",
                        "qdts": [79, 69, 12],
                        "querymethod": "point_frequency_tuples",
                        "supported_regions_qdt": {
                            "12": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                            "69": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 1000, 9999, 10000, 10001, 10002, 10003, 10004, 10005],
                            "79": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 108, 109, 110, 10000, 10001, 10002]
                        },
                        "value": 60,
                        "visualization_subtype": 4
                    },
                    "political_committee": {
                        "filter_keys": {},
                        "key": "political_committee",
                        "label": "Political Committee",
                        "qdts": [107],
                        "querymethod": "political_committee_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 74,
                        "visualization_subtype": 1
                    },
                    "political_committee_types": {
                        "enum": { "114": "app.pac.types.PoliticalCommitteeType" },
                        "filter_keys": { "114": "committee_type__in" },
                        "key": "political_committee_types",
                        "label": "Political Committee Type",
                        "qdts": [114],
                        "querymethod": "type_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 73,
                        "visualization_subtype": 1
                    },
                    "priority": {
                        "enum": { "2": "app.userdata.types.LegislativePriorityType", "80": "app.userdata.types.LegislativePriorityType" },
                        "filter_keys": { "2": "my_priority", "80": "my_priority" },
                        "key": "priority",
                        "label": "Priority",
                        "qdts": [2, 80],
                        "querymethod": "priority_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 23,
                        "visualization_subtype": 1
                    },
                    "quarter": {
                        "filter_keys": { "105": null, "107": null, "12": null, "135": null, "2": null, "20": null, "25": null, "28": null, "3": null, "4": null, "62": null, "69": null, "80": null },
                        "key": "quarter",
                        "label": "Quarters",
                        "qdts": [4, 62, 2, 25, 28, 69, 105, 20, 80, 12, 107, 135, 3],
                        "supported_regions_qdt": {},
                        "value": 34,
                        "visualization_subtype": 1
                    },
                    "rank": {
                        "filter_keys": { "105": "source__rank__in" },
                        "key": "rank",
                        "label": "Rank",
                        "qdts": [105],
                        "querymethod": "term_frequency_tuples",
                        "querymethod_kwargs": { "field_name": "source__rank" },
                        "supported_regions_qdt": {},
                        "value": 65,
                        "visualization_subtype": 1
                    },
                    "regulation_types": {
                        "enum": { "80": "app.reg.types.RegType" },
                        "filter_keys": { "80": "reg_type__in" },
                        "key": "regulation_types",
                        "label": "Regulation Types",
                        "qdts": [80],
                        "querymethod": "type_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 31,
                        "visualization_subtype": 1
                    },
                    "reply_count": {
                        "filter_keys": {},
                        "key": "reply_count",
                        "label": "Replies",
                        "qdts": [44],
                        "querymethod": "reply_count_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 46,
                        "visualization_subtype": 1
                    },
                    "retweets": {
                        "filter_keys": { "25": "retweet_sources" },
                        "key": "retweets",
                        "label": "Retweets",
                        "qdts": [25],
                        "querymethod": "retweet_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 9,
                        "visualization_subtype": 1
                    },
                    "role_type": {
                        "filter_keys": {},
                        "key": "role_type",
                        "label": "Types",
                        "qdts": [120],
                        "querymethod": "role_type_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 72,
                        "visualization_subtype": 1
                    },
                    "sent_by_user": {
                        "filter_keys": {},
                        "key": "sent_by_user",
                        "label": "Team Member Sent",
                        "qdts": [44],
                        "querymethod": "sent_by_user_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 49,
                        "visualization_subtype": 1
                    },
                    "sms_count": {
                        "filter_keys": {},
                        "key": "sms_count",
                        "label": "Sent",
                        "qdts": [113],
                        "querymethod": "sms_count_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 79,
                        "visualization_subtype": 1
                    },
                    "source_industries": {
                        "filter_keys": { "105": "for_article_source_editorial_topics" },
                        "key": "source_industries",
                        "label": "Source Industries",
                        "qdts": [105],
                        "querymethod": "term_frequency_tuples",
                        "querymethod_kwargs": { "field_name": "source__editorial_topics" },
                        "supported_regions_qdt": {},
                        "value": 68,
                        "visualization_subtype": 1
                    },
                    "sources": {
                        "filter_keys": { "12": "source_type__in", "69": "source_type__in" },
                        "key": "sources",
                        "label": "Sources",
                        "qdts": [69, 12],
                        "querymethod": "source_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 30,
                        "visualization_subtype": 1
                    },
                    "sponsors": {
                        "filter_keys": { "2": "sponsored_or_cosponsored_by_or" },
                        "key": "sponsors",
                        "label": "Sponsors",
                        "qdts": [2],
                        "querymethod": "person_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 1,
                        "visualization_subtype": 1
                    },
                    "staffer_clicks": {
                        "filter_keys": {},
                        "key": "staffer_clicks",
                        "label": "Staffer Clicks",
                        "qdts": [44],
                        "querymethod": "clicked_by_staffer_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 57,
                        "visualization_subtype": 1
                    },
                    "staffer_opens": {
                        "filter_keys": {},
                        "key": "staffer_opens",
                        "label": "Staffer Opens",
                        "qdts": [44],
                        "querymethod": "opened_by_staffer_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 54,
                        "visualization_subtype": 1
                    },
                    "staffer_received": {
                        "filter_keys": {},
                        "key": "staffer_received",
                        "label": "Staffer Received",
                        "qdts": [44],
                        "querymethod": "received_by_staffer_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 51,
                        "visualization_subtype": 1
                    },
                    "stance": {
                        "enum": { "2": "app.userdata.types.LegislativeStanceType", "80": "app.userdata.types.LegislativeStanceType" },
                        "filter_keys": { "2": "my_stance", "80": "my_stance" },
                        "key": "stance",
                        "label": "Stance",
                        "qdts": [2, 80],
                        "querymethod": "stance_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 22,
                        "visualization_subtype": 1
                    },
                    "states": {
                        "filter_keys": { "105": "for_states", "107": "for_regions", "114": "for_regions", "120": "for_regions", "2": "for_supported_regions", "20": "for_regions", "25": "for_regions", "69": "for_regions", "80": "for_regions" },
                        "key": "states",
                        "label": "States",
                        "qdts": [2, 25, 69, 105, 20, 114, 120, 80, 107, 135],
                        "querymethod": "state_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 15,
                        "visualization_subtype": 1
                    },
                    "status": {
                        "enum": { "2": "app.bill.status.GeneralBillStatus", "80": "app.reg.types.GeneralRegStatus" },
                        "filter_keys": { "2": "current_general_status__in", "80": "general_status__in" },
                        "key": "status",
                        "label": "Status",
                        "qdts": [2, 80],
                        "querymethod": "status_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 21,
                        "visualization_subtype": 1
                    },
                    "supporter_bounces": {
                        "filter_keys": {},
                        "key": "supporter_bounces",
                        "label": "Bounces (Contacts)",
                        "qdts": [44],
                        "querymethod": "supporter_bounces_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 44,
                        "visualization_subtype": 1
                    },
                    "supporter_clicks": {
                        "filter_keys": {},
                        "key": "supporter_clicks",
                        "label": "Contact Clicks",
                        "qdts": [44, 113],
                        "querymethod": "clicked_by_supporter_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 58,
                        "visualization_subtype": 1
                    },
                    "supporter_opens": {
                        "filter_keys": {},
                        "key": "supporter_opens",
                        "label": "Contact Opens",
                        "qdts": [44],
                        "querymethod": "opened_by_supporter_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 55,
                        "visualization_subtype": 1
                    },
                    "supporter_received": {
                        "filter_keys": {},
                        "key": "supporter_received",
                        "label": "Contact Received",
                        "qdts": [44, 113],
                        "querymethod": "received_by_supporter_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 52,
                        "visualization_subtype": 1
                    },
                    "texts_failures": {
                        "filter_keys": {},
                        "key": "texts_failures",
                        "label": "Failures",
                        "qdts": [113],
                        "querymethod": "failures_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 78,
                        "visualization_subtype": 1
                    },
                    "transaction_method": {
                        "enum": { "107": "app.ledger.types.TransactionMethodType", "135": "app.pac_classic.types.PacClassicTransactionMethodType" },
                        "filter_keys": { "107": "transaction_method__in", "135": "transaction_method__in" },
                        "key": "transaction_method",
                        "label": "Transaction Method",
                        "qdts": [107, 135],
                        "querymethod": "method_type_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 75,
                        "visualization_subtype": 1
                    },
                    "transaction_types": {
                        "enum": { "107": "app.ledger.types.TransactionType", "135": "app.pac_classic.types.PacClassicTransactionType" },
                        "filter_keys": { "107": "transaction_type__in", "135": "transaction_type__in" },
                        "key": "transaction_types",
                        "label": "Transaction Type",
                        "qdts": [107, 135],
                        "querymethod": "type_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 76,
                        "visualization_subtype": 1
                    },
                    "unsubscribes": {
                        "filter_keys": {},
                        "key": "unsubscribes",
                        "label": "Unsubscribes",
                        "qdts": [44, 113],
                        "querymethod": "unsubscribes_frequency_tuples",
                        "supported_regions_qdt": {},
                        "value": 42,
                        "visualization_subtype": 1
                    },
                    "valid_bar_qdts": [1, 2, 3, 4, 135, 8, 12, 15, 16, 20, 25, 28, 42, 44, 47, 62, 69, 80, 105, 107, 113, 114, 120],
                    "valid_map_qdts": [1, 2, 69, 8, 105, 42, 12, 79, 80, 20, 120, 25],
                    "week": {
                        "filter_keys": { "105": null, "107": null, "12": null, "135": null, "2": null, "20": null, "25": null, "28": null, "3": null, "4": null, "62": null, "69": null, "80": null },
                        "key": "week",
                        "label": "Weeks",
                        "qdts": [4, 62, 2, 25, 28, 69, 105, 20, 80, 12, 107, 3, 135],
                        "supported_regions_qdt": {},
                        "value": 33,
                        "visualization_subtype": 1
                    },
                    "year": {
                        "filter_keys": { "105": null, "107": null, "114": null, "12": null, "135": null, "2": null, "20": null, "25": null, "28": null, "3": null, "4": null, "62": null, "69": null, "80": null },
                        "key": "year",
                        "label": "Years",
                        "qdts": [4, 62, 2, 25, 28, 69, 20, 105, 114, 80, 12, 107, 135, 3],
                        "supported_regions_qdt": {},
                        "value": 35,
                        "visualization_subtype": 1
                    }
                },
                "LineGraphTimeIntervals": {
                    "__is_enum": true,
                    "day": {
                        "key": "day",
                        "label": "Days",
                        "trunc": "day",
                        "ttl": { "hours": 6 },
                        "value": 1
                    },
                    "month": {
                        "key": "month",
                        "label": "Months",
                        "trunc": "month",
                        "ttl": { "days": 2 },
                        "value": 3
                    },
                    "quarter": {
                        "key": "quarter",
                        "label": "Quarters",
                        "trunc": "quarter",
                        "ttl": { "days": 2 },
                        "value": 4
                    },
                    "week": {
                        "key": "week",
                        "label": "Weeks",
                        "trunc": "week",
                        "ttl": { "days": 1 },
                        "value": 2
                    },
                    "year": {
                        "key": "year",
                        "label": "Years",
                        "trunc": "year",
                        "ttl": { "days": 7 },
                        "value": 5
                    }
                },
                "MapVisualizationHeatColorScale": {
                    "__is_enum": true,
                    "quantize": { "d3_key": "scaleQuantize", "key": "quantize", "label": "Quantize", "value": 2 },
                    "sequential": { "d3_key": "scaleSequential", "key": "sequential", "label": "Sequential", "value": 1 }
                },
                "MapVisualizationHeatColorType": {
                    "__is_enum": true,
                    "custom": { "key": "custom", "label": "Custom", "value": 2 },
                    "default": { "key": "default", "label": "Default", "value": 1 }
                },
                "MapVisualizationHeatInterpolationColorSpace": {
                    "__is_enum": true,
                    "cube_helix": { "d3_key": "interpolateCubehelix", "key": "cube_helix", "label": "Cube Helix", "value": 7 },
                    "cube_helix_long": { "d3_key": "interpolateCubehelixLong", "key": "cube_helix_long", "label": "Cube Helix Long", "value": 8 },
                    "hcl": { "d3_key": "interpolateHcl", "key": "hcl", "label": "HCL", "value": 5 },
                    "hcl_long": { "d3_key": "interpolateHclLong", "key": "hcl_long", "label": "HCL Long", "value": 6 },
                    "hsl": { "d3_key": "interpolateHsl", "key": "hsl", "label": "HSL", "value": 2 },
                    "hsl_long": { "d3_key": "interpolateHslLong", "key": "hsl_long", "label": "HSL Long", "value": 3 },
                    "lab": { "d3_key": "interpolateLab", "key": "lab", "label": "LAB", "value": 4 },
                    "rgb": { "d3_key": "interpolateRgb", "key": "rgb", "label": "RGB", "value": 1 }
                },
                "MapVisualizationHeatInterpolationColors": {
                    "__is_enum": true,
                    "blue": { "d3_quantize_key": "schemeBlues", "d3_sequential_key": "interpolateBlues", "key": "blue", "label": "Blue", "type": "single", "value": 10 },
                    "blue_green": { "d3_quantize_key": "schemeBuGn", "d3_sequential_key": "interpolateBuGn", "key": "blue_green", "label": "Blue Green", "type": "multi", "value": 25 },
                    "blue_purple": { "d3_quantize_key": "schemeBuPu", "d3_sequential_key": "interpolateBuPu", "key": "blue_purple", "label": "Blue Purple", "type": "multi", "value": 26 },
                    "brown_blue_green": { "d3_quantize_key": "schemeBrBG", "d3_sequential_key": "interpolateBrBG", "key": "brown_blue_green", "label": "Brown Blue Green", "type": "diverging", "value": 1 },
                    "cividis": { "d3_sequential_key": "interpolateCividis", "key": "cividis", "label": "Cividis", "type": "multi", "value": 21 },
                    "cool": { "d3_sequential_key": "interpolateCool", "key": "cool", "label": "Cool", "type": "multi", "value": 23 },
                    "cube_helix_default": { "d3_sequential_key": "interpolateCubehelixDefault", "key": "cube_helix_default", "label": "Cube Helix Default", "type": "multi", "value": 24 },
                    "cyclical": [37, 38],
                    "diverging": [1, 2, 3, 4, 5, 6, 7, 8, 9],
                    "filter_groups": [
                        {
                            "display": "list",
                            "title": "Diverging",
                            "values": [1, 2, 3, 4, 5, 6, 7, 8, 9]
                        },
                        {
                            "display": "list",
                            "title": "Single",
                            "values": [10, 11, 12, 13, 14, 15]
                        },
                        {
                            "display": "list",
                            "title": "Multi",
                            "values": [16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36]
                        },
                        {
                            "display": "list",
                            "title": "Cyclical",
                            "values": [37, 38]
                        }
                    ],
                    "green": { "d3_quantize_key": "schemeGreens", "d3_sequential_key": "interpolateGreens", "key": "green", "label": "Green", "type": "single", "value": 11 },
                    "green_blue": { "d3_quantize_key": "schemeGnBu", "d3_sequential_key": "interpolateGnBu", "key": "green_blue", "label": "Green Blue", "type": "multi", "value": 27 },
                    "grey": { "d3_quantize_key": "schemeGreys", "d3_sequential_key": "interpolateGreys", "key": "grey", "label": "Grey", "type": "single", "value": 12 },
                    "inferno": { "d3_sequential_key": "interpolateInferno", "key": "inferno", "label": "Inferno", "type": "multi", "value": 18 },
                    "magma": { "d3_sequential_key": "interpolateMagma", "key": "magma", "label": "Magma", "type": "multi", "value": 19 },
                    "multi": [16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
                    "orange": { "d3_quantize_key": "schemeOranges", "d3_sequential_key": "interpolateOranges", "key": "orange", "label": "Orange", "type": "single", "value": 13 },
                    "orange_red": { "d3_quantize_key": "schemeOrRd", "d3_sequential_key": "interpolateOrRd", "key": "orange_red", "label": "Orange Red", "type": "multi", "value": 28 },
                    "pink_yellow_green": { "d3_quantize_key": "schemePiYG", "d3_sequential_key": "interpolatePiYG", "key": "pink_yellow_green", "label": "Pink Yellow Green", "type": "diverging", "value": 3 },
                    "plasma": { "d3_sequential_key": "interpolatePlasma", "key": "plasma", "label": "Plasma", "type": "multi", "value": 20 },
                    "purple": { "d3_quantize_key": "schemePurples", "d3_sequential_key": "interpolatePurples", "key": "purple", "label": "Purple", "type": "single", "value": 14 },
                    "purple_blue": { "d3_quantize_key": "schemePuBu", "d3_sequential_key": "interpolatePuBu", "key": "purple_blue", "label": "Purple Blue", "type": "multi", "value": 30 },
                    "purple_blue_green": { "d3_quantize_key": "schemePuBuGn", "d3_sequential_key": "interpolatePuBuGn", "key": "purple_blue_green", "label": "Purple Blue Green", "type": "multi", "value": 29 },
                    "purple_green": { "d3_quantize_key": "schemePRGn", "d3_sequential_key": "interpolatePRGn", "key": "purple_green", "label": "Purple Green", "type": "diverging", "value": 2 },
                    "purple_orange": { "d3_quantize_key": "schemePuOr", "d3_sequential_key": "interpolatePuOr", "key": "purple_orange", "label": "Purple Orange", "type": "diverging", "value": 4 },
                    "purple_red": { "d3_quantize_key": "schemePuRd", "d3_sequential_key": "interpolatePuRd", "key": "purple_red", "label": "Purple Red", "type": "multi", "value": 31 },
                    "rainbow": { "d3_sequential_key": "interpolateRainbow", "key": "rainbow", "label": "Rainbow", "type": "cyclical", "value": 37 },
                    "red": { "d3_quantize_key": "schemeReds", "d3_sequential_key": "interpolateReds", "key": "red", "label": "Red", "type": "single", "value": 15 },
                    "red_blue": { "d3_quantize_key": "schemeRdBu", "d3_sequential_key": "interpolateRdBu", "key": "red_blue", "label": "Red Blue", "type": "diverging", "value": 5 },
                    "red_grey": { "d3_quantize_key": "schemeRdGy", "d3_sequential_key": "interpolateRdGy", "key": "red_grey", "label": "Red Grey", "type": "diverging", "value": 6 },
                    "red_purple": { "d3_quantize_key": "schemeRdPu", "d3_sequential_key": "interpolateRdPu", "key": "red_purple", "label": "Red Purple", "type": "multi", "value": 32 },
                    "red_yellow_blue": { "d3_quantize_key": "schemeRdYlBu", "d3_sequential_key": "interpolateRdYlBu", "key": "red_yellow_blue", "label": "Red Yellow Blue", "type": "diverging", "value": 7 },
                    "red_yellow_green": { "d3_quantize_key": "schemeRdYlGn", "d3_sequential_key": "interpolateRdYlGn", "key": "red_yellow_green", "label": "Red Yellow Green", "type": "diverging", "value": 8 },
                    "sinebow": { "d3_sequential_key": "interpolateSinebow", "key": "sinebow", "label": "Sinebow", "type": "cyclical", "value": 38 },
                    "single": [10, 11, 12, 13, 14, 15],
                    "spectral": { "d3_quantize_key": "schemeSpectral", "d3_sequential_key": "interpolateSpectral", "key": "spectral", "label": "Spectral", "type": "diverging", "value": 9 },
                    "turbo": { "d3_sequential_key": "interpolateTurbo", "key": "turbo", "label": "Turbo", "type": "multi", "value": 16 },
                    "viridis": { "d3_sequential_key": "interpolateViridis", "key": "viridis", "label": "Viridis", "type": "multi", "value": 17 },
                    "warm": { "d3_sequential_key": "interpolateWarm", "key": "warm", "label": "Warm", "type": "multi", "value": 22 },
                    "yellow_green": { "d3_quantize_key": "schemeYlGn", "d3_sequential_key": "interpolateYlGn", "key": "yellow_green", "label": "Yellow Green", "type": "multi", "value": 34 },
                    "yellow_green_blue": { "d3_quantize_key": "schemeYlGnBu", "d3_sequential_key": "interpolateYlGnBu", "key": "yellow_green_blue", "label": "Yellow Green Blue", "type": "multi", "value": 33 },
                    "yellow_orange_brown": { "d3_quantize_key": "schemeYlOrBr", "d3_sequential_key": "interpolateYlOrBr", "key": "yellow_orange_brown", "label": "Yellow Orange Brown", "type": "multi", "value": 35 },
                    "yellow_orange_red": { "d3_quantize_key": "schemeYlOrRd", "d3_sequential_key": "interpolateYlOrRd", "key": "yellow_orange_red", "label": "Yellow Orange Red", "type": "multi", "value": 36 }
                },
                "MapVisualizationRegion": {
                    "__is_enum": true,
                    "eu": { "d3_projection": "geoAzimuthalEqualArea", "d3_topology_key": "NUTS_RG_10M_2021_4326", "d3_url": "https://gisco-services.ec.europa.eu/distribution/v2/nuts/topojson/NUTS_RG_10M_2021_4326_LEVL_0.json", "key": "eu", "label": "European Union (EU)", "supported_region": 106, "value": 3 },
                    "us": { "d3_projection": "geoAlbersUsa", "d3_topology_key": "states", "d3_url": "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json", "key": "us", "label": "United States", "supported_region": 10000, "value": 2 },
                    "world": { "d3_projection": "geoEqualEarth", "d3_topology_key": "countries", "d3_url": "https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json", "key": "world", "label": "World", "supported_region": 10003, "value": 1 }
                },
                "NumberWidgetDisplayType": {
                    "__is_enum": true,
                    "count": { "icon": "hashtag", "key": "count", "label": "Count", "value": 1 },
                    "fraction": { "icon": "divide", "key": "fraction", "label": "Fraction", "operation": 1, "value": 3 },
                    "percentage": { "icon": "percent", "key": "percentage", "label": "Percentage", "operation": 2, "value": 2 },
                    "sum": {
                        "feature_flags": ["ff_pac"],
                        "icon": "plus",
                        "key": "sum",
                        "label": "Sum",
                        "permission_enum": [8, 215],
                        "value": 4
                    }
                },
                "QuicklinkType": {
                    "__is_enum": true,
                    "custom": { "key": "custom", "label": "Add Your Custom Quicklink", "sort": -1, "value": 1 },
                    "new_campaign": {
                        "key": "new_campaign",
                        "label": "New Campaign",
                        "permission_enum": [201],
                        "sort": 5,
                        "url": "/action_centers/",
                        "value": 7
                    },
                    "new_contact": {
                        "key": "new_contact",
                        "label": "Add Contact",
                        "permission_enum": [3, 102],
                        "sort": 8,
                        "url": "/edit_contact/",
                        "value": 10
                    },
                    "new_disbursement": {
                        "feature_flags": ["ff_pac"],
                        "key": "new_disbursement",
                        "label": "New Disbursement",
                        "permission_enum": [8],
                        "sort": 12,
                        "url": "/pacinternal/:ledgerSettingsId/transactions/disbursement/new/",
                        "value": 14
                    },
                    "new_download": { "key": "new_download", "label": "New Download", "sort": 4, "url": "/download_center/", "value": 6 },
                    "new_email": { "key": "new_email", "label": "New Email", "sort": 0, "url": "/outbox/compose/", "value": 2 },
                    "new_event": {
                        "key": "new_event",
                        "label": "New Event",
                        "permission_enum": [3],
                        "sort": 2,
                        "url": "/edit_custom_event/",
                        "value": 4
                    },
                    "new_file": {
                        "key": "new_file",
                        "label": "Add File",
                        "permission_enum": [3],
                        "sort": 7,
                        "url": "/search/files/",
                        "value": 9
                    },
                    "new_issue": { "key": "new_issue", "label": "New Issue", "sort": 1, "url": "/issue_management/new/", "value": 3 },
                    "new_note": { "key": "new_note", "label": "Add Note", "sort": 10, "url": "/log_interaction/", "value": 12 },
                    "new_org": { "key": "new_org", "label": "Create Organization", "sort": 9, "url": "/edit_organization/", "value": 11 },
                    "new_receipt": {
                        "feature_flags": ["ff_pac"],
                        "key": "new_receipt",
                        "label": "New Receipt",
                        "permission_enum": [8],
                        "sort": 11,
                        "url": "/pacinternal/:ledgerSettingsId/transactions/receipt/new/",
                        "value": 13
                    },
                    "new_sheet": { "key": "new_sheet", "label": "New Sheet", "sort": 3, "url": "/sheets/new/", "value": 5 },
                    "new_tracking_dashboard": {
                        "key": "new_tracking_dashboard",
                        "label": "New Tracking Board",
                        "permission_enum": [1, 2, 100, 101, 103],
                        "sort": 6,
                        "url": "/tracking_board/new/",
                        "value": 8
                    }
                },
                "ReactGridLayoutTypes": {
                    "__is_enum": true,
                    "free": { "key": "free", "label": "free", "value": 3 },
                    "horizontal": { "compactType": "horizontal", "key": "horizontal", "label": "horizontal", "value": 2 },
                    "vertical": { "compactType": "vertical", "key": "vertical", "label": "vertical", "value": 1 }
                },
                "VisualizationSubtype": {
                    "__is_enum": true,
                    "bar": { "icon": "chart-bar", "icon_family": "far", "key": "bar", "label": "Bar", "value": 1 },
                    "line": { "icon": "chart-line", "icon_family": "far", "key": "line", "label": "Line", "value": 3 },
                    "map": { "icon": "map", "icon_family": "far", "key": "map", "label": "Map", "value": 4 },
                    "pie": { "icon": "chart-pie", "icon_family": "fas", "key": "pie", "label": "Pie", "value": 2 }
                },
                "WidgetOperation": {
                    "__is_enum": true,
                    "fraction": { "key": "fraction", "label": "Fraction", "number_of_data_sources": 2, "value": 1 },
                    "percentage": { "key": "percentage", "label": "Percentage", "number_of_data_sources": 2, "value": 2 }
                }
            }
        }
    },
    "djangio": {
        "types": {}
    },
    "quorum": {
        "types": {}
    }
} as const

export type DjangIOType = typeof DjangIOCache
