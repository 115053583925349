import React from "react"

import { TextAndNumberField } from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/components/TextAndNumberField"
import { Heading } from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/PersonalizedMessages.styles"
import {
    Container,
    IconContainer,
    PersonalStory,
    Subtitle,
    StyledButton,
} from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/components/QuestionsContainer.styles"
import { CustomField } from "@/types/djangio"
import { useFormReturn } from "QuorumGrassroots/campaign-forms/hooks/useForm"
import { DatePickerField } from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/components/DatePickerField"
import { SingleSelectionField } from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/components/SingleSelectionField"
import { MultiSelectionField } from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/components/MultiSelectionField"
import { BooleanField } from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/components/BooleanField"
import {
    PromptAndResponseCampaignForm,
    validateFormValues,
} from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/PersonalizedMessages.helper"
import { Contents } from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/PersonalizedMessages"
import { isFeatureEnabled } from "shared/featureflags/helperFunctions"

const { TagType } = DjangIO.app.person.types

const QUESTION_INPUT_MAP = {
    [TagType.string.value]: TextAndNumberField,
    [TagType.number.value]: TextAndNumberField,
    [TagType.date.value]: DatePickerField,
    [TagType.single_option_list.value]: SingleSelectionField,
    [TagType.multi_options_list.value]: MultiSelectionField,
    [TagType.boolean.value]: BooleanField,
}

const renderQuestion = (question: CustomField, form: useFormReturn<PromptAndResponseCampaignForm>) => {
    const QuestionComponent = QUESTION_INPUT_MAP[question.tag_type]
    if (!QuestionComponent) return <></>
    return <QuestionComponent question={question} form={form} />
}

interface Props {
    form: useFormReturn<PromptAndResponseCampaignForm>
    questions: CustomField[]
    handleChangeContent: (contentName: Contents) => void
}

export const QuestionsContainer = ({ form, questions, handleChangeContent }: Props) => {
    const handleNavigateToNextPage = () => {
        const errors = validateFormValues(form.values, questions)
        if (Object.keys(errors).length > 0) {
            form.setFormErrors(errors)
            return
        }
        handleChangeContent(Contents.sendMessage)
    }

    return (
        <PersonalStory>
            {isFeatureEnabled("ff_ngg_personalized_messages_design") ? (
                <Heading>
                    <h3>Act now!</h3>
                    <h5>Fill out the form below to support us!</h5>
                </Heading>
            ) : (
                <>
                    <IconContainer>
                        <i className="fa fa-comment" />
                    </IconContainer>
                    <Subtitle>My personal story</Subtitle>
                </>
            )}
            <Container isSingleColumnLayout={isFeatureEnabled("ff_ngg_personalized_messages_design")}>
                {questions.map((question) => renderQuestion(question, form))}
            </Container>
            <StyledButton className="send-button" onClick={handleNavigateToNextPage}>
                Review and send <i className={"fa fa-arrow-right"} />
            </StyledButton>
        </PersonalStory>
    )
}
