import styled from "styled-components"
import { constants } from "QuorumGrassroots/constants"

export const PersonalStory = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1.5rem;

    color: black;

    max-width: 654px;
`

export const TitleAndSubtitle = styled.div`
    width: 100%;
    padding-bottom: 1rem;
    border-bottom: 1px solid ${constants.colors.gray[2]};
`

export const StyledButton = styled.button`
    min-width: 145px;
    border: 0;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    background: black;
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    font-size: 16px;
    font-weight: 500;
`
