import React, { useMemo } from "react"
import swal from "sweetalert"

import { api, ResponseNotOkError } from "@/api"

import { AsyncPaginate } from "react-select-async-paginate"
import { ReduxFormFieldWrapper } from "app/static/frontend/forms/components/ReduxFormFieldWrapper"

import { defaultFilters } from "@/services/charities"
import type { CandidApiCharity, CharityApiResponse } from "@/services/charities"

/**
 * Grassroots-compatible select for Charities. This provides the same functionality as the CharitySelect PieSelect, but
 * this select doesn't have grassroots-incompatible dependencies.
 *
 * The value of this select, expected by input.value and passed to
 * input.onChange, is the option object. For instance,
 *      { label: "American Red Cross", value: "53-0196605" }
 */
export const CharitySelect = ({
    accessibilityId,
    className,
    isClearable,
    dataCy,
    placeholder,
    displayErrorWithoutTouch,
    isDisabled,
    isLoading,
    label,
    labelStyle,
    meta: { error, warning, touched },
    input: { value, onChange },
    onMenuOpen,
    selectLabel,
    showAsterisk,
    style,
    tooltipText,
    defaultValue,
    description,
    organizationDesign,
}: CharitySelectProps) => {
    const loadOptions = useMemo(() => {
        // Store the cache in this closure so it's garbage-collected on component unmount
        const cache = {}
        return (query, options) => loadCharityOptions(query, options, cache)
    }, [])

    // We are using this to properly apply the organizationDesign color settings to the CharitySelect component
    // If we cannot access the organizationDesign from the props, we use it from the window global object
    const orgDesignStyle = {
        control: (base, state) => ({
            ...base,
            boxShadow:
                state.isFocused &&
                // @ts-expect-error organizationDesign is not defined on the Window, according to TS
                `0 0 0 1px ${organizationDesign ? organizationDesign.primary_color : window?.organization_design.primary_color}`,
            "&:focus-within": {
                border: "none",
            },
        }),
    }

    return (
        // @ts-expect-error There is a type issue in the ReduxFormFieldWrapper component
        <ReduxFormFieldWrapper
            accessibilityId={accessibilityId}
            additionalClassNames={className}
            className="select"
            dataCy={dataCy}
            displayErrorWithoutTouch={displayErrorWithoutTouch}
            error={error}
            label={label}
            labelStyle={labelStyle}
            showAsterisk={showAsterisk}
            style={style}
            tooltipText={tooltipText}
            touched={touched}
            warning={warning}
            description={description}
        >
            <AsyncPaginate
                loadOptions={loadOptions}
                onChange={onChange}
                value={value}
                placeholder={placeholder}
                isLoading={isLoading}
                noOptionsMessage={getNoOptionsMessage}
                isClearable={isClearable}
                // @ts-expect-error Potentially a bug - should be isMulti?
                multi={false}
                styles={orgDesignStyle}
                onMenuOpen={onMenuOpen}
                isDisabled={isDisabled}
                selectLabel={selectLabel}
                defaultValue={defaultValue}
                debounceTimeout={500}
                async
            />
        </ReduxFormFieldWrapper>
    )
}

export const charityApiToOption = (apiCharity: CandidApiCharity): CharityOption => {
    let label = apiCharity.organization.organization_name
    if (apiCharity.organization.also_known_as) {
        label += ` (${apiCharity.organization.also_known_as})`
    }

    return {
        label,
        value: apiCharity.organization.ein,
        address: `${apiCharity.geography.address_line_1} ${apiCharity.geography.address_line_2} ${apiCharity.geography.city} ${apiCharity.geography.county} ${apiCharity.geography.state} ${apiCharity.geography.zip}`,
    }
}

const getNoOptionsMessage = ({ inputValue }) =>
    inputValue && inputValue.length < 3 ? "Enter 3 or more characters" : "No options"

const loadCharityOptions = async (query: string, options: CharityOption[], cache: CharityCache) => {
    try {
        // To save API calls, don't search for queries less than 3
        if (query.length < 3) {
            return Promise.resolve({
                options: [],
                hasMore: false,
            })
        }

        // Before returning, check the cache if we already got the result
        const cacheResult = charityCacheGet(cache, query, options.length)
        if (cacheResult) {
            return Promise.resolve(cacheResult)
        }

        // Grab the response from the API and handle unsuccessful responses
        // We should filter out organizations that aren’t active, so make sure to keep the default filters here
        const response = await api.post(
            "/api/charities/",
            {
                filters: { ...defaultFilters },
                search_terms: query,
                size: 10,
                from: options.length,
            },
            { throwOnNotOk: false },
        )
        if (response.status === 404) {
            const extraOptions = {
                options: [],
                hasMore: false,
            }
            charityCacheSet(cache, query, options.length, extraOptions)
            return Promise.resolve(extraOptions)
        } else if (response.status === 429) {
            console.warn("Got 429 too many requests when fetching charities")

            return Promise.resolve({
                options: [],
                hasMore: false,
            })
        } else if (!response.ok) {
            // @ts-expect-error Should be throw new ResponseNotOkError(response)?
            throw ResponseNotOkError(response)
        }

        const data = (await response.json()) as CharityApiResponse
        const extraOptions = {
            options: data.hits.map(charityApiToOption),
            hasMore: !!data.hits.length && data.results_count > options.length + data.hits.length,
        }

        charityCacheSet(cache, query, options.length, extraOptions)

        return Promise.resolve(extraOptions)
    } catch (err) {
        // react-select-async-paginate likes to eat errors, so log them to make debugging easier
        console.warn("The below error was logged when loading charities")
        console.error(err)

        // Let the user know something went wrong. This could be network error, serialization error or bug. If it's
        // a network error attempt to grab the message text from the response.
        let message = "There was an error loading charities. You can still donate without a charity."
        try {
            if (err.response) {
                const responseData = await err.response.json()
                message = responseData?.message || message
            }
        } catch (err2) {
            console.error(err2)
        }
        swal({
            icon: "error",
            title: "Unable to load charities",
            text: message,
        })

        // Return empty options so react-select-async-paginate doesn't continually try to retry the query.
        return Promise.resolve({
            options: [],
            hasMore: true,
        })
    }
}

const charityCacheGet = (
    cache: CharityCache,
    inputQuery: string,
    numOptions: number,
): CharityLoadOptionsResult | null => {
    const query = inputQuery.toLowerCase()
    if (cache[query] && cache[query][numOptions]) {
        return cache[query][numOptions]
    } else {
        return null
    }
}

const charityCacheSet = (
    cache: CharityCache,
    inputQuery: string,
    numOptions: number,
    result: CharityLoadOptionsResult,
) => {
    const query = inputQuery.toLowerCase()
    if (!cache[query]) {
        cache[query] = {}
    }
    cache[query][numOptions] = result

    // Cache eviction
    const existingKeys = Object.keys(cache)
    let leftToEvict = 10 - existingKeys.length
    for (const key in existingKeys) {
        if (leftToEvict <= 0) {
            break
        }

        // Never evict a key that's a prefix of the current query or vice versa
        if (key.startsWith(query) || query.startsWith(key)) {
            continue
        }

        delete cache[key]
        leftToEvict -= 1
    }
}

export type CharitySelectProps = {
    accessibilityId?: string
    className?: string
    dataCy?: string
    defaultValue?: CharityOption | null
    displayErrorWithoutTouch?: boolean
    input: { value?: CharityOption; onChange?: (option: CharityOption) => void }
    isClearable?: boolean
    isDisabled?: boolean
    isLoading?: boolean
    label?: string
    labelStyle?: object
    meta: { error?: string; warning?: string; touched: boolean }
    onMenuOpen?: () => void
    placeholder?: string
    selectLabel?: string
    showAsterisk?: boolean
    style?: object
    tooltipText?: string
    description?: string
    organizationDesign?: Record<string, any>
}

type CharityOption = {
    /** How the Charity should be displayed to the user */
    label: string
    /** The EIN of the Charity */
    value: string
    /** Address of the charity */
    address: string
}

type CharityLoadOptionsResult = {
    options: CharityOption[]
    hasMore: boolean
}

type CharityCache = { [query: string]: { [count: number]: CharityLoadOptionsResult } }
