import React from "react"

import {
    Button,
    ButtonContainer,
    Container,
    Error,
    Label,
} from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/components/BooleanField.styles"
import { CustomField } from "@/types/djangio"
import { useFormReturn } from "QuorumGrassroots/campaign-forms/hooks/useForm"
import { PromptAndResponseCampaignForm } from "../PersonalizedMessages.helper"

interface Props {
    question: CustomField
    form: useFormReturn<PromptAndResponseCampaignForm>
}

export const BooleanField = ({ question, form }: Props) => {
    const handleChange = (value: boolean) => {
        form.setFieldValue(question.slug, value, true)
    }

    const currentValue = form.values[question.slug]

    return (
        <Container>
            <Label>{question.external_name}</Label>
            <ButtonContainer>
                <Button
                    isSelected={currentValue === true}
                    onClick={() => handleChange(currentValue === true ? null : true)}
                >
                    Yes
                </Button>
                <Button
                    isSelected={currentValue === false}
                    onClick={() => handleChange(currentValue === false ? null : false)}
                >
                    No
                </Button>
            </ButtonContainer>
            {form.errors[question.slug] && <Error>{form.errors[question.slug]}</Error>}
        </Container>
    )
}
