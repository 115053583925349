import { DatePickerInput } from "@mantine/dates"
import styled from "styled-components"

import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"

export const Input = styleWithOrgDesign(styled(DatePickerInput)`
    width: 100%;

    font-size: 14px;
    color: var(--custom-font-color);
    border-color: var(--custom-border-color);

    & .mantine-DatePickerInput-input {
        border-radius: 8px;
        background-color: white;
        border-color: var(----custom-font-color);

        min-height: 48px;
    }
`)
