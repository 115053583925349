import * as Sentry from "@sentry/browser"
import * as djangioActionCreators from "shared/djangio/action-creators"

import { isFeatureEnabled } from "shared/featureflags/helperFunctions"
import { postGrassrootsSupporterAction, completeCampaign } from "QuorumGrassroots/campaign-forms/action-creators.js"
import { formatUserInformationData } from "QuorumGrassroots/widgets/action-creators"
import { postDonationActionTypes } from "QuorumGrassroots/widgets/DonationForm/action-types"
import { donationSwalConfigs } from "QuorumGrassroots/swalConfigs"
import swal from "sweetalert"
import { getNestedObjectKey } from "shared/imports/sharedHelperFunctions"
import { selectWidgetFormRegisteredFields } from "QuorumGrassroots/widgets/selectors"
import { constants } from "QuorumGrassroots/constants"
import { loginActionTypes } from "QuorumGrassroots/framework/action-types"

const { GrassrootsRegistrationPage } = DjangIO.app.grassroots.models

const { CampaignType } = DjangIO.app.grassroots.campaign.types
const { TransactionMethodType } = DjangIO.app.ledger.types
const { PayrollDonationType } = DjangIO.app.grassroots.types

export const createDonation = ({ kwargs = {}, payload = {} } = {}) => {
    return djangioActionCreators.sendAction(DjangIO.app.ledger.models.StripePayment, postDonationActionTypes, {
        kwargs,
        action: "create_donation",
        method: "post",
        payload: {
            ...kwargs,
        },
    })
}

export const validate = (immutableValues, props) => {
    const errors = {}

    if (isFeatureEnabled("ff_pac_action_center_enhancements_terms_and_conditions")) {
        if (props.termsAndConditionsSwitch && !immutableValues.get("agreeTermsConditions")) {
            errors.agreeTermsConditions = "Must agree to Terms and Conditions" // TODO: i18n
        }
    } else {
        if (!immutableValues.get("agreeTermsConditions")) {
            errors.agreeTermsConditions = "Must agree to Terms and Conditions" // TODO: i18n
        }
    }

    const donationAmount = immutableValues.get("donationAmount")
    const donationAmountCustom = immutableValues.get("donationAmountCustom")
    if (!donationAmount && !(donationAmount === 0 && donationAmountCustom)) {
        errors.donationAmount = "You must select a donation amount"
    }

    return errors
}

export const onSubmit = async (immutableValues, dispatch, props) => {
    const amount = props.amountToSubmit
    const registration_page = GrassrootsRegistrationPage.foreignKey(props.donationFormId)
    const transaction_method_type = immutableValues.get("donationMethod")
    const payroll_donation_type = immutableValues.get("payrollDonationType")
    const payroll_frequency_type = props.payrollFrequencyType
    const enabled_pac_match = Boolean(props.enabledPacMatch)
    const charity = immutableValues.get("charity")
    const pac_charity_additional_info = immutableValues.get("pac_charity_additional_info")
    const pac_charity_anonymous = immutableValues.get("pac_charity_anonymous")
    const percentage_label = immutableValues.get("percentage_label")
    const payroll_decimal_value = immutableValues.get("payroll_decimal_value")
    const customAfterRegistrationJavascript = props.customAfterRegistrationJavascript
    const { stripeObject, stripeCard } = immutableValues.get("creditCardForm") || {}

    const isPayrollDonation = transaction_method_type === TransactionMethodType.payroll.value
    const isCheckDonation = transaction_method_type === TransactionMethodType.check.value

    const isPercentagePayrollDonation =
        isPayrollDonation && payroll_donation_type === PayrollDonationType.percentage.value

    // if the donation method is payroll, create a GrassrootsSupporterAction
    if (isPayrollDonation || isCheckDonation) {
        const res = await dispatch(
            postGrassrootsSupporterAction({
                uniqueWidgetId: props.uniqueWidgetId,
                campaign: { campaign_type: CampaignType.authorized_contribution.value },
                payload: {
                    amount: isPercentagePayrollDonation ? undefined : amount,
                    transaction_method_type,
                    payroll_frequency_type,
                    payroll_donation_type,
                    charity,
                    enabled_pac_match,
                    pac_charity_additional_info,
                    pac_charity_anonymous,
                    registration_page,
                    ...(isPercentagePayrollDonation
                        ? {
                              percentage_label,
                              payroll_decimal_value,
                          }
                        : {}),
                },
                shouldCompleteCampaign: true,
                customAfterRegistrationJavascript,
            }),
        )

        // Update supporter information, so custom fields, etc get updated
        // This also gets the new userdata and puts it in the store
        await dispatch(updateInformationForDonationForm(immutableValues))
        await dispatch(completeCampaign(undefined, props.uniqueWidgetId))

        return res
    }

    // otherwise it's a credit card donation
    // we can't confirm the payment if we don't have the required Stripe objects
    if (!stripeObject || !stripeCard) {
        await swal(donationSwalConfigs.internalPaymentError)
        const error = "Cannot submit payment; Stripe failed to initialize correctly"
        Sentry.captureException(new Error(error))
        return Promise.reject(error)
    }

    // submit the form with donation information and receive the client secret in return
    try {
        // post a new payment to the backend, which will contact Stripe
        // to create a PaymentIntent
        const {
            data: { client_secret },
        } = await dispatch(
            createDonation({
                kwargs: {
                    amount,
                    donation_form_id: props.donationFormId,
                },
            }),
        )

        // use the client secret for the PaymentIntent we created, supplied
        // by the backend, to confirm the payment with Stripe
        const payment = await stripeObject.confirmCardPayment(client_secret, { payment_method: { card: stripeCard } })

        if (payment.error) {
            const swalConfig =
                payment.error.type === "validation_error"
                    ? donationSwalConfigs.stripeValidationError
                    : donationSwalConfigs.stripeProcessingError

            return swal({
                ...swalConfig,
                text: payment.error.message,
            })
        } else {
            await swal(donationSwalConfigs.donationSuccess)
            await dispatch(
                postGrassrootsSupporterAction({
                    uniqueWidgetId: props.uniqueWidgetId,
                    campaign: { campaign_type: CampaignType.authorized_contribution.value },
                    payload: {
                        amount,
                        transaction_method_type,
                        payroll_frequency_type,
                        charity,
                        enabled_pac_match,
                        pac_charity_additional_info,
                        pac_charity_anonymous,
                        registration_page,
                    },
                    shouldCompleteCampaign: true,
                    customAfterRegistrationJavascript,
                }),
            )
            await dispatch(completeCampaign(undefined, props.uniqueWidgetId))
        }

        // maybe the payment succeeded, maybe more info is needed, maybe it failed
        // check the payment object for more details
    } catch (error) {
        BACKENDERROR(error)

        const humanReadableMessage = getNestedObjectKey(error, ["response", "data", "message"])
        if (humanReadableMessage) {
            return swal({
                ...donationSwalConfigs.internalPaymentError,
                text: humanReadableMessage,
            })
        } else {
            return swal(donationSwalConfigs.internalPaymentError)
        }
    }
}

export const onSubmitFail = (errors, dispatch, submitError) => {
    if (submitError) {
        const error = { ...submitError }

        if (error.response && error.response.status === 418) {
            error.response.data = { ...error.response.data, issue: "This donation amount is already active" }
        }

        BACKENDERROR(error)
    }
}

/**
 * Update supporter information.
 *
 * This is similar to updateInformation in QuorumGrassroots/widgets/action-creators.js which is used in the
 * registration form.
 */
const updateInformationForDonationForm = (immutableFormValues) => (dispatch, getState) => {
    const registeredFormFields = selectWidgetFormRegisteredFields(getState(), {
        form: constants.donationFormReduxFormKey,
    })
    const supporterId = getState().framework.getIn(["userdata", "id"])

    const formValues = immutableFormValues
        .filter((_, key) => registeredFormFields.includes(key))
        .set("id", supporterId)
        .toJS()

    const data = formatUserInformationData(formValues, getState())

    return dispatch(
        djangioActionCreators.sendAction(DjangIO.app.grassroots.models.NewSupporter, loginActionTypes, {
            action: "grassroots_update_information",
            method: "patch",
            kwargs: data,
        }),
    )
}
